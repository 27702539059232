const general = [
  { key: "drug_row", label: "Drug Row Template", src: "drug_row.png" },
  {
    key: "comment_name_value_row",
    label: "Row with columns",
    src: "comment_name_value_row.png",
  },
  {
    key: "group_3_columns",
    label: "Group row with columns",
    src: "group_3_columns.png",
  },
];

const compact_textbox = [
  {
    key: "compact_textbox_row",
    label: "Section name with compact textboxes",
    src: "compact_textbox_row.png",
  },
];

const examples = {
  general,
  compact_textbox,
};

export default examples;
