import { t } from "i18next";
import { Navigate, Route, Routes } from "react-router-dom";
import PrescriptionTemplateCreate from "../pages/admin/PrescriptionTemplates/components/template-create.page";
import PrescriptionTemplateEdit from "../pages/admin/PrescriptionTemplates/components/template-edit";
import PrescriptionTemplatesList from "../pages/admin/PrescriptionTemplates/prescription-templates.page";
import EmbedDashboard from "../pages/boss/Analytics/Analytics.page";
import DiagnosticSummary from "../pages/boss/Diagnostics/diagnostics.page";
import DoctorCordinator from "../pages/boss/DoctotCordinator/doctorCordinator";
import InstantVCPage from "../pages/boss/InstantVC/InstantVC.page";
import InstantVCDoctorSummaryPage from "../pages/boss/InstantVC/InstantVCDoctor.page";
import BossLandingPage from "../pages/boss/landing.page";
import PaymentSummary from "../pages/boss/PaymentSummary/paymentSummary.page";
import PharmacyOrderSummary from "../pages/boss/Pharmacy/order-fulfilment.page";
import BOSSDesktopLayout from "../shared/layouts/boss-desktop.layout";
import {
  ROUTE_BOSS_ANALYTICS,
  ROUTE_BOSS_DASHBAORD,
  ROUTE_BOSS_DOCTOR_CORDINATOR,
  ROUTE_BOSS_INSTANT_VC,
  ROUTE_BOSS_INSTANT_VC_DOCTOR,
  ROUTE_BOSS_PHARMACY_FULFIL,
  ROUTE_DIAGNOSTICS_ORDERS,
  ROUTE_PAYMENT_SUMMARY,
  ROUTE_PRESCRIPTION_TEMPLATES,
  ROUTE_PRESCRIPTION_TEMPLATE_ACTION,
  ROUTE_PRESCRIPTION_TEMPLATE_CREATE
} from "../shared/routes/boss.routes.constants";

const ROUTES: any[] = [
  {
    name: "Dashbaord",
    route: ROUTE_BOSS_DASHBAORD,
    component: BossLandingPage,
  },
  {
    name: "Analytics",
    route: ROUTE_BOSS_ANALYTICS,
    component: EmbedDashboard,
  },
  {
    name: "Instant Video Calls",
    route: ROUTE_BOSS_INSTANT_VC,
    component: InstantVCPage,
  },
  {
    name: "Instant Doctor Summary",
    route: ROUTE_BOSS_INSTANT_VC_DOCTOR,
    component: InstantVCDoctorSummaryPage,
  },
  {
    name: "Pharmacy Order Summary",
    route: ROUTE_BOSS_PHARMACY_FULFIL,
    component: PharmacyOrderSummary,
  },
  {
    name: "Doctor Cordinator",
    route: ROUTE_BOSS_DOCTOR_CORDINATOR,
    component: DoctorCordinator,
  },
  {
    name: "Diagnostic Order Summary",
    route: ROUTE_DIAGNOSTICS_ORDERS,
    component: DiagnosticSummary,
  },
  {
    name: "Payment Summary",
    route: ROUTE_PAYMENT_SUMMARY,
    component: PaymentSummary,
  },
  {
    name: "Prescription Templates",
    route: ROUTE_PRESCRIPTION_TEMPLATES,
    component: PrescriptionTemplatesList,
  },
  {
    name: t("Prescriptions.AddTemplate"),
    route: ROUTE_PRESCRIPTION_TEMPLATE_CREATE,
    component: PrescriptionTemplateCreate,
  },
  {
    name: t("Prescriptions.EditTemplate"),
    route: ROUTE_PRESCRIPTION_TEMPLATE_ACTION(":id", "edit"),
    component: PrescriptionTemplateEdit,
  },
];


const BossRoutes = () => {
  const RoutesPermitted = ROUTES;
  return (
    <BOSSDesktopLayout>
      <Routes>
        {RoutesPermitted.map((route: any) => {
          return <Route path={route.route} element={route.component} />;
        })}
        <Route element={<Navigate replace to={ROUTE_BOSS_DASHBAORD} />} />
        {/* <Redirect to={ROUTE_BOSS_DASHBAORD} /> */}
      </Routes>
    </BOSSDesktopLayout>
  );
};
export default BossRoutes;
