// convert into upper case
export const capitalizeFirstLetter = (text: string) => {
  if (!text) return "";
  return text
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

// truncate letters
export const truncateString = (
  text: string,
  maxLength: number = 24
): string => {
  if (text.length <= maxLength) {
    return text;
  }
  return text.slice(0, maxLength) + "...";
};

// converts string to base64 string
export const encodeBase64 = (s: string) => {
  return window.btoa(s);
};

// converts base64 string to string
export const decodeBase64 = (s: string) => {
  return window.atob(s);
};

export function decodeJWT(token: string) {
  const [_header, payload, _signature] = token.split(".");

  // Base64URL decode the payload (which is Base64 encoded)
  const decodedPayload = JSON.parse(
    atob(payload.replace(/-/g, "+").replace(/_/g, "/"))
  );

  return decodedPayload;
}

export const debouncedSearch = debounce(
  (
    searchValue: string,
    fetchFn: (cursor: string, search: string) => Promise<any>
  ) => {
    fetchFn("MA==", searchValue);
  },
  800
);

export function debounce<T extends (...args: any[]) => any>(
  func: T,
  wait: number
): (...args: Parameters<T>) => void {
  let timeout: ReturnType<typeof setTimeout>;
  return (...args: Parameters<T>) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
}

// date formatter
export const dateFormatter = (dateString: any) => {
  let date = new Date(dateString);

  let month = date.getMonth() + 1;
  let day = date.getDate();
  let year = date.getFullYear();

  let formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
};

export function getDateAfterDays(
  dateString: string,
  daysToAdd: number
): string | null {
  try {
    // if inputs are not valid
    if (!dateString || typeof daysToAdd !== "number") {
      console.error("Invalid inputs:", { dateString, daysToAdd });
      return null;
    }

    const date = new Date(dateString);

    // if date is not valid
    if (isNaN(date.getTime())) {
      console.error("Invalid date string:", dateString);
      return null;
    }

    const newDate = new Date(date);
    newDate.setDate(date.getDate() + daysToAdd);

    return newDate.toISOString();
  } catch (error) {
    console.error("Error in getDateAfterDays:", error);
    return null;
  }
}
