import React from "react";
// component imports
import CompactSelectCOmponent from "../../../Common/CompactSelect";
import CompactTableCheckBoxComponent from "../../../Common/CompactTableCheckBoxComponent";
import DateComponent from "../../../Common/DateComponent";
import RadioComponent from "../../../Common/RadioComponent";
import TextareaComponent from "../../../Common/TextareaComponent";
import TextFormatterComponent from "../../../Common/TextFormatterComponent";

class CompactTableColumnViewComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDirty: false,
      errorStatus: null,
    };
    if (props.indColumnData.formula) {
      props.setConfigForFormula({
        colIndex: this.props.colIndex,
        localvar: props.indColumnData.localvar.split(","),
        formula: props.indColumnData.formula,
      });
    }
  }

  emitTextboxValue = (event) => {
    if (!this.state.isDirty) {
      this.setState({ isDirty: true }, () =>
        this.props.emitTextboxValue(event)
      );
    } else this.props.emitTextboxValue(event);
  };

  emitFocusEvent = (event) => {
    this.props.emitFocusEvent(event);
  };

  getEmitedAddNewRowObjectData = (event) => {
    this.props.emitAddNewRowEvent(event);
  };

  getEmitedDeleteRowObjectData = (event) => {
    this.props.emitDeleteRowObjectData(event);
  };

  getEmitedFormalLocalVar = (event) => {
    // console.log(event);
    this.props.emitFormalLocalVar(event);
  };

  getEmitedSuggestionEvent = (event) => {
    // console.log(event);
    this.props.emitSuggestionEvent(event);
  };

  setErrorStatus = (errorStatus) => {
    if (this.state.errorStatus !== errorStatus) {
      this.setState({ errorStatus }, () =>
        this.props.emitErrorStatus(errorStatus)
      );
    }
  };

  changeValue = (name, event) => {
    event.preventDefault();
    let value = event.currentTarget.textContent;
    let key = name;
    if (!this.state.isDirty) {
      this.setState({ isDirty: true }, () =>
        this.props.emitTextboxValue({ name: key, value: value })
      );
    } else {
      this.props.emitTextboxValue({ name: key, value: value });
    }
  };

  getEmitedLoadOptionsEvent = (event, callback) => {
    this.props.emitLoadOptionsEvent(event, callback);
  };

  render() {
    const {
      type,
      label,
      name,
      placeholder,
      labelSize,
      size,
      options,
      pattern,
      formula,
      colId,
      localvar,
      value,
      values,
      suggestion,
      readOnly,
      offset,
      validation,
    } = this.props.indColumnData;
    let { isDirty } = this.state;

    // todo items destructure
    return (
      <React.Fragment>
        {type === "header" && (
          <>
            {readOnly ? (
              <th
                className="pt-3-half  bg-light"
                style={{ padding: "0.1rem", width: "20%" }}
              >
                {label}
              </th>
            ) : (
              <td
                className="pt-3-half"
                contenteditable="true"
                name={name}
                validation={validation}
                checkForm={this.props.checkForm}
                isDirty={isDirty}
                rowType={this.props.rowType}
                placeholder={placeholder}
                label={label}
                labelSize={labelSize}
                formula={formula}
                colId={colId}
                localvar={localvar}
                size={size}
                offset={offset}
                readOnly={readOnly}
                suggestion={suggestion}
                value={value}
                emitErrorStatus={this.setErrorStatus.bind(this)}
                onBlur={this.changeValue.bind(this, name)}
                emitFormalLocalVar={this.getEmitedFormalLocalVar.bind(this)}
                emitSuggestionEvent={this.getEmitedSuggestionEvent.bind(this)}
                style={{ marginRight: "10px", padding: "0.1rem", width: "20%" }}
              >
                {value}
              </td>
            )}
          </>
        )}
        {type === "textbox" && (
          <React.Fragment>
            <td
              className="pt-3-half"
              contenteditable="true"
              name={name}
              validation={validation}
              checkForm={this.props.checkForm}
              isDirty={isDirty}
              rowType={this.props.rowType}
              placeholder={placeholder}
              label={label}
              labelSize={labelSize}
              formula={formula}
              colId={colId}
              localvar={localvar}
              size={size}
              offset={offset}
              readOnly={readOnly}
              suggestion={suggestion}
              value={value}
              emitErrorStatus={this.setErrorStatus.bind(this)}
              onBlur={this.changeValue.bind(this, name)}
              emitFormalLocalVar={this.getEmitedFormalLocalVar.bind(this)}
              emitSuggestionEvent={this.getEmitedSuggestionEvent.bind(this)}
              style={{ marginRight: "10px", padding: "0.1rem" }}
            >
              {value}
            </td>
          </React.Fragment>
        )}
        {type === "textFormatter" && (
          <TextFormatterComponent
            name={name}
            validation={validation}
            checkForm={this.props.checkForm}
            rowType={this.props.rowType}
            placeholder={placeholder}
            label={label}
            labelSize={labelSize}
            isDirty={isDirty}
            emitErrorStatus={this.setErrorStatus.bind(this)}
            offset={offset}
            size={size}
            pattern={pattern}
            readOnly={readOnly}
            type={type}
            value={value}
            emitTextboxValue={this.emitTextboxValue.bind(this)}
          ></TextFormatterComponent>
        )}
        {type === "textarea" && (
          <TextareaComponent
            name={name}
            validation={validation}
            checkForm={this.props.checkForm}
            rowType={this.props.rowType}
            placeholder={placeholder}
            label={label}
            isDirty={isDirty}
            emitErrorStatus={this.setErrorStatus.bind(this)}
            labelSize={labelSize}
            size={size}
            offset={offset}
            type={type}
            readOnly={readOnly}
            value={value}
            emitTextboxValue={this.emitTextboxValue.bind(this)}
            emitAddNewRowObjectData={this.getEmitedAddNewRowObjectData.bind(
              this
            )}
            emitDeleteRowObjectData={this.getEmitedDeleteRowObjectData.bind(
              this
            )}
          ></TextareaComponent>
        )}
        {type === "selection" && (
          <CompactSelectCOmponent
            name={name}
            validation={validation}
            checkForm={this.props.checkForm}
            placeholder={placeholder}
            isDirty={isDirty}
            emitErrorStatus={this.setErrorStatus.bind(this)}
            label={label}
            rowType={this.props.rowType}
            offset={offset}
            labelSize={labelSize}
            size={size}
            type={type}
            value={value}
            options={options}
            emitTextboxValue={this.emitTextboxValue.bind(this)}
          ></CompactSelectCOmponent>
        )}
        {/*type === "autoComplete" &&
                    <AutocompleteComponent
                        name={name}
                        validation={validation}
                        checkForm={this.props.checkForm}
                        placeholder={placeholder}
                        showType={showType}
                        isDirty={isDirty}
                        emitErrorStatus={this.setErrorStatus.bind(this)}
                        label={label}
                        rowType={this.props.rowType}
                        offset={offset}
                        labelSize={labelSize}
                        multiselect={multiselect}
                        autoKey={autoKey}
                        size={size}
                        type={type}
                        value={value}
                        options={options}
                        emitLoadOptionsEvent={this.getEmitedLoadOptionsEvent.bind(this)}
                        emitFocusEvent={this.emitFocusEvent.bind(this)}
                        emitTextboxValue={this.emitTextboxValue.bind(this)}></AutocompleteComponent>
            */}
        {type === "date" && (
          <DateComponent
            name={name}
            validation={validation}
            checkForm={this.props.checkForm}
            placeholder={placeholder}
            isDirty={isDirty}
            emitErrorStatus={this.setErrorStatus.bind(this)}
            label={label}
            rowType={this.props.rowType}
            offset={offset}
            labelSize={labelSize}
            size={size}
            type={type}
            value={value}
            emitFocusEvent={this.emitFocusEvent.bind(this)}
            emitTextboxValue={this.emitTextboxValue.bind(this)}
          ></DateComponent>
        )}
        {type === "radio" && (
          <td style={{ padding: "0.1rem", marginRight: "10px" }}>
            <RadioComponent
              name={name}
              validation={validation}
              checkForm={this.props.checkForm}
              placeholder={placeholder}
              isDirty={isDirty}
              emitErrorStatus={this.setErrorStatus.bind(this)}
              label={label}
              rowType={this.props.rowType}
              offset={offset}
              labelSize={labelSize}
              size={size}
              type={type}
              value={value}
              options={options}
              emitTextboxValue={this.emitTextboxValue.bind(this)}
            ></RadioComponent>
          </td>
        )}
        {type === "checkbox" && (
          <CompactTableCheckBoxComponent
            name={name}
            validation={validation}
            checkForm={this.props.checkForm}
            placeholder={placeholder}
            isDirty={isDirty}
            emitErrorStatus={this.setErrorStatus.bind(this)}
            label={label}
            rowType={this.props.rowType}
            offset={offset}
            labelSize={labelSize}
            size={size}
            type={type}
            value={value}
            values={values}
            options={options}
            emitTextboxValue={this.emitTextboxValue.bind(this)}
          ></CompactTableCheckBoxComponent>
        )}
      </React.Fragment>
    );
  }
}

export default CompactTableColumnViewComponent;
