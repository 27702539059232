import { SettingIcon } from "@100mslive/react-icons";
import {
  selectIsLocalVideoEnabled,
  selectLocalPeer,
  useAVToggle,
  useHMSStore,
  useParticipantList,
  usePreviewJoin,
} from "@100mslive/react-sdk";
import {
  Avatar,
  Button,
  Flex,
  IconButton,
  Loading,
  styled,
  StyledVideoTile,
  Text,
  useBorderAudioLevel,
  useTheme,
  Video,
} from "@100mslive/react-ui";
import { useCallback, useEffect, useState } from "react";

import { AudioVideoToggle } from "./AudioVideoToggle";
import { ConnectionIndicator } from "./Connection/ConnectionIndicator";
import {
  UserPreferencesKeys,
  useUserPreferences,
} from "./hooks/useUserPreferences";
import Settings from "./Settings";

const defaultPreviewPreference = {
  name: "",
  isAudioMuted: false,
  isVideoMuted: false,
};

const Preview = ({
  token,
  onJoin,
  env,
  skipPreview,
  initialName,
  loginType,
  appointmentId,
}) => {
  const [previewPreference, setPreviewPreference] = useUserPreferences(
    UserPreferencesKeys.PREVIEW,
    defaultPreviewPreference
  );
  const [name] = useState(initialName || previewPreference.name);
  const { isLocalAudioEnabled, isLocalVideoEnabled } = useAVToggle();

  const { participantsByRoles } = useParticipantList();
  const { enableJoin, preview, join } = usePreviewJoin({
    name,
    token,
    initEndpoint: env ? `https://${env}-init.100ms.live/init` : undefined,
    initialSettings: {
      isAudioMuted: skipPreview ? true : previewPreference.isAudioMuted,
      isVideoMuted: skipPreview ? true : previewPreference.isVideoMuted,
    },
    captureNetworkQualityInPreview: true,
  });

  const savePreferenceAndJoin = useCallback(() => {
    setPreviewPreference({
      name,
      isAudioMuted: !isLocalAudioEnabled,
      isVideoMuted: !isLocalVideoEnabled,
    });
    join();
    onJoin && onJoin();
  }, [
    join,
    isLocalAudioEnabled,
    isLocalVideoEnabled,
    name,
    setPreviewPreference,
    onJoin,
  ]);
  useEffect(() => {
    if (token) {
      if (skipPreview) {
        savePreferenceAndJoin();
      } else {
        preview();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, skipPreview]);

  return (
    <Container>
      {loginType === "doctor" ? (
        <>
          {
            <Text css={{ my: "1rem" }}>
              {" "}
              Click on <b>'Join Video Call'</b> to start the consultation
            </Text>
          }
        </>
      ) : (
        <>
          {participantsByRoles &&
          Object.keys(participantsByRoles).length !== 0 &&
          participantsByRoles.hasOwnProperty("doctor-360") ? (
            <div
              style={{
                backgroundColor: "#003873",
                borderRadius: "5px",
                padding: "10px 10px",
              }}
            >
              <Text style={{ fontSize: "18px" }}>
                {" "}
                {"Doctor"} has joined the video call{" "}
              </Text>
              <Text>
                Click on <b>'Join Video Call'</b> to start the consultation{" "}
              </Text>
            </div>
          ) : (
            <>
              <Text css={{ my: "1rem" }} variant="h6">
                {" "}
                Video Preview Test your audio and video here{" "}
              </Text>
              <Text style={{ marginTop: "-10px" }}>
                {" "}
                Click on <b>'Join Video Call'</b> to start the consultation
              </Text>
            </>
          )}
        </>
      )}
      <div style={{ height: "30px" }}></div>
      <PreviewTile name={name} />

      <Flex direction="column" align="center">
        {/* <Text css={{ mb: "1rem" }}>What's your name?</Text> */}
        <Flex
          as="form"
          direction="column"
          align="center"
          onSubmit={(e) => {
            e.preventDefault();
            savePreferenceAndJoin();
          }}
        >
          {/* <Input
            css={{ mb: "1rem" }}
            autoComplete="name"
            type="text"
            required
            autoFocus
            maxLength={100}
            disabled
            value={name}
            onChange={e => setName(e.target.value)}
            data-testid="preview_name_field"
          /> */}
          <Button
            type="submit"
            className={"doctor-secondary-button cursor mt20"}
            disabled={!name || !enableJoin}
            data-testid="preview_join_btn"
          >
            Join Video Call
          </Button>
        </Flex>
      </Flex>
    </Container>
  );
};

const PreviewTile = ({ name }) => {
  const localPeer = useHMSStore(selectLocalPeer);
  const borderAudioRef = useBorderAudioLevel(localPeer?.audioTrack);
  const isVideoOn = useHMSStore(selectIsLocalVideoEnabled);
  const {
    aspectRatio: { width, height },
  } = useTheme();
  return (
    <StyledVideoTile.Container
      css={{
        aspectRatio: width / height,
        width: "unset",
        height: "min(360px, 60vh)",
        "@sm": {
          height: "unset",
          width: "min(360px, 90%)",
          maxWidth: "90%",
        },
      }}
      ref={borderAudioRef}
    >
      {localPeer ? (
        <>
          <ConnectionIndicator isTile peerId={localPeer?.id} />
          <Video
            mirror={true}
            trackId={localPeer?.videoTrack}
            data-testid="preview_tile"
          />
          {!isVideoOn ? (
            <Avatar name={name} data-testid="preview_avatar_tile" />
          ) : null}
          <StyledVideoTile.AttributeBox css={controlStyles}>
            <AudioVideoToggle compact />
          </StyledVideoTile.AttributeBox>
          <Settings>
            <StyledVideoTile.AttributeBox css={settingStyles}>
              <IconButton data-testid="preview_setting_btn">
                <SettingIcon />
              </IconButton>
            </StyledVideoTile.AttributeBox>
          </Settings>
        </>
      ) : (
        <Loading size={100} />
      )}
    </StyledVideoTile.Container>
  );
};

const Container = styled("div", {
  borderRadius: "$2",
  backgroundColor: "$previewBg",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "2rem 6rem",
  "@md": {
    borderRadius: "0",
    padding: "0",
    width: "100%",
    height: "100%",
  },
});

const controlStyles = {
  bottom: "10px",
  left: "50%",
  transform: "translate(-50%, 0)",
  display: "flex",
  "& > * + *": {
    marginRight: "0",
    marginLeft: "0.5rem",
  },
};

const settingStyles = {
  bottom: "10px",
  right: "20px",
};

export default Preview;
