import { Navigate, Route, Routes } from "react-router-dom";
import AppointmentSupportPage from "../pages/patient/Appointment/appointment-support.page";
import PatientAppointmentPage from "../pages/patient/Appointment/appointment.page";
import PatientAppointmentDetail from "../pages/patient/Appointment/components/appointment-detail.page";
import LinkPatient from "../pages/patient/Dashboard/link-patient.page";
import PatientDashboardPage from "../pages/patient/Dashboard/patient-dashboard.page";
import PatientDocumentsPage from "../pages/patient/Documents/documents.page";
import PatientDesktopLayout from "../shared/layouts/patientdesktop.layout";
import {
  ROUTE_PATIENT_APPOINTMENTS,
  ROUTE_PATIENT_APPOINTMENT_DETAILS,
  ROUTE_PATIENT_APPOINTMENT_SUPPORT,
  ROUTE_PATIENT_DASHBOARD,
  ROUTE_PATIENT_DOCUMENTS,
  ROUTE_PATIENT_LINKING,
} from "../shared/routes/patient.routes.constants";

const ROUTES: any[] = [
  {
    name: "Patient Dashboard",
    route: ROUTE_PATIENT_DASHBOARD,
    component: PatientDashboardPage,
  },
  {
    name: "Patient Documents",
    route: ROUTE_PATIENT_DOCUMENTS(":type"),
    component: PatientDocumentsPage,
  },
  {
    name: "Patient Appointments",
    route: ROUTE_PATIENT_APPOINTMENTS,
    component: PatientAppointmentPage,
  },
  {
    name: "Patient Appointment Details",
    route: ROUTE_PATIENT_APPOINTMENT_DETAILS(":id"),
    component: PatientAppointmentDetail,
  },
  {
    name: "Patient Appointment Support",
    route: ROUTE_PATIENT_APPOINTMENT_SUPPORT(":id"),
    component: AppointmentSupportPage,
  },
  {
    name: "Patient Linking Page",
    route: ROUTE_PATIENT_LINKING(":number"),
    component: LinkPatient,
  },
];

const PatientRoutes = () => {
  const RoutesPermitted = ROUTES;
  return (
    <PatientDesktopLayout>
      <Routes>
        {RoutesPermitted.map((route: any) => {
          return <Route path={route.route} element={route.component} />;
        })}
        {/* <Redirect to={ROUTE_PATIENT_DASHBOARD} /> */}
        <Route element={<Navigate replace to={ROUTE_PATIENT_DASHBOARD} />} />
      </Routes>
    </PatientDesktopLayout>
  );
};
export default PatientRoutes;
