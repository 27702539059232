import { HangUpIcon } from "@100mslive/react-icons";
import {
  selectPermissions,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Popover,
  styled,
  Tooltip,
} from "@100mslive/react-ui";
import axios from "axios";
import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  setVideoRoom,
  submitCallLogs,
} from "../../../../redux/reducers/appointment.slice";
import { ROUTE_PUBLIC_APPOINTMENT_VIEW } from "../../../../shared/routes/route.constants";
import {
  DialogCheckbox,
  DialogContent,
  DialogRow,
} from "../primitives/DialogContent";
export const LeaveRoom = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [showEndRoomModal, setShowEndRoomModal] = useState(false);
  const [lockRoom, setLockRoom] = useState(false);
  const permissions = useHMSStore(selectPermissions);
  const hmsActions = useHMSActions();
  const { appointment } = useSelector((state) => state.appointment);
  const { profile } = useSelector((state) => state.profile);
  const { currentApplication } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const redirectToLeavePage = async () => {
    try {
      // Get the user's location
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          if (params["source"] === "guest") {
            window.location.href = ROUTE_PUBLIC_APPOINTMENT_VIEW(
              params["id"],
              params["participant_id"],
              "guest"
            );
            return;
          }

          // Optional: Get IP address using a different service if needed
          // const res = await axios.get("https://api.ipify.org?format=json");
          // const ip = res.data.ip;

          let data = {
            event: "LEFT",
            room: appointment?.room_id,
            user_type: currentApplication,
            user_id: profile?.id,
            device_type: "Browser",
            device_name: window.navigator.appCodeName,
            // device_ip: ip, // Use this if IP is required and retrieved
            device_os: window.navigator.appVersion,
            location: { latitude, longitude },
            reason: "Left with consent",
          };

          if (params["source"] === "patient") {
            dispatch(submitCallLogs(data));
            navigate.goBack();
            return;
          } else {
            dispatch(submitCallLogs(data));
            dispatch(setVideoRoom(null));
            return;
          }
        },
        (error) => {
          console.error("Error getting location", error);
          // Handle error or fallback logic here
        }
      );
    } catch (error) {
      console.error("Error in redirectToLeavePage", error);
      // Handle error or fallback logic here
    }
  };
  const leaveRoom = () => {
    hmsActions.leave();
    redirectToLeavePage();
  };

  const endRoom = () => {
    hmsActions.endRoom(lockRoom, "End Room");
    redirectToLeavePage();
  };

  return (
    <Fragment>
      {permissions.endRoom ? (
        <Popover.Root>
          <Popover.Trigger asChild>
            <LeaveIconButton
              variant="danger"
              key="LeaveRoom"
              data-testid="leave_room_btn"
            >
              <Tooltip title="Leave Room">
                <Box>
                  <HangUpIcon key="hangUp" />
                </Box>
              </Tooltip>
            </LeaveIconButton>
          </Popover.Trigger>
          <Popover.Content sideOffset={10}>
            <Button
              variant="standard"
              css={{ w: "100%" }}
              onClick={() => {
                setShowEndRoomModal(true);
              }}
              data-testid="end_room_btn"
            >
              End Room
            </Button>
            <Button
              variant="danger"
              css={{ mt: "$4" }}
              onClick={leaveRoom}
              data-testid="just_leave_btn"
            >
              Just Leave
            </Button>
          </Popover.Content>
        </Popover.Root>
      ) : (
        <LeaveIconButton onClick={leaveRoom} variant="danger" key="LeaveRoom">
          <Tooltip title="Leave Room">
            <Box>
              <HangUpIcon key="hangUp" />
            </Box>
          </Tooltip>
        </LeaveIconButton>
      )}

      <Dialog.Root
        open={showEndRoomModal}
        onOpenChange={(value) => {
          if (!value) {
            setLockRoom(false);
          }
          setShowEndRoomModal(value);
        }}
      >
        <DialogContent title="End Room" Icon={HangUpIcon}>
          <DialogCheckbox
            id="lockRoom"
            title="Disable future joins"
            value={lockRoom}
            onChange={setLockRoom}
          />
          <DialogRow justify="end">
            <Button
              variant="danger"
              onClick={endRoom}
              data-testid="lock_end_room"
            >
              End Room
            </Button>
          </DialogRow>
        </DialogContent>
      </Dialog.Root>
    </Fragment>
  );
};

const LeaveIconButton = styled(IconButton, {
  color: "$white",
  width: "$15",
  mx: "$4",
  bg: "$error",
  "&:not([disabled]):hover": {
    bg: "$errorTint",
  },
  "&:not([disabled]):active": {
    bg: "$errorTint",
  },
});
