export const PrescriptionEditorStatusInProgress = "IN_PROGRESS";
export const PrescriptionEditorStatusReWork = "RE_WORK";
export const PrescriptionEditorStatusSubmitted = "SUBMITTED";
export const PrescriptionReviewerPending = "PENDING_REVIEW";
export const PrescriptionReviewerApproved = "APPROVED";
export const PrescriptionReviewerUpdateRejected = "REJECTED_UPDATE";
export const PrescriptionReviewerRejected = "REJECTED";
export const PrescriptionPatientNew = "NEW";

export const DateFormat = "DD-MM-YYYY";
export const PrescriptionEditorStatuses = [
  {
    key: PrescriptionEditorStatusInProgress,
    name: "In Progress",
  },
  {
    key: PrescriptionEditorStatusSubmitted,
    name: "Submitted",
  },
  {
    key: PrescriptionEditorStatusReWork,
    name: "Re Work",
  },
];
export const PrescriptionReviewStatuses = [
  {
    key: PrescriptionReviewerApproved,
    name: "Approved",
  },
  {
    key: PrescriptionReviewerPending,
    name: "Pending Review",
    cannotSelect: true,
  },
  {
    key: PrescriptionReviewerUpdateRejected,
    name: "Rejected",
  },
];
export const GlobalListLimit = 25;
export type ListPagination = {
  offset: number;
  limit: number;
};
export const GENDERS = ["MALE", "FEMALE", "OTHER"];
export const USER_TYPES = [
  {
    key: "normal",
    name: "Normal",
  },
  {
    key: "branchadmin",
    name: "Branch Admin",
  },
];
