import { useHMSActions } from "@100mslive/react-sdk";
import { Flex, Loading } from "@100mslive/react-ui";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  setVideoRoom,
  submitCallLogs,
} from "../../../../redux/reducers/appointment.slice";
import { ROUTE_PUBLIC_APPOINTMENT_VIEW } from "../../../../shared/routes/route.constants";

const FullPageProgress = () => {
  const navigate = useNavigate();
  const params = useParams();
  const hmsActions = useHMSActions();
  const { appointment } = useSelector((state) => state.appointment);
  const { profile } = useSelector((state) => state.profile);
  const { currentApplication } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const redirectToLeavePage = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    if (params["source"] === "guest") {
      // window.location.href = `patient/appointment-room/${appointment?.room_id}/${profile?.id}/guest`;
      window.location.href = ROUTE_PUBLIC_APPOINTMENT_VIEW(
        params["id"],
        params["participant_id"],
        "guest"
      );
      return;
    }

    let data = {
      event: "LEFT",
      room: appointment?.room_id,
      user_type: currentApplication,
      user_id: profile?.id,
      device_type: "Browser",
      device_name: window.navigator.appCodeName,
      device_ip: res?.data?.IPv4,
      device_os: window.navigator.appVersion,
      reason: "Left without consent, Loader is taking time",
    };

    if (params["source"] === "patient") {
      dispatch(submitCallLogs(data));
      navigate.goBack();
      return;
    } else {
      dispatch(submitCallLogs(data));
      dispatch(setVideoRoom(null));
      return;
    }
  };
  // const leaveRoom = () => {
  //   hmsActions.leave();
  //   redirectToLeavePage();
  // };

  // useEffect(() => {
  //   let timer1 = setTimeout(() => leaveRoom(), 10000);
  //   return () => {
  //     clearTimeout(timer1);
  //   };
  // }, []);

  return (
    <>
      <Flex justify="center" align="center" css={{ size: "100%" }}>
        <Loading size={100} />
      </Flex>
    </>
  );
};

export default FullPageProgress;
