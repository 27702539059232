import { Card, Col, Row, Tabs, Typography } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAssignedOrders,
  fetchCancelledOrders,
  fetchDeliveredOrders,
  fetchInvoicedOrders,
  fetchPharmacyOrderRequest,
  fetchPlacedOrders,
  fetchReturnedOrders,
} from "../../../redux/reducers/pharmacy.slice";
import { RootState } from "../../../shared/constants";
import TabTitle from "../../crm/components/tabtitle";
import OrderTable from "./components/order-table.component";
const { Title } = Typography;
type Props = {};

function PharmacyOrderSummary(props: Props) {
  var allTabs: any = [];
  const dispatch = useDispatch();
  const [currentTabStatus, setCurrentTabStatus] = useState(null as any);
  const { summaryCount, pagination } = useSelector(
    (state: RootState) => state.pharmacy
  );

  allTabs = [
    {
      title: (
        <TabTitle
          title="New"
          // unread={summaryCount.open}
        />
      ),
      component: <OrderTable status={"newOrders"} />,
    },
    {
      title: (
        <TabTitle
          title="Qc Done"
          // unread="3"
        />
      ),
      component: <OrderTable status={"open"} />,
    },
    {
      title: (
        <TabTitle
          title="Invoiced"
          // unread={summaryCount.invoiced}
        />
      ),
      component: <OrderTable status={"invoiced"} />,
    },
    {
      title: (
        <TabTitle
          title="Assigned"
          // unread={summaryCount.invoiced}
        />
      ),
      component: <OrderTable status={"assigned"} />,
    },
    {
      title: (
        <TabTitle
          title="Delivered"
          // unread={summaryCount.invoiced}
        />
      ),
      component: <OrderTable status={"delivered"} />,
    },
    {
      title: (
        <TabTitle
          title="Returned"
          // unread={summaryCount.invoiced}
        />
      ),
      component: <OrderTable status={"returned"} />,
    },
    {
      title: (
        <TabTitle
          title="Cancelled Orders "
          // unread={summaryCount.cancelled}
        />
      ),
      component: <OrderTable status={"cancelled"} />,
    },
  ];

  const onTabChange = (e: any) => {
    console.log("eeee 44444", e);
    if (e == 1) {
      dispatch(
        fetchPharmacyOrderRequest({
          offset: pagination.offset,
          limit: pagination.limit,
        })
      );
    } else if (e == 2) {
      fetchSummaryOrders("open");
    } else if (e == 3) {
      fetchSummaryOrders("invoiced");
    } else if (e == 4) {
      fetchSummaryOrders("assigned");
    } else if (e == 5) {
      fetchSummaryOrders("delivered");
    } else if (e == 6) {
      fetchSummaryOrders("returned");
    } else if (e == 7) {
      fetchSummaryOrders("cancelled");
    }
  };

  useEffect(() => {
    // if (!currentTabStatus) {
    //   dispatch(
    //     fetchPharmacyOrderRequest({
    //       offset: pagination.offset,
    //       limit: pagination.limit,
    //     })
    //   );
    // }
    fetchSummaryOrders("open");
  }, []);

  const fetchSummaryOrders = async (status: any) => {
    let payload = {
      order_status: status,
      order_type: "pharmacy",
      offset: pagination.offset,
      limit: pagination.limit,
    };
    // console.log('eeee 33333', payload);
    if (status === "open") {
      await dispatch(fetchPlacedOrders(payload));
    } else if (status === "invoiced") {
      await dispatch(fetchInvoicedOrders(payload));
    } else if (status === "cancelled") {
      await dispatch(fetchCancelledOrders(payload));
    } else if (status === "assigned") {
      await dispatch(fetchAssignedOrders(payload));
    } else if (status === "delivered") {
      await dispatch(fetchDeliveredOrders(payload));
    } else if (status === "returned") {
      await dispatch(fetchReturnedOrders(payload));
    }
  };

  const onChangeTab = (key: string) => {
    fetchSummaryOrders(key);
  };

  return (
    <div className={"col-md-12 main-container padding15"}>
      <Title className="paddingcollapse" level={4}>
        Pharmacy Order Summary
      </Title>
      <Row>
        <Col span={24}>
          <Card className="nopaddingcard crm-tabs">
            {/* {<TabsLayout AllTabs={allTabs} onChange={onTabChange}></TabsLayout>} */}
            <Tabs onChange={onChangeTab}>
              {/* <Tabs.TabPane tab="New" key="newOrders">
                <OrderTable status={"newOrders"} />
              </Tabs.TabPane> */}
              <Tabs.TabPane tab="New" key="open">
                <OrderTable status={"open"} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Invoiced" key="invoiced">
                <OrderTable status={"invoiced"} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Assigned" key="assigned">
                <OrderTable status={"assigned"} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Delivered" key="delivered">
                <OrderTable status={"delivered"} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Returned" key="returned">
                <OrderTable status={"returned"} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Cancelled" key="cancelled">
                <OrderTable status={"cancelled"} />
              </Tabs.TabPane>
            </Tabs>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default PharmacyOrderSummary;
