import { Line, LineConfig } from "@ant-design/plots";
import React, { useMemo } from "react";

interface DataItem {
  day: number | string;
  value: number;
}

interface LineChartLayoutProps {
  status: DataItem[];
}

const LineChartLayout: React.FC<LineChartLayoutProps> = ({ status }) => {
  const config: LineConfig = useMemo(
    () => ({
      data: status,
      xField: "day",
      yField: "value",
      point: {
        shape: "circle",
        size: 10,
        style: {
          fill: "#FFFFFF",
          stroke: "#002060",
          lineWidth: 2,
        },
      },
      tooltip: {
        showMarkers: false,
      },
      lineStyle: {
        stroke: "#00B5AD",
        lineWidth: 2,
      },
      yAxis: {
        min: -100,
        max: 100,
        tickInterval: 20,
        label: {
          formatter: (val) => `${val} %`,
          style: {
            fill: "#003878",
            fontSize: 14,
            fontWeight: "bold",
          },
        },
        title: {
          text: "Percentage % of Recovery",
          style: {
            fill: "#003878",
            fontSize: 18,
            fontWeight: "bold",
          },
        },
      },
      xAxis: {
        label: {
          style: {
            fill: "#003878",
            fontSize: 14,
            fontWeight: "bold",
          },
        },
        title: {
          text: "Days",
          style: {
            fill: "#003878",
            fontSize: 18,
            fontWeight: "bold",
          },
        },
      },
      smooth: true,
    }),
    [status]
  );

  return <Line {...config} />;
};

export default LineChartLayout;
