import { CheckCircleOutlined } from "@ant-design/icons";
import { Col, Divider, Row, Typography } from "antd";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getPaymentStatus } from "../../redux/reducers/pharmacy.slice";
import { RootState } from "../../shared/constants";

const { Title, Text } = Typography;

function PaymentSuccess() {
  const RouteParams: any = useParams();
  const dispatch = useDispatch();
  const { fetchPaymentStatus } = useSelector(
    (state: RootState) => state.pharmacy
  );

  useEffect(() => {
    dispatch(getPaymentStatus(RouteParams["id"]));
  }, [RouteParams, dispatch]);

  return (
    <Row>
      <Row className="full-width padding20" style={{ background: "#028b02" }}>
        <Col span={24} className="text-center">
          <CheckCircleOutlined className="fts70 white-text" />
          <Title className="mt20 white-text" level={4}>
            Payment Done Successfully
          </Title>
          <Text className="white-text">
            {fetchPaymentStatus?.msg_body ||
              "Your transaction has been successfully done"}
          </Text>
          <br />
          <br />
          <Text className="white-text">
            {moment(fetchPaymentStatus?.payment_date).format(
              "DD MMMM YYYY hh:mm:ss A"
            )}
          </Text>
        </Col>
      </Row>
      <Row className="full-width mt30">
        <Col span={24} className="text-center">
          <Title className="Sub-heading" level={5}>
            Transaction ID
          </Title>
          <Text>{fetchPaymentStatus?.gateway_payment_id}</Text>
        </Col>
      </Row>
      <Divider />
      <Row className="full-width mt10">
        <Col span={8} className="text-center">
          <Title className="Sub-heading" level={5}>
            Payment Amount
          </Title>
          <Text>{fetchPaymentStatus?.amount}</Text>
        </Col>
        <Col span={8} className="text-center">
          <Title className="Sub-heading" level={5}>
            Currency
          </Title>
          <Text>INR</Text>
        </Col>
        <Col span={8} className="text-center">
          <Title className="Sub-heading" level={5}>
            Mode
          </Title>
          <Text>{fetchPaymentStatus?.payment_mode}</Text>
        </Col>
      </Row>
      <Divider />
    </Row>
  );
}

export default PaymentSuccess;
