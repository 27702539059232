// @ts-check
import {
  selectHLSState,
  selectIsConnectedToRoom,
  selectLocalPeer,
  selectPeerCount,
  selectPermissions,
  selectRecordingState,
  selectRemotePeers,
  selectRTMPState,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { setVideoRoom } from "../../../../redux/reducers/appointment.slice";
import { useIsHeadless } from "../components/AppData/useUISettings";

/**
 * Hook to execute a callback when alone in room(after a certain 5d of time)
 * @param {number} thresholdMs The threshold(in ms) after which the callback is executed,
 * starting from the instant when alone in room.
 * note: the cb is not called when another peer joins during this period.
 */
export const useWhenAloneInRoom = (thresholdMs = 5 * 60 * 1000) => {
  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const peerCount = useHMSStore(selectPeerCount);
  const [aloneForLong, setAloneForLong] = useState(false);
  const cbTimeout = useRef(null);
  const alone = isConnected && peerCount === 1;

  useEffect(() => {
    if (alone && !cbTimeout.current) {
      cbTimeout.current = setTimeout(() => {
        setAloneForLong(true);
      }, thresholdMs);
    } else if (!alone) {
      cbTimeout.current && clearTimeout(cbTimeout.current);
      cbTimeout.current = null;
      setAloneForLong(false);
    }
  }, [alone, thresholdMs]);

  useEffect(() => {
    return () => {
      clearTimeout(cbTimeout.current);
    };
  }, []);

  return { alone, aloneForLong };
};

export const useLeaveOnDuplicate = (appointment, role) => {
  const remotePeers = useHMSStore(selectRemotePeers);
  const localPeer = useHMSStore(selectLocalPeer);
  const navigate = useNavigate();
  const { profile } = useSelector((state) => state?.profile);
  const actions = useHMSActions();
  const dispatch = useDispatch();
  const sameAsMe = remotePeers.filter(
    (p) => p.customerUserId === localPeer.customerUserId
  );

  if (sameAsMe.length > 0) {
    let shouldILeave = true;
    if (localPeer.joinedAt > sameAsMe[0].joinedAt) {
      shouldILeave = false;
    }
    if (shouldILeave) {
      actions.leave();
      if (role === "patient") {
        navigate(-1);
        return;
      } else if (role === "doctor") {
        dispatch(setVideoRoom(null));
        return;
      } else {
        window.location.href = `/appointment-room/${appointment?.room_id}/${profile?.id}/guest`;
      }
    }
  }
};

export const useBeamAutoLeave = () => {
  const hmsActions = useHMSActions();
  const permissions = useHMSStore(selectPermissions);
  const isHeadless = useIsHeadless();
  const { aloneForLong } = useWhenAloneInRoom();
  const hls = useHMSStore(selectHLSState);
  const rtmp = useHMSStore(selectRTMPState);
  const recording = useHMSStore(selectRecordingState);

  /**
   * End room after 5 minutes of being alone in the room to stop beam
   * Note: Leave doesn't stop beam
   */
  useEffect(() => {
    if (aloneForLong && isHeadless) {
      if (permissions.endRoom) {
        hmsActions.endRoom(false, "Stop Beam");
      } else {
        if (hls.running && permissions.streaming) {
          hmsActions.stopHLSStreaming();
        }
        if (
          (rtmp.running && permissions.streaming) ||
          (recording.browser.running && permissions.recording)
        ) {
          hmsActions.stopRTMPAndRecording();
        }
      }
    }
  }, [aloneForLong, isHeadless, hmsActions, permissions, hls, recording, rtmp]);
};
