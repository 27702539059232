import { useEffect, useState } from "react";
// common imports

// For Docs Refer: https://jpuri.github.io/react-draft-wysiwyg/#/docs?_k=jjqinp
// find css classes in enhance.css
function TextEditor(props) {
  const [html, setHtml] = useState(props.html);

  useEffect(() => {
    if (props.html) {
      setHtml(props.html);
    }
  }, [props.html]);

  return (
    <div>
      {/* <Editor
                editorState={editorState}
                wrapperClassName="react-wysiwyg-editor-wrapper"
                editorClassName="react-wysiwyg-editor"
                onEditorStateChange={(newState) => onEditorStateChange(newState)}
                toolbarCustomButtons={[<Placeholders />]}
                toolbar={{
                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'image', 'remove', 'history'], //deleted embedded & emoji options
                  }}
            />*/}
      <textarea disabled value={html} />
    </div>
  );
}

export default TextEditor;
