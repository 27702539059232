// display tab data with filtered data

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter, dateFormatter } from "../../../../helpers";
import { useOutsideClick } from "../../../../hooks";

const filterPatientOptions = [
  { value: "name", label: "Name sort A to Z" },
  { value: "date", label: "Date" },
  // { value: "procedure", label: "Procedure" },
];

export const PatientsList: React.FC<{
  data: any[];
  setFilterPatientDropdown: React.Dispatch<React.SetStateAction<boolean>>;
  filterPatientDropdown: boolean;
}> = ({ data, setFilterPatientDropdown, filterPatientDropdown }) => {
  const navigate = useNavigate();

  const sortDropDownRef = useOutsideClick(() => {
    setFilterPatientDropdown(false);
  });

  const [filterPatient, setFilterPatient] = useState("name");

  const handlePatientFilter = () => {
    setFilterPatientDropdown((prev) => !prev);
  };

  const handleClickPatient = (patientProps: any) => {
    navigate("/prom/patients", { state: { patientProps } });
  };

  const handleFilterChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    item: any
  ) => {
    event.preventDefault();

    setFilterPatient(item?.value);
    setFilterPatientDropdown((prev) => !prev);
  };

  const filteredPatientData = data?.sort((a, b) => {
    if (filterPatient === "name") {
      return a.patient_name.localeCompare(b.patient_name);
    } else if (filterPatient === "date") {
      return (
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      );
    }

    return 0;
  });

  return (
    <>
      {data.length !== 0 && (
        <div className="tab_checkbox_filter">
          {filterPatientDropdown && (
            <div className="check_container" ref={sortDropDownRef}>
              {filterPatientOptions?.map((item: any) => (
                <div
                  className="dropdown_label"
                  key={item?.value}
                  onClick={(e: any) => handleFilterChange(e, item)}
                >
                  {item?.label}
                </div>
              ))}
            </div>
          )}

          <div
            className="filter_all"
            onClick={handlePatientFilter}
            style={{ cursor: "pointer" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="54"
              height="42"
              viewBox="0 0 54 42"
              fill="none"
            >
              <rect width="54" height="42" rx="4" fill="white" />
              <mask
                id="mask0_277_489"
                maskUnits="userSpaceOnUse"
                x="15"
                y="9"
                width="24"
                height="24"
              >
                <rect x="15" y="9" width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_277_489)">
                <path
                  d="M23 22V14.825L20.425 17.4L19 16L24 11L29 16L27.575 17.4L25 14.825V22H23ZM30 31L25 26L26.425 24.6L29 27.175V20H31V27.175L33.575 24.6L35 26L30 31Z"
                  fill="#003878"
                />
              </g>
            </svg>
          </div>
        </div>
      )}

      {data.length === 0 && (
        <div className="no_patient_found_container">
          <div className="no_patient_found">No Patients Found.</div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="177"
            height="177"
            viewBox="0 0 177 177"
            fill="none"
          >
            <path
              d="M53.3333 123.667H97.2916V79.7082H53.3333V123.667ZM53.3333 62.1249H158.833V18.1666H53.3333V62.1249ZM114.875 123.667H158.833V79.7082H114.875V123.667ZM53.3333 141.25C48.4979 141.25 44.3585 139.528 40.9151 136.085C37.4717 132.641 35.75 128.502 35.75 123.667V18.1666C35.75 13.3312 37.4717 9.19176 40.9151 5.74836C44.3585 2.30495 48.4979 0.583252 53.3333 0.583252H158.833C163.669 0.583252 167.808 2.30495 171.252 5.74836C174.695 9.19176 176.417 13.3312 176.417 18.1666V123.667C176.417 128.502 174.695 132.641 171.252 136.085C167.808 139.528 163.669 141.25 158.833 141.25H53.3333ZM18.1666 176.417C13.3312 176.417 9.19182 174.695 5.74842 171.251C2.30501 167.808 0.583313 163.669 0.583313 158.833V35.7499H18.1666V158.833H141.25V176.417H18.1666Z"
              fill="#B8C8D7"
            />
          </svg>
        </div>
      )}
      {filteredPatientData?.map((patient) => (
        <div
          className="tab_patient_info_display"
          key={patient?.id}
          onClick={() => handleClickPatient(patient)}
        >
          <div className="display_name_disease">
            <div className="display_name">
              {capitalizeFirstLetter(patient?.patient_name)}
            </div>
            <div className="display_disease">
              {capitalizeFirstLetter(patient?.procedure_name)}
            </div>
          </div>
          <div className="date_container">
            <div className="discharge_date_container">
              <div className="discharge_date">Discharge Date</div>
              <div className="display_date">
                {patient?.created_at ? dateFormatter(patient?.created_at) : ""}
              </div>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="33"
              viewBox="0 0 32 33"
              fill="none"
            >
              <mask
                id="mask0_2254_2001"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="32"
                height="33"
              >
                <rect y="0.5" width="32" height="32" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_2254_2001)">
                <path
                  d="M22.4576 16.5L16.2646 10.3667L18.1495 8.5L26.2273 16.5L18.1495 24.5L16.2646 22.6333L22.4576 16.5Z"
                  fill="#003878"
                />
              </g>
            </svg>
          </div>
        </div>
      ))}
    </>
  );
};
