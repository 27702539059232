import React, { useEffect, useState } from "react";
import { Button, Tooltip, Input, Skeleton, Menu } from "antd";
import {
  UnorderedListOutlined,
  CodepenCircleOutlined,
  EyeOutlined,
  CopyOutlined,
  CodeOutlined,
  AppstoreAddOutlined,
  QuestionOutlined,
} from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../../../../shared/constants";
import EditContainer from "./Views/EditContainer";
import PasteJsonComponent from "./Views/PasteJsonComponent";
import HelpComponent from "./Views/HelpComponent";
import JsonViewerComponent from "./Views/JsonViewerComponent";
import PreviewComponent from "./Views/PreviewComponent";
import TemplateForm from "./Views/TemplateForm";
import { useDispatch } from "react-redux";
import { uploadFile } from "../../../../redux/reducers/upload.slice";
import {
  addNewPrescriptionTemplate,
  updatePrescriptionTemplate,
} from "../../../../redux/reducers/prescription.slice";
import { useNavigate } from "react-router-dom";

type Props = {
  onSubmit: (values: any) => void;
  template?: any;
};
const PrescriptionTemplateBuilder: React.FunctionComponent<Props> = ({
  template,
  onSubmit,
}) => {
  const [currentMenu, SetCurrentMenu] = useState("edit");
  const [globalJSONObj, setGlobalJSONObj] = useState({
    sections: [],
    config: [],
  });
  const [templateData, setTemplateData] = useState({
    content: { name: "", code: "", id: "" },
  });
  //const {currentMenu,SetCurrentMenu}= useState("edit");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    //console.log("templ010",template);

    if (template && Object.keys(template).length !== 0) {
      setGlobalJSONObj(template.data);
      setTemplateData(template);
    }
  }, [template]);
  //console.log("templ",templateData);
  const icon_size = "20px";
  const handleClick = (e: { key: any }) => {
    //console.log('click ', e);
    SetCurrentMenu(e.key);
    //this.setState({ current: e.key });
  };
  const modifyGlobalJSON = (form: any) => {
    let gObj = globalJSONObj;
    gObj.sections = form;
    setGlobalJSONObj(gObj);
  };

  const changeGlobalConfig = (config: any) => {
    let gObj = globalJSONObj;
    gObj.config = config;
    setGlobalJSONObj(gObj);
  };
  const onPastedJsonEmit = (validatedJson: any) => {
    setGlobalJSONObj(validatedJson);
    SetCurrentMenu("edit");
  };
  const renderItem = (e: string) => {
    //console.log("renderItem",e);
    switch (e) {
      case "edit":
        return (
          <EditContainer
            onFormChange={modifyGlobalJSON.bind(this)}
            sections={globalJSONObj["sections"]}
            activeKey={currentMenu}
            globalConfig={globalJSONObj["config"]}
            changeGlobalConfig={changeGlobalConfig.bind(this)}
          />
        );
      case "PasteJSON":
        return (
          <div style={{ minHeight: "500px" }}>
            <PasteJsonComponent
              activeKey={currentMenu}
              onPastedJsonEmit={onPastedJsonEmit.bind(this)}
            ></PasteJsonComponent>
          </div>
        );
      case "Help":
        return (
          <div style={{ minHeight: "500px" }}>
            <HelpComponent activeKey={currentMenu}></HelpComponent>
          </div>
        );
      case "JSON":
        return (
          <div>
            {globalJSONObj && (
              <JsonViewerComponent
                JsonObj={globalJSONObj}
                activeKey={currentMenu}
              ></JsonViewerComponent>
            )}
          </div>
        );
      case "preview":
        return (
          <div style={{ minHeight: "500px" }}>
            {globalJSONObj && (
              <PreviewComponent
                activeKey={currentMenu}
                previewObj={globalJSONObj}
                updatePreview={true}
                template_id={""}
              ></PreviewComponent>
            )}
          </div>
        );
      case "Save":
        return (
          <TemplateForm
            questionnaire={{
              ...templateData,
              content: {
                ...templateData.content,
                data: JSON.stringify(globalJSONObj),
              },
            }}
            onSubmit={async (values: any) => {
              try {
                //console.log("templateFormSubmit",templateData);
                //return;

                const response = await dispatch(
                  uploadFile({
                    file_name: values.name,
                    type: "TEMPLATE",
                    content_type: "json",
                  })
                );
                const response2 = new Promise((resolve, reject) => {
                  resolve(
                    axios
                      .request({
                        headers: {
                          "Content-Type": "json", // This is set to application/flac
                        },
                        method: "PUT",
                        data: values.content,
                        url: response?.payload?.data?.data?.url,
                        timeout: 0,
                      })
                      .then(function () {
                        if (templateData.content.id) {
                          dispatch(
                            updatePrescriptionTemplate({
                              upload_id: response?.payload?.data?.data?.id,
                              name: values.name,
                              code: values.code,
                              type: values.type,
                              template_id: templateData.content.id,
                            })
                          );
                        } else {
                          dispatch(
                            addNewPrescriptionTemplate({
                              upload_id: response?.payload?.data?.data?.id,
                              name: values.name,
                              code: values.code,
                              type: values.type,
                            })
                          );
                        }

                        navigate(-1);
                        //console.log("responseresponse",response);
                      })
                  );
                });
                //console.log("TemplateForm",values);
                //await dispatch(addNewRole(values));
                //navigate(-1);
              } catch (e) {}
              return false;
            }}
          />
        );
    }
  };
  return (
    <>
      <Menu
        onClick={handleClick}
        selectedKeys={[currentMenu]}
        mode="horizontal"
      >
        <Menu.Item key="edit">
          <Tooltip placement="right" title="Build template" className="tooltip">
            <CodepenCircleOutlined style={{ fontSize: icon_size }} />
          </Tooltip>
        </Menu.Item>

        <Menu.Item key="preview">
          <Tooltip placement="right" title="preview" className="tooltip">
            <EyeOutlined style={{ fontSize: icon_size }} />
          </Tooltip>
        </Menu.Item>

        <Menu.Item key="JSON">
          <Tooltip placement="right" title="JSON" className="tooltip">
            <CodeOutlined style={{ fontSize: icon_size }} />
          </Tooltip>
        </Menu.Item>

        <Menu.Item key="PasteJSON">
          <Tooltip placement="right" title="Paste JSON" className="tooltip">
            <CopyOutlined style={{ fontSize: icon_size }} />
          </Tooltip>
        </Menu.Item>

        <Menu.Item key="Save">
          <Tooltip placement="right" title="Save" className="tooltip">
            <AppstoreAddOutlined style={{ fontSize: icon_size }} />
          </Tooltip>
        </Menu.Item>

        <Menu.Item key="Help">
          <Tooltip placement="right" title="Help" className="tooltip">
            <QuestionOutlined style={{ fontSize: icon_size }} />
          </Tooltip>
        </Menu.Item>
      </Menu>
      {renderItem(currentMenu)}
    </>
  );
};

export default PrescriptionTemplateBuilder;
