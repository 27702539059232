import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllProcedures,
  promMatrics,
  promMatricsDoctor,
  promMatricsHospital,
  promMatricsPatient,
} from "../../../redux/reducers/prom.slice";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrowDownIcon.svg";
import { RootState } from "../../../shared/constants";
import LineChartLayout from "./Components/LineChartLayout";
import LoadingProm from "./Components/loadingProm";
import "./styles/prom.analytics.scss";
import { Select, Spin, Typography } from "antd";
import { capitalizeFirstLetter, truncateString } from "../../../helpers";

const { Option } = Select;

const { Title } = Typography;

interface MatriceItem {
  day: number | string;
  value: number;
}

const NoDataFound = (): JSX.Element => {
  return (
    <div className="ant-empty ant-empty-normal ant-empty-small">
      <div className="ant-empty-image">
        <svg
          className="ant-empty-img-simple"
          width="64"
          height="41"
          viewBox="0 0 64 41"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g transform="translate(0 1)" fill="none" fill-rule="evenodd">
            <ellipse
              className="ant-empty-img-simple-ellipse"
              cx="32"
              cy="33"
              rx="32"
              ry="7"
            ></ellipse>
            <g className="ant-empty-img-simple-g" fill-rule="nonzero">
              <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
              <path
                d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                className="ant-empty-img-simple-path"
              ></path>
            </g>
          </g>
        </svg>
      </div>
      <div className="ant-empty-description">No Data</div>
    </div>
  );
};

const LoadingData = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Spin size="small" />
    </div>
  );
};

function PromAnalytics() {
  const dispatch = useDispatch();

  const {
    loading,
    allProceduresList,
    matricsData,
    matricsHospital,
    matricsDoctor,
    matricsPatient,
  } = useSelector((state: RootState) => state.prom);

  const allTreatmentList: Array<{ label: string; value: string }> =
    useMemo(() => {
      return allProceduresList?.map((item: any, index: any) => ({
        label: item.name,
        value: item.procedure_id,
      }));
    }, [allProceduresList]);

  const allHospitalList: Array<{ label: string; value: string }> =
    useMemo(() => {
      return matricsHospital?.map((item: any, index: any) => ({
        label: item.name,
        value: item.hospital_id,
      }));
    }, [matricsHospital]);

  const allDoctorList: Array<{ label: string; value: string }> = useMemo(() => {
    return matricsDoctor?.map((item: any, index: any) => ({
      label: item.name,
      value: item.doctor_id,
    }));
  }, [matricsDoctor]);

  const allPatientsList: Array<{ label: string; value: string }> =
    useMemo(() => {
      return matricsPatient?.map((item: any, index: any) => ({
        label: item.name,
        value: item.patient_id,
      }));
    }, [matricsPatient]);

  const [isFetchingData, setIsFetchingData] = useState(false);

  // procedure states
  const [procedureOptionsList, setProcedureListOptions] = useState<
    Array<{ label: string; value: string }>
  >([]);
  const [selectedProcedureId, setSelectedProcedureId] = useState<string | null>(
    null
  );

  // hospital states
  const [selectedHospitalId, setSelectedHospitalId] = useState<string | null>(
    null
  );
  const [isFetchingHospitals, setIsFetchingHospitals] =
    useState<boolean>(false);
  const [isHospitalInputDisabled, setIsHospitalInputDisabled] = useState(false);
  const [hospitalListOptions, setHospitalListOptions] = useState<
    Array<{ label: string; value: string }>
  >([]);

  // doctor states
  const [selectedDoctorId, setSelectedDoctorId] = useState<string | null>(null);
  const [doctorOptionsList, setDoctorOptionList] = useState<
    Array<{ label: string; value: string }>
  >([]);
  const [isFetchingDoctor, setIsFetchingDoctor] = useState<boolean>(false);
  const [isDoctorInputDisabled, setIsDoctorInputDisabled] = useState(false);

  // patient states
  const [patientOptionsList, setPatientOptionsList] = useState<
    Array<{ label: string; value: string }>
  >([]);

  const [selectedPatientId, setSelectedPatientId] = useState<string | null>(
    null
  );

  const [isFetchingPatients, setIsFetchingPatients] = useState<boolean>(false);

  const matricsResult: MatriceItem[] = useMemo(
    () =>
      Object.entries(matricsData?.response || {})
        .map(([day, value]) => {
          let processedValue: number;

          if (typeof value === "number") {
            if (value >= 0.0 && value < 1) {
              processedValue = value * 100;
            } else {
              processedValue = value;
            }
            processedValue = Number(processedValue.toFixed(2));
          } else {
            processedValue = 0;
          }

          return {
            day: day,
            value: processedValue,
          };
        })
        .filter((item) => item.value !== null && item.value !== 0),
    [matricsData?.response]
  );

  // fetch matrix API data
  const fetchMatrixApi = useCallback(
    (payload: any) => {
      try {
        (async function () {
          if (payload) {
            await dispatch(promMatrics(payload));
          }
        })();
      } catch (e) {
        console.error(e);
      }
    },
    [dispatch]
  );

  // Handle Clear All
  const handleClearAll = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setSelectedProcedureId(null);
    setSelectedHospitalId(null);
    setSelectedDoctorId(null);
    setSelectedPatientId(null);
  }, []);

  // procedure treatment state function
  const handleTreatmentClick = useCallback(
    async (value: string) => {
      if (value !== selectedProcedureId) {
        setSelectedProcedureId(value);

        // clear all other filters if procedure is resetted
        setSelectedHospitalId(null);
        setHospitalListOptions([]);
        setSelectedDoctorId(null);
        setDoctorOptionList([]);
        setSelectedPatientId(null);
        setPatientOptionsList([]);
        setIsDoctorInputDisabled(false);
        setIsHospitalInputDisabled(false);

        // reset options
        setProcedureListOptions(allTreatmentList);

        //fetch new metrics
        setIsFetchingData(true);
        await dispatch(promMatrics({ procedure_id: value }));
        setIsFetchingData(false);
      }
    },
    [dispatch, selectedProcedureId, allTreatmentList]
  );

  const handleSearchTreatments = useCallback(
    (e) => {
      // regex for the query value to be search
      const regex = new RegExp(e, "ig");

      const filteredTreatmentList = allTreatmentList.filter((treatment) => {
        return regex.test(treatment.label) || regex.test(treatment.value);
      });

      setProcedureListOptions(filteredTreatmentList);
    },
    [allTreatmentList]
  );

  // hospital state functions
  const handleHospitalFocus = useCallback(
    async (e) => {
      //  if hospital is not yet selected
      if (
        selectedProcedureId !== null &&
        (selectedHospitalId === null || allHospitalList.length === 0)
      ) {
        setIsFetchingHospitals(true);
        await dispatch(
          promMatricsHospital({ procedure_id: selectedProcedureId })
        );

        setIsFetchingHospitals(false);
      }
    },
    [dispatch, selectedProcedureId, selectedHospitalId, allHospitalList.length]
  );

  const handleHospitalClick = useCallback(
    async (value: string) => {
      if (value !== selectedHospitalId) {
        setSelectedHospitalId(value);

        const dataPayload = {
          procedure_id: selectedProcedureId,
          hospital_id: value,
        };

        // clear all other filters forward if hospital is resetted
        setSelectedDoctorId(null);
        setSelectedPatientId(null);
        setDoctorOptionList([]);
        setPatientOptionsList([]);

        // reset options
        setHospitalListOptions(allHospitalList);

        setIsFetchingData(true);
        fetchMatrixApi(dataPayload);
        setIsFetchingData(false);
      }
    },
    [selectedHospitalId, fetchMatrixApi, selectedProcedureId, allHospitalList]
  );

  // doctor state function
  const handleDoctorClick = useCallback(
    async (value: string) => {
      if (value !== selectedDoctorId) {
        setSelectedDoctorId(value);

        // clear patient filter if doctor is resetted
        setSelectedPatientId(null);
        setPatientOptionsList([]);

        setIsFetchingData(true);

        // reset doctor options
        setDoctorOptionList(allDoctorList);

        //fetch new metrics
        const dataPayload = {
          procedure_id: selectedProcedureId,
          hospital_id: selectedHospitalId,
          doctor_id: value,
        };

        // stop backward flow
        setIsHospitalInputDisabled(true);

        fetchMatrixApi(dataPayload);

        setIsFetchingData(false);
      }
    },
    [
      selectedDoctorId,
      fetchMatrixApi,
      selectedHospitalId,
      selectedProcedureId,
      allDoctorList,
    ]
  );

  const handleDoctorFocus = useCallback(
    async (e) => {
      //  if hospital is not yet selected
      if (
        selectedProcedureId !== null &&
        (selectedDoctorId === null || allDoctorList.length === 0)
      ) {
        setIsFetchingDoctor(true);

        const dataPayload = {
          procedure_id: selectedProcedureId,
          hospital_id: selectedHospitalId,
        };

        await dispatch(promMatricsDoctor(dataPayload));

        setIsFetchingDoctor(false);
      }
    },
    [
      allDoctorList.length,
      dispatch,
      selectedProcedureId,
      selectedHospitalId,
      selectedDoctorId,
    ]
  );

  const handleSearchDoctors = useCallback(
    (e) => {
      // regex for the query value to be search
      const regex = new RegExp(e, "ig");

      const filteredDoctorstList = allDoctorList.filter((doc) => {
        return regex.test(doc.label) || regex.test(doc.value);
      });

      setDoctorOptionList(filteredDoctorstList);
    },
    [allDoctorList]
  );

  // patient state functions
  const handlePatientClick = useCallback(
    (value: string) => {
      if (selectedPatientId !== value) {
        setSelectedPatientId(value);

        const dataPayload = {
          procedure_id: selectedProcedureId,
          hospital_id: selectedHospitalId,
          doctor_id: selectedDoctorId,
          patient_id: value,
        };

        setIsFetchingData(true);

        // reset options
        setPatientOptionsList(allPatientsList);

        // stop the backward flow
        setIsHospitalInputDisabled(true);
        setIsDoctorInputDisabled(true);

        fetchMatrixApi(dataPayload);

        setIsFetchingData(false);
      }
    },
    [
      selectedDoctorId,
      selectedHospitalId,
      selectedProcedureId,
      selectedPatientId,
      fetchMatrixApi,
      allPatientsList,
    ]
  );

  const handleSearchPatients = useCallback(
    (e) => {
      // regex for the query value to be search
      const regex = new RegExp(e, "ig");

      const filteredPatientList = allPatientsList.filter((patient) => {
        return regex.test(patient.label) || regex.test(patient.value);
      });

      setPatientOptionsList(filteredPatientList);
    },
    [allPatientsList]
  );

  const handlePatientFocus = useCallback(
    async (e) => {
      //  if hospital is not yet selected
      if (
        selectedProcedureId !== null &&
        (selectedPatientId === null || allPatientsList.length === 0)
      ) {
        setIsFetchingPatients(true);

        const dataPayload = {
          procedure_id: selectedProcedureId,
          hospital_id: selectedHospitalId,
          doctor_id: selectedDoctorId,
        };

        await dispatch(promMatricsPatient(dataPayload));

        setIsFetchingPatients(false);
      }
    },
    [
      dispatch,
      allPatientsList.length,
      selectedDoctorId,
      selectedHospitalId,
      selectedPatientId,
      selectedProcedureId,
    ]
  );

  useEffect(() => {
    setProcedureListOptions(
      allProceduresList?.map((item: any, index: any) => ({
        label: item.name,
        value: item.procedure_id,
      }))
    );
  }, [allProceduresList]);

  useEffect(() => {
    setDoctorOptionList(allDoctorList);
  }, [allDoctorList]);

  useEffect(() => {
    setHospitalListOptions(
      matricsHospital?.map((item: any, index: any) => ({
        label: item.name,
        value: item.hospital_id,
      }))
    );
  }, [matricsHospital]);

  useEffect(() => {
    setPatientOptionsList(allPatientsList);
  }, [allPatientsList]);

  useEffect(() => {
    try {
      (async function () {
        await dispatch(fetchAllProcedures());
      })();
    } catch (e) {
      console.error(e);
    }
  }, [dispatch]);

  return (
    <>
      <div className="prom_analytics_container">
        {((loading && !selectedProcedureId && allProceduresList.length === 0) ||
          isFetchingData) && <LoadingProm />}
        <div className="analytics_clear_container">
          <Title className="title">Statistics</Title>

          <div className="clear_all" onClick={(e: any) => handleClearAll(e)}>
            Clear All
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="27"
              height="27"
              viewBox="0 0 27 27"
              fill="none"
            >
              <mask
                id="mask0_3178_2978"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="27"
                height="27"
              >
                <rect width="27" height="27" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_3178_2978)">
                <path
                  d="M9.45 19.125L7.875 17.5499L11.925 13.5L7.875 9.47808L9.45 7.90308L13.5 11.9531L17.5219 7.90308L19.0969 9.47808L15.0469 13.5L19.0969 17.5499L17.5219 19.125L13.5 15.075L9.45 19.125Z"
                  fill="white"
                />
              </g>
            </svg>
          </div>
        </div>

        <div className="analytics_dropdown_container">
          {/* Treatment Procedure Select */}
          <Select
            showSearch
            autoFocus={false}
            filterOption={false}
            style={{
              color: "#003878",
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "150%",
            }}
            className={"procedure filter_select"}
            placeholder="Procedure"
            suffixIcon={<ArrowDown />}
            options={procedureOptionsList}
            onSearch={handleSearchTreatments}
            value={selectedProcedureId}
            onChange={(treatment: string) => {
              handleTreatmentClick(treatment);
            }}
          >
            {procedureOptionsList?.map(
              (p: { label: string; value: string }) => {
                return (
                  <Option value={p.value} label={p.label} key={p.value}>
                    <div
                      style={{
                        color: "#003878 !important",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "500",
                      }}
                    >
                      {truncateString(capitalizeFirstLetter(p.label))}
                    </div>
                  </Option>
                );
              }
            )}
          </Select>

          {/* Hospital Select */}
          <Select
            autoFocus={false}
            className={"hospital filter_select"}
            placeholder="Hospital"
            disabled={selectedProcedureId === null || isHospitalInputDisabled}
            suffixIcon={<ArrowDown />}
            options={hospitalListOptions}
            value={selectedHospitalId}
            style={{
              color: "#003878",
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "150%",
            }}
            notFoundContent={
              isFetchingHospitals ? <LoadingData /> : <NoDataFound />
            }
            onFocus={handleHospitalFocus}
            onChange={(hospital: string) => {
              handleHospitalClick(hospital);
            }}
          >
            {hospitalListOptions?.map((p: { label: string; value: string }) => {
              return (
                <Option value={p.value} label={p.label} key={p.value}>
                  <div
                    style={{
                      color: "#003878 !important",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "500",
                    }}
                  >
                    {truncateString(capitalizeFirstLetter(p.label))}
                  </div>
                </Option>
              );
            })}
          </Select>

          {/* Doctor Select */}
          <Select
            showSearch
            autoFocus={false}
            filterOption={false}
            className={"doctor filter_select"}
            placeholder="Doctor"
            style={{
              color: "#003878",
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "150%",
            }}
            disabled={selectedProcedureId === null || isDoctorInputDisabled}
            suffixIcon={<ArrowDown />}
            options={doctorOptionsList}
            onSearch={handleSearchDoctors}
            notFoundContent={
              isFetchingDoctor ? <LoadingData /> : <NoDataFound />
            }
            value={selectedDoctorId}
            onFocus={handleDoctorFocus}
            onChange={(doc: string) => {
              handleDoctorClick(doc);
            }}
          >
            {doctorOptionsList?.map((p: { label: string; value: string }) => {
              return (
                <Option value={p.value} label={p.label} key={p.value}>
                  <div
                    style={{
                      color: "#003878 !important",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "500",
                    }}
                  >
                    {truncateString(capitalizeFirstLetter(p.label))}
                  </div>
                </Option>
              );
            })}
          </Select>

          {/* Patient Select */}
          <Select
            showSearch
            autoFocus={false}
            style={{
              color: "#003878",
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "150%",
            }}
            filterOption={false}
            className={"patient filter_select"}
            placeholder="Patient"
            disabled={selectedProcedureId === null}
            suffixIcon={<ArrowDown />}
            options={patientOptionsList}
            onSearch={handleSearchPatients}
            notFoundContent={
              isFetchingPatients ? <LoadingData /> : <NoDataFound />
            }
            value={selectedPatientId}
            onFocus={handlePatientFocus}
            onChange={handlePatientClick}
          >
            {patientOptionsList?.map((p: { label: string; value: string }) => {
              return (
                <Option value={p.value} label={p.label} key={p.value}>
                  <div
                    style={{
                      color: "#003878 !important",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "500",
                    }}
                  >
                    {truncateString(capitalizeFirstLetter(p.label))}
                  </div>
                </Option>
              );
            })}
          </Select>
        </div>

        <div className="chat_container">
          {matricsResult.length > 0 ? (
            <LineChartLayout status={matricsResult} />
          ) : (
            <div className="empty_data">
              {selectedProcedureId === null
                ? "Please choose a procedure to continue"
                : "No data found."}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default PromAnalytics;
