import { Col, Row } from "antd";
import ReportLayout from "../../../shared/layouts/report-issue.layout";

function DoctorReportIssues() {
  return (
    <Row>
      <Col span={24} className={"white-background padding20"}>
        <h1 className={"doctor-primary-color bold"}>Report Issues</h1>
      </Col>

      <Col span={24} className={"padding20"}>
        <Row>
          <ReportLayout></ReportLayout>
        </Row>
      </Col>
    </Row>
  );
}

export default DoctorReportIssues;
