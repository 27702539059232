const SectionOptions = [
  { value: "sectionOffset", label: "Section Offset" },
  { value: "ordered", label: "Show Serial Number" },
  { value: "hideShowOff", label: "Hide/Show Off" },
  { value: "bookmarking", label: "Allow Bookmarking" },
  { value: "print", label: "Printable" },
  { value: "collapse", label: "Section collapse on load" },
];

const Actions = [
  { value: "save", label: "Allow Saving" },
  { value: "copy", label: "Allow Copying" },
  { value: "load", label: "Allow Loading" },
];
const AdditionalOptions = {
  SectionOptions,
  Actions,
};
export default AdditionalOptions;
