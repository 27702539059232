import { Button, Col, Layout, List, Skeleton, Typography } from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { dateFormatter, debouncedSearch } from "../../../helpers";
import { Client } from "../../../shared/Utils/api.client-prom";
import {
  PROM_GET_ALL_PATIENT,
  PROM_GET_PATIENT_BY_IDS,
} from "../../../shared/routes/doctor.routes.constants";
import PatientDisplay from "./Components/patient.display";
import "./styles/prom.patient.scss";

const { Title } = Typography;

const PAGE_SIZE = 20;

interface IPatient {
  patient_id: string;
  name: string;
  form_id: number;
  procedure_id: number;
  procedure_name: string;
  doctor_id: string;
  combined_response_status: string[];
  created_at: string;
}

async function fetchPatientByIdsRequest(params: {
  doctor_id: string;
  form_id: string;
  patient_id: string;
  procedure_id: string;
  created_at: string;
}) {
  const response = await Client.post(PROM_GET_PATIENT_BY_IDS, params);

  return response;
}

async function fetchAllPatientsRequest(params: {
  cursor: string;
  searchString: string;
}) {
  const response = await Client.post(PROM_GET_ALL_PATIENT, {
    ...params,
    limit: PAGE_SIZE,
  });

  return response;
}

async function hasMorePatientsAtNextCursor(
  cursor: string,
  searchString = ""
): Promise<boolean> {
  const res = await fetchAllPatientsRequest({ cursor, searchString });
  if (res?.status === 200 && res?.data?.patients.length > 0) {
    return true;
  } else {
    return false;
  }
}
function PromPatient(props: any) {
  const location = useLocation();

  const { patientProps } = useMemo(
    () => location.state || {},
    [location.state]
  );

  const [patients, setPatients] = useState<IPatient[]>([]);
  const [nextCursor, setNextCursor] = useState<string>("MA==");
  const [hasMorePatients, setHasMorePatients] = useState(false);

  const [patientDetails, setPatientDetails] = useState<any>([]);

  const [isLoadingPatients, setIsLoadingPatients] = useState(true);
  const [isLoadingPatientDetails, setIsLoadingPatientDetails] = useState(true);

  const PatientArrayData: any = [];

  const [searchText, setSearchText] = useState<string>("");

  const [selectedPatient, setSelectedPatient] = useState<any | null>(null);

  const svgRef = useRef<SVGSVGElement | null>(null);
  const scrollableDivRef = useRef<HTMLDivElement>(null);
  const scrollPositionRef = useRef(0);

  const handleFocus = useCallback(() => {
    document.querySelector(".search-container")?.classList.add("focused");
    svgRef?.current?.classList.add("focused");
  }, []);

  const handleBlur = useCallback(() => {
    document.querySelector(".search-container")?.classList.remove("focused");
    svgRef?.current?.classList.remove("focused");
  }, []);

  if (patientDetails.length !== 0) {
    const {
      score_system,
      form_name,
      dob,
      gender,
      phone_number,
      patient_name,
      doctor_name,
    } = patientDetails[0];

    const patient_details = {
      score_system,
      form_name,
      dob,
      gender,
      phone_number,
      patient_name,

      doctor_name,
    };

    const groupedData = patientDetails?.reduce((acc: any, item: any) => {
      const date = item?.created_at;
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});

    if (groupedData) {
      for (const date in groupedData) {
        groupedData[date].sort(
          (a: any, b: any) => a.frequency_value - b.frequency_value
        );
      }

      const sortedArray = Object.values(groupedData).flat();
      if (sortedArray) {
        const updatedSortedArray = sortedArray?.map((item: any) => {
          const { ...rest } = item;
          return rest;
        });

        var processtype = "";

        if (selectedPatient) {
          const responseStatus = selectedPatient?.combined_response_status;

          if (
            (!responseStatus.includes("started") &&
              responseStatus.includes("pending") &&
              !responseStatus.includes("completed")) ||
            (responseStatus.includes("started") &&
              responseStatus.includes("pending") &&
              !responseStatus.includes("completed"))
          ) {
            processtype = "New";
          } else if (
            (responseStatus.includes("started") &&
              responseStatus.includes("pending") &&
              responseStatus.includes("completed")) ||
            (!responseStatus.includes("started") &&
              responseStatus.includes("pending") &&
              responseStatus.includes("completed"))
          ) {
            processtype = "In-Progress";
          } else if (
            responseStatus.includes("completed") &&
            !responseStatus.includes("started") &&
            !responseStatus.includes("pending")
          ) {
            processtype = "Completed";
          }
        }

        const data = {
          created_at: selectedPatient?.created_at,
          procedure_name: selectedPatient?.procedure_name,
          processtype,
          name: selectedPatient?.name,
        };

        const result = {
          patient_details: { ...data, ...patient_details },
          scoreData: updatedSortedArray,
        };

        PatientArrayData.push(result);
      }
    }
  }

  // handle patient click
  const handlePatientClick = useCallback(async (patient: any) => {
    if (patient !== null && patient !== undefined) {
      const data = {
        doctor_id: patient?.doctor_id,
        form_id: patient?.form_id,
        patient_id: patient?.patient_id,
        procedure_id: patient?.procedure_id,
        created_at: patient?.created_at,
      };

      if (data) {
        try {
          setIsLoadingPatientDetails(true);
          const resp = await fetchPatientByIdsRequest(data);

          if (resp?.status === 200) {
            setPatientDetails(resp?.data);
          } else {
            setPatientDetails([]);
            setIsLoadingPatientDetails(false);
            return new Error(
              "Something went wrong with fetching patient details"
            );
          }

          setIsLoadingPatientDetails(false);
        } catch (e) {
          console.error(e);
        }
      }
    } else {
      setPatientDetails([]);
    }

    setSelectedPatient(patient ?? null);
    if (scrollableDivRef.current) {
      scrollPositionRef.current = scrollableDivRef.current.scrollTop;
    }
  }, []);

  const handleFetchPatientsList = useCallback(
    async (cursor = "MA==", searchString = "") => {
      try {
        setIsLoadingPatients(true);

        const resp = await fetchAllPatientsRequest({
          cursor,
          searchString,
        });

        if (resp?.status === 200) {
          resp?.data.patients &&
            setPatients((prev) => [...prev, ...resp?.data?.patients]);

          setNextCursor(resp.data?.nextCursor);
          setIsLoadingPatients(false);

          // select first patient when initially loaded
          if (cursor === "MA==") {
            if (patientProps && searchText.length === 0) {
              handlePatientClick(patientProps);
            } else if (resp.data?.patients?.[0]) {
              handlePatientClick(resp.data?.patients?.[0] ?? null);
            }
            // TODO: to be solved later
            // else {
            //   setSelectedPatient(null);
            // }
          }

          // send a call to check for next 20 patients
          const hasMore = await hasMorePatientsAtNextCursor(
            resp.data?.nextCursor,
            searchString
          );

          setHasMorePatients(hasMore);
        } else {
          setPatients([]);
          setIsLoadingPatients(false);

          return new Error("Something went wrong with fetching patients list");
        }
      } catch (e) {
        console.error(e);
      }
    },
    [handlePatientClick, patientProps, searchText.length]
  );

  const handleFetchPatientsOnSearch = useCallback(
    (value: string) => {
      setSearchText(value);

      setIsLoadingPatients(true);
      setIsLoadingPatientDetails(true);

      debouncedSearch(value, async (cursor = "MA==", value) => {
        try {
          setIsLoadingPatients(true);

          setPatients([]);

          const resp = await fetchAllPatientsRequest({
            cursor,
            searchString: value,
          });

          if (resp?.status === 200) {
            resp?.data.patients &&
              setPatients(resp?.data?.patients as IPatient[]);

            setNextCursor(resp.data?.nextCursor);
            setIsLoadingPatients(false);

            // select first patient when initially loaded
            if (cursor === "MA==") {
              if (patientProps && value.length === 0) {
                handlePatientClick(patientProps);
              } else if (resp.data?.patients?.[0]) {
                handlePatientClick(resp.data?.patients?.[0] ?? null);
              }
              // TODO: to be solved later
              // else {
              //   setSelectedPatient(null);
              // }
            }

            // send a call to check for next 20 patients
            const hasMore = await hasMorePatientsAtNextCursor(
              resp.data?.nextCursor,
              value
            );

            setHasMorePatients(hasMore);
          } else {
            setPatients([]);
            setIsLoadingPatients(false);

            return new Error(
              "Something went wrong with fetching patients list"
            );
          }
        } catch (e) {
          console.error(e);
        }
      });
      setIsLoadingPatients(false);
      setIsLoadingPatientDetails(false);
    },
    [handlePatientClick, patientProps]
  );

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value.trim();

      // prev value is not equal to new value then fetch
      if (searchText !== value) {
        setNextCursor("MA==");
        handleFetchPatientsOnSearch(value);
      }
    },
    [searchText, handleFetchPatientsOnSearch]
  );

  const handleLoadMorePatients = useCallback(
    () => handleFetchPatientsList(nextCursor, searchText),
    [handleFetchPatientsList, nextCursor, searchText]
  );

  useEffect(() => {
    handleFetchPatientsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="prom__patient_container">
        <Layout className="prom__doctor_patient_container">
          <Layout className="left_layout">
            <Title className="title">Patient</Title>
            <div className="search-container">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <mask
                  id="mask0_3030_1174"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="25"
                  height="24"
                >
                  <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_3030_1174)">
                  <path
                    d="M20.1 20.4348L13.8 14.6826C13.3 15.0478 12.725 15.337 12.075 15.55C11.425 15.763 10.7333 15.8696 10 15.8696C8.18333 15.8696 6.64583 15.2951 5.3875 14.1462C4.12917 12.9973 3.5 11.5935 3.5 9.93478C3.5 8.27609 4.12917 6.87228 5.3875 5.72337C6.64583 4.57446 8.18333 4 10 4C11.8167 4 13.3542 4.57446 14.6125 5.72337C15.8708 6.87228 16.5 8.27609 16.5 9.93478C16.5 10.6043 16.3833 11.2359 16.15 11.8293C15.9167 12.4228 15.6 12.9478 15.2 13.4043L21.5 19.1565L20.1 20.4348ZM10 14.0435C11.25 14.0435 12.3125 13.644 13.1875 12.8451C14.0625 12.0462 14.5 11.0761 14.5 9.93478C14.5 8.79348 14.0625 7.82337 13.1875 7.02446C12.3125 6.22554 11.25 5.82609 10 5.82609C8.75 5.82609 7.6875 6.22554 6.8125 7.02446C5.9375 7.82337 5.5 8.79348 5.5 9.93478C5.5 11.0761 5.9375 12.0462 6.8125 12.8451C7.6875 13.644 8.75 14.0435 10 14.0435Z"
                    fill="#B8C8D7"
                  />
                </g>
              </svg>

              <input
                placeholder="Search"
                onChange={handleSearchChange}
                className="search_input"
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
            </div>

            <div
              className="patient_card_container"
              id="scrollableDiv"
              ref={scrollableDivRef}
            >
              <List
                loading={isLoadingPatients}
                dataSource={patients}
                renderItem={(item: any) => (
                  <div
                    key={item?.procedure_id}
                    className={`patient_card ${
                      item?.procedure_id === selectedPatient?.procedure_id &&
                      item?.patient_id === selectedPatient?.patient_id &&
                      item?.created_at === selectedPatient?.created_at &&
                      item?.form_id === selectedPatient?.form_id
                        ? "active"
                        : ""
                    }`}
                    onClick={() => handlePatientClick(item)}
                  >
                    <div>
                      <div className="patient_name">{item?.name}</div>
                      <div className="template_name">
                        {item?.procedure_name}
                      </div>
                    </div>
                    <div className="time_and_type_display_container">
                      <div className="time_and_type_display">
                        <div className="display_time">
                          {item?.created_at
                            ? dateFormatter(item?.created_at)
                            : ""}
                        </div>
                        <div className="display_type">
                          {(!item?.combined_response_status.includes(
                            "started"
                          ) &&
                            item?.combined_response_status.includes(
                              "pending"
                            ) &&
                            !item?.combined_response_status.includes(
                              "completed"
                            )) ||
                          (item?.combined_response_status.includes("started") &&
                            item?.combined_response_status.includes(
                              "pending"
                            ) &&
                            !item?.combined_response_status.includes(
                              "completed"
                            )) ? (
                            <>
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0_9911_6898)">
                                  <path
                                    d="M12.9583 6.99982L11.6366 5.49398L11.8208 3.49794L9.86538 3.05648L8.84163 1.33398L6.99996 2.12482L5.15829 1.33398L4.13454 3.05648L2.17913 3.49794L2.36329 5.49398L1.04163 6.99982L2.36329 8.50565L2.17913 10.5017L4.13454 10.9432L5.15829 12.6657L6.99996 11.8748L8.84163 12.6657L9.86538 10.9432L11.8208 10.5017L11.6366 8.50565L12.9583 6.99982ZM7.54163 9.70815H6.45829V8.62482H7.54163V9.70815ZM7.54163 7.54148H6.45829V4.29148H7.54163V7.54148Z"
                                    fill="#00B5AD"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_9911_6898">
                                    <rect
                                      width="13"
                                      height="13"
                                      fill="white"
                                      transform="translate(0.5 0.5)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                              New
                            </>
                          ) : (
                            <></>
                          )}
                          {(item?.combined_response_status.includes(
                            "started"
                          ) &&
                            item?.combined_response_status.includes(
                              "pending"
                            ) &&
                            item?.combined_response_status.includes(
                              "completed"
                            )) ||
                          (!item?.combined_response_status.includes(
                            "started"
                          ) &&
                            item?.combined_response_status.includes(
                              "pending"
                            ) &&
                            item?.combined_response_status.includes(
                              "completed"
                            )) ? (
                            <>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                              >
                                <path
                                  d="M7.5 14.375C6.54896 14.375 5.65521 14.1945 4.81875 13.8336C3.98229 13.4727 3.25469 12.9828 2.63594 12.3641C2.01719 11.7453 1.52734 11.0177 1.16641 10.1813C0.805469 9.34479 0.625 8.45104 0.625 7.5C0.625 6.54896 0.805469 5.65521 1.16641 4.81875C1.52734 3.98229 2.01719 3.25469 2.63594 2.63594C3.25469 2.01719 3.98229 1.52734 4.81875 1.16641C5.65521 0.805469 6.54896 0.625 7.5 0.625C8.45104 0.625 9.34479 0.805469 10.1813 1.16641C11.0177 1.52734 11.7453 2.01719 12.3641 2.63594C12.9828 3.25469 13.4727 3.98229 13.8336 4.81875C14.1945 5.65521 14.375 6.54896 14.375 7.5C14.375 8.45104 14.1945 9.34479 13.8336 10.1813C13.4727 11.0177 12.9828 11.7453 12.3641 12.3641C11.7453 12.9828 11.0177 13.4727 10.1813 13.8336C9.34479 14.1945 8.45104 14.375 7.5 14.375ZM7.5 13C8.23333 13 8.93802 12.8625 9.61406 12.5875C10.2901 12.3125 10.8859 11.9172 11.4016 11.4016L7.5 7.5V2C5.96458 2 4.66406 2.53281 3.59844 3.59844C2.53281 4.66406 2 5.96458 2 7.5C2 9.03542 2.53281 10.3359 3.59844 11.4016C4.66406 12.4672 5.96458 13 7.5 13Z"
                                  fill="#00B5AD"
                                />
                              </svg>
                              In-Progress
                            </>
                          ) : (
                            <></>
                          )}
                          {!item.combined_response_status.includes("started") &&
                            item.combined_response_status.includes(
                              "completed"
                            ) &&
                            !item.combined_response_status.includes(
                              "pending"
                            ) && (
                              <>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="17"
                                  height="18"
                                  viewBox="0 0 17 18"
                                  fill="none"
                                >
                                  <mask
                                    id="mask0_285_2290"
                                    maskUnits="userSpaceOnUse"
                                    x="0"
                                    y="0"
                                    width="17"
                                    height="18"
                                  >
                                    <rect
                                      x="0.25"
                                      y="0.75"
                                      width="16.5"
                                      height="16.5"
                                      fill="#D9D9D9"
                                    />
                                  </mask>
                                  <g mask="url(#mask0_285_2290)">
                                    <path
                                      d="M8.5 15.875C7.54896 15.875 6.65521 15.6945 5.81875 15.3336C4.98229 14.9727 4.25469 14.4828 3.63594 13.8641C3.01719 13.2453 2.52734 12.5177 2.16641 11.6813C1.80547 10.8448 1.625 9.95104 1.625 9C1.625 8.04896 1.80547 7.15521 2.16641 6.31875C2.52734 5.48229 3.01719 4.75469 3.63594 4.13594C4.25469 3.51719 4.98229 3.02734 5.81875 2.66641C6.65521 2.30547 7.54896 2.125 8.5 2.125C9.45104 2.125 10.3448 2.30547 11.1813 2.66641C12.0177 3.02734 12.7453 3.51719 13.3641 4.13594C13.9828 4.75469 14.4727 5.48229 14.8336 6.31875C15.1945 7.15521 15.375 8.04896 15.375 9C15.375 9.95104 15.1945 10.8448 14.8336 11.6813C14.4727 12.5177 13.9828 13.2453 13.3641 13.8641C12.7453 14.4828 12.0177 14.9727 11.1813 15.3336C10.3448 15.6945 9.45104 15.875 8.5 15.875ZM7.1 12.25L12.3 7.05005L11.15 5.90005L7.1 9.95005L5.35 8.20005L4.2 9.35005L7.1 12.25Z"
                                      fill="#00B5AD"
                                    />
                                  </g>
                                </svg>
                                Completed
                              </>
                            )}
                        </div>
                      </div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                      >
                        <mask
                          id="mask0_3030_1158"
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="32"
                          height="32"
                        >
                          <rect width="32" height="32" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_3030_1158)">
                          <path
                            d="M22.4577 16L16.2648 9.86667L18.1496 8L26.2274 16L18.1496 24L16.2648 22.1333L22.4577 16Z"
                            fill="#003878"
                          />
                        </g>
                      </svg>
                    </div>
                  </div>
                )}
              />

              {hasMorePatients && patients?.length > 0 && (
                <Button
                  onClick={handleLoadMorePatients}
                  loading={isLoadingPatients}
                  type="primary"
                  style={{ width: "max-content", alignSelf: "center" }}
                >
                  Load More
                </Button>
              )}
            </div>
          </Layout>

          <div className="right_layout">
            {isLoadingPatientDetails ? (
              <div className="loading_data">
                <Col span={16} md={24}>
                  <Skeleton
                    active
                    avatar={{ shape: "square" }}
                    title={false}
                    paragraph={{}}
                  ></Skeleton>

                  <Skeleton active></Skeleton>
                  <Skeleton active></Skeleton>
                </Col>
              </div>
            ) : PatientArrayData?.length !== 0 ? (
              <>
                <PatientDisplay selectedPatient={PatientArrayData?.[0]} />
              </>
            ) : (
              <div className="no_data">No data</div>
            )}
          </div>
        </Layout>
      </div>
    </>
  );
}

export default PromPatient;
