import {
  HMSNotificationTypes,
  useHMSActions,
  useHMSNotifications,
} from "@100mslive/react-sdk";
import axios from "axios";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { submitCallLogs } from "../../../../../redux/reducers/appointment.slice";
import { ROUTE_PUBLIC_APPOINTMENT_VIEW } from "../../../../../shared/routes/route.constants";
import {
  UserPreferencesKeys,
  useUserPreferences,
} from "../hooks/useUserPreferences";
import { ToastBatcher } from "../Toast/ToastBatcher";

const notificationTypes = [
  HMSNotificationTypes?.PEER_LIST,
  HMSNotificationTypes?.PEER_JOINED,
  HMSNotificationTypes?.PEER_LEFT,
];

export const PeerNotifications = () => {
  const notification = useHMSNotifications(notificationTypes);
  const [{ subscribedNotifications }] = useUserPreferences(
    UserPreferencesKeys.UI_SETTINGS
  );
  const navigate = useNavigate();

  const hmsActions = useHMSActions();
  const { appointment } = useSelector((state) => state.appointment);
  const { profile } = useSelector((state) => state.profile);
  const { currentApplication } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const redirectToLeavePage = async () => {
    clearTimeout(startTime);
    const res = await axios.get("https://geolocation-db.com/json/");

    if (window.location.pathname.split("/")[5] === "guest") {
      // window.location.href = `patient/appointment-room/${appointment?.room_id}/${profile?.id}/guest`;
      window.location.href = ROUTE_PUBLIC_APPOINTMENT_VIEW(
        window.location.pathname.split("/")[4],
        window.location.pathname.split("/")[6],
        "guest"
      );
      return;
    }
    let data = {
      event: "LEFT",
      room: appointment?.room_id,
      user_type: currentApplication,
      user_id: profile?.id,
      device_type: "Browser",
      device_name: window.navigator.appCodeName,
      device_ip: res?.data?.IPv4,
      device_os: window.navigator.appVersion,
      reason: "Left because doctor left",
    };
    if (window.location.pathname.split("/")[1] === "patient") {
      dispatch(submitCallLogs(data));
      navigate(-1);
      return;
    }
  };

  const leaveRoom = () => {
    hmsActions.leave();
    redirectToLeavePage();
  };

  var startTime;

  useEffect(() => {
    if (!notification) {
      return;
    }
    console.debug(`[${notification.type}]`, notification);
    switch (notification.type) {
      case HMSNotificationTypes?.PEER_LIST:
      case HMSNotificationTypes?.PEER_JOINED:
        if (notification?.data?.roleName === "doctor") {
          clearTimeout(startTime);
          localStorage.setItem("away", "no");
          return;
        }
        break;
      case HMSNotificationTypes?.PEER_LEFT:
        if (notification?.data?.roleName === "doctor") {
          startTime = setTimeout(() => {
            if (localStorage?.getItem("away") === "yes") {
              localStorage.removeItem("away");
              leaveRoom();
            }
          }, 30000);
          localStorage.setItem("away", "yes");
          return;
        }
        break;
      default:
        clearTimeout(startTime);
        return;
    }
    ToastBatcher.showToast({ notification });
  }, [
    notification,
    subscribedNotifications?.PEER_JOINED,
    subscribedNotifications?.PEER_LEFT,
  ]);

  return null;
};
