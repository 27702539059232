import { Col, Row, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchQuicksightDashboardData } from "../../../redux/reducers/dashboard.slice";
import { ACCOUNT_PREFIX } from "../../../shared/constants";
type Props = {};
const Option = Select;
const EmbedDashboard: React.FunctionComponent<Props> = () => {
  const [url, setUrl] = useState(null as any);
  const [allDashboards, setAllDashboards] = useState(null as any);
  const [selectedDashboard, setSelectedDashboard] = useState({} as any);
  const dispatch = useDispatch();
  useEffect(() => {
    const data = localStorage.getItem("config");
    const allData = JSON.parse(data || "{}");
    setAllDashboards(
      allData?.DASHBOARDS_CONFIG?.allDashboards
        ? allData.DASHBOARDS_CONFIG.allDashboards
        : []
    );
  }, []);
  useEffect(() => {
    allDashboards?.length > 0 && setSelectedDashboard(allDashboards[0]);
  }, [allDashboards]);
  useEffect(() => {
    if (selectedDashboard?.id) {
      dispatch(
        fetchQuicksightDashboardData({
          dashboardId: selectedDashboard?.id,
          client: ACCOUNT_PREFIX,
        })
      ).then((val: any) => {
        if (val?.payload?.data?.data?.embedUrl) {
          setUrl(val?.payload?.data?.data?.embedUrl);
        }
      });
    }
  }, [dispatch, selectedDashboard]);

  const switchDashboard = (e: any) => {
    const selected = allDashboards.filter((val: any) => {
      return val.id === e;
    })[0];
    setSelectedDashboard(selected);
  };
  return (
    <>
      <Row
        style={{
          height: "100vh",
          position: "absolute",
          left: "0",
          top: "0",
          width: "100%",
          zIndex: 1,
        }}
        justify="center"
        align="middle"
      >
        <Col>
          <Spin size="large" />
        </Col>
      </Row>
      {url !== null && (
        <iframe
          title="quick sight iframe"
          className="quicksight-embedding-iframe"
          width="100%"
          height="100%"
          scrolling="no"
          src={url}
          style={{
            border: "0px",
            padding: "0px",
            height: "calc(100vh - 0px)",
            zIndex: 2,
            position: "absolute",
          }}
        ></iframe>
      )}
      <div
        style={{
          position: "absolute",
          zIndex: 9999,
          right: 0,
          padding: "5px 10px",
          background: "#e3e3e3",
          borderRadius: "0 0 0 10px",
          boxShadow: "0 0 5px rgba(0,0,0,0.6)",
        }}
      >
        {allDashboards?.length > 0 && selectedDashboard?.id && (
          <Row align="middle" gutter={16}>
            <Col>Select Dashboard</Col>
            <Col>
              <Select
                onChange={switchDashboard}
                style={{
                  width: "200px",
                }}
                defaultValue={selectedDashboard?.id}
              >
                {allDashboards?.map((val: any) => {
                  return <Option value={val.id}>{val.name}</Option>;
                })}
              </Select>
            </Col>
          </Row>
        )}
        {allDashboards?.length === 0 && "No dashboard present"}
      </div>
    </>
  );
};

export default EmbedDashboard;
