import { Skeleton } from "antd";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchPermissions } from "../../../../redux/reducers/permission.slice";
import { fetchAllTemplates } from "../../../../redux/reducers/prescription.slice";
import { RootState } from "../../../../shared/constants";
import DesktopFormLayout from "../../../../shared/layouts/page-header.layout";
import PrescriptionTemplateForm from "./template-form.page";

const PrescriptionTemplateEdit: React.FunctionComponent<any> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const RouteParams: any = useParams();
  const { prescriptionTemplate, loading } = useSelector(
    (state: RootState) => state.prescription
  );
  const [tempData, SetIempData] = useState({} as any);
  let tempRawData: any = {};
  //let tempData:any={}
  //console.log("prescriptionTemplateUrl",prescriptionTemplate,loading)
  const {
    permission: { permissions, loading: loadingPermissions },
  } = useSelector((state: RootState) => state);
  useEffect(() => {
    if (RouteParams["id"]) {
      dispatch(fetchAllTemplates({ id: RouteParams["id"] }));
    }
    dispatch(fetchPermissions({}));
  }, []);
  useEffect(() => {
    if (prescriptionTemplate.length && !tempData.length) {
      [tempRawData] = prescriptionTemplate;
      //console.log("tempData111",tempRawData,prescriptionTemplate);
      //console.log("tempRawData",tempRawData)
      (async function () {
        try {
          let selectedTemplateDataResponse = await fetch(tempRawData.url);
          let selectedTemplateDataRaw =
            await selectedTemplateDataResponse.json();
          SetIempData({ content: tempRawData, data: selectedTemplateDataRaw });
          //console.log("ttttt",tempData)
          //setGlobalJSONObj( selectedTemplateDataRaw);
        } catch (e) {
          console.error("tempRawData", e);
        }
      })();
      //console.log("tempData",tempData)
      //setTemplateDate({content:template});
    }
  }, [prescriptionTemplate]);

  return (
    <div className={"padding15"}>
      <DesktopFormLayout
        title={t("Prescription.EditTemplate")}
        subtitle={t("Prescription.AddTemplateSub")}
      >
        {prescriptionTemplate.length ? (
          <>
            <PrescriptionTemplateForm
              template={tempData}
              onSubmit={async (values: any) => {}}
            />
          </>
        ) : (
          <>
            <Skeleton />
          </>
        )}
      </DesktopFormLayout>
    </div>
  );
};

export default PrescriptionTemplateEdit;
