import { RightOutlined } from "@ant-design/icons";
import {
  Card,
  Col,
  Divider,
  Input,
  Row,
  Select,
  Skeleton,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAppointment,
  findAppointmentPatients,
  findLastVistedAppointmentPatients,
  resetAppointments,
} from "../../../redux/reducers/appointment.slice";
import { fetchPatientsDocuments } from "../../../redux/reducers/patients.slice";
import {
  fetchPatientsDischargeSummary,
  fetchPrescriptions,
  fetchPrescriptionsLabReports,
} from "../../../redux/reducers/prescription.slice";
import { syncProfile } from "../../../redux/reducers/profile.slice";
import { RootState } from "../../../shared/constants";
import ChatLayout from "../../../shared/layouts/chat.layout";
import FilesLayout from "../../../shared/layouts/files.layout";
import PatientNameCard from "../../../shared/layouts/name-card";
import NoDataLayout from "../../../shared/layouts/no-data.layout";
import { formatOnlyDate } from "../../../shared/Utils/utilities";
import AppointmentDetails from "../Appointments/components/appointment-details";
import AppointmentRecords from "../Appointments/components/appointment-records";
import PatientList from "../Patients/patients.page";

const { Text, Paragraph } = Typography;
const _ = require("lodash");
const { Search } = Input;
const { Option } = Select;
type Props = {};

const DoctorPatientsList: React.FunctionComponent<Props> = () => {
  const dispatch = useDispatch();
  const [detail, setDetail] = useState(false);
  const [patientDetail, setpatientDetail] = useState(null as any);
  const {
    loading,
    appointments,
    appointment_patients,
    patient_loading,
    last_visit,
  } = useSelector((state: RootState) => state.appointment);
  const {
    prescriptions,
    labreports,
    dischargeSummary,
    loading: prescriptionLoader,
  } = useSelector((state: RootState) => state.prescription);
  const { Documents } = useSelector((state: RootState) => state.patient);
  const [chat, setChat] = useState(false);
  const [appointment, setAppointment] = useState(null as any);
  const [documents, setdocuments] = useState(false);
  const { profile } = useSelector((state: RootState) => state.profile);
  const [isPatient, setPatientStatus] = useState(false);
  const [foundPatientShow, setfoundPatientShow] = useState(false);
  const [first_time, setfirst_time] = useState(false);
  const [dateFilter, setdateFilter] = useState("6 months");
  const [defaultSearchValue, setdefaultSearchValue] = useState("T");
  const [searchPatient, setSearchPatient] = useState("");
  const [loader, setloader] = useState(false);

  useEffect(() => {
    dispatch(
      findLastVistedAppointmentPatients({
        doctor_id: profile.id,
        historical: 1,
      })
    );
    setfirst_time(true);
    return () => {
      dispatch(resetAppointments());
    };
  }, [dispatch, profile.id]);

  const showPatientDetails = async (value: any) => {
    setloader(true);
    setdateFilter("6 months");
    setDetail(false);
    setdocuments(false);
    setpatientDetail(value);
    dispatch(
      fetchAppointment({
        patient_id: value.id,
        doctor_id: profile.id,
        historical: 1,
      })
    );
    await dispatch(
      fetchPrescriptions({
        patient_id: value.id,
        doctor_id: profile.id,
        start_date: moment().subtract(6, "month").format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
      })
    );
    await dispatch(
      fetchPrescriptionsLabReports({
        patient_id: value.id,
        historical: "1",
        start_date: moment().subtract(6, "month").format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
      })
    );
    await dispatch(
      fetchPatientsDischargeSummary({
        uhid: value?.uhid,
        start_date: moment().subtract(6, "month").format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
      })
    );
    dispatch(syncProfile({ patient_id: value.id }));
    setDetail(true);
    setAppointment(null);
    setloader(false);
  };

  const getPatientDetails = (patient: any) => {
    setSearchPatient(patient);
    if (patient === "") {
      setfoundPatientShow(false);
      setfirst_time(true);
      return;
    }
    if (patient.length < 3) {
      return;
    }

    if (defaultSearchValue === "T") {
      dispatch(
        findAppointmentPatients({
          doctor_id: profile.id,
          q: patient,
          historical: 1,
        })
      );
    } else {
      dispatch(
        findAppointmentPatients({
          doctor_id: profile.id,
          q: patient,
          search_by: "uhid",
          historical: 1,
        })
      );
    }
    setfirst_time(false);
    setfoundPatientShow(true);
  };

  const openDetails = async () => {
    setloader(true);
    setdateFilter("6 months");
    await dispatch(
      fetchPrescriptions({
        patient_id: patientDetail.id,
        doctor_id: profile.id,
        start_date: moment().subtract(6, "month").format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
      })
    );
    await dispatch(
      fetchPrescriptionsLabReports({
        patient_id: patientDetail.id,
        historical: "1",
        start_date: moment().subtract(6, "month").format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
      })
    );
    await dispatch(
      fetchPatientsDischargeSummary({
        uhid: patientDetail?.uhid,
        start_date: moment().subtract(6, "month").format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
      })
    );
    setdocuments(false);
    setDetail(true);
    setloader(false);
  };

  const openDocuments = (type: any) => {
    setdocuments(true);
    setPatientStatus(type);
    setDetail(false);
  };

  const onDateFilter = (date: any, start_date: any, end_date: any) => {
    setdateFilter(date);
    dispatch(
      fetchPrescriptions({
        patient_id: patientDetail.id,
        doctor_id: profile.id,
        start_date: start_date,
        end_date: end_date,
      })
    );
    dispatch(
      fetchPrescriptionsLabReports({
        patient_id: patientDetail.id,
        historical: "1",
        start_date: start_date,
        end_date: end_date,
      })
    );
    dispatch(
      fetchPatientsDischargeSummary({
        uhid: patientDetail?.uhid,
        start_date: start_date,
        end_date: end_date,
      })
    );
  };

  const showAppointmentDetails = (_appointment: any) => {
    (async function () {
      try {
        if (_appointment?.type === "thb") {
          const response = await dispatch(
            fetchPatientsDocuments({
              patient_id: _appointment?.patient_id,
              doctor_id: profile.id,
              appointment_id: _appointment.id,
            })
          );
          dispatch(
            fetchPrescriptions({
              patient_id: _appointment.patient_id,
              doctor_id: profile.id,
              appointment_id: _appointment?.id,
            })
          );
          if (
            response.payload.status === 201 ||
            response.payload.status === 200
          ) {
            setAppointment(_appointment);
            setPatientStatus(true);
          }
        } else {
          dispatch(
            fetchPrescriptions({
              max_id: _appointment.max_id,
              doctor_id: profile.id,
              external_appointment_id: _appointment?.external_booking_no,
            })
          );
          setAppointment(_appointment);
          setPatientStatus(true);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  };
  let _data = prescriptions.concat(labreports);
  let filesCount = _.groupBy(_data, "group");
  return (
    <Row>
      <Col
        span={7}
        className="full-height white-background  overflowhide border-right "
        style={{ paddingBottom: "50px" }}
      >
        <Row>
          <Col span={24} className={"padding20 "}>
            <h2 className="bold doctor-primary-color mt10 ">
              My Patients (OPD Only)
            </h2>
            <Input.Group compact>
              <Select
                size={"large"}
                style={{ width: "40%" }}
                className={"doctor-secondary-color rightBorderRadius"}
                value={defaultSearchValue}
                onChange={(evt) => {
                  setdefaultSearchValue(evt);
                  setSearchPatient("");
                  setfoundPatientShow(false);
                  setDetail(false);
                  setdocuments(false);
                  setfirst_time(true);
                }}
              >
                <Option value={"T"}> Name/Ph.No</Option>
                <Option value={"U"}>UHID</Option>
              </Select>
              <Search
                style={{ width: "60%" }}
                allowClear
                size={"large"}
                placeholder="Search Patient"
                className="doctor-search leftBorderRadius border-radius5"
                value={searchPatient}
                onChange={(evt: any) => getPatientDetails(evt.target.value)}
              ></Search>
            </Input.Group>
          </Col>
        </Row>

        {patient_loading ? (
          <Row className="mt20 nomarginLR">
            {" "}
            <Col span={20} offset={2} className={"padding10"}>
              <Skeleton active></Skeleton>{" "}
              <Skeleton className={"mt20"} active></Skeleton>
            </Col>
          </Row>
        ) : (
          <Fragment>
            {foundPatientShow && (
              <Row className="doctor-light-background  borderRightGray nomarginLR20">
                <Col span={20} offset={2} className={"padding10"}>
                  {appointment_patients && (
                    <h3 className="bold doctor-primary-color mt10 ">
                      {appointment_patients &&
                        appointment_patients.length + " Patients Found"}
                    </h3>
                  )}
                </Col>
              </Row>
            )}
            {first_time && (
              <Row className="doctor-light-background  borderRightGray nomarginLR20">
                <Col span={20} offset={2} className={"padding10"}>
                  {last_visit && (
                    <h3 className="bold doctor-primary-color mt10 ">
                      {last_visit &&
                        last_visit.length +
                          `${
                            last_visit.length === 1 ? " Patient" : " Patients"
                          }  Visted Yesterday`}
                    </h3>
                  )}
                </Col>
              </Row>
            )}

            <Row>
              <Col span={24} className={"nopadding"}>
                <PatientList
                  type={1}
                  patients={first_time ? last_visit : appointment_patients}
                  selectedAppointment={patientDetail}
                  showDetails={(item: any) => showPatientDetails(item)}
                ></PatientList>
              </Col>
            </Row>
          </Fragment>
        )}
      </Col>
      <Col span={17} className="full-height">
        {loader ? (
          <p className={"text-center mt200"}>
            {" "}
            <Spin className={"text-center mt200"}></Spin>{" "}
          </p>
        ) : !detail && !documents ? (
          <NoDataLayout
            className={""}
            placeholder={"Select a patient to view their details"}
          ></NoDataLayout>
        ) : detail && !documents ? (
          <Fragment>
            {" "}
            <Row>
              <Col span={20} className="white-background paddingLR20">
                <PatientNameCard
                  type={"patient"}
                  showBack={appointment === null ? false : true}
                  onBack={() =>
                    showPatientDetails(appointment?.patient || patientDetail)
                  }
                  details={patientDetail}
                ></PatientNameCard>
              </Col>
              <Col span={4} className="white-background text-right padding20">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  className=" cursor"
                  onClick={() => setChat(true)}
                  style={{ marginTop: "7px" }}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.1318 0.615234C10.7112 0.615234 10.2906 0.615234 9.87001 0.615234C9.81675 0.62707 9.76391 0.641864 9.70981 0.650741C9.20511 0.732322 8.69153 0.776705 8.19697 0.899288C3.10302 2.16316 -0.00382186 7.34757 1.28752 12.4314C1.88226 14.7731 3.18967 16.6351 5.18777 17.9882C7.18164 19.3383 9.39447 19.8328 11.7789 19.5285C12.946 19.3797 14.0488 19.012 15.0777 18.4354C15.1821 18.3771 15.2734 18.369 15.3892 18.4066C16.5668 18.79 17.7474 19.165 18.9259 19.5471C19.1724 19.627 19.4234 19.6646 19.6335 19.4997C19.781 19.3844 19.8795 19.2068 20 19.0568C20 18.9456 20 18.834 20 18.7228C19.9924 18.7131 19.9818 18.7047 19.978 18.6937C19.5858 17.4691 19.1964 16.2437 18.8 15.0208C18.7585 14.8927 18.7653 14.7938 18.8304 14.6763C19.3736 13.691 19.732 12.6423 19.8909 11.5268C19.9265 11.2787 19.9636 11.0305 20 10.7824C20 10.3496 20 9.91673 20 9.48389C19.9708 9.25225 19.9459 9.01976 19.9121 8.78897C19.6809 7.20934 19.1115 5.76371 18.1528 4.48505C16.588 2.39818 14.5138 1.14023 11.9349 0.724714C11.6686 0.681175 11.3998 0.651164 11.1318 0.615234ZM18.0979 17.7029C17.9901 17.6745 17.9254 17.6602 17.8628 17.6403C17.1705 17.418 16.4667 17.2248 15.7895 16.9623C15.2734 16.7623 14.8583 16.8363 14.3806 17.1064C12.6645 18.0757 10.8185 18.3542 8.88724 17.9565C6.85025 17.5367 5.22032 16.4627 4.00675 14.7799C2.64481 12.8908 2.20351 10.7833 2.66003 8.50661C3.06878 6.46793 4.14962 4.83758 5.83196 3.62359C7.72608 2.257 9.83831 1.81528 12.1222 2.27729C14.1608 2.68942 15.7937 3.76899 16.9988 5.45768C18.521 7.59062 18.8748 9.93618 18.16 12.4483C17.9748 13.0988 17.6921 13.7083 17.3391 14.2845C17.1836 14.5381 17.1493 14.7947 17.2516 15.0787C17.3683 15.4034 17.4646 15.7352 17.5703 16.064C17.7411 16.5958 17.9131 17.1288 18.0979 17.7029Z"
                    fill="#163B6D"
                  />
                  <path
                    d="M13.2855 10.1141C13.2855 10.6289 13.7006 11.0423 14.2167 11.0406C14.7312 11.0389 15.1416 10.6221 15.1387 10.1048C15.1357 9.59879 14.7236 9.1892 14.2167 9.18751C13.7006 9.18581 13.2855 9.59879 13.2855 10.1141Z"
                    fill="#163B6D"
                  />
                  <path
                    d="M10.5062 9.18751C9.9897 9.18539 9.57418 9.59795 9.57418 10.1132C9.57376 10.6285 9.98843 11.0419 10.5045 11.0406C11.0118 11.0394 11.4239 10.6298 11.4277 10.1242C11.4307 9.60683 11.0207 9.18962 10.5062 9.18751Z"
                    fill="#163B6D"
                  />
                  <path
                    d="M5.86362 10.1225C5.86658 10.6361 6.28505 11.0448 6.80328 11.0406C7.31559 11.0364 7.72265 10.6154 7.71673 10.0963C7.71082 9.59245 7.29488 9.18623 6.78637 9.1875C6.27153 9.18877 5.86066 9.60513 5.86362 10.1225Z"
                    fill="#163B6D"
                  />
                </svg>
              </Col>
            </Row>
            {appointment === null ? (
              <Row className="padding20">
                <Col span={24} className="doctor-box-shadow doctor-top-border ">
                  <Row>
                    <Col span={24} className="doctor-color-heading2">
                      <Row>
                        <Col span={24} className={""}>
                          <span className="doctor-primary-color font15 bold">
                            All Appointments{" "}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24} className="white-background padding-main">
                      {loading ? (
                        <Skeleton></Skeleton>
                      ) : appointments.length === 0 ? (
                        <p className={"text-center mt20"}>No Appointments</p>
                      ) : (
                        appointments.map((_appointment: any, index: any) => {
                          return (
                            <>
                              <Row key={index}>
                                <Col span={20}>
                                  <span
                                    className="doctor-primary-color cursor"
                                    onClick={() =>
                                      showAppointmentDetails(_appointment)
                                    }
                                  >
                                    {formatOnlyDate(
                                      _appointment.appointment_date
                                    )}{" "}
                                    | {_appointment.start_time} <br></br>{" "}
                                    <span
                                      style={{
                                        color: "#163B6D",
                                        opacity: 0.5,
                                      }}
                                      className=" capitalize"
                                    >
                                      Status: {_appointment.latest_status}
                                    </span>{" "}
                                  </span>
                                </Col>
                                <Col
                                  style={{ alignSelf: "center" }}
                                  span={4}
                                  className="text-right "
                                >
                                  <RightOutlined
                                    className="cursor"
                                    onClick={() =>
                                      showAppointmentDetails(_appointment)
                                    }
                                  />
                                </Col>
                              </Row>
                              {
                                // console.log("xxxxxxxxxxxx",appointments.filter((a: any) => a.latest_status !== "pending").length)
                                index + 1 !== appointments.length ? (
                                  <Divider style={{ margin: "20px 0" }} />
                                ) : (
                                  ""
                                )
                              }
                            </>
                          );
                        })
                      )}
                    </Col>
                  </Row>
                </Col>
                {!loading && (
                  <Col
                    span={24}
                    className="doctor-box-shadow doctor-top-border mt20"
                  >
                    <Row>
                      <Col span={24} className="doctor-color-heading2">
                        <Row>
                          <Col span={24} className={""}>
                            <span className="doctor-primary-color font15 bold">
                              Max Records{" "}
                            </span>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24} className="white-background padding-main">
                        {Object.keys(filesCount).length === 0 ? (
                          <p className={"text-center max-subHead-color mt20"}>
                            No Records found
                          </p>
                        ) : (
                          <Row>
                            <Col span={20}>
                              {
                                <span className="doctor-primary-color">
                                  {Object.keys(filesCount).map((item) => {
                                    return (
                                      <span>
                                        {" "}
                                        {filesCount[item].length + " " + item}
                                      </span>
                                    );
                                  })}
                                </span>
                              }
                            </Col>
                            <Col span={4} className="text-right ">
                              {_data.length !== 0 && (
                                <Text
                                  className="doctor-secondary-color cursor font12"
                                  underline
                                  onClick={() => openDocuments(false)}
                                >
                                  View All
                                </Text>
                              )}
                            </Col>
                          </Row>
                        )}
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            ) : (
              <Row className="padding20">
                <Col span={24} className="doctor-box-shadow doctor-top-border ">
                  <Row>
                    <Col span={24} className="doctor-color-heading2">
                      <Row>
                        <Col span={24} className={""}>
                          <span className="doctor-primary-color font15 bold">
                            Appointment Details{" "}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <AppointmentDetails
                        showCall={false}
                        appointment={appointment}
                      ></AppointmentDetails>
                    </Col>
                  </Row>
                </Col>
                <Col
                  span={24}
                  className="doctor-box-shadow doctor-top-border mt20"
                >
                  <Row>
                    <Col span={24} className="doctor-color-heading2">
                      <Row>
                        <Col span={20} className={""}>
                          <span className="doctor-primary-color font15 bold">
                            Records Uploaded by Patient
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24} className={"white-background"}>
                      {prescriptionLoader ? (
                        <Skeleton></Skeleton>
                      ) : (
                        <AppointmentRecords
                          prescriptions={Documents}
                          details={appointment}
                          isPrescription={false}
                          showNoData={true}
                          openFilesUploaded={() => openDocuments(true)}
                        ></AppointmentRecords>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col
                  span={24}
                  className="doctor-box-shadow doctor-top-border mt20"
                >
                  <Row>
                    <Col span={24} className="doctor-color-heading2">
                      <Row>
                        <Col span={20} className={""}>
                          <span className="doctor-primary-color font15 bold">
                            Prescriptions Uploaded by Doctor
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24} className={"white-background"}>
                      {prescriptionLoader ? (
                        <Skeleton></Skeleton>
                      ) : (
                        <AppointmentRecords
                          prescriptions={prescriptions}
                          details={appointment}
                          isPrescription={true}
                          showNoData={true}
                          openFilesUploaded={() => openDocuments(false)}
                        ></AppointmentRecords>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </Fragment>
        ) : (
          !detail &&
          documents && (
            <>
              <FilesLayout
                files={isPatient ? Documents : _data}
                details={patientDetail}
                type={isPatient ? "patient" : "doctor"}
                showPatientCard={true}
                showBack={true}
                dateFilter={dateFilter}
                onBack={() => openDetails()}
                onDateFilter={(date: any, start_date: any, end_date: any) =>
                  onDateFilter(date, start_date, end_date)
                }
              ></FilesLayout>
              {/* <FilesLayout
                files={isPatient ? Documents : prescriptions}
                details={patientDetail}
                type={isPatient ? "patient" : "doctor"}
                showPatientCard={true}
                showBack={true}
                dateFilter={dateFilter}
                onBack={() => openDetails()}
                onDateFilter={(date: any) => onDateFilter(date)}
              ></FilesLayout> */}
              {dischargeSummary &&
                dischargeSummary?.discharge_reports &&
                dischargeSummary?.discharge_reports?.length !== 0 && (
                  <Col span={24} className="padding20 doctor-top-border">
                    <Row>
                      <Col span={24} className="doctor-color-heading2">
                        <Row>
                          <Col span={24} className={""}>
                            <span className="doctor-primary-color font15 bold">
                              Discharge Summary
                            </span>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24} className="white-background padding-main">
                        <Row gutter={20}>
                          {dischargeSummary?.discharge_reports.map(
                            (file: any) => {
                              return (
                                <Col xs={24} sm={12} md={6} lg={4} xl={4}>
                                  {" "}
                                  <Card
                                    className={"padding10 mb-10"}
                                    title={
                                      <p className="text-center">
                                        <div style={{ height: "50px" }}>
                                          <img
                                            onClick={() =>
                                              window.open(file.url)
                                            }
                                            alt="img"
                                            src={
                                              require("../../../assets/images/doc.svg")
                                                .default
                                            }
                                            height="38px"
                                            width={"60%"}
                                            className={"cursor"}
                                          ></img>
                                        </div>
                                      </p>
                                    }
                                    bordered={true}
                                  >
                                    <Tooltip
                                      placement="top"
                                      title={file.report_name}
                                    >
                                      <Paragraph
                                        ellipsis={{
                                          rows: 1,
                                          expandable: false,
                                          symbol: "",
                                        }}
                                        title={file.name}
                                        className="text-center doctor-primary-color cursor mb-0 capitalize"
                                        onClick={() => window.open(file.url)}
                                      >
                                        {file.report_name}
                                      </Paragraph>
                                    </Tooltip>
                                    {/* <p className={"text-center font12"}>
                                     
                                      {formatDate(file.created_at, "DD MMM'YY")}
                                    </p> */}
                                  </Card>{" "}
                                </Col>
                              );
                            }
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                )}
            </>
          )
        )}

        {chat && (
          <ChatLayout
            isDrawer={true}
            show={chat}
            details={patientDetail}
            type={"patient"}
            loginUser={"doctor"}
            setChat={(value: any) => setChat(value)}
          ></ChatLayout>
        )}
      </Col>
    </Row>
  );
};

export default DoctorPatientsList;
