import { Row, Col, Space } from "antd";
import { useNavigate } from "react-router-dom";
import BackIcon from "../icons/BackIcon";

function GoBacklayout({ heading, route }: any) {
  const navigate = useNavigate();
  return (
    <Row>
      <Col span={24}>
        {route === undefined ? (
          <Space
            style={{ fontSize: "20px" }}
            onClick={() => navigate(-1)}
            className="cursor"
          >
            {" "}
            <BackIcon height="20" /> {heading}{" "}
          </Space>
        ) : (
          <Space
            style={{ fontSize: "20px" }}
            onClick={() => navigate(route)}
            className="cursor"
          >
            {" "}
            <BackIcon height="20" /> {heading}{" "}
          </Space>
        )}
      </Col>
    </Row>
  );
}

export default GoBacklayout;
