import React from "react";
import ValidationComponent from "./ValidationComponent";
// datepicker component
import { Col, DatePicker } from "antd";
import moment from "moment";
import { Portal } from "react-overlays";

import { DateFormat } from "../../../Constants/AppConstants"; //'../../../constants/AppConstants';
const CalendarContainer = ({ children }) => {
  const el = document.getElementById("calendar-portal");

  return <Portal container={el}>{children}</Portal>;
};

class DateComponent extends React.Component {
  getFormatedDate(date) {
    if (!date) {
      return;
    }
    if (date.indexOf("-") === -1) {
      return date;
    }
    let dateArray = date.split("-");
    return dateArray[0] + "-" + dateArray[1] + "-" + dateArray[2];
  }

  changeValue = (name, dateValue, value) => {
    this.props.emitTextboxValue({ name: name, value: value });
  };

  setErrorStatus = (errorStatus) => {
    this.props.emitErrorStatus(errorStatus);
  };

  checkForLabelValue(str) {
    if (!str || !str.trim()) {
      return false;
    }
    return true;
  }

  onFocus = (event) => {
    event.preventDefault();
    this.props.emitFocusEvent();
  };

  getFormFormatedDate = (date) => {
    if (!date) {
      return;
    }
    if (date.indexOf("-") === -1) {
      return date;
    }
    let dateArray = date.split("-");
    return dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0];
  };
  render() {
    const {
      name,
      value,
      label,
      labelSize,
      size,
      offset,
      rowType,
      validation,
      viewType,
    } = this.props;
    let selectedDate = value;
    // && value.split ? `${value.split('-')[1]}-${value.split('-')[0]}-${value.split('-')[2]}` : ''
    return (
      <>
        {labelSize && (
          <>
            {viewType ? (
              <>
                {selectedDate && (
                  <div
                    className={`ant-col ant-col-${
                      labelSize * 2
                    } ant-form-item-label`}
                    style={{
                      textAlign: "left",
                      display: this.checkForLabelValue(label) ? "" : "none",
                    }}
                  >
                    <label
                      for="basic_username"
                      className="ant-form-item"
                      style={{ color: "#000000d9" }}
                    >
                      {/* ant-form-item-required */}
                      {label}
                    </label>
                  </div>
                )}
              </>
            ) : (
              <>
                <div
                  className={`ant-col ant-col-${
                    labelSize * 2
                  } ant-form-item-label`}
                  style={{
                    textAlign: "left",
                    display: this.checkForLabelValue(label) ? "" : "none",
                  }}
                >
                  <label
                    for="basic_username"
                    className="ant-form-item"
                    style={{ color: "#000000d9" }}
                  >
                    {/* ant-form-item-required */}
                    {label}
                    {validation && validation.required && (
                      <span style={{ color: "#ff4d4f" }}>*</span>
                    )}
                  </label>
                </div>
              </>
            )}
          </>
        )}
        <Col
          span={`col-${size * 2}`}
          style={{
            padding: rowType === "row" ? "" : "",
            margin: rowType === "row" ? "" : "",
          }}
        >
          {!labelSize && (
            <label
              className={`col-form-label mb-0`}
              style={{
                display: this.checkForLabelValue(label) ? "" : "none",
                color: "#000000d9",
              }}
            >
              {label}
            </label>
          )}
          {/* -auto */}
          {viewType ? (
            <>{this.getFormatedDate(selectedDate)}</>
          ) : (
            <div className="" style={{ marginBottom: ".5rem" }}>
              <DatePicker
                style={{ width: "100%" }}
                onFocus={this.onFocus}
                className="ant-picker ant-picker"
                disabledDate={(d) =>
                  (validation && validation.disableFutureDates) ||
                  validation.disablePastDates
                    ? validation.disableFutureDates
                      ? d.isAfter(new Date())
                      : d.isSameOrBefore(new Date())
                    : false
                }
                minDate={
                  validation && validation.disablePastDates
                    ? moment(new Date())
                    : ""
                }
                maxDate={
                  validation && validation.disableFutureDates
                    ? moment(new Date())
                    : ""
                }
                peekNextMonth
                format={DateFormat}
                popperContainer={CalendarContainer}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                value={value ? moment(this.getFormFormatedDate(value)) : null}
                onChange={this.changeValue.bind(this, name)}
              />
            </div>
          )}

          {this.props.validation && (
            <ValidationComponent
              value={value}
              checkForm={this.props.checkForm}
              isDirty={this.props.isDirty}
              validation={this.props.validation}
              emitErrorStatus={this.setErrorStatus.bind(this)}
            />
          )}
        </Col>
        {offset && <div className={offset}> </div>}
      </>
    );
  }
}

export default DateComponent;
