import { DownOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Affix,
  Button,
  Col,
  message,
  Modal,
  notification,
  Radio,
  Row,
  Skeleton,
  Space,
  Typography,
} from "antd";
import moment from "moment";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewAppointemnt,
  fetchAppointment,
} from "../../../redux/reducers/appointment.slice";
import {
  coordinatorSwitch,
  fetchMyPermissions,
} from "../../../redux/reducers/auth.slice";
import { fetchProfile } from "../../../redux/reducers/profile.slice";
import UserNameComponent from "../../../shared/components/user.name.components";
import { RootState } from "../../../shared/constants";
import CalenderTaskView from "../../../shared/layouts/calender-task-view.layout";
import PatientNameCard from "../../../shared/layouts/name-card";
import NoDataLayout from "../../../shared/layouts/no-data.layout";
import { ROUTE_DOCTOR_DASHBOARD } from "../../../shared/routes/doctor.routes.constants";
import {
  generateGreeting,
  getDayFormat,
} from "../../../shared/Utils/utilities";
import DoctorAddAppointment from "../Appointments/components/add-appointment";
import AppointmentCountCard from "../Appointments/components/appointment-count-card";

const { Title, Text } = Typography;

const DoctorDashboardPage: React.FunctionComponent<any> = () => {
  const [calender, setCalender] = useState(false);
  const dispatch = useDispatch();
  const { loading } = useSelector((state: RootState) => state.appointment);
  const { profile, reset, coordinators_doctors } = useSelector(
    (state: RootState) => state.profile
  );
  const [_selectedDoctor, _setSelectedDoctor] = useState(null as any);
  const [nextAppointment, setNextAppointments] = useState([] as Array<String>);
  const [todayAppointments, setTodayAppointments] = useState(
    [] as Array<String>
  );
  const [currentDate, setCurrentDate] = useState(moment());
  const [loader, setLoader] = useState(true as any);
  const [tomloader, setTomLoader] = useState(true as any);
  const [addAppointment, setAddAppointment] = useState(false);
  const [enableProfileSelector, setEnableProfileSelector] = useState(false);
  const [calenderAppointments, setCalenderAppointments] = useState([] as any);
  const [calnderloader, setcalnderloader] = useState(true as any);
  const { connection } = useSelector((state: RootState) => state.chat);

  useEffect(() => {
    if (profile && coordinators_doctors?.length && !_selectedDoctor) {
      const _temp = coordinators_doctors.find(
        (a: any) => a.doctor_id === profile.id
      );
      if (_temp) {
        _setSelectedDoctor(_temp.doctor_id);
        getDoctorAppointments(_temp.doctor_id, currentDate);
      } else {
        setEnableProfileSelector(true);
      }
    }
  }, [profile, coordinators_doctors]);

  useEffect(() => {
    if (!enableProfileSelector) {
      (async function () {
        try {
          const response = await dispatch(fetchProfile("users"));
          if (response.payload.status === 200) {
            if (response?.payload?.data?.data?.type !== "coordinator") {
              setLoader(true);
              setTomLoader(true);
              getDoctorAppointments(
                response?.payload?.data?.data?.id,
                currentDate
              );
            }
          }
        } catch (e) {
          console.error(e);
        }
      })();
    }
  }, []);

  useEffect(() => {
    const wsCurrent = connection;

    if (!wsCurrent) return;
    wsCurrent.onmessage = (e: any) => {
      const _message = JSON.parse(e.data);
      if (_message.type === "appointment_update") {
        getDoctorAppointments(profile.id, currentDate);
        return;
      }
    };
  });

  const getDoctorAppointments = (id: any, _currentDate: any) => {
    (async function () {
      try {
        setLoader(true);
        setTomLoader(true);
        setTodayAppointments([]);
        setNextAppointments([]);
        const todayAppointments = await dispatch(
          fetchAppointment({
            doctor_id: id,
            appointment_date: moment(_currentDate).format("YYYY-MM-DD"),
            branch_wise_count: true,
          })
        );

        if (todayAppointments.payload.status === 200) {
          let _todayAppointments = [] as Array<any>;
          todayAppointments.payload?.data?.data?.items.forEach((_item: any) => {
            _todayAppointments.push(_item);
          });
          setTodayAppointments(_todayAppointments);
          setLoader(false);
        }

        const tomorrowAppointments = await dispatch(
          fetchAppointment({
            doctor_id: id,
            appointment_date: moment(_currentDate)
              .add(1, "days")
              .format("YYYY-MM-DD"),
            branch_wise_count: true,
          })
        );
        if (tomorrowAppointments.payload.status === 200) {
          let _nextAppointment = [] as Array<any>;
          tomorrowAppointments.payload?.data?.data?.items.forEach(
            (_item: any) => {
              _nextAppointment.push(_item);
            }
          );
          setNextAppointments(_nextAppointment);
          setTomLoader(false);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const handleAddAppointment = useCallback(
    async (values: any) => {
      try {
        const response = await dispatch(addNewAppointemnt(values));
        if (
          response.payload.status === 201 ||
          response.payload.status === 200
        ) {
          setAddAppointment(false);
          message.success("Appointment Added");
          dispatch(reset());
        }
      } catch (e) {
        console.error(e);
      }
    },
    [dispatch, reset]
  );

  const showCalenderView = () => {
    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();

    (async function () {
      try {
        setcalnderloader(true);
        const response = await dispatch(
          fetchAppointment({
            doctor_id: profile.id,
            calender_view: true,
            start_date: moment(new Date(y, m, 1)).format("YYYY-MM-DD"),
            end_date: moment(new Date(y, m + 1, 0)).format("YYYY-MM-DD"),
          })
        );
        if (
          response.payload.status === 201 ||
          response.payload.status === 200
        ) {
          setCalenderAppointments(response.payload?.data?.data?.items);
          setcalnderloader(false);
          setCalender(true);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const showPreviousAppointments = () => {
    setLoader(true);
    setTomLoader(true);
    setCurrentDate(moment(currentDate).subtract(1, "days"));
    getDoctorAppointments(profile.id, moment(currentDate).subtract(1, "days"));
    // getDoctorAppointments(profile.id, moment(currentDate).format("YYYY-MM-DD"))
  };

  const showNextAppointments = () => {
    setLoader(true);
    setTomLoader(true);
    setCurrentDate(moment(currentDate).add(1, "days"));
    getDoctorAppointments(profile.id, moment(currentDate).add(1, "days"));
    // getDoctorAppointments(profile.id, moment(currentDate).add(2, 'days').format("YYYY-MM-DD"))
  };

  const openaddAppointment = () => {
    setAddAppointment(true);
  };

  const onChangeDate = (_date: any) => {
    setcalnderloader(true);
    setTomLoader(true);
    setCurrentDate(moment(_date));
    (async function () {
      try {
        const response = await dispatch(
          fetchAppointment({
            doctor_id: profile.id,
            calender_view: true,
            start_date: moment(_date).startOf("month").format("YYYY-MM-DD"),
            end_date: moment(_date).endOf("month").format("YYYY-MM-DD"),
          })
        );
        if (
          response.payload.status === 201 ||
          response.payload.status === 200
        ) {
          setCalenderAppointments(response.payload?.data?.data?.items);
          setcalnderloader(false);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const onSelectDate = (_date: any) => {
    (async function () {
      try {
        setCalender(false);
        setLoader(true);
        setTomLoader(true);
        setCurrentDate(moment(_date));
        await getDoctorAppointments(
          profile.id,
          moment(_date).format("YYYY-MM-DD")
        );
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const changeUser = async () => {
    if (_selectedDoctor) {
      try {
        const response = await dispatch(coordinatorSwitch(_selectedDoctor));
        if (response?.payload?.data?.data) {
          await dispatch(fetchMyPermissions());
          const response = await dispatch(fetchProfile("users"));
          if (response.payload.status === 200) {
            window.location.href = ROUTE_DOCTOR_DASHBOARD;
          }
        }
      } catch (e) {
        notification.error({ message: "Unable to switch doctor" });
      }
    }
    // dispatch(setUserImpersonation(coordinators_doctors.find((a: any) => a.doctor_id === _selectedDoctor)))
    // setEnableProfileSelector(false);
    // getDoctorAppointments(_selectedDoctor)
  };
  return !addAppointment ? (
    <div className={"doctor-grey-background full-height "}>
      <Row
        style={{ paddingLeft: "40px", paddingRight: "40px" }}
        className={"padding20 doctor-app"}
      >
        <Col span={18}>
          <Space>
            <svg
              width="36"
              height="36"
              className={"cursor"}
              viewBox="0 0 46 46"
              onClick={() => showPreviousAppointments()}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle opacity="0.34" cx="23" cy="23" r="23" fill="#003E72" />
              <path
                d="M24.5284 17.8697C24.9423 17.504 25.5743 17.5432 25.9399 17.9571C26.3055 18.371 26.2664 19.003 25.8525 19.3686L24.5284 17.8697ZM19.7142 23.4564L19.0385 24.1936C18.8295 24.002 18.7117 23.7307 18.7143 23.4472C18.7169 23.1637 18.8397 22.8947 19.0522 22.707L19.7142 23.4564ZM25.8662 27.7391C26.2733 28.1123 26.3008 28.7449 25.9276 29.152C25.5544 29.5591 24.9218 29.5866 24.5147 29.2134L25.8662 27.7391ZM25.8525 19.3686L20.3763 24.2059L19.0522 22.707L24.5284 17.8697L25.8525 19.3686ZM20.39 22.7193L25.8662 27.7391L24.5147 29.2134L19.0385 24.1936L20.39 22.7193Z"
                fill="#003E72"
              />
            </svg>
            <div style={{ marginTop: "10px" }}>
              <Title
                level={1}
                style={{ fontSize: "30px" }}
                className={"paddingLR10  doctor-primary-color "}
              >
                {getDayFormat(currentDate, false)},{" "}
                {moment(currentDate).format("Do MMM'YY")}
              </Title>
            </div>
            <svg
              width="36"
              height="36"
              viewBox="0 0 46 46"
              onClick={() => showNextAppointments()}
              className={"cursor"}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                opacity="0.34"
                r="23"
                transform="matrix(-1 0 0 1 23 23)"
                fill="#003E72"
              />
              <path
                d="M21.4716 17.8697C21.0577 17.504 20.4257 17.5432 20.0601 17.9571C19.6945 18.371 19.7336 19.003 20.1475 19.3686L21.4716 17.8697ZM26.2858 23.4564L26.9615 24.1936C27.1705 24.002 27.2883 23.7307 27.2857 23.4472C27.2831 23.1637 27.1603 22.8947 26.9478 22.707L26.2858 23.4564ZM20.1338 27.7391C19.7267 28.1123 19.6992 28.7449 20.0724 29.152C20.4456 29.5591 21.0782 29.5866 21.4853 29.2134L20.1338 27.7391ZM20.1475 19.3686L25.6237 24.2059L26.9478 22.707L21.4716 17.8697L20.1475 19.3686ZM25.61 22.7193L20.1338 27.7391L21.4853 29.2134L26.9615 24.1936L25.61 22.7193Z"
                fill="#003E72"
              />
            </svg>
            <div style={{ marginTop: "-5px" }}>
              <Text
                className="doctor-primary-color  paddingLR20  cursor"
                style={{
                  fontSize: "16px",
                  marginTop: "-5px",
                  fontWeight: "600",
                }}
                underline
                onClick={() => showCalenderView()}
              >
                View Calendar
              </Text>
            </div>
          </Space>
        </Col>
        <Col span={6} className={"text-right"}>
          <small className={"light-text"}>{generateGreeting()}</small>{" "}
          <p className="doctor-primary-color bold mt-5">
            {coordinators_doctors?.length ? (
              <span
                className="cursor font16 capitalize"
                onClick={() => setEnableProfileSelector(true)}
              >
                <UserNameComponent />
                <DownOutlined />{" "}
              </span>
            ) : (
              <span className="capitalize font16">
                <UserNameComponent />{" "}
              </span>
            )}
          </p>
        </Col>

        {loader === true ? (
          <>
            <Skeleton active></Skeleton>
            <Skeleton className={"md20"} active></Skeleton>
          </>
        ) : (
          <Fragment>
            {todayAppointments.length === 0 ? (
              <NoDataLayout
                className={"mt100"}
                placeholder={"No Appointments"}
              ></NoDataLayout>
            ) : (
              <Col span={24}>
                <AppointmentCountCard
                  current={currentDate}
                  appointments={todayAppointments}
                ></AppointmentCountCard>
              </Col>
            )}
          </Fragment>
        )}
      </Row>
      <Row
        style={{ paddingLeft: "40px", paddingRight: "40px" }}
        className={"padding20 "}
      >
        <Col span={24}>
          <Title level={2} className={"doctor-primary-color opacity03 "}>
            {getDayFormat(moment(currentDate).add(1, "days"), false)},{" "}
            {moment(currentDate).add(1, "days").format("Do MMM'YY")}
          </Title>
        </Col>
        {tomloader ? (
          <>
            <Skeleton active></Skeleton>
            <Skeleton className={"md20"} active></Skeleton>
          </>
        ) : (
          <Fragment>
            <Col span={24}>
              <AppointmentCountCard
                current={currentDate}
                appointments={nextAppointment}
              ></AppointmentCountCard>
            </Col>
          </Fragment>
        )}
      </Row>
      {profile?.allow_add_appointment && (
        <Affix
          style={{
            position: "fixed",
            bottom: "90px",
            right: "50px",
            textAlign: "right",
          }}
        >
          {/* <PermissionComponent permission={APPOINTMENT_MODULE_ACTIONS_CREATE}> */}
          <Button
            onClick={() => openaddAppointment()}
            className="doctor-secondary-color-background"
            style={{ padding: "10px", width: "60px", height: "60px" }}
            shape="circle"
            icon={<PlusOutlined style={{ color: "white" }} />}
            size={"large"}
          />
          {/* </PermissionComponent> */}
        </Affix>
      )}

      <Modal
        className={"calander-modal"}
        visible={calender}
        centered
        closable={false}
        maskClosable={false}
        footer={false}
        width={"70%"}
      >
        {calnderloader ? (
          <>
            <Skeleton></Skeleton>
            <Skeleton></Skeleton>
            <Skeleton></Skeleton>
            <Skeleton></Skeleton>
          </>
        ) : (
          <CalenderTaskView
            onCancel={() => setCalender(false)}
            data={calenderAppointments}
            currentDate={currentDate}
            onSelect={(date: any) => onSelectDate(date)}
            onChangeMonth={(date: any) => onChangeDate(date)}
          ></CalenderTaskView>
        )}
      </Modal>
      {
        <Modal
          destroyOnClose={true}
          visible={enableProfileSelector}
          style={{ paddingBottom: "0px" }}
          className={"doctor-app-dark-modal"}
          title={"Select Profile"}
          footer={false}
          onCancel={() => {
            if (profile.type !== "coordinator" || _selectedDoctor) {
              setEnableProfileSelector(false);
            }
            // _setSelectedDoctor(impersonatedUser?.doctor_id)
          }}
        >
          <Row>
            <Radio.Group
              value={_selectedDoctor}
              className={"full-width choose-profile"}
              onChange={(evt: any) => _setSelectedDoctor(evt.target.value)}
            >
              {coordinators_doctors &&
                coordinators_doctors?.map((_item: any, index: number) => {
                  return (
                    <Radio
                      key={index}
                      value={_item?.doctor_id}
                      className={"full-width mt20"}
                    >
                      <PatientNameCard
                        type={"doctor"}
                        showImage={true}
                        details={_item.doctor}
                      />
                    </Radio>
                  );
                })}
            </Radio.Group>

            <Button
              className="doctor-secondary-button mt20"
              block
              size={"large"}
              onClick={() => changeUser()}
            >
              Submit
            </Button>
          </Row>
        </Modal>
      }
    </div>
  ) : (
    <DoctorAddAppointment
      createAppointment={(values: any) => handleAddAppointment(values)}
      showBack={true}
      onBack={(value: any) => setAddAppointment(value)}
      loading={loading}
    ></DoctorAddAppointment>
  );
};

export default DoctorDashboardPage;
