import { Card, Col, Divider, Row, Typography } from "antd";
import React from "react";
const { Text } = Typography;
const _ = require("lodash");
type Props = {
  prescriptions?: any;
  showFileDetails?: (file: any, type: any) => void;
  openFilesUploaded?: (type: any) => void;
  type?: any;
  details?: any;
  isPrescription?: any;
  enableCaseSummary?: (value: any) => void;
  showNoData?: any;
  noRecordMessage?: any;
};

const AppointmentRecords: React.FunctionComponent<Props> = ({
  prescriptions,
  openFilesUploaded,
  details,
  isPrescription,
  showFileDetails,
  enableCaseSummary,
  showNoData,
  type,
  noRecordMessage,
}) => {
  let filesCount: any = _.groupBy(prescriptions, "group");

  return (
    <Card className="doctor-app-dark-card noborder">
      {prescriptions && prescriptions.length == 0 && showNoData && (
        <Row>
          <Col span={24}>
            <p className="max-subHead-color capitalize text-center mb-10">
              {noRecordMessage ? noRecordMessage : "No Record Found"}
            </p>
          </Col>
        </Row>
      )}

      {prescriptions && prescriptions.length !== 0 ? (
        <Row>
          <Col span={20}>
            <p className="doctor-primary-color">
              {Object.keys(filesCount).map((item, index) => {
                return (
                  <>
                    {" "}
                    {index !== 0 && "and"}{" "}
                    <span className={"capitalize"}>
                      {filesCount[item].length === 1
                        ? filesCount[item].length + " " + item.toLowerCase()
                        : filesCount[item].length + " " + item.toLowerCase()}
                    </span>
                  </>
                );
              })}
            </p>
          </Col>
          <Col span={4} className="text-right ">
            {prescriptions.length !== 0 && (
              <Text
                className="doctor-secondary-color cursor font12 capitalize"
                underline
                onClick={() => openFilesUploaded && openFilesUploaded(type)}
              >
                View All
              </Text>
            )}
          </Col>
          {<Divider dashed className="margin5"></Divider>}
        </Row>
      ) : (
        ""
      )}

      {isPrescription &&
        prescriptions &&
        prescriptions.slice(0, 3).map((item: any) => {
          return (
            item.appointment_id !== details?.id && (
              <Row className="mt10">
                <Col span={12}>
                  <p className="doctor-primary-color capitalize">{item.name}</p>
                </Col>
                <Col span={12} className="text-right">
                  <Text
                    className="doctor-secondary-color cursor font12"
                    underline
                    onClick={() =>
                      showFileDetails && showFileDetails(item, type)
                    }
                  >
                    View
                  </Text>
                </Col>
              </Row>
            )
          );
        })}

      {!isPrescription &&
        prescriptions &&
        prescriptions.map((item: any) => {
          return (
            item.appointment_id !== details?.id && (
              <Row className="mt10">
                <Col span={12}>
                  <p className="doctor-primary-color capitalize">{item.name}</p>
                </Col>
                <Col span={12} className="text-right">
                  <Text
                    className="doctor-secondary-color cursor font12"
                    underline
                    onClick={() =>
                      showFileDetails && showFileDetails(item, type)
                    }
                  >
                    View
                  </Text>
                </Col>
              </Row>
            )
          );
        })}

      {!isPrescription &&
        details &&
        details?.notes &&
        details?.notes
          .filter((issue: any, i: any) => {
            return issue?.type === "symptom";
          })
          .map((issue: any, i: any) => {
            return (
              issue.note && (
                <Row>
                  <Col span={20} className={"paddingy10"}>
                    {
                      <Text className="doctor-primary-color  capitalize">
                        <span className="bold">Symptoms and complaints</span>{" "}
                        {<br></br>}
                        <i style={{ color: "#163B6D" }} className="sub-text">
                          {issue.note}
                        </i>
                      </Text>
                    }
                  </Col>
                  {details?.notes.length !== i + 1 && (
                    <Divider dashed className="margin5"></Divider>
                  )}
                </Row>
              )
            );
          })}
      {!isPrescription &&
        details &&
        details?.notes &&
        details?.notes
          .filter((issue: any, i: any) => {
            return issue?.type == "case_summary";
          })
          .map((issue: any, i: any) => {
            return (
              <Row>
                <Col span={20} className={"paddingy10"}>
                  <Text className="doctor-primary-color  capitalize">
                    <span className="bold">Case Summary</span> {<br></br>}
                    <i className="sub-text max-subHead-color2">
                      Prepared By {issue.note_by}
                    </i>
                  </Text>
                </Col>
                <Col span={4} className={"text-right paddingy10"}>
                  {showNoData && (
                    <Text
                      className="doctor-secondary-color cursor font12"
                      underline
                      onClick={() =>
                        enableCaseSummary && enableCaseSummary(true)
                      }
                    >
                      View
                    </Text>
                  )}
                </Col>
                {/* {
                            details?.notes.length !== i+1 && <Divider dashed className="margin5"></Divider>
                        } */}
              </Row>
            );
          })}
    </Card>
  );
};

export default AppointmentRecords;
