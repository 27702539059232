// unique on the basis of "type"
export const DefaultProps = [
  {
    name: "",
    placeholder: "Enter text",
    type: "textbox",
    size: 3,
    readOnly: false,
    uiData: "",
    label: "",
    offset: "",
    labelSize: "",
    appSize: "",
    showUnit: "",
    value: "",
    auto: false,
    conditionalFormulas: [],
    colId: "",
    info: "",
    formula: "",
    globalsectionvars: [],
    validation: {
      isNumber: false,
      minValue: "",
      maxValue: "",
      maxLength: "",
      minLength: "",
      required: false,
    },
    values: [],
  },
  {
    name: "",
    placeholder: "Enter text",
    type: "autoComplete",
    size: 3,
    readOnly: false,
    uiData: "",
    autoKey: "",
    label: "",
    offset: "",
    labelSize: "",
    appSize: "",
    value: "",
    auto: false,
    conditionalFormulas: [],
    colId: "",
    formula: "",
    globalsectionvars: [],
    validation: {
      isNumber: false,
      minValue: "",
      maxValue: "",
      maxLength: "",
      minLength: "",
      required: false,
    },
    values: [],
  },
  {
    name: "",
    placeholder: "Enter text",
    type: "textarea",
    size: 3,
    readOnly: false,
    uiData: "",
    label: "",
    offset: "",
    labelSize: "",
    appSize: "",
    showUnit: "",
    value: "",
    auto: false,
    conditionalFormulas: [],
    colId: "",
    formula: "",
    globalsectionvars: [],
    validation: {
      isNumber: false,
      minValue: "",
      maxValue: "",
      maxLength: "",
      minLength: "",
      required: false,
    },
    values: [],
  },
  {
    name: "",
    placeholder: "Enter text",
    type: "header",
    size: 3,
    readOnly: true,
    uiData: "",
    label: "",
    offset: "",
    labelSize: "",
    appSize: "",
    value: "",
    auto: false,
    conditionalFormulas: [],
    colId: "",
    formula: "",
    globalsectionvars: [],
    validation: {
      isNumber: false,
      minValue: "",
      maxValue: "",
      maxLength: "",
      minLength: "",
      required: false,
    },
    values: [],
  },
  {
    name: "",
    placeholder: "Enter text",
    type: "textFormatter",
    size: 3,
    readOnly: false,
    uiData: "",
    label: "",
    offset: "",
    labelSize: "",
    appSize: "",
    value: "",
    auto: false,
    conditionalFormulas: [],
    pattern: "",
    colId: "",
    formula: "",
    globalsectionvars: [],
    validation: {
      isNumber: false,
      minValue: "",
      maxValue: "",
      maxLength: "",
      minLength: "",
      required: false,
    },
    values: [],
  },
  {
    name: "",
    placeholder: "Enter text",
    type: "hidden",
    size: 3,
    readOnly: false,
    uiData: "",
    label: "",
    offset: "",
    labelSize: "",
    appSize: "",
    value: "",
    auto: false,
    conditionalFormulas: [],
    colId: "",
    formula: "",
    globalsectionvars: [],
    validation: {
      isNumber: false,
      minValue: "",
      maxValue: "",
      maxLength: "",
      minLength: "",
      required: false,
    },
    values: [],
  },
  {
    name: "",
    placeholder: "Enter text",
    type: "time",
    size: 3,
    readOnly: false,
    uiData: "",
    label: "",
    offset: "",
    labelSize: "",
    appSize: "",
    value: "",
    auto: false,
    conditionalFormulas: [],
    colId: "",
    formula: "",
    globalsectionvars: [],
    validation: {
      required: false,
    },
    values: [],
  },
  {
    name: "",
    placeholder: "Select",
    size: 3,
    type: "radio",
    uiData: "",
    value: "",
    label: "",
    offset: "",
    labelSize: "",
    appSize: "",
    conditionalFormulas: [],
    colId: "",
    formula: "",
    globalsectionvars: [],
    validation: {
      required: false,
    },
    values: [],
  },
  {
    name: "",
    placeholder: "Enter text",
    size: 3,
    type: "checkbox",
    uiData: "",
    value: "",
    label: "",
    offset: "",
    labelSize: "",
    appSize: "",
    conditionalFormulas: [],
    colId: "",
    formula: "",
    globalsectionvars: [],
    validation: {
      minSelections: "",
      maxSelections: "",
      required: false,
    },
    values: [],
  },
  {
    name: "",
    size: 2,
    labelSize: "",
    appSize: "",
    label: "",
    offset: "",
    placeholder: "Select",
    type: "selection",
    uiData: "",
    value: "",
    conditionalFormulas: [],
    colId: "",
    formula: "",
    globalsectionvars: [],
    validation: {
      required: false,
    },
    values: [],
    updateTimeStamp: "",
  },
  {
    name: "",
    size: 2,
    labelSize: "",
    appSize: "",
    label: "",
    offset: "",
    placeholder: "Type for options...",
    type: "typeahead",
    uiData: "",
    value: "",
    conditionalFormulas: [],
    colId: "",
    async: false,
    globalsectionvars: [],
    validation: {
      required: false,
    },
    values: [],
    updateTimeStamp: "",
  },
  {
    name: "date",
    placeholder: "Enter date",
    type: "date",
    size: 3,
    uiData: "",
    value: "",
    label: "",
    offset: "",
    labelSize: "",
    appSize: "",
    conditionalFormulas: [],
    colId: "",
    formula: "",
    globalsectionvars: [],
    validation: {
      required: false,
      disablePastDates: false,
      disableFutureDates: false,
    },
    values: [],
  },
  {
    name: "",
    placeholder: "Enter text",
    type: "hyper_link",
    size: 3,
    readOnly: true,
    uiData: "",
    label: "",
    value: "",
    auto: false,
    conditionalFormulas: [],
    colId: "",

    validation: {
      required: true,
    },
  },
  {
    name: "",
    placeholder: "Enter text",
    type: "numeric_pain_score",
    size: 3,
    readOnly: false,
    uiData: "",
    label: "",
    offset: "",
    labelSize: "",
    appSize: "",
    value: "",
    auto: false,
    conditionalFormulas: [],
    colId: "",
    formula: "",
    globalsectionvars: [],
    validation: {
      required: true,
      minSelections: "",
      maxSelections: "",
    },
    values: [],
  },
];
