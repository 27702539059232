import { EditFilled } from "@ant-design/icons";
import { Button, Card, Col, Row, Typography } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../shared/constants";
import DesktopListLayout from "../../../shared/layouts/desktop-list.layout";
import {
  ROUTE_PRESCRIPTION_TEMPLATE_ACTION,
  ROUTE_PRESCRIPTION_TEMPLATE_CREATE,
} from "../../../shared/routes/boss.routes.constants";
//import {fetchRoles} from "../../../redux/reducers/role.slice";
import { t } from "i18next";
import { fetchAllTemplates } from "../../../redux/reducers/prescription.slice";

const { Text, Title } = Typography;
type Props = {};

const PrescriptionTemplatesList: React.FunctionComponent<Props> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { prescriptionTemplate, loading } = useSelector(
    (state: RootState) => state.prescription
  );
  const columns = [
    {
      title: t("SNo"),
      key: "sNo",
      render: (a: any, value: any, index: number) => <Text>{index + 1}</Text>,
    },
    {
      title: t("Name"),
      dataIndex: "name",
    },
    {
      fixed: "right" as "right",
      width: "150px",
      title: t("Action"),
      key: "action",
      render: (a: any) => {
        return (
          <Row gutter={10}>
            <Button
              onClick={() =>
                navigate(ROUTE_PRESCRIPTION_TEMPLATE_ACTION(a.id, "edit"))
              }
              shape="circle"
              icon={<EditFilled />}
              size={"middle"}
            />
          </Row>
        );
      },
    },
  ];
  useEffect(() => {
    dispatch(fetchAllTemplates({ type: "all" }));
  }, []);

  return (
    <div className={"col-md-12 main-container padding15"}>
      <Title className="paddingcollapse" level={4}>
        Templates
      </Title>
      <Col span={24}>
        <Card className="nopaddingcard crm-tabs">
          <DesktopListLayout
            columns={columns}
            dataSource={prescriptionTemplate}
            enableAddRoute={ROUTE_PRESCRIPTION_TEMPLATE_CREATE}
            loading={loading}
            title="Template"
          />
        </Card>
      </Col>
    </div>
    // <div className={'col-md-12 main-container'}>
    //     {/*<PermissionComponent permission={PATIENT_MODULE_ACTIONS_READ}>*/}
    //         <DesktopListLayout
    //             columns={columns}
    //             dataSource={prescriptionTemplate}
    //             enableAddRoute={ROUTE_PRESCRIPTION_TEMPLATE_CREATE}
    //             loading={loading}
    //             title={t("Prescription.Templates")}/>
    //     {/*</PermissionComponent>*/}

    // </div>
  );
};

export default PrescriptionTemplatesList;
