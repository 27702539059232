import React from "react";
// component imports
import { MinusCircleTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import { Col, Row } from "antd";
import ColumnViewComponent from "../Column/ColumnViewComponent";

class ChildrenViewComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      colErrorArray: [],
    };
  }

  componentDidMount() {
    try {
      if (
        this.props.indChildrenData &&
        this.props.indChildrenData.config &&
        this.props.indChildrenData.config.displaycondition &&
        this.props.indChildrenData.config.displaycondition.length !== 0
      ) {
        this.props.setConditionalHide({
          class: this.props.indChildrenData.class,
          displaycondition: this.props.indChildrenData.config.displaycondition,
          childIndex: this.props.childIndex,
        });
      }
    } catch (err) {}
  }

  componentDidUpdate(prevProps) {
    try {
      // reset config if childrow position changes
      if (
        this.props.indChildrenData &&
        prevProps.indChildrenData &&
        this.props.indChildrenData["rowId"] !==
          prevProps.indChildrenData["rowId"] &&
        prevProps.indChildrenData.config &&
        prevProps.indChildrenData.config.displaycondition &&
        prevProps.indChildrenData.config.displaycondition.length !== 0
      ) {
        this.props.unsetConditionalHide({ childIndex: this.props.childIndex });
      }

      if (
        this.props.indChildrenData &&
        this.props.indChildrenData.config &&
        this.props.indChildrenData.config !==
          prevProps.indChildrenData.config &&
        this.props.indChildrenData.config.displaycondition &&
        this.props.indChildrenData.config.displaycondition.length !== 0 &&
        this.props.indChildrenData.config.displaycondition !==
          prevProps.indChildrenData.config.displaycondition
      ) {
        if (
          this.props.indChildrenData["rowId"] ===
            prevProps.indChildrenData["rowId"] &&
          (this.props.indChildrenData.config.displaycondition[0].source ===
            "" ||
            this.props.indChildrenData.config.displaycondition[0].value === "")
        ) {
          this.props.unsetConditionalHide({
            childIndex: this.props.childIndex,
          });
        } else {
          this.props.setConditionalHide({
            class: this.props.indChildrenData.class,
            displaycondition:
              this.props.indChildrenData.config.displaycondition,
            childIndex: this.props.childIndex,
          });
        }
      }
    } catch (err) {}
  }

  getEmitedTextboxValue = (index, event) => {
    this.props.emitTextboxValue(index, event);
  };
  getEmitedChildTextboxValue = (index, event) => {
    this.props.emitChildTextboxValue(index, event);
  };

  getEmitedFocusEvent = (index, event) => {
    this.props.emitFocusEvent(index, event);
  };

  getEmitedChildAddNewRowEvent = (index, event) => {
    this.props.emitChildAddNewRowEvent(index, event);
  };

  getEmitedChildDeleteRowObjectData = (index, event) => {
    this.props.emitChildDeleteRowObjectData(index, event);
  };

  setConfigForFormula = (e) => {
    this.props.setConfigForFormula({ ...e, childIndex: this.props.childIndex });
  };

  setErrorForColumn = (colIndex, errorStatus) => {
    let { colErrorArray } = this.state;
    let index = colErrorArray.indexOf(colIndex);
    if (index !== -1 && !errorStatus) {
      colErrorArray.splice(index, 1);
    } else if (index === -1 && errorStatus) {
      colErrorArray.push(colIndex);
    }
    this.setState({ colErrorArray }, () =>
      this.props.emitErrorStatus(colErrorArray.length ? true : false)
    );
  };

  getEmitedLoadOptionsEvent = (index, event, callback) => {
    this.props.emitChildLoadOptionsEvent(index, event, callback);
  };

  getEmitedTypeAheadSuggestionObj = (event) => {
    this.props.emitTypeAheadSuggestionObj(event);
  };

  render() {
    const { indChildrenData, viewType } = this.props;
    let show =
      viewType && indChildrenData.status !== "filled"
        ? false
        : !indChildrenData.class ||
          indChildrenData.class !== "deferRendering hide";
    return (
      show && (
        <React.Fragment>
          {indChildrenData.type === "group" ? (
            <Row>
              <Col span={24}>
                {indChildrenData.columns &&
                  indChildrenData.columns.map((indColumnData, index) => {
                    return (
                      <React.Fragment key={index}>
                        <ColumnViewComponent
                          showType={this.props.showType}
                          checkForm={this.props.checkForm}
                          fieldConfig={this.props.fieldConfig}
                          emitErrorStatus={this.setErrorForColumn.bind(
                            this,
                            index
                          )}
                          colIndex={index}
                          viewType={viewType}
                          rowType={indChildrenData.type}
                          rowId={indChildrenData.rowId}
                          rowIndex={indChildrenData.index}
                          indColumnData={indColumnData}
                          setConfigForFormula={this.setConfigForFormula.bind(
                            this
                          )}
                          emitFocusEvent={this.getEmitedFocusEvent.bind(
                            this,
                            index
                          )}
                          emitTypeAheadSuggestionObj={this.getEmitedTypeAheadSuggestionObj.bind(
                            this
                          )}
                          emitTextboxValue={this.getEmitedChildTextboxValue.bind(
                            this,
                            index
                          )}
                          emitAddNewRowEvent={this.getEmitedChildAddNewRowEvent.bind(
                            this,
                            index
                          )}
                          emitLoadOptionsEvent={this.getEmitedLoadOptionsEvent.bind(
                            this,
                            index
                          )}
                          emitDeleteRowObjectData={this.getEmitedChildDeleteRowObjectData.bind(
                            this,
                            index
                          )}
                        ></ColumnViewComponent>
                      </React.Fragment>
                    );
                  })}
              </Col>
            </Row>
          ) : (
            <>
              <Row>
                {indChildrenData.columns &&
                  indChildrenData.columns.map((indColumnData, index) => {
                    return (
                      <React.Fragment key={index}>
                        <ColumnViewComponent
                          showType={this.props.showType}
                          checkForm={this.props.checkForm}
                          emitErrorStatus={this.setErrorForColumn.bind(
                            this,
                            index
                          )}
                          colIndex={index}
                          viewType={viewType}
                          fieldConfig={this.props.fieldConfig}
                          setConfigForFormula={this.setConfigForFormula.bind(
                            this
                          )}
                          rowType={indChildrenData.type}
                          rowId={indChildrenData.rowId}
                          rowIndex={indChildrenData.index}
                          indColumnData={indColumnData}
                          emitFocusEvent={this.getEmitedFocusEvent.bind(
                            this,
                            index
                          )}
                          emitTypeAheadSuggestionObj={this.getEmitedTypeAheadSuggestionObj.bind(
                            this
                          )}
                          emitTextboxValue={this.getEmitedChildTextboxValue.bind(
                            this,
                            index
                          )}
                          emitAddNewRowEvent={this.getEmitedChildAddNewRowEvent.bind(
                            this,
                            index
                          )}
                          emitLoadOptionsEvent={this.getEmitedLoadOptionsEvent.bind(
                            this,
                            index
                          )}
                          emitDeleteRowObjectData={this.getEmitedChildDeleteRowObjectData.bind(
                            this,
                            index
                          )}
                        ></ColumnViewComponent>
                      </React.Fragment>
                    );
                  })}

                <Col
                  span={2}
                  style={{
                    padding: 0,
                    display: indChildrenData.ismultiple ? "" : "none",
                  }}
                >
                  <div
                    className="btn-group plus-group "
                    role="group"
                    aria-label="Basic example"
                  >
                    {this.props.showDelete && (
                      <button
                        type="button"
                        className="btn btn-outline-danger btn-rounded"
                        onClick={this.getEmitedChildDeleteRowObjectData.bind(
                          this
                        )}
                      >
                        <MinusCircleTwoTone twoToneColor="#f87a2f" />
                      </button>
                    )}
                    <button
                      type="button"
                      className="btn btn-outline-success btn-rounded"
                      onClick={this.getEmitedChildAddNewRowEvent.bind(this)}
                    >
                      <PlusCircleTwoTone twoToneColor="#45b568" />
                    </button>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </React.Fragment>
      )
    );
  }
}

export default ChildrenViewComponent;
