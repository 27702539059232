import { MenuOutlined } from "@ant-design/icons";
import { Avatar, Col, Dropdown, Layout, Menu, Modal, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import VideoChat from "../../pages/doctor/Video-Room/video.room.page";
import { setVideoRoom } from "../../redux/reducers/appointment.slice";
import {
  clearNotificationDevice,
  logoutUser,
} from "../../redux/reducers/auth.slice";
import { reset } from "../../redux/reducers/patients.slice";
import { RootState } from "../constants";
import { ROUTE_BOSS_INSTANT_VC_DOCTOR } from "../routes/boss.routes.constants";
import { ROUTE_CRM_DASHBOARD } from "../routes/crm.routes.constants";
import { getCookie, getInitials, setCookie } from "../Utils/utilities";
import Sidebar from "./sidebar";
var _ = require("lodash");
const { Sider, Content } = Layout;

function DoctorDesktopLayout({ children }: any) {
  const [collapsed, setCollapsed] = useState(true);
  const { profile } = useSelector((state: RootState) => state.profile);
  const { videoChatProps, pipEnabled } = useSelector(
    (state: RootState) => state.appointment
  );
  const location = useLocation();

  const [chooseApp, setChooseApp] = useState(false);
  const permissions = JSON.parse(localStorage.getItem("persist:root") as any);
  const allPermissions = permissions && JSON.parse(permissions?.auth);
  const myPermissions = allPermissions?.applications;
  const [settingClickCount, setsettingClickCount] = useState(0);
  const [positions, setposition] = useState("absolute" as any);

  // assuming that the routes for all prom pages initiate with /prom/XYZ
  const [isProm, setIsProm] = useState(
    location.pathname.split("/")[1] === "prom"
  );

  const onClickSubmenu = (e: any) => {
    if (!collapsed) {
      setsettingClickCount(settingClickCount + 1);
      if (settingClickCount % 2 === 0) {
        // alert("position retative")
        setposition("relative");
      } else {
        // alert("position absolute")
        setposition("absolute");
      }
    }
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setVideoRoom(null));
  }, [dispatch]);

  useEffect(() => {
    if (location.pathname.split("/")[1] === "prom") {
      setIsProm(true);
    } else {
      setIsProm(false);
    }
  }, [location.pathname]);

  const style: any = {
    overflow: "auto",
    height: "100vh",
    position: "fixed",
    left: 0,
    top: 0,
    bottom: 0,
  };

  const logOut = async () => {
    let device_id: any = new Date().getTime() + "";

    if (getCookie("device_id")) {
      device_id = getCookie("device_id");
    } else {
      setCookie("device_id", device_id, 100);
    }
    await dispatch(
      clearNotificationDevice({
        app_id: "max_app",
        user_id: profile.id,
        account_id: "max",
        device_id,
      })
    );
    dispatch(logoutUser({ id: profile.id }));
    dispatch(reset());
  };

  const menu = (
    <Menu>
      <Menu.Item key="0" onClick={() => logOut()}>
        <span> Logout </span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Col span={24}>
      {!!videoChatProps && (
        <div id="video-section" className={pipEnabled ? "soft-hidden" : ""}>
          <VideoChat
            onClose={() => {
              dispatch(setVideoRoom(null));
            }}
            {...videoChatProps}
          />
        </div>
      )}

      <div
        id="video-section-non"
        className={!!videoChatProps && !pipEnabled ? "video-section-non" : ""}
      >
        <Row>
          <Layout>
            <Sider
              trigger={null}
              collapsible
              collapsed={collapsed}
              className={"doctor-app-sidebar noborder"}
              style={style}
            >
              <Row>
                <Col span={24}>
                  {React.createElement(
                    collapsed ? MenuOutlined : MenuOutlined,
                    {
                      className: collapsed ? "trigger" : "trigger2",
                      onClick: () => setCollapsed(!collapsed),
                    }
                  )}

                  {!collapsed ? (
                    <img
                      alt="doctorlogo"
                      src={
                        require("../../assets/images/doctorlogo.svg").default
                      }
                      width={"50%"}
                      style={{ marginLeft: "-20px", marginTop: "-5px" }}
                    ></img>
                  ) : (
                    <div className={"mt20 text-center"}>
                      <svg
                        width="29"
                        height="34"
                        viewBox="0 0 29 34"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M28.7237 0.615234V25.164C28.7237 26.318 28.1585 27.3985 27.2011 28.0719L20.3049 32.9357C19.681 33.3765 18.9275 33.6152 18.1554 33.6152H11.5371C10.765 33.6152 10.0145 33.3765 9.38757 32.9357L2.4913 28.0719C1.53701 27.3985 0.96875 26.3149 0.96875 25.164V0.615234H28.7237V0.615234Z"
                          fill="#003873"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.2721 15.8536H18.4278C19.6909 15.8536 20.8984 15.3577 21.791 14.4731L25.9479 10.3531H20.3981V4.85254L14.8515 10.3531L9.30171 4.85254V10.3531H3.75195L7.90886 14.4731C8.7983 15.3546 10.0089 15.8536 11.2721 15.8536ZM18.4278 16.2117H11.2721C10.0089 16.2117 8.80139 16.7076 7.90886 17.5922L3.75195 21.7122H9.30171V27.2127L14.8484 21.7122L20.3981 27.2127V21.7122H25.9479L21.791 17.5922C20.8984 16.7076 19.6909 16.2117 18.4278 16.2117Z"
                          fill="#5AB9B4"
                        />
                        <path
                          d="M19.2179 14.6709H10.7373V17.386H19.2179V14.6709Z"
                          fill="white"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M19.2179 14.6708H16.3488V11.8271H13.6064V14.6708H10.7373V17.3889H13.6064V20.2325H16.3488V17.3889H19.2179V14.6708Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  )}
                </Col>
              </Row>

              <div className="mt10">
                <Sidebar
                  buttonEvt={(e: any) => {
                    onClickSubmenu(e);
                  }}
                  setIsProm={setIsProm}
                  type={isProm ? "prom" : "doctor"}
                  className={"doctor-layout"}
                  profile={profile}
                ></Sidebar>
              </div>

              <div
                className={"padding10"}
                style={{
                  position: positions,
                  bottom: "0px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  left: 0,
                  right: 0,
                  textAlign: "center",
                  backgroundColor: "#C1DAE8",
                }}
              >
                {collapsed ? (
                  <p className={"capitalize cursor"}>
                    <Space>
                      <Dropdown
                        overlay={menu}
                        placement={"topRight"}
                        overlayStyle={{ width: "10%" }}
                        trigger={["click"]}
                      >
                        <Avatar>
                          {" "}
                          {profile && getInitials(profile?.name)}{" "}
                        </Avatar>
                      </Dropdown>
                    </Space>
                  </p>
                ) : (
                  <Dropdown
                    overlay={menu}
                    placement={"topRight"}
                    overlayStyle={{ width: "10%" }}
                    trigger={["click"]}
                  >
                    <p className={"capitalize cursor"}>
                      <Space>
                        <Avatar className="user-nav-letter-circle">
                          {" "}
                          {profile && getInitials(profile?.name)}{" "}
                        </Avatar>
                        {/* <p> {profile?.name}</p>
                                             <p>{profile?.mobile}</p> */}
                        <small
                          style={{
                            position: "relative",
                            top: "-7px",
                            color: "#858e93",
                          }}
                        >
                          {profile?.name ? profile?.name : ""}{" "}
                          <span
                            style={{
                              position: "absolute",
                              top: "16px",
                              left: "0px",
                            }}
                          >
                            {profile.mobile ? profile.mobile : ""}
                          </span>
                        </small>
                        {/* <svg width="8" height="12"
                                                 className={"cursor"} viewBox="0 0 8 12" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">

                                            {/* <Avatar> {profile && getInitials(profile?.name)}   </Avatar> 
                                            {profile?.name} */}
                        {/* <svg width="8" height="12"
                                                className={"cursor"} viewBox="0 0 8 12" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M2.56733 1.04618C2.16676 0.665972 1.53381 0.682482 1.1536 1.08306C0.773393 1.48363 0.789903 2.11658 1.19048 2.49679L2.56733 1.04618ZM6.25979 5.92964L6.96153 6.64207C7.15464 6.45187 7.26226 6.19142 7.25975 5.92038C7.25724 5.64934 7.14482 5.39093 6.94822 5.20433L6.25979 5.92964ZM1.17717 9.53227C0.783703 9.91983 0.778911 10.553 1.16647 10.9464C1.55403 11.3399 2.18717 11.3447 2.58064 10.9571L1.17717 9.53227ZM1.19048 2.49679L5.57137 6.65494L6.94822 5.20433L2.56733 1.04618L1.19048 2.49679ZM5.55806 5.2172L1.17717 9.53227L2.58064 10.9571L6.96153 6.64207L5.55806 5.2172Z"
                                                    fill="#223042" />
                                            </svg> */}
                      </Space>
                    </p>
                  </Dropdown>
                )}
              </div>
            </Sider>
            <Layout
              className="site-layout"
              style={{ marginLeft: !collapsed ? 200 : 80 }}
            >
              <Content
                className="doctor-app nopadding"
                style={{
                  minHeight: "100vh",
                }}
              >
                <Col span={24}>{children}</Col>
              </Content>
            </Layout>
          </Layout>

          <Modal
            centered
            title={"All Apps"}
            className={"doctor-app-dark-modal"}
            visible={chooseApp}
            onCancel={() => setChooseApp(false)}
            footer={false}
          >
            <Row>
              {_.find(myPermissions, { slug: "boss" }) && (
                <Col span={8} className={"text-center"}>
                  <a href={ROUTE_BOSS_INSTANT_VC_DOCTOR}>
                    <svg
                      width="105"
                      height="104"
                      className={"cursor"}
                      viewBox="0 0 105 104"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g filter="url(#filter0_d_0_1)">
                        <rect
                          x="6.36133"
                          y="4.10742"
                          width="92.1846"
                          height="92.1846"
                          rx="9.07066"
                          fill="white"
                        />
                      </g>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M73.2301 25.4961V62.2501C73.2301 63.9778 72.3839 65.5956 70.9505 66.6038L60.6255 73.8858C59.6915 74.5458 58.5633 74.9032 57.4074 74.9032H47.4985C46.3425 74.9032 45.219 74.5458 44.2803 73.8858L33.9553 66.6038C32.5266 65.5956 31.6758 63.9733 31.6758 62.2501V25.4961H73.2301Z"
                        fill="#003873"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M47.1008 48.3104H57.8142C59.7053 48.3104 61.5133 47.568 62.8495 46.2436L69.0732 40.0751H60.7642V31.8398L52.4598 40.0751L44.1508 31.8398V40.0751H35.8418L42.0655 46.2436C43.3971 47.5634 45.2097 48.3104 47.1008 48.3104ZM57.8142 48.8466H47.1008C45.2097 48.8466 43.4017 49.589 42.0655 50.9134L35.8418 57.0819H44.1508V65.3172L52.4552 57.0819L60.7642 65.3172V57.0819H69.0732L62.8495 50.9134C61.5133 49.589 59.7053 48.8466 57.8142 48.8466Z"
                        fill="#23BCB5"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M58.9988 46.5406H54.7032V42.2832H50.5973V46.5406H46.3018V50.6101H50.5973V54.8676H54.7032V50.6101H58.9988V46.5406Z"
                        fill="#E0EBF1"
                      />
                      <defs>
                        <filter
                          id="filter0_d_0_1"
                          x="0.692165"
                          y="0.139008"
                          width="103.523"
                          height="103.522"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dy="1.70075" />
                          <feGaussianBlur stdDeviation="2.83458" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0.607843 0 0 0 0 0.72549 0 0 0 0 0.784314 0 0 0 1 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_0_1"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_0_1"
                            result="shape"
                          />
                        </filter>
                      </defs>
                    </svg>
                    <br />
                    BOSS Panel
                  </a>
                </Col>
              )}

              {_.find(myPermissions, { slug: "doctor" }) && (
                <Col span={8} className={"text-center"}>
                  <svg
                    width="104"
                    height="104"
                    viewBox="0 0 104 104"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g filter="url(#filter0_d_0_1)">
                      <rect
                        x="6.08789"
                        y="4.10742"
                        width="92.1846"
                        height="92.1846"
                        rx="9.07066"
                        fill="white"
                      />
                    </g>
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M72.9576 25.4961V62.2501C72.9576 63.9778 72.1115 65.5956 70.6781 66.6038L60.3531 73.8858C59.4191 74.5458 58.2908 74.9032 57.1349 74.9032H47.226C46.0701 74.9032 44.9465 74.5458 44.0079 73.8858L33.6829 66.6038C32.2541 65.5956 31.4033 63.9733 31.4033 62.2501V25.4961H72.9576Z"
                      fill="#003873"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M46.8283 48.3104H57.5417C59.4329 48.3104 61.2408 47.568 62.5771 46.2436L68.8007 40.0751H60.4917V31.8398L52.1874 40.0751L43.8783 31.8398V40.0751H35.5693L41.793 46.2436C43.1247 47.5634 44.9372 48.3104 46.8283 48.3104ZM57.5417 48.8466H46.8283C44.9372 48.8466 43.1293 49.589 41.793 50.9134L35.5693 57.0819H43.8783V65.3172L52.1827 57.0819L60.4917 65.3172V57.0819H68.8007L62.5771 50.9134C61.2408 49.589 59.4329 48.8466 57.5417 48.8466Z"
                      fill="#23BCB5"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M58.7263 46.5406H54.4308V42.2832H50.3248V46.5406H46.0293V50.6101H50.3248V54.8676H54.4308V50.6101H58.7263V46.5406Z"
                      fill="#E0EBF1"
                    />
                    <defs>
                      <filter
                        id="filter0_d_0_1"
                        x="0.418727"
                        y="0.139008"
                        width="103.523"
                        height="103.522"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="1.70075" />
                        <feGaussianBlur stdDeviation="2.83458" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0.607843 0 0 0 0 0.72549 0 0 0 0 0.784314 0 0 0 1 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_0_1"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_0_1"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                  <br />
                  Doctor App
                </Col>
              )}

              {_.find(myPermissions, { slug: "crm" }) && (
                <Col span={8} className={"text-center"}>
                  <a href={ROUTE_CRM_DASHBOARD}>
                    <svg
                      width="105"
                      height="104"
                      viewBox="0 0 105 104"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g filter="url(#filter0_d_0_1)">
                        <rect
                          x="6.61914"
                          y="4.20898"
                          width="92.1846"
                          height="92.1846"
                          rx="9.07066"
                          fill="white"
                        />
                      </g>
                      <path
                        d="M71.7527 76.5865H33.6714C29.515 76.5865 26.1016 73.1731 26.1016 69.0166V31.5855C26.1016 27.429 29.515 24.0156 33.6714 24.0156H71.7527C75.9092 24.0156 79.3226 27.429 79.3226 31.5855V69.0398C79.3226 73.1963 75.9324 76.5865 71.7527 76.5865Z"
                        fill="#37C2D8"
                      />
                      <path
                        d="M52.7352 74.5426C66.1237 74.5426 76.9772 63.6891 76.9772 50.3006C76.9772 36.9121 66.1237 26.0586 52.7352 26.0586C39.3467 26.0586 28.4932 36.9121 28.4932 50.3006C28.4932 63.6891 39.3467 74.5426 52.7352 74.5426Z"
                        fill="#1C394B"
                      />
                      <path
                        d="M67.1095 50.3467C66.4826 48.9999 65.6234 47.9782 64.5553 47.2816C63.4639 46.585 62.2565 46.2367 60.9097 46.2367C59.7951 46.2367 58.8198 46.4689 58.0071 46.9333C57.1944 47.3977 56.5907 48.0247 56.1495 48.8142V42.7536H62.9066V36.6699H41.7065V42.7304H48.9744V49.0696C48.4404 48.2569 47.7437 47.5835 46.8382 47.0726C45.9558 46.5618 44.8876 46.3064 43.6802 46.3064C41.7065 46.3064 40.1507 46.9798 39.0361 48.3033C37.9215 49.6269 37.3643 51.4148 37.3643 53.6904V63.9306H44.1214V54.5496C44.1214 53.7369 44.3304 53.0867 44.7715 52.5991C45.2127 52.1114 45.7932 51.8792 46.5363 51.8792C47.3026 51.8792 47.8831 52.1114 48.301 52.5991C48.719 53.0867 48.9512 53.7369 48.9512 54.5496V63.9306H49.3227H55.7548H56.1263V61.3299C56.5443 62.1194 57.1712 62.7464 57.9839 63.2108C58.7966 63.6752 59.7487 63.9074 60.8865 63.9074C62.2332 63.9074 63.4407 63.5591 64.532 62.8625C65.6234 62.1658 66.4593 61.1442 67.0863 59.7974C67.7132 58.4506 68.0151 56.8948 68.0151 55.0604C68.0383 53.2492 67.7364 51.6703 67.1095 50.3467ZM60.4453 57.2199C59.9809 57.7308 59.3771 57.9862 58.6341 57.9862C57.891 57.9862 57.2873 57.7308 56.8229 57.2199C56.3585 56.7091 56.1263 55.9892 56.1263 55.0836C56.1263 54.1548 56.3585 53.4582 56.8229 52.9474C57.2873 52.4365 57.891 52.1811 58.6341 52.1811C59.3771 52.1811 59.9809 52.4365 60.4453 52.9474C60.9097 53.4582 61.1419 54.1781 61.1419 55.0836C61.1651 55.9892 60.9329 56.7091 60.4453 57.2199Z"
                        fill="white"
                      />
                      <defs>
                        <filter
                          id="filter0_d_0_1"
                          x="0.949977"
                          y="0.24057"
                          width="103.523"
                          height="103.522"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dy="1.70075" />
                          <feGaussianBlur stdDeviation="2.83458" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0.607843 0 0 0 0 0.72549 0 0 0 0 0.784314 0 0 0 1 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_0_1"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_0_1"
                            result="shape"
                          />
                        </filter>
                      </defs>
                    </svg>
                    <br />
                    THB CRM
                  </a>
                </Col>
              )}
            </Row>
          </Modal>
        </Row>
      </div>
    </Col>
  );
}

export default DoctorDesktopLayout;
