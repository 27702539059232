import { notification } from "antd";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import "./App.less";
import { fetchAccountByDomain } from "./redux/reducers/auth.slice";
import { RootState } from "./shared/constants";

function AccountRouteFetcher({ children }: any) {
  const navigate = useNavigate();

  const dispatch: any = useDispatch();

  const location: any = useLocation();
  const { account } = useSelector((state: RootState) => state.auth);
  const checkRoute = useCallback(() => {
    if (account && !window.location.pathname.startsWith("/" + account?.slug)) {
      notification.error({
        message: "Didn't find the account in route, Going back",
      });
      navigate("/" + (account?.slug || "max"));
    }
  }, [account, navigate]);

  useEffect(() => {
    setTimeout(() => {
      if (!account) {
        dispatch(fetchAccountByDomain());
      }
    }, 100);
  }, [account, dispatch]);

  useEffect(() => {
    checkRoute();
  }, [account, checkRoute, location]);
  if (!account) {
    return <div>Loading account</div>;
  }
  return children;
}

export default AccountRouteFetcher;
