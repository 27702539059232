import { Flex } from "@100mslive/react-ui";
import { PlusOutlined } from "@ant-design/icons";
import { Input, InputRef, List, Select, Tooltip } from "antd";
import Tag from "antd/es/tag";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { capitalizeFirstLetter, truncateString } from "../../../helpers";
import {
  fetchAllFormList,
  fetchAllProcedures,
  postPromForm,
} from "../../../redux/reducers/prom.slice";
import { RootState } from "../../../shared/constants";
import Eq5d5QuestionnaireData from "./Components/Eq5d5QuestionnaireData";
import KoovsQuestionnaireData from "./Components/KoovsQuestionnaireData";
import LoadingProm from "./Components/loadingProm";
import TemplateList from "./Components/template.list";
import "./styles/prom.template.scss";

const { Option } = Select;

interface IOption {
  label: string;
  value: number;
  flag: boolean;
}

interface Question {
  id: string;
  question: string;
  options: IOption[];
}

interface Section {
  title: string;
  description: string;
  questions: Question[];
}

interface QuestionnaireDataKoovsInterface {
  stiffness: Section;
  pain: Section;
  functionDailyLiving: Section;
}

interface QuestionnaireDataEq_5d_5lInteface {
  mobility: Section;
  selfCare: Section;
  usualActivities: Section;
  painDiscomfort: Section;
  anxietyDepression: Section;
}

export interface OptionSpecialties {
  label: string;
  value: number;
}

export interface QuestionSpecialties {
  id: string;
  question: string;
  options: OptionSpecialties[];
}

export interface SectionSpecialties {
  title: string;
  description: string;
  questions: QuestionSpecialties[];
}

export interface Form {
  type: string;
  formdata: {
    stiffness: SectionSpecialties;
    pain: SectionSpecialties;
    functionDailyLiving: SectionSpecialties;
  };
}

export interface SelectedSpecialties {
  id: string;
  doctor: string[];
  daysInputs: { value: string }[];
  days: number;
  department: string;
  treatment: string;
  template_name: string;
  form: Form;
}

const questionnaireDataKoos: QuestionnaireDataKoovsInterface = {
  stiffness: {
    title: "Stiffness",
    description:
      "Stiffness is a sensation of restriction or slowness in the ease with which you move your knee joint. What amount of knee stiffness have you experienced the last week during the following activities?",
    questions: [
      {
        id: "S1",
        question:
          "How severe is your knee stiffness after first wakening in the morning?",
        options: [
          { label: "None", value: 0, flag: false },
          { label: "Mild", value: 1, flag: false },
          { label: "Moderate", value: 2, flag: false },
          { label: "Severe", value: 3, flag: false },
          { label: "Extreme", value: 4, flag: false },
        ],
      },
    ],
  },
  pain: {
    title: "Pain",
    description:
      "What amount of knee pain have you experienced the last week during the following activities?",
    questions: [
      {
        id: "P1",
        question: "Twisting/pivoting on your knee",
        options: [
          { label: "None", value: 0, flag: false },
          { label: "Mild", value: 1, flag: false },
          { label: "Moderate", value: 2, flag: false },
          { label: "Severe", value: 3, flag: false },
          { label: "Extreme", value: 4, flag: false },
        ],
      },
      {
        id: "P2",
        question: "Straightening knee fully",
        options: [
          { label: "None", value: 0, flag: false },
          { label: "Mild", value: 1, flag: false },
          { label: "Moderate", value: 2, flag: false },
          { label: "Severe", value: 3, flag: false },
          { label: "Extreme", value: 4, flag: false },
        ],
      },
      {
        id: "P3",
        question: "Going up or down stairs",
        options: [
          { label: "None", value: 0, flag: false },
          { label: "Mild", value: 1, flag: false },
          { label: "Moderate", value: 2, flag: false },
          { label: "Severe", value: 3, flag: false },
          { label: "Extreme", value: 4, flag: false },
        ],
      },
      {
        id: "P4",
        question: "Standing upright",
        options: [
          { label: "None", value: 0, flag: false },
          { label: "Mild", value: 1, flag: false },
          { label: "Moderate", value: 2, flag: false },
          { label: "Severe", value: 3, flag: false },
          { label: "Extreme", value: 4, flag: false },
        ],
      },
    ],
  },
  functionDailyLiving: {
    title: "Function, daily living",
    description:
      "This section describes your ability to move around and to look after yourself. For each of the following activities, please indicate the degree of difficulty you have experienced in the last week due to your knee.",
    questions: [
      {
        id: "A1",
        question: "Rising from sitting",
        options: [
          { label: "None", value: 0, flag: false },
          { label: "Mild", value: 1, flag: false },
          { label: "Moderate", value: 2, flag: false },
          { label: "Severe", value: 3, flag: false },
          { label: "Extreme", value: 4, flag: false },
        ],
      },
      {
        id: "A2",
        question: "Bending to the floor/pick up an object",
        options: [
          { label: "None", value: 0, flag: false },
          { label: "Mild", value: 1, flag: false },
          { label: "Moderate", value: 2, flag: false },
          { label: "Severe", value: 3, flag: false },
          { label: "Extreme", value: 4, flag: false },
        ],
      },
    ],
  },
};

const questionnaireDataEq_5d_5l: QuestionnaireDataEq_5d_5lInteface = {
  mobility: {
    title: "Mobility",
    description: "",
    questions: [
      {
        id: "M1",
        question: "I have no problems in walking about",
        options: [{ label: "", value: 1, flag: false }],
      },
      {
        id: "M2",
        question: "I have slight problems in walking about",
        options: [{ label: "", value: 2, flag: false }],
      },
      {
        id: "M3",
        question: "I have moderate problems in walking about",
        options: [{ label: "", value: 3, flag: false }],
      },
      {
        id: "M4",
        question: "I have severe problems in walking about",
        options: [{ label: "", value: 4, flag: false }],
      },
      {
        id: "M5",
        question: "I am unable to walk about",
        options: [{ label: "", value: 5, flag: false }],
      },
    ],
  },
  selfCare: {
    title: "Self-Care",
    description: "",
    questions: [
      {
        id: "SC1",
        question: "I have no problems washing or dressing myself",
        options: [{ label: "", value: 1, flag: false }],
      },
      {
        id: "SC2",
        question: "I have slight problems washing or dressing myself",
        options: [{ label: "", value: 2, flag: false }],
      },
      {
        id: "SC3",
        question: "I have moderate problems washing or dressing myself",
        options: [{ label: "", value: 3, flag: false }],
      },
      {
        id: "SC4",
        question: "I have severe problems washing or dressing myself",
        options: [{ label: "", value: 4, flag: false }],
      },
      {
        id: "SC5",
        question: "I am unable to wash or dress myself",
        options: [{ label: "", value: 5, flag: false }],
      },
    ],
  },
  usualActivities: {
    title: "Usual Activities",
    description: "e.g. work, study, housework, family or leisure activities",
    questions: [
      {
        id: "UA1",
        question: "I have no problems doing my usual activities",
        options: [{ label: "", value: 1, flag: false }],
      },
      {
        id: "UA2",
        question: "I have slight problems doing my usual activities",
        options: [{ label: "", value: 2, flag: false }],
      },
      {
        id: "UA3",
        question: "I have moderate problems doing my usual activities",
        options: [{ label: "", value: 3, flag: false }],
      },
      {
        id: "UA4",
        question: "I have severe problems doing my usual activities",
        options: [{ label: "", value: 4, flag: false }],
      },
      {
        id: "UA5",
        question: "I am unable to do my usual activities",
        options: [{ label: "", value: 5, flag: false }],
      },
    ],
  },
  painDiscomfort: {
    title: "Pain / Discomfort",
    description: "",
    questions: [
      {
        id: "PD1",
        question: "I have no pain or discomfort",
        options: [{ label: "", value: 1, flag: false }],
      },
      {
        id: "PD2",
        question: "I have slight pain or discomfort",
        options: [{ label: "", value: 2, flag: false }],
      },
      {
        id: "PD3",
        question: "I have moderate pain or discomfort",
        options: [{ label: "", value: 3, flag: false }],
      },
      {
        id: "PD4",
        question: "I have severe pain or discomfort",
        options: [{ label: "", value: 4, flag: false }],
      },
      {
        id: "PD5",
        question: "I have extreme pain or discomfort",
        options: [{ label: "", value: 5, flag: false }],
      },
    ],
  },
  anxietyDepression: {
    title: "Anxiety / Depression",
    description: "",
    questions: [
      {
        id: "AD1",
        question: "I am not anxious or depressed",
        options: [{ label: "", value: 1, flag: false }],
      },
      {
        id: "AD2",
        question: "I am slightly anxious or depressed",
        options: [{ label: "", value: 2, flag: false }],
      },
      {
        id: "AD3",
        question: "I am moderately anxious or depressed",
        options: [{ label: "", value: 3, flag: false }],
      },
      {
        id: "AD4",
        question: "I am severely anxious or depressed",
        options: [{ label: "", value: 4, flag: false }],
      },
      {
        id: "AD5",
        question: "I am extremely anxious or depressed",
        options: [{ label: "", value: 5, flag: false }],
      },
    ],
  },
};

function PromTemplate() {
  const dispatch = useDispatch();
  const { loading, allFormsList, allProceduresList } = useSelector(
    (state: RootState) => state.prom
  );

  const [addTemplateEnable, setAddTemplateEnable] = useState(false);
  const [selectedFormDropdownOpen, setSelectedFormDropdownOpen] =
    useState(false);
  const [previewEnable, setPreviewEnable] = useState(false);
  const [onSelectSpecialtiesAction, setOnSelectSpecialtiesAction] =
    useState(true);
  const [templateName, setTemplateName] = useState<string | null>("");
  const [selectedTreatmentList, setSelectedTreatmentList] = useState<any[]>([]);

  const [searchText, setSearchText] = useState<string>("");

  const [selectedSpecialtiesInfo, setSelectedSpecialtiesInfo] = useState<
    any | null
  >();

  // CHECK DAYS TAGS STATES
  const editCheckDayInputRef = useRef<InputRef>(null);
  const checkDayInputRef = useRef<InputRef>(null);
  const [checkDayTags, setCheckDayTags] = useState<number[]>([]);
  const [currentCheckDayValue, setCurrentCheckDayValue] = useState<
    null | number
  >(null);
  const [editCheckDayIndex, setEditCheckDayIndex] = useState(-1);
  const [editCurrentCheckDayValue, setEditCurrentCheckDayValue] = useState<
    null | number
  >(null);
  const [isDayInputVisible, setIsDayInputVisible] = useState(false);
  const [totalDays, setTotalDays] = useState(0);

  const [treatmentOptionsList, setTreatmentListOptions] = useState<
    Array<{ label: string; value: string }>
  >([]);

  const [selectedFormType, setSelectedFormType] = useState("koos");

  const treatmentsForm = ["koos", "EQ-5D-5L"];

  const selectedFormRef = useRef<HTMLDivElement>(null);
  const svgRef = useRef<SVGSVGElement | null>(null);

  // DAY TAG INPUT HANDLERS

  const handleCheckDayInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCurrentCheckDayValue(parseInt(e.target.value));
  };

  const showCheckDaysInput = () => {
    setIsDayInputVisible(true);
  };

  const handleCheckDayInputConfirm = () => {
    if (currentCheckDayValue && currentCheckDayValue > 0) {
      // prevent duplicates
      if (
        !checkDayTags.includes(currentCheckDayValue) &&
        currentCheckDayValue > totalDays
      ) {
        calculateTotal([...checkDayTags, currentCheckDayValue]);
        setCheckDayTags([...checkDayTags, currentCheckDayValue]);
      } else {
        alert("Error: The value is less than or equal to the previous value.");
      }
    } else {
      alert("Please add a valid value for check day");
    }
    setIsDayInputVisible(false);
    setCurrentCheckDayValue(null);
  };

  const handleEditCheckDayInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEditCurrentCheckDayValue(parseInt(e.target.value));
  };

  const handleEditInputConfirm = () => {
    const newTags = [...checkDayTags];

    if (editCurrentCheckDayValue !== null) {
      if (!checkDayTags.includes(editCurrentCheckDayValue)) {
        newTags[editCheckDayIndex] = editCurrentCheckDayValue;
      } else {
        alert("Error: The value is less than or equal to the previous value.");
      }
    } else {
      alert("Please add a valid value for check day");
    }

    setCheckDayTags(newTags);
    setEditCheckDayIndex(-1);
    setEditCurrentCheckDayValue(null);
    calculateTotal(newTags);
  };

  const handleClose = (removedDay: number) => {
    const newTags = checkDayTags.filter((day) => day !== removedDay);
    setCheckDayTags(newTags);
    if (newTags.length > 0) {
      calculateTotal(newTags);
    } else {
      setTotalDays(0);
    }
  };

  // END OF  DAY TAG INPUT HANDLERS
  const handleFocus = () => {
    document.querySelector(".search-container")?.classList.add("focused");
    svgRef?.current?.classList.add("focused");
  };

  const handleBlur = () => {
    document.querySelector(".search-container")?.classList.remove("focused");
    svgRef?.current?.classList.remove("focused");
  };

  const toggleDropdownSelectedForm = () => {
    setSelectedFormDropdownOpen(!selectedFormDropdownOpen);
  };

  const handleOptionClickSelectedForm = (
    e: React.MouseEvent<HTMLAnchorElement>,
    item: string
  ) => {
    e.preventDefault();
    setSelectedFormType(item);
    setSelectedFormDropdownOpen(false);
  };

  // const handleChangeDoctor = (e: React.MouseEvent<HTMLAnchorElement>, item: any) => {
  //   e.preventDefault();
  //   const { name } = item;

  //   setSelectedDoctor(name);

  //   if (!selectedDoctorsList.includes(item)) {
  //     setSelectedDoctorsList([...selectedDoctorsList, item]);
  //   }
  //   setDoctorDropdownOpen(!doctorDropdownOpen);
  // };

  // const removeDoctor = (id: number) => {
  //   const newSelectedDoctorsList = selectedDoctorsList.filter((item) => item.id !== id);
  //   setSelectedDoctorsList(newSelectedDoctorsList);
  // };

  // const toggleDepartmentDropdown = () => {
  //   setDepartmentDropdownOpen(!departmentDropdownOpen);
  // };

  const allTreatmentList: Array<{ label: string; value: string }> =
    useMemo(() => {
      return allProceduresList?.map((item: any, index: any) => ({
        label: item.name,
        value: item.procedure_id,
      }));
    }, [allProceduresList]);

  const handleSearchTreatments = useCallback(
    (e) => {
      // regex for the query value to be search
      const regex = new RegExp(e, "ig");

      const filteredTreatmentList = allTreatmentList.filter((treatment) => {
        return regex.test(treatment.label) || regex.test(treatment.value);
      });

      setTreatmentListOptions(filteredTreatmentList);
    },
    [allTreatmentList]
  );

  const handleTreatmentClick = (items: string[]) => {
    setSelectedTreatmentList(items);
  };

  const calculateTotal = (inputs: number[]) => {
    const highestValue = Math.max(...inputs);
    setTotalDays(highestValue);
  };

  const formValidation = () => {
    if (!templateName) {
      alert("Please enter template name.");
      return false;
    }

    if (checkDayTags.length < 1) {
      alert("Please fill in check days input.");
      return false;
    }

    if (selectedTreatmentList.length < 1) {
      alert("Please select at least one treatment from the list.");
      return false;
    } else {
      return true;
    }
  };

  const handleSubmitForm = async (e: any) => {
    const isvalid = formValidation();

    if (isvalid) {
      const postData = {
        name: templateName,
        procedureIds: selectedTreatmentList,
        adminId: 1,
        scoreSystem: selectedFormType === "koos" ? "koos" : "eq",
        formTemplate:
          selectedFormType === "koos"
            ? { formdata: questionnaireDataKoos }
            : { formdata: questionnaireDataEq_5d_5l },
        formFrequency: checkDayTags,
      };

      await dispatch(postPromForm(postData));

      e.preventDefault();
      setOnSelectSpecialtiesAction(true);
      setAddTemplateEnable(false);
      try {
        (async function () {
          await dispatch(fetchAllFormList());
        })();
      } catch (e) {
        console.error(e);
      }
    }
  };
  // console.log(daysInputs);

  // const filteredDoctors = doctors.filter((doctor) => doctor.name.toLowerCase().includes(searchDoctor.toLowerCase()));

  // const handleSearchDoctor = (event: { target: { value: React.SetStateAction<string> } }) => {
  //   setSearchDoctor(event.target.value);
  // };

  // const handleDoctorDropdown = () => {
  //   setDoctorDropdownOpen(!doctorDropdownOpen);
  // };

  const handlePreview = () => {
    const res = formValidation();
    if (res) {
      setPreviewEnable((prev) => {
        // scroll to top if preview is enabled
        if (!prev) {
          selectedFormRef?.current?.scrollIntoView({ behavior: "smooth" });
        }
        return !prev;
      });
    }
  };

  const handleClickTemplateList = (
    event: React.MouseEvent<HTMLAnchorElement>,
    item: any
  ) => {
    event.preventDefault();
    setOnSelectSpecialtiesAction(true);
    setAddTemplateEnable(false);
    setSelectedSpecialtiesInfo(item);
  };

  const handleAddTemplateEnable = () => {
    setAddTemplateEnable(!addTemplateEnable);
    if (onSelectSpecialtiesAction) {
      // setOnSelectSpecialtiesAction(false);
      setTemplateName("");
      // setSelectedDepartment(null);
      setSelectedTreatmentList([]);

      selectedFormType === "koos" ? setTotalDays(180) : setTotalDays(0);
      setCheckDayTags(() =>
        selectedFormType === "koos" ? [1, 6, 45, 90, 180] : []
      );
      setPreviewEnable(false);

      // setSelectedDoctorsList([]);
      if (addTemplateEnable) setSelectedSpecialtiesInfo(allFormsList?.[0]);
    } else {
    }
  };

  const filteredTempList = allFormsList?.filter((temp: any) =>
    temp?.form_name?.toLowerCase().includes(searchText.toLowerCase())
  );

  useEffect(() => {
    try {
      (async function () {
        await dispatch(fetchAllProcedures());
        await dispatch(fetchAllFormList());
      })();
    } catch (e) {
      console.error(e);
    }
  }, [dispatch]);

  useEffect(() => {
    if (selectedFormType === "koos") {
      setTotalDays(180);
      setCheckDayTags([1, 6, 45, 90, 180]);
    } else {
      setCheckDayTags([]);
      setTotalDays(0);
    }
  }, [selectedFormType]);

  useEffect(() => {
    if (allFormsList) {
      setSelectedSpecialtiesInfo(allFormsList?.[0]);
    }
  }, [allFormsList]);

  useEffect(() => {
    setTreatmentListOptions(
      allProceduresList?.map((item: any, index: any) => ({
        label: item.name,
        value: item.procedure_id,
      }))
    );
  }, [allProceduresList]);

  return (
    <>
      <div className="prom_template_container">
        {loading && <LoadingProm />}
        <div className="prom_template_layout_container">
          <section className="prom_template_left">
            <div className="title_container">
              <div className="title_name">Template</div>
              <div className="add_template">
                {!addTemplateEnable ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                    onClick={handleAddTemplateEnable}
                  >
                    <path
                      d="M15.5833 24.0833H18.4166V18.4166H24.0833V15.5833H18.4166V9.91659H15.5833V15.5833H9.91659V18.4166H15.5833V24.0833ZM16.9999 31.1666C15.0402 31.1666 13.1985 30.7947 11.4749 30.051C9.75131 29.3072 8.252 28.2978 6.977 27.0228C5.702 25.7478 4.69263 24.2485 3.94888 22.5249C3.20513 20.8013 2.83325 18.9596 2.83325 16.9999C2.83325 15.0402 3.20513 13.1985 3.94888 11.4749C4.69263 9.75131 5.702 8.252 6.977 6.977C8.252 5.702 9.75131 4.69263 11.4749 3.94888C13.1985 3.20513 15.0402 2.83325 16.9999 2.83325C18.9596 2.83325 20.8013 3.20513 22.5249 3.94888C24.2485 4.69263 25.7478 5.702 27.0228 6.977C28.2978 8.252 29.3072 9.75131 30.051 11.4749C30.7947 13.1985 31.1666 15.0402 31.1666 16.9999C31.1666 18.9596 30.7947 20.8013 30.051 22.5249C29.3072 24.2485 28.2978 25.7478 27.0228 27.0228C25.7478 28.2978 24.2485 29.3072 22.5249 30.051C20.8013 30.7947 18.9596 31.1666 16.9999 31.1666Z"
                      fill="#003878"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                    onClick={handleAddTemplateEnable}
                  >
                    <mask
                      id="mask0_1521_1430"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="34"
                      height="34"
                    >
                      <rect width="34" height="34" fill="#003878" />
                    </mask>
                    <g mask="url(#mask0_1521_1430)">
                      <path
                        d="M15.5833 24.0833H18.4166V18.4166H24.0833V15.5833H18.4166V9.91659H15.5833V15.5833H9.91659V18.4166H15.5833V24.0833ZM16.9999 31.1666C15.0402 31.1666 13.1985 30.7947 11.4749 30.051C9.75131 29.3072 8.252 28.2978 6.977 27.0228C5.702 25.7478 4.69263 24.2485 3.94888 22.5249C3.20513 20.8013 2.83325 18.9596 2.83325 16.9999C2.83325 15.0402 3.20513 13.1985 3.94888 11.4749C4.69263 9.75131 5.702 8.252 6.977 6.977C8.252 5.702 9.75131 4.69263 11.4749 3.94888C13.1985 3.20513 15.0402 2.83325 16.9999 2.83325C18.9596 2.83325 20.8013 3.20513 22.5249 3.94888C24.2485 4.69263 25.7478 5.702 27.0228 6.977C28.2978 8.252 29.3072 9.75131 30.051 11.4749C30.7947 13.1985 31.1666 15.0402 31.1666 16.9999C31.1666 18.9596 30.7947 20.8013 30.051 22.5249C29.3072 24.2485 28.2978 25.7478 27.0228 27.0228C25.7478 28.2978 24.2485 29.3072 22.5249 30.051C20.8013 30.7947 18.9596 31.1666 16.9999 31.1666Z"
                        fill="#003878"
                      />
                    </g>
                  </svg>
                )}
              </div>
            </div>
            <div className="search-container">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <mask
                  id="mask0_3030_1174"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="25"
                  height="24"
                >
                  <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_3030_1174)">
                  <path
                    d="M20.1 20.4348L13.8 14.6826C13.3 15.0478 12.725 15.337 12.075 15.55C11.425 15.763 10.7333 15.8696 10 15.8696C8.18333 15.8696 6.64583 15.2951 5.3875 14.1462C4.12917 12.9973 3.5 11.5935 3.5 9.93478C3.5 8.27609 4.12917 6.87228 5.3875 5.72337C6.64583 4.57446 8.18333 4 10 4C11.8167 4 13.3542 4.57446 14.6125 5.72337C15.8708 6.87228 16.5 8.27609 16.5 9.93478C16.5 10.6043 16.3833 11.2359 16.15 11.8293C15.9167 12.4228 15.6 12.9478 15.2 13.4043L21.5 19.1565L20.1 20.4348ZM10 14.0435C11.25 14.0435 12.3125 13.644 13.1875 12.8451C14.0625 12.0462 14.5 11.0761 14.5 9.93478C14.5 8.79348 14.0625 7.82337 13.1875 7.02446C12.3125 6.22554 11.25 5.82609 10 5.82609C8.75 5.82609 7.6875 6.22554 6.8125 7.02446C5.9375 7.82337 5.5 8.79348 5.5 9.93478C5.5 11.0761 5.9375 12.0462 6.8125 12.8451C7.6875 13.644 8.75 14.0435 10 14.0435Z"
                    fill="#B8C8D7"
                  />
                </g>
              </svg>
              <input
                placeholder="Search"
                onChange={(e) => setSearchText(e.target.value)}
                className="search_input"
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
            </div>
            <div className="specialties_container">
              <List
                itemLayout="horizontal"
                dataSource={filteredTempList}
                renderItem={(item: any, index) => (
                  <List.Item
                    className={`specialties_list ${
                      item?.form_id === selectedSpecialtiesInfo?.form_id
                        ? "active"
                        : ""
                    }`}
                    onClick={(e: any) => handleClickTemplateList(e, item)}
                  >
                    <div className="image_name_display">
                      <div>
                        <div className="display_template_name">
                          {capitalizeFirstLetter(item?.form_name)}
                        </div>
                        <div className="display_department">
                          {capitalizeFirstLetter(item?.department)}
                        </div>
                      </div>
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="11"
                      height="17"
                      viewBox="0 0 11 17"
                      fill="none"
                    >
                      <path
                        d="M6.80002 8.5L0.666687 2.36667L2.53335 0.5L10.5334 8.5L2.53335 16.5L0.666687 14.6333L6.80002 8.5Z"
                        fill="#003878"
                      />
                    </svg>
                  </List.Item>
                )}
              />
            </div>
          </section>

          <section
            className={`prom_template_right ${
              onSelectSpecialtiesAction ? "prom_template_right_action" : ""
            }`}
          >
            <div className="right_layout_sub_container">
              {addTemplateEnable && (
                <div className="right_layout_sub_temp">
                  {previewEnable && (
                    <div className="preview_save_container preview_active">
                      <div className="preview_btn" onClick={handlePreview}>
                        Preview
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="15"
                          viewBox="0 0 22 15"
                          fill="#fff"
                        >
                          <path
                            d="M10.9998 11.9998C12.2498 11.9998 13.3123 11.5623 14.1873 10.6873C15.0623 9.81226 15.4998 8.74976 15.4998 7.49976C15.4998 6.24976 15.0623 5.18726 14.1873 4.31226C13.3123 3.43726 12.2498 2.99976 10.9998 2.99976C9.74976 2.99976 8.68726 3.43726 7.81226 4.31226C6.93726 5.18726 6.49976 6.24976 6.49976 7.49976C6.49976 8.74976 6.93726 9.81226 7.81226 10.6873C8.68726 11.5623 9.74976 11.9998 10.9998 11.9998ZM10.9998 10.1998C10.2498 10.1998 9.61226 9.93726 9.08726 9.41226C8.56226 8.88726 8.29976 8.24976 8.29976 7.49976C8.29976 6.74976 8.56226 6.11226 9.08726 5.58726C9.61226 5.06226 10.2498 4.79976 10.9998 4.79976C11.7498 4.79976 12.3873 5.06226 12.9123 5.58726C13.4373 6.11226 13.6998 6.74976 13.6998 7.49976C13.6998 8.24976 13.4373 8.88726 12.9123 9.41226C12.3873 9.93726 11.7498 10.1998 10.9998 10.1998ZM10.9998 14.9998C8.56642 14.9998 6.34976 14.3206 4.34976 12.9623C2.34976 11.6039 0.899756 9.78309 -0.000244141 7.49976C0.899756 5.21642 2.34976 3.39559 4.34976 2.03726C6.34976 0.678923 8.56642 -0.000244141 10.9998 -0.000244141C13.4331 -0.000244141 15.6498 0.678923 17.6498 2.03726C19.6498 3.39559 21.0998 5.21642 21.9998 7.49976C21.0998 9.78309 19.6498 11.6039 17.6498 12.9623C15.6498 14.3206 13.4331 14.9998 10.9998 14.9998ZM10.9998 12.9998C12.8831 12.9998 14.6123 12.5039 16.1873 11.5123C17.7623 10.5206 18.9664 9.18309 19.7998 7.49976C18.9664 5.81642 17.7623 4.47892 16.1873 3.48726C14.6123 2.49559 12.8831 1.99976 10.9998 1.99976C9.11642 1.99976 7.38726 2.49559 5.81226 3.48726C4.23726 4.47892 3.03309 5.81642 2.19976 7.49976C3.03309 9.18309 4.23726 10.5206 5.81226 11.5123C7.38726 12.5039 9.11642 12.9998 10.9998 12.9998Z"
                            fill="#fff"
                          />
                        </svg>
                      </div>
                      <div onClick={handleSubmitForm} className="save_temp_btn">
                        Save to Templates
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="#fff"
                        >
                          <mask
                            id="mask0_277_694"
                            maskUnits="userSpaceOnUse"
                            x="0"
                            y="0"
                            width="24"
                            height="24"
                          >
                            <rect width="24" height="24" fill="#fff" />
                          </mask>
                          <g mask="url(#mask0_277_694)">
                            <path
                              d="M18 21L22 17L20.6 15.6L19 17.2V13.025H17V17.2L15.4 15.6L14 17L18 21ZM14 24V22H22V24H14ZM6 20C5.45 20 4.97917 19.8042 4.5875 19.4125C4.19583 19.0208 4 18.55 4 18V4C4 3.45 4.19583 2.97917 4.5875 2.5875C4.97917 2.19583 5.45 2 6 2H13L19 8V11.025H17V9H12V4H6V18H12V20H6Z"
                              fill="#fff"
                            />
                          </g>
                        </svg>
                      </div>
                    </div>
                  )}

                  <div className="select_form_container">
                    <div
                      className={`select_form_type ${
                        previewEnable ? "preview_globle_style" : ""
                      } `}
                      ref={selectedFormRef}
                      onClick={
                        !previewEnable ? toggleDropdownSelectedForm : undefined
                      }
                    >
                      <div className="dropdown_display">
                        <div className="dropbtn">
                          {selectedFormType
                            ? `${capitalizeFirstLetter(
                                selectedFormType
                              )} Template`
                            : ""}
                        </div>

                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="27"
                          height="27"
                          viewBox="0 0 27 27"
                          fill="none"
                        >
                          <mask
                            id="mask0_277_470"
                            maskUnits="userSpaceOnUse"
                            x="0"
                            y="0"
                            width="27"
                            height="27"
                          >
                            <rect
                              x="27"
                              width="27"
                              height="27"
                              transform="rotate(90 27 0)"
                              fill="#D9D9D9"
                            />
                          </mask>
                          <g mask="url(#mask0_277_470)">
                            <path
                              d="M13.5 14.175L18.675 9L20.25 10.575L13.5 17.325L6.75 10.575L8.325 9L13.5 14.175Z"
                              fill="#003878"
                            />
                          </g>
                        </svg>
                      </div>
                      <div
                        id="myDropdown"
                        className={
                          selectedFormDropdownOpen
                            ? "dropdown_content_form show"
                            : "dropdown_content_form"
                        }
                      >
                        {treatmentsForm.map((item, index) => (
                          <div
                            className="item_name"
                            key={index}
                            onClick={(e: any) =>
                              handleOptionClickSelectedForm(e, item)
                            }
                          >
                            {capitalizeFirstLetter(item)} Template
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="form_container">
                    <input
                      disabled={previewEnable}
                      placeholder="Template Name"
                      type="text"
                      className={`tamp_name ${
                        previewEnable ? "preview_globle_style" : ""
                      }`}
                      onChange={(e) => setTemplateName(e.target.value)}
                    />

                    <Select
                      mode="multiple"
                      allowClear
                      onSearch={handleSearchTreatments}
                      showSearch={true}
                      filterOption={false}
                      className={`treatment_multi_select ${
                        previewEnable ? "preview_globle_style" : ""
                      }`}
                      style={{
                        width: "100%",
                        minHeight: "42px",
                        height: "maxContent",
                      }}
                      placeholder="Treatments"
                      defaultValue={selectedTreatmentList}
                      onChange={(treatments) => {
                        handleTreatmentClick(treatments);
                      }}
                      options={treatmentOptionsList}
                      disabled={previewEnable}
                    >
                      {treatmentOptionsList?.map(
                        (p: { label: string; value: string }) => {
                          return (
                            <Option value={p.value} label={p.label}>
                              <div
                                style={{
                                  color: "#003878 !important",
                                  fontSize: "14px",
                                  fontStyle: "normal",
                                  fontWeight: "500",
                                }}
                              >
                                {truncateString(capitalizeFirstLetter(p.label))}
                              </div>
                            </Option>
                          );
                        }
                      )}
                    </Select>

                    <div className="tag_input_container">
                      <div
                        className={`${
                          totalDays
                            ? `tamp_name ${
                                previewEnable ? "preview_globle_style" : ""
                              }`
                            : "inactive_state_style_tamp_name"
                        }`}
                      >
                        {totalDays === 0 || checkDayTags.length < 1
                          ? "PROM Duration"
                          : `PROM Duration: ${totalDays} ${
                              totalDays < 2 ? "Day" : "Days"
                            }`}
                      </div>

                      {/* Tag Input Code Starts Here   !! TODO: Refactor It */}

                      <Flex
                        className="check_days"
                        style={{ flexWrap: "wrap", gap: "4px" }}
                      >
                        {checkDayTags.map<React.ReactNode>((tag, index) => {
                          if (editCheckDayIndex === index) {
                            return (
                              <Input
                                ref={editCheckDayInputRef}
                                key={tag}
                                disabled={previewEnable}
                                autoFocus
                                type="number"
                                size="small"
                                className={"tag added-tags"}
                                placeholder="Add Check Day"
                                value={editCurrentCheckDayValue ?? undefined}
                                onChange={handleEditCheckDayInputChange}
                                onBlur={handleEditInputConfirm}
                                onPressEnter={handleEditInputConfirm}
                              />
                            );
                          }
                          const isLongTag = tag > 99999;

                          const tagElem = (
                            <Tag
                              className="tag added-tags"
                              key={tag}
                              closable={!previewEnable}
                              aria-disabled={previewEnable}
                              onClose={() => handleClose(tag)}
                            >
                              <span>Day</span>
                              <span
                                onDoubleClick={(e) => {
                                  setEditCheckDayIndex(index);
                                  setEditCurrentCheckDayValue(tag);
                                  e.preventDefault();
                                }}
                              >
                                {isLongTag
                                  ? `${String(tag).slice(0, 5)}...`
                                  : tag}
                              </span>
                            </Tag>
                          );
                          return isLongTag ? (
                            <Tooltip title={tag} key={tag}>
                              {tagElem}
                            </Tooltip>
                          ) : (
                            tagElem
                          );
                        })}

                        {/* visible button for ADD check days */}
                        {!previewEnable &&
                          (isDayInputVisible ? (
                            <Input
                              ref={checkDayInputRef}
                              disabled={previewEnable}
                              autoFocus
                              type="number"
                              size="small"
                              placeholder="Add Check Day"
                              className="tag added-tags__button_input"
                              value={currentCheckDayValue ?? undefined}
                              onChange={handleCheckDayInputChange}
                              onBlur={handleCheckDayInputConfirm}
                              onPressEnter={handleCheckDayInputConfirm}
                            />
                          ) : (
                            <Tag
                              icon={<PlusOutlined />}
                              className="tag added-tags__button"
                              onClick={showCheckDaysInput}
                            >
                              Add Check Day
                            </Tag>
                          ))}
                      </Flex>

                      {/* Tag Input Code Ends Here */}
                    </div>
                  </div>

                  {selectedFormType === "koos" && (
                    <div className="template-form">
                      <div className="question_container_list">
                        {Object.entries(
                          questionnaireDataKoos as QuestionnaireDataKoovsInterface
                        ).map(([sectionKey, section]) => {
                          return (
                            <KoovsQuestionnaireData
                              key={sectionKey}
                              section={section}
                              sectionKey={sectionKey}
                            />
                          );
                        })}
                      </div>
                    </div>
                  )}

                  {selectedFormType === "EQ-5D-5L" && (
                    <div className="template-form">
                      <form className="question_container_list">
                        {Object.keys(questionnaireDataEq_5d_5l).map(
                          (sectionKey) => {
                            const section =
                              questionnaireDataEq_5d_5l[
                                sectionKey as keyof QuestionnaireDataEq_5d_5lInteface
                              ];
                            return (
                              <Eq5d5QuestionnaireData
                                section={section}
                                sectionKey={sectionKey}
                              />
                            );
                          }
                        )}
                      </form>
                    </div>
                  )}

                  {!previewEnable && (
                    <div className="preview_save_container">
                      <div className="preview_btn" onClick={handlePreview}>
                        Preview
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="15"
                          viewBox="0 0 22 15"
                          fill="none"
                        >
                          <path
                            d="M10.9998 11.9998C12.2498 11.9998 13.3123 11.5623 14.1873 10.6873C15.0623 9.81226 15.4998 8.74976 15.4998 7.49976C15.4998 6.24976 15.0623 5.18726 14.1873 4.31226C13.3123 3.43726 12.2498 2.99976 10.9998 2.99976C9.74976 2.99976 8.68726 3.43726 7.81226 4.31226C6.93726 5.18726 6.49976 6.24976 6.49976 7.49976C6.49976 8.74976 6.93726 9.81226 7.81226 10.6873C8.68726 11.5623 9.74976 11.9998 10.9998 11.9998ZM10.9998 10.1998C10.2498 10.1998 9.61226 9.93726 9.08726 9.41226C8.56226 8.88726 8.29976 8.24976 8.29976 7.49976C8.29976 6.74976 8.56226 6.11226 9.08726 5.58726C9.61226 5.06226 10.2498 4.79976 10.9998 4.79976C11.7498 4.79976 12.3873 5.06226 12.9123 5.58726C13.4373 6.11226 13.6998 6.74976 13.6998 7.49976C13.6998 8.24976 13.4373 8.88726 12.9123 9.41226C12.3873 9.93726 11.7498 10.1998 10.9998 10.1998ZM10.9998 14.9998C8.56642 14.9998 6.34976 14.3206 4.34976 12.9623C2.34976 11.6039 0.899756 9.78309 -0.000244141 7.49976C0.899756 5.21642 2.34976 3.39559 4.34976 2.03726C6.34976 0.678923 8.56642 -0.000244141 10.9998 -0.000244141C13.4331 -0.000244141 15.6498 0.678923 17.6498 2.03726C19.6498 3.39559 21.0998 5.21642 21.9998 7.49976C21.0998 9.78309 19.6498 11.6039 17.6498 12.9623C15.6498 14.3206 13.4331 14.9998 10.9998 14.9998ZM10.9998 12.9998C12.8831 12.9998 14.6123 12.5039 16.1873 11.5123C17.7623 10.5206 18.9664 9.18309 19.7998 7.49976C18.9664 5.81642 17.7623 4.47892 16.1873 3.48726C14.6123 2.49559 12.8831 1.99976 10.9998 1.99976C9.11642 1.99976 7.38726 2.49559 5.81226 3.48726C4.23726 4.47892 3.03309 5.81642 2.19976 7.49976C3.03309 9.18309 4.23726 10.5206 5.81226 11.5123C7.38726 12.5039 9.11642 12.9998 10.9998 12.9998Z"
                            fill="#6F7E7E"
                          />
                        </svg>
                      </div>
                      <div onClick={handleSubmitForm} className="save_temp_btn">
                        Save to Templates
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <mask
                            id="mask0_277_694"
                            maskUnits="userSpaceOnUse"
                            x="0"
                            y="0"
                            width="24"
                            height="24"
                          >
                            <rect width="24" height="24" fill="#D9D9D9" />
                          </mask>
                          <g mask="url(#mask0_277_694)">
                            <path
                              d="M18 21L22 17L20.6 15.6L19 17.2V13.025H17V17.2L15.4 15.6L14 17L18 21ZM14 24V22H22V24H14ZM6 20C5.45 20 4.97917 19.8042 4.5875 19.4125C4.19583 19.0208 4 18.55 4 18V4C4 3.45 4.19583 2.97917 4.5875 2.5875C4.97917 2.19583 5.45 2 6 2H13L19 8V11.025H17V9H12V4H6V18H12V20H6Z"
                              fill="#6F7E7E"
                            />
                          </g>
                        </svg>
                      </div>
                    </div>
                  )}

                  {previewEnable && (
                    <div className="preview_save_container preview_active">
                      <div className="preview_btn" onClick={handlePreview}>
                        Preview
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="15"
                          viewBox="0 0 22 15"
                          fill="#fff"
                        >
                          <path
                            d="M10.9998 11.9998C12.2498 11.9998 13.3123 11.5623 14.1873 10.6873C15.0623 9.81226 15.4998 8.74976 15.4998 7.49976C15.4998 6.24976 15.0623 5.18726 14.1873 4.31226C13.3123 3.43726 12.2498 2.99976 10.9998 2.99976C9.74976 2.99976 8.68726 3.43726 7.81226 4.31226C6.93726 5.18726 6.49976 6.24976 6.49976 7.49976C6.49976 8.74976 6.93726 9.81226 7.81226 10.6873C8.68726 11.5623 9.74976 11.9998 10.9998 11.9998ZM10.9998 10.1998C10.2498 10.1998 9.61226 9.93726 9.08726 9.41226C8.56226 8.88726 8.29976 8.24976 8.29976 7.49976C8.29976 6.74976 8.56226 6.11226 9.08726 5.58726C9.61226 5.06226 10.2498 4.79976 10.9998 4.79976C11.7498 4.79976 12.3873 5.06226 12.9123 5.58726C13.4373 6.11226 13.6998 6.74976 13.6998 7.49976C13.6998 8.24976 13.4373 8.88726 12.9123 9.41226C12.3873 9.93726 11.7498 10.1998 10.9998 10.1998ZM10.9998 14.9998C8.56642 14.9998 6.34976 14.3206 4.34976 12.9623C2.34976 11.6039 0.899756 9.78309 -0.000244141 7.49976C0.899756 5.21642 2.34976 3.39559 4.34976 2.03726C6.34976 0.678923 8.56642 -0.000244141 10.9998 -0.000244141C13.4331 -0.000244141 15.6498 0.678923 17.6498 2.03726C19.6498 3.39559 21.0998 5.21642 21.9998 7.49976C21.0998 9.78309 19.6498 11.6039 17.6498 12.9623C15.6498 14.3206 13.4331 14.9998 10.9998 14.9998ZM10.9998 12.9998C12.8831 12.9998 14.6123 12.5039 16.1873 11.5123C17.7623 10.5206 18.9664 9.18309 19.7998 7.49976C18.9664 5.81642 17.7623 4.47892 16.1873 3.48726C14.6123 2.49559 12.8831 1.99976 10.9998 1.99976C9.11642 1.99976 7.38726 2.49559 5.81226 3.48726C4.23726 4.47892 3.03309 5.81642 2.19976 7.49976C3.03309 9.18309 4.23726 10.5206 5.81226 11.5123C7.38726 12.5039 9.11642 12.9998 10.9998 12.9998Z"
                            fill="#fff"
                          />
                        </svg>
                      </div>
                      <div onClick={handleSubmitForm} className="save_temp_btn">
                        Save to Templates
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="#fff"
                        >
                          <mask
                            id="mask0_277_694"
                            maskUnits="userSpaceOnUse"
                            x="0"
                            y="0"
                            width="24"
                            height="24"
                          >
                            <rect width="24" height="24" fill="#fff" />
                          </mask>
                          <g mask="url(#mask0_277_694)">
                            <path
                              d="M18 21L22 17L20.6 15.6L19 17.2V13.025H17V17.2L15.4 15.6L14 17L18 21ZM14 24V22H22V24H14ZM6 20C5.45 20 4.97917 19.8042 4.5875 19.4125C4.19583 19.0208 4 18.55 4 18V4C4 3.45 4.19583 2.97917 4.5875 2.5875C4.97917 2.19583 5.45 2 6 2H13L19 8V11.025H17V9H12V4H6V18H12V20H6Z"
                              fill="#fff"
                            />
                          </g>
                        </svg>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {onSelectSpecialtiesAction && selectedSpecialtiesInfo && (
                <TemplateList selectedInfo={selectedSpecialtiesInfo} />
              )}
              {onSelectSpecialtiesAction &&
                !selectedSpecialtiesInfo &&
                !addTemplateEnable && <div className="no_data">No data</div>}
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default PromTemplate;
