import { useEffect, useMemo, useState } from "react";
import { dateFormatter, getDateAfterDays } from "../../../../helpers";
import "../styles/patient.display.scss";
import Eq5d5QuestionnaireData from "./Eq5d5QuestionnaireData";
import KoovsQuestionnaireData from "./KoovsQuestionnaireData";

interface Dimension {
  title: string;
  description: string;
  questions: Question[];
}

interface Question {
  id: string;
  question: string;
  options: Option[];
}

interface Option {
  label: string;
  value: number;
  flag: boolean;
}

interface QuestionnaireDataKoovsInterface {
  stiffness: Dimension;
  pain: Dimension;
  functionDailyLiving: Dimension;
}

interface QuestionnaireDataEq_5d_5lInteface {
  mobility: Dimension;
  selfCare: Dimension;
  usualActivities: Dimension;
  painDiscomfort: Dimension;
  anxietyDepression: Dimension;
}

function PatientDisplay(props: any) {
  const { selectedPatient } = props;

  const [activePatientData, setActivePatientData] = useState<any>(null);

  // convert into upper case
  const capitalizeFirstLetter = (text: string) => {
    if (!text) return "";
    return text
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  function calculateAge(dob: any) {
    const birthDate = new Date(dob);
    const today = new Date();

    let years = today.getFullYear() - birthDate.getFullYear();
    let months = today.getMonth() - birthDate.getMonth();

    // If the current month is before the birth month, or if it's the same month but the current day is before the birth day
    if (months < 0 || (months === 0 && today.getDate() < birthDate.getDate())) {
      years--;
      months += 12;
    }

    // If the current day is before the birth day in the month, reduce the months count
    if (today.getDate() < birthDate.getDate()) {
      months--;
    }

    return { years, months };
  }

  // calculate prom send days
  const calculatePromDays = (date: any): number => {
    const birthDate = new Date(date);
    const today = new Date();
    const timeDifference = today.getTime() - birthDate.getTime();
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    return daysDifference;
  };

  useEffect(() => {
    const lastCompleted = selectedPatient?.scoreData?.reduce(
      (last: any, current: any) => {
        return current.response_status === "completed" ? current : last;
      },
      null
    );
    setActivePatientData(lastCompleted);
  }, [selectedPatient?.scoreData]);

  // active day and max day calculater
  const resultDays = selectedPatient?.scoreData?.reduce(
    (acc: any, current: any) => {
      if (current.frequency_value > acc.max_day) {
        acc.max_day = current.frequency_value;
      }

      if (current.response_status === "completed") {
        acc.active_day = current.frequency_value;
      }
      return acc;
    },
    { active_day: null, max_day: -Infinity }
  );

  const completedPROMVsTotalSent = useMemo(() => {
    const completed = selectedPatient?.scoreData.filter(
      (formData: any) =>
        formData.response !== null && formData.response_status === "completed"
    )?.length;
    const total = selectedPatient?.scoreData?.length;

    return `${completed}/${total}`;
  }, [selectedPatient?.scoreData]);

  return (
    <div className="right_layout_sub_patient">
      <div className="top_action_container">
        <div className="department_and_specialties_container">
          <div className="name_and_days">
            <div className="template_department_doc_container">
              <div className="image_name_display">
                <div>
                  <div className="template_name">
                    {selectedPatient?.patient_details?.patient_name
                      ? capitalizeFirstLetter(
                          selectedPatient?.patient_details?.patient_name
                        )
                      : ""}

                    <div className="age_gender">
                      {selectedPatient?.patient_details?.dob &&
                      selectedPatient?.patient_details?.gender
                        ? `${
                            calculateAge(selectedPatient?.patient_details?.dob)
                              .years
                          } Yrs, ${
                            calculateAge(selectedPatient?.patient_details?.dob)
                              .months
                          } Months, ${capitalizeFirstLetter(
                            selectedPatient?.patient_details?.gender
                          )}`
                        : ""}
                    </div>
                  </div>
                  <div className="department_name">
                    {selectedPatient?.patient_details?.procedure_name
                      ? capitalizeFirstLetter(
                          selectedPatient?.patient_details?.procedure_name
                        )
                      : ""}
                  </div>

                  {selectedPatient?.patient_details?.phone_number && (
                    <div className="phone_number_display">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="15"
                        viewBox="0 0 14 15"
                        fill="none"
                      >
                        <mask
                          id="mask0_2694_7723"
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="14"
                          height="15"
                        >
                          <rect y="0.5" width="14" height="14" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_2694_7723)">
                          <path
                            d="M11.6375 12.75C10.4222 12.75 9.22153 12.4851 8.03542 11.9552C6.84931 11.4253 5.77014 10.6743 4.79792 9.70208C3.82569 8.72986 3.07465 7.65069 2.54479 6.46458C2.01493 5.27847 1.75 4.07778 1.75 2.8625C1.75 2.6875 1.80833 2.54167 1.925 2.425C2.04167 2.30833 2.1875 2.25 2.3625 2.25H4.725C4.86111 2.25 4.98264 2.29618 5.08958 2.38854C5.19653 2.4809 5.25972 2.59028 5.27917 2.71667L5.65833 4.75833C5.67778 4.91389 5.67292 5.04514 5.64375 5.15208C5.61458 5.25903 5.56111 5.35139 5.48333 5.42917L4.06875 6.85833C4.26319 7.21806 4.4941 7.56562 4.76146 7.90104C5.02882 8.23646 5.32292 8.55972 5.64375 8.87083C5.94514 9.17222 6.26111 9.45174 6.59167 9.70937C6.92222 9.96701 7.27222 10.2028 7.64167 10.4167L9.0125 9.04583C9.1 8.95833 9.21424 8.89271 9.35521 8.84896C9.49618 8.80521 9.63472 8.79306 9.77083 8.8125L11.7833 9.22083C11.9194 9.25972 12.0312 9.33021 12.1187 9.43229C12.2062 9.53437 12.25 9.64861 12.25 9.775V12.1375C12.25 12.3125 12.1917 12.4583 12.075 12.575C11.9583 12.6917 11.8125 12.75 11.6375 12.75Z"
                            fill="#003878"
                          />
                        </g>
                      </svg>
                      {selectedPatient?.patient_details?.phone_number || ""}
                    </div>
                  )}
                </div>
              </div>
              <div className="appointmenttime_patienttype_display">
                {selectedPatient?.patient_details?.created_at && (
                  <>
                    <div className="discharge_date_container">
                      <div className="discharge_date">Discharge Date: </div>
                      <div className="display_appointmenttime">
                        {dateFormatter(
                          selectedPatient?.patient_details?.created_at
                        )}
                      </div>
                    </div>

                    <div className="prom_completed_ratio_container">
                      PROM Completed:{" "}
                      <span className="prom_completed_ratio_value">
                        {completedPROMVsTotalSent}
                      </span>
                    </div>
                  </>
                )}
                {selectedPatient?.patient_details?.processtype && (
                  <div className="display_patienttype">
                    {selectedPatient?.patient_details?.processtype === "New" ? (
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_9911_6898)">
                          <path
                            d="M12.9583 6.99982L11.6366 5.49398L11.8208 3.49794L9.86538 3.05648L8.84163 1.33398L6.99996 2.12482L5.15829 1.33398L4.13454 3.05648L2.17913 3.49794L2.36329 5.49398L1.04163 6.99982L2.36329 8.50565L2.17913 10.5017L4.13454 10.9432L5.15829 12.6657L6.99996 11.8748L8.84163 12.6657L9.86538 10.9432L11.8208 10.5017L11.6366 8.50565L12.9583 6.99982ZM7.54163 9.70815H6.45829V8.62482H7.54163V9.70815ZM7.54163 7.54148H6.45829V4.29148H7.54163V7.54148Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_9911_6898">
                            <rect
                              width="13"
                              height="13"
                              fill="#00B5AD"
                              transform="translate(0.5 0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    ) : selectedPatient?.patient_details?.processtype ===
                      "In-Progress" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="18"
                        viewBox="0 0 17 18"
                        fill="none"
                      >
                        <mask
                          id="mask0_285_2290"
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="17"
                          height="18"
                        >
                          <rect
                            x="0.25"
                            y="0.75"
                            width="16.5"
                            height="16.5"
                            fill="#D9D9D9"
                          />
                        </mask>
                        <g mask="url(#mask0_285_2290)">
                          <path
                            d="M8.5 15.875C7.54896 15.875 6.65521 15.6945 5.81875 15.3336C4.98229 14.9727 4.25469 14.4828 3.63594 13.8641C3.01719 13.2453 2.52734 12.5177 2.16641 11.6813C1.80547 10.8448 1.625 9.95104 1.625 9C1.625 8.04896 1.80547 7.15521 2.16641 6.31875C2.52734 5.48229 3.01719 4.75469 3.63594 4.13594C4.25469 3.51719 4.98229 3.02734 5.81875 2.66641C6.65521 2.30547 7.54896 2.125 8.5 2.125C9.45104 2.125 10.3448 2.30547 11.1813 2.66641C12.0177 3.02734 12.7453 3.51719 13.3641 4.13594C13.9828 4.75469 14.4727 5.48229 14.8336 6.31875C15.1945 7.15521 15.375 8.04896 15.375 9C15.375 9.95104 15.1945 10.8448 14.8336 11.6813C14.4727 12.5177 13.9828 13.2453 13.3641 13.8641C12.7453 14.4828 12.0177 14.9727 11.1813 15.3336C10.3448 15.6945 9.45104 15.875 8.5 15.875ZM8.5 14.5C9.23333 14.5 9.93802 14.3625 10.6141 14.0875C11.2901 13.8125 11.8859 13.4172 12.4016 12.9016L8.5 9V3.5C6.96458 3.5 5.66406 4.03281 4.59844 5.09844C3.53281 6.16406 3 7.46458 3 9C3 10.5354 3.53281 11.8359 4.59844 12.9016C5.66406 13.9672 6.96458 14.5 8.5 14.5Z"
                            fill="#FFF"
                          />
                        </g>
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="18"
                        viewBox="0 0 17 18"
                        fill="none"
                      >
                        <mask
                          id="mask0_285_2253"
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="17"
                          height="18"
                        >
                          <rect
                            x="0.25"
                            y="0.75"
                            width="16.5"
                            height="16.5"
                            fill="#D9D9D9"
                          />
                        </mask>
                        <g mask="url(#mask0_285_2253)">
                          <path
                            d="M6.4375 13.125H10.2188C10.4135 13.125 10.594 13.0763 10.7602 12.9789C10.9263 12.8815 11.0437 12.7469 11.1125 12.575L12.5563 9.20625C12.5792 9.14896 12.5964 9.09167 12.6078 9.03438C12.6193 8.97708 12.625 8.91979 12.625 8.8625V8.3125C12.625 8.11771 12.5591 7.95443 12.4273 7.82266C12.2956 7.69089 12.1323 7.625 11.9375 7.625H8.775L9.1875 5.2875C9.21042 5.17292 9.20469 5.06406 9.17031 4.96094C9.13594 4.85781 9.07865 4.76615 8.99844 4.68594L8.5 4.1875L5.3375 7.625C5.24583 7.71667 5.17708 7.81979 5.13125 7.93437C5.08542 8.04896 5.0625 8.175 5.0625 8.3125V11.75C5.0625 12.1281 5.19714 12.4518 5.46641 12.7211C5.73568 12.9904 6.05937 13.125 6.4375 13.125ZM8.5 15.875C7.54896 15.875 6.65521 15.6945 5.81875 15.3336C4.98229 14.9727 4.25469 14.4828 3.63594 13.8641C3.01719 13.2453 2.52734 12.5177 2.16641 11.6813C1.80547 10.8448 1.625 9.95104 1.625 9C1.625 8.04896 1.80547 7.15521 2.16641 6.31875C2.52734 5.48229 3.01719 4.75469 3.63594 4.13594C4.25469 3.51719 4.98229 3.02734 5.81875 2.66641C6.65521 2.30547 7.54896 2.125 8.5 2.125C9.45104 2.125 10.3448 2.30547 11.1813 2.66641C12.0177 3.02734 12.7453 3.51719 13.3641 4.13594C13.9828 4.75469 14.4727 5.48229 14.8336 6.31875C15.1945 7.15521 15.375 8.04896 15.375 9C15.375 9.95104 15.1945 10.8448 14.8336 11.6813C14.4727 12.5177 13.9828 13.2453 13.3641 13.8641C12.7453 14.4828 12.0177 14.9727 11.1813 15.3336C10.3448 15.6945 9.45104 15.875 8.5 15.875ZM8.5 14.5C10.0354 14.5 11.3359 13.9672 12.4016 12.9016C13.4672 11.8359 14 10.5354 14 9C14 7.46458 13.4672 6.16406 12.4016 5.09844C11.3359 4.03281 10.0354 3.5 8.5 3.5C6.96458 3.5 5.66406 4.03281 4.59844 5.09844C3.53281 6.16406 3 7.46458 3 9C3 10.5354 3.53281 11.8359 4.59844 12.9016C5.66406 13.9672 6.96458 14.5 8.5 14.5Z"
                            fill="#FFF"
                          />
                        </g>
                      </svg>
                    )}
                    {selectedPatient?.patient_details?.processtype}
                  </div>
                )}
              </div>
            </div>
            <div className="specialties_action_days_container">
              <div className="total_days">
                <h1>Post Discharge Days</h1>
                {/* <span>
                {resultDays
                  ? `${resultDays?.active_day ? `${resultDays?.active_day} /` : ""} ${resultDays?.max_day}`
                  : ""}
              </span> */}
                <span>
                  {resultDays
                    ? `${
                        selectedPatient?.patient_details?.created_at
                          ? resultDays?.max_day >=
                            `${calculatePromDays(
                              selectedPatient?.patient_details?.created_at
                            )}`
                            ? `${calculatePromDays(
                                selectedPatient?.patient_details?.created_at
                              )} /`
                            : `${resultDays?.max_day} /`
                          : ""
                      } ${resultDays?.max_day}`
                    : ""}
                </span>
              </div>
            </div>
          </div>

          <div>
            <div className="doctor_form_container">
              {selectedPatient?.patient_details?.form_name && (
                <div className="form_name_display">
                  {capitalizeFirstLetter(
                    selectedPatient?.patient_details?.form_name
                  )}
                </div>
              )}
              <div className="display_doctor_procedure">
                {selectedPatient?.patient_details?.procedure_name && (
                  <div>
                    {capitalizeFirstLetter(
                      selectedPatient?.patient_details?.procedure_name
                    )}
                  </div>
                )}
                {selectedPatient?.patient_details?.doctor_name && (
                  <div>
                    {capitalizeFirstLetter(
                      selectedPatient?.patient_details?.doctor_name
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="down_container">
        <div
          className={`${
            selectedPatient?.patient_details?.score_system
              ? "display_form_type"
              : ""
          }  `}
        >
          {selectedPatient?.patient_details?.score_system === "koos"
            ? `Koos Scoring System`
            : selectedPatient?.patient_details?.score_system === "eq"
            ? `EQ-5D-5L Scoring System`
            : ""}
        </div>

        <div className="frequency_value_container">
          {selectedPatient?.scoreData?.map((item: any, index: any) => {
            return (
              <div
                className={`${
                  activePatientData?.frequency_value === item?.frequency_value
                    ? "frequency_value"
                    : item?.response_status === "completed"
                    ? "frequency_value complete_value"
                    : "frequency_value inactive"
                } `}
                onClick={() => {
                  setActivePatientData(item);
                }}
              >
                Day {item?.frequency_value}
                <span className="frequency_date">
                  {dateFormatter(
                    getDateAfterDays(item?.created_at, item?.frequency_value)
                  )}
                </span>
                {activePatientData?.frequency_value ===
                  item?.frequency_value && (
                  <span className="selected_dot">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="18"
                      viewBox="0 0 17 18"
                      fill="none"
                    >
                      <mask
                        id="mask0_285_2290"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="17"
                        height="18"
                      >
                        <rect
                          x="0.25"
                          y="0.75"
                          width="16.5"
                          height="16.5"
                          fill="#D9D9D9"
                        />
                      </mask>
                      <g mask="url(#mask0_285_2290)">
                        <path
                          d="M8.5 15.875C7.54896 15.875 6.65521 15.6945 5.81875 15.3336C4.98229 14.9727 4.25469 14.4828 3.63594 13.8641C3.01719 13.2453 2.52734 12.5177 2.16641 11.6813C1.80547 10.8448 1.625 9.95104 1.625 9C1.625 8.04896 1.80547 7.15521 2.16641 6.31875C2.52734 5.48229 3.01719 4.75469 3.63594 4.13594C4.25469 3.51719 4.98229 3.02734 5.81875 2.66641C6.65521 2.30547 7.54896 2.125 8.5 2.125C9.45104 2.125 10.3448 2.30547 11.1813 2.66641C12.0177 3.02734 12.7453 3.51719 13.3641 4.13594C13.9828 4.75469 14.4727 5.48229 14.8336 6.31875C15.1945 7.15521 15.375 8.04896 15.375 9C15.375 9.95104 15.1945 10.8448 14.8336 11.6813C14.4727 12.5177 13.9828 13.2453 13.3641 13.8641C12.7453 14.4828 12.0177 14.9727 11.1813 15.3336C10.3448 15.6945 9.45104 15.875 8.5 15.875ZM7.1 12.25L12.3 7.05005L11.15 5.90005L7.1 9.95005L5.35 8.20005L4.2 9.35005L7.1 12.25Z"
                          fill="#003878"
                        />
                      </g>
                    </svg>
                  </span>
                )}
              </div>
            );
          })}
        </div>

        {activePatientData && (
          <div className="score_container">
            {activePatientData?.total_score && (
              <div className="total_score">
                Total Score : {activePatientData?.total_score}
              </div>
            )}
            {activePatientData?.calculated_score && (
              <div className="interval_score active_score">
                Interval Score : {activePatientData?.calculated_score}
              </div>
            )}
          </div>
        )}

        {activePatientData?.response?.type === "koos" && (
          <div className="template_form_list">
            <div className="question_container_list">
              {Object.entries(
                activePatientData?.response
                  ?.formdata as QuestionnaireDataKoovsInterface
              ).map(([sectionKey, section]) => {
                return (
                  <KoovsQuestionnaireData
                    section={section}
                    sectionKey={sectionKey}
                  />
                );
              })}
            </div>
          </div>
        )}

        {activePatientData?.response?.type === "eq" && (
          <div className="template_form_list">
            <div className="question_container_list">
              {Object.entries(
                activePatientData?.response
                  ?.formdata as QuestionnaireDataEq_5d_5lInteface
              ).map(([sectionKey, section]) => {
                return (
                  <Eq5d5QuestionnaireData
                    section={section}
                    sectionKey={sectionKey}
                  />
                );
              })}
            </div>
          </div>
        )}

        {activePatientData !== null && !activePatientData?.response?.type && (
          <div className="no_responsse_recive">
            No Response Recieved !
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="211"
              height="211"
              viewBox="0 0 211 211"
              fill="none"
            >
              <mask
                id="mask0_285_961"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="211"
                height="211"
              >
                <rect width="211" height="211" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_285_961)">
                <path
                  d="M70.3333 140.667H114.292V96.7082H70.3333V140.667ZM70.3333 79.1249H175.833V35.1666H70.3333V79.1249ZM131.875 140.667H175.833V96.7082H131.875V140.667ZM70.3333 158.25C65.4979 158.25 61.3585 156.528 57.9151 153.085C54.4717 149.641 52.75 145.502 52.75 140.667V35.1666C52.75 30.3312 54.4717 26.1918 57.9151 22.7484C61.3585 19.305 65.4979 17.5833 70.3333 17.5833H175.833C180.669 17.5833 184.808 19.305 188.252 22.7484C191.695 26.1918 193.417 30.3312 193.417 35.1666V140.667C193.417 145.502 191.695 149.641 188.252 153.085C184.808 156.528 180.669 158.25 175.833 158.25H70.3333ZM35.1666 193.417C30.3312 193.417 26.1918 191.695 22.7484 188.251C19.305 184.808 17.5833 180.669 17.5833 175.833V52.7499H35.1666V175.833H158.25V193.417H35.1666Z"
                  fill="#B8C8D7"
                />
              </g>
            </svg>
          </div>
        )}

        {activePatientData === null && (
          <div className="no_responsse_recive">
            No Response Received! !
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="211"
              height="211"
              viewBox="0 0 211 211"
              fill="none"
            >
              <mask
                id="mask0_285_961"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="211"
                height="211"
              >
                <rect width="211" height="211" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_285_961)">
                <path
                  d="M70.3333 140.667H114.292V96.7082H70.3333V140.667ZM70.3333 79.1249H175.833V35.1666H70.3333V79.1249ZM131.875 140.667H175.833V96.7082H131.875V140.667ZM70.3333 158.25C65.4979 158.25 61.3585 156.528 57.9151 153.085C54.4717 149.641 52.75 145.502 52.75 140.667V35.1666C52.75 30.3312 54.4717 26.1918 57.9151 22.7484C61.3585 19.305 65.4979 17.5833 70.3333 17.5833H175.833C180.669 17.5833 184.808 19.305 188.252 22.7484C191.695 26.1918 193.417 30.3312 193.417 35.1666V140.667C193.417 145.502 191.695 149.641 188.252 153.085C184.808 156.528 180.669 158.25 175.833 158.25H70.3333ZM35.1666 193.417C30.3312 193.417 26.1918 191.695 22.7484 188.251C19.305 184.808 17.5833 180.669 17.5833 175.833V52.7499H35.1666V175.833H158.25V193.417H35.1666Z"
                  fill="#B8C8D7"
                />
              </g>
            </svg>
          </div>
        )}
      </div>
    </div>
  );
}

export default PatientDisplay;
