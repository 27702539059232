import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  attemptOTPLogin,
  resetOTPSessionId,
} from "../../../redux/reducers/auth.slice";
import { RootState } from "../../../shared/constants";
import { ROUTE_LOGIN } from "../../../shared/routes/route.constants";
import AuthLayout from "../auth.layout";
import { ForgetPasswordForm } from "./forget-password.form";
import { ResetWithOTPForm } from "./reset-with-otp.form";

export const ForgetPasswordPage = () => {
  const [phone, setPhone] = useState("");

  const [loginByPin, setLoginByPin] = useState(false);
  const [countryCode, setCountryCode] = useState("+91" as string);

  const { otpSessionId, account } = useSelector(
    (state: RootState) => state.auth
  );
  const RouteParams: any = useParams();
  const [kind, setKind] = useState("org");
  const navigate = useNavigate();

  const dispatch = useDispatch();
  useEffect(() => {
    setKind(RouteParams["source"] === "patient" ? "patient" : "org");
    dispatch(resetOTPSessionId());
    if (RouteParams["number"] !== "") {
      dispatch(
        attemptOTPLogin({
          username: RouteParams["number"],
          account_id: account.id,
          country_code: "+91",
          kind: RouteParams["source"] === "patient" ? "patient" : "org",
        })
      );
    }
  }, []);
  return (
    <AuthLayout isotp={false} otpSessionId="">
      <>
        <div>
          {otpSessionId ? (
            <ResetWithOTPForm
              kind={kind}
              countryCode={countryCode}
              phone={phone}
              loginByPin={setLoginByPin}
            />
          ) : (
            <ForgetPasswordForm
              onUsernameChange={setPhone}
              kind={kind}
              phone={phone}
            />
          )}
        </div>
        <a
          href={"/"}
          className={"mt20"}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            navigate(
              ROUTE_LOGIN(RouteParams["account"], RouteParams["source"])
            );
          }}
        >
          Login ?
        </a>
      </>
    </AuthLayout>
  );
};
