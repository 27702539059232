import "../styles/questionnaire.scss";

function QuestionnaireDataKoovs(props: any) {
  const { sectionKey, section } = props;

  return (
    <div key={sectionKey} className="question-sub-container">
      <div className="question_title">{section?.title}</div>
      {section?.description && (
        <div className="question_description">{section?.description}</div>
      )}
      {section?.questions.map((question: any) => (
        <div key={question?.id} className="multiple_question_container">
          <div className="multiple_question_title">{question?.question}</div>
          {question?.options.map((option?: any) => (
            <label key={option?.value} className="multiple_question_label">
              <div className="radio_label_container">
                {option?.flag ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                  >
                    <mask
                      id="mask0_2260_2559"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="25"
                      height="25"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="24"
                        height="24"
                        fill="#D9D9D9"
                      />
                    </mask>
                    <g mask="url(#mask0_2260_2559)">
                      <path
                        d="M12.5 17.5C13.8833 17.5 15.0625 17.0125 16.0375 16.0375C17.0125 15.0625 17.5 13.8833 17.5 12.5C17.5 11.1167 17.0125 9.9375 16.0375 8.9625C15.0625 7.9875 13.8833 7.5 12.5 7.5C11.1167 7.5 9.9375 7.9875 8.9625 8.9625C7.9875 9.9375 7.5 11.1167 7.5 12.5C7.5 13.8833 7.9875 15.0625 8.9625 16.0375C9.9375 17.0125 11.1167 17.5 12.5 17.5ZM12.5 22.5C11.1167 22.5 9.81667 22.2375 8.6 21.7125C7.38333 21.1875 6.325 20.475 5.425 19.575C4.525 18.675 3.8125 17.6167 3.2875 16.4C2.7625 15.1833 2.5 13.8833 2.5 12.5C2.5 11.1167 2.7625 9.81667 3.2875 8.6C3.8125 7.38333 4.525 6.325 5.425 5.425C6.325 4.525 7.38333 3.8125 8.6 3.2875C9.81667 2.7625 11.1167 2.5 12.5 2.5C13.8833 2.5 15.1833 2.7625 16.4 3.2875C17.6167 3.8125 18.675 4.525 19.575 5.425C20.475 6.325 21.1875 7.38333 21.7125 8.6C22.2375 9.81667 22.5 11.1167 22.5 12.5C22.5 13.8833 22.2375 15.1833 21.7125 16.4C21.1875 17.6167 20.475 18.675 19.575 19.575C18.675 20.475 17.6167 21.1875 16.4 21.7125C15.1833 22.2375 13.8833 22.5 12.5 22.5ZM12.5 20.5C14.7333 20.5 16.625 19.725 18.175 18.175C19.725 16.625 20.5 14.7333 20.5 12.5C20.5 10.2667 19.725 8.375 18.175 6.825C16.625 5.275 14.7333 4.5 12.5 4.5C10.2667 4.5 8.375 5.275 6.825 6.825C5.275 8.375 4.5 10.2667 4.5 12.5C4.5 14.7333 5.275 16.625 6.825 18.175C8.375 19.725 10.2667 20.5 12.5 20.5Z"
                        fill="#00B5AD"
                      />
                    </g>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="25"
                    viewBox="0 0 23 25"
                    fill="none"
                  >
                    <mask
                      id="mask0_2260_2554"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="23"
                      height="25"
                    >
                      <rect y="0.5" width="23" height="24" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_2260_2554)">
                      <path
                        d="M11 23.5C9.61667 23.5 8.31667 23.2375 7.1 22.7125C5.88333 22.1875 4.825 21.475 3.925 20.575C3.025 19.675 2.3125 18.6167 1.7875 17.4C1.2625 16.1833 1 14.8833 1 13.5C1 12.1167 1.2625 10.8167 1.7875 9.6C2.3125 8.38333 3.025 7.325 3.925 6.425C4.825 5.525 5.88333 4.8125 7.1 4.2875C8.31667 3.7625 9.61667 3.5 11 3.5C12.3833 3.5 13.6833 3.7625 14.9 4.2875C16.1167 4.8125 17.175 5.525 18.075 6.425C18.975 7.325 19.6875 8.38333 20.2125 9.6C20.7375 10.8167 21 12.1167 21 13.5C21 14.8833 20.7375 16.1833 20.2125 17.4C19.6875 18.6167 18.975 19.675 18.075 20.575C17.175 21.475 16.1167 22.1875 14.9 22.7125C13.6833 23.2375 12.3833 23.5 11 23.5ZM11 21.5C13.2333 21.5 15.125 20.725 16.675 19.175C18.225 17.625 19 15.7333 19 13.5C19 11.2667 18.225 9.375 16.675 7.825C15.125 6.275 13.2333 5.5 11 5.5C8.76667 5.5 6.875 6.275 5.325 7.825C3.775 9.375 3 11.2667 3 13.5C3 15.7333 3.775 17.625 5.325 19.175C6.875 20.725 8.76667 21.5 11 21.5Z"
                        fill="#A7A4AE"
                      />
                    </g>
                  </svg>
                )}
                {option?.label}
              </div>
            </label>
          ))}
        </div>
      ))}
    </div>
  );
}

export default QuestionnaireDataKoovs;
