import { Col, PageHeader, Row } from "antd";
import { useNavigate } from "react-router-dom";

type Props = {
  title: string;
  children?: any;
  subtitle?: string;
};

function DesktopFormLayout({ title, children, subtitle }: Props) {
  const navigate = useNavigate();
  return (
    <div className={"desktop-form-layout"}>
      <div className="title">
        <Row>
          <Col>
            <PageHeader
              style={{ paddingLeft: "0px" }}
              className="primary-color-text"
              onBack={() => navigate(-1)}
              title={title}
              subTitle={subtitle}
            />
          </Col>
        </Row>
      </div>
      {children}
    </div>
  );
}

export default DesktopFormLayout;
