import { useEffect, useRef } from "react";

const useOutsideClick = (callback: () => void) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      // check if the clicked target is outside of the referenced element
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };

    document.addEventListener("click", handleClick);

    // clean up on unmount
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [callback]);

  // return the ref to be attached to the element you want to monitor
  return ref;
};

export { useOutsideClick };
