import React from "react";

class ValidationComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRequiredErr: false,
      isNumberErr: false,
      minLengthErr: false,
      maxLengthErr: false,
      minValueErr: false,
      maxValueErr: false,
      minSelectionsErr: false,
      maxSelectionsErr: false,
    };
  }

  checkForErrors(value) {
    let {
      isNumber,
      maxLength,
      minLength,
      required,
      maxValue,
      minValue,
      minSelections,
      maxSelections,
    } = this.props.validation;
    let {
      isRequiredErr,
      isNumberErr,
      minLengthErr,
      maxLengthErr,
      minValueErr,
      maxValueErr,
      minSelectionsErr,
      maxSelectionsErr,
    } = this.state;
    if (required) {
      isRequiredErr = this.isRequired(value);
    }
    if (isNumber) this.isNumberErr(value);
    if (maxLength || maxLength === 0) this.maxLengthErr(value);
    if (minLength || minLength === 0) this.minLengthErr(value);
    if (maxValue || maxValue === 0) this.maxValueErr(value);
    if (minValue || minValue === 0) this.minValueErr(value);
    if (minSelections || minSelections === 0) this.minSelectionsErr(value);
    if (maxSelections || maxSelections === 0) this.maxSelectionsErr(value);
    let errorStatus =
      isRequiredErr ||
      isNumberErr ||
      minLengthErr ||
      maxLengthErr ||
      minValueErr ||
      maxValueErr ||
      minSelectionsErr ||
      maxSelectionsErr;
    this.props.emitErrorStatus(errorStatus);
  }

  isRequired(value) {
    let { isRequiredErr } = this.state;
    isRequiredErr =
      value === "" ||
      value === undefined ||
      value === null ||
      (value && value.length === 0 ? true : false);
    if (this.state.isRequiredErr !== isRequiredErr) {
      let s = { isRequiredErr };
      if (isRequiredErr) {
        s = {
          ...s,
          isNumberErr: false,
          minLengthErr: false,
          maxLengthErr: false,
          minValueErr: false,
          maxValueErr: false,
          minSelectionsErr: false,
          maxSelectionsErr: false,
        };
      }
      this.setState(s);
    }
    return isRequiredErr;
  }

  isNumberErr(value) {
    let { isNumberErr } = this.state;
    if (
      value === "" ||
      value === undefined ||
      value === null ||
      (value && value.length === 0 ? true : false)
    ) {
      isNumberErr = false;
      if (this.state.isNumberErr !== isNumberErr)
        this.setState({ isNumberErr });
      return isNumberErr;
    }
    let re = /^[-+]?\d+(\.\d+)?$/;
    isNumberErr = !re.test(value);
    if (this.state.isNumberErr !== isNumberErr) this.setState({ isNumberErr });
    return isNumberErr;
  }

  minLengthErr(value) {
    let { minLengthErr } = this.state;
    minLengthErr = value && value.length < this.props.validation["minLength"];
    if (this.state.minLengthErr !== minLengthErr)
      this.setState({ minLengthErr });
    return minLengthErr;
  }

  maxLengthErr(value) {
    let { maxLengthErr } = this.state;
    maxLengthErr = value && value.length > this.props.validation["maxLength"];
    if (this.state.maxLengthErr !== maxLengthErr)
      this.setState({ maxLengthErr });
    return maxLengthErr;
  }

  minValueErr(value) {
    let { minValueErr } = this.state;
    minValueErr =
      parseFloat(value) < parseFloat(this.props.validation["minValue"]);
    if (this.state.minValueErr !== minValueErr) this.setState({ minValueErr });
    return minValueErr;
  }

  maxValueErr(value) {
    let { maxValueErr } = this.state;
    maxValueErr =
      parseFloat(value) > parseFloat(this.props.validation["maxValue"]);
    if (this.state.maxValueErr !== maxValueErr) this.setState({ maxValueErr });
    return maxValueErr;
  }

  minSelectionsErr(value) {
    let { minSelectionsErr } = this.state;
    minSelectionsErr =
      value && value.length < this.props.validation["minSelections"];
    if (this.state.minSelectionsErr !== minSelectionsErr)
      this.setState({ minSelectionsErr });
    return minSelectionsErr;
  }

  maxSelectionsErr(value) {
    let { maxSelectionsErr } = this.state;
    maxSelectionsErr =
      value && value.length > this.props.validation["maxSelections"];
    if (this.state.maxSelectionsErr !== maxSelectionsErr)
      this.setState({ maxSelectionsErr });
    return maxSelectionsErr;
  }

  render() {
    this.checkForErrors(this.props.value);
    let {
      maxLength,
      minLength,
      maxValue,
      minValue,
      minSelections,
      maxSelections,
    } = this.props.validation;
    let {
      isRequiredErr,
      isNumberErr,
      minLengthErr,
      maxLengthErr,
      minValueErr,
      maxValueErr,
      minSelectionsErr,
      maxSelectionsErr,
    } = this.state;
    return this.props.isDirty || this.props.checkForm ? (
      <>
        {isRequiredErr && (
          <small
            style={{ display: "block" }}
            className="text-danger error-text"
          >
            Required Field
          </small>
        )}
        {isNumberErr && (
          <small
            style={{ display: "block" }}
            className="text-danger error-text"
          >
            Only Numbers Allowed
          </small>
        )}
        {minLengthErr && (
          <small
            style={{ display: "block" }}
            className="text-danger error-text"
          >
            At least {minLength} letters
          </small>
        )}
        {maxLengthErr && (
          <small
            style={{ display: "block" }}
            className="text-danger error-text"
          >
            Maximum {maxLength} letters
          </small>
        )}
        {minValueErr && (
          <small
            style={{ display: "block" }}
            className="text-danger error-text"
          >
            Should be greater than {minValue}
          </small>
        )}
        {maxValueErr && (
          <small
            style={{ display: "block" }}
            className="text-danger error-text"
          >
            Should be lesser than {maxValue}
          </small>
        )}
        {minSelectionsErr && (
          <small
            style={{ display: "block" }}
            className="text-danger error-text"
          >
            At least {minSelections} choices
          </small>
        )}
        {maxSelectionsErr && (
          <small
            style={{ display: "block" }}
            className="text-danger error-text"
          >
            Maximum {maxSelections} choices
          </small>
        )}
      </>
    ) : (
      <></>
    );
  }
}

export default ValidationComponent;
