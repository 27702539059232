export const ROUTE_DOCTOR_APPOINTMENTS = (type: string) =>
  `/doctor/appointment/${type}`;
export const ROUTE_DOCTOR_DASHBOARD = "/doctor/dashboard";
export const ROUTE_DOCTOR_APPOINTMENTS_CALL = (
  room_id: string,
  source: string,
  appointment: string
) => `/patient/appointment/room/${room_id}/${source}/${appointment}`;
export const ROUTE_DOCTOR_APPOINTMENTS_CALL_ATTANDANT = (
  room_id: string,
  source: string,
  appointment: string,
  participant_id: any
) =>
  `/patient/appointment/room/${room_id}/${source}/${appointment}/${participant_id}`;
export const ROUTE_DOCTOR_PROFILE = "/doctor/setting/profile";
export const ROUTE_DOCTOR_NOTIFCATION = "/doctor/setting/notification";
export const ROUTE_DOCTOR_PERMISSIONS = "/doctor/setting/permissions";
export const ROUTE_DOCTOR_REPORT_ISSUE = "/doctor/setting/report";
export const ROUTE_DOCTOR_RESET_PIN = "/doctor/setting/reset";
export const ROUTE_DOCTOR_PRESCRIPTION_SETTING = "/doctor/setting/prescription";
export const ROUTE_DOCTOR_ANALYTICS = "/doctor/analytics";
export const ROUTE_DOCTOR_PROM = "/doctor/prom";
export const ROUTE_DOCTOR_PATIENTS = "/doctor/patients";
export const ROUTE_DOCTOR_CHATS = "/doctor/chats";
export const ROUTE_NOTIFICATIONS = (type: any) => `/${type}/notifications`;
export const ROUTE_DOCTOR_SLOT_CREATE = (id: string) => `/admin/${id}/slot`;
export const ROUTE_SLOT_ACTION = (id: string, mode: string) =>
  `/admin/slot/${id}/${mode}`;
export const ROUTE_DOCTOR_SLOTS = (id: string) => `/admin/${id}/slots`;
export const ROUTE_DOCTOR_CORDINATOR = `/admin/cordinator`;

//// APIS
// export const DOCTORS_QUERY = (type: string, query: string) => `doctor?search_term=${query}&search_column=${type}`
export const DOCTORS_QUERY = `doctor`;
export const DOCTORS = `doctor`;
export const DOCTOR_UPDATE = `doctor/update`;
export const ASSIGN_DOCTOR_BY_USER_ID = (id: string) =>
  `users/${id}/assign-doctor`;
export const ASSIGN_DOCTOR_SLOT_CONFIG = `slots/create-doctor-slot-config`;
export const UPDATE_DOCTOR_SLOT_CONFIG = (id: string) =>
  `slots/doctor-slot-config/${id}`;
export const ASSIGN_DOCTOR_SLOT_PROCEDURE = `slots/create-doctor-procedure-config`;
export const UPDATE_DOCTOR_SLOT_PROCEDURE = (id: string) =>
  `slots/doctor-slot-procedure/${id}`;
export const DOCTOR_BY_ID = (id: string) => `doctor/${id}`;
export const DOCTOR_SLOTS_BY_ID = (id: string) =>
  `slots/doctor-slot-config?doctor_id=${id}`;
export const DOCTOR_AUTOCOMPLETE = (doctor: string) =>
  `doctor/get-search-autocomplete?search_term=${doctor}`;
export const DOCTOR_AVAIL_SLOTS_BY_ID = `slots/avail-slots`;
export const DOCTOR_COORDINATOR = `doctor/coordinator`;
export const DOCTOR_COORDINATOR_BY_ID = (id: string) =>
  `doctor/coordinator/${id}`;
export const DOCTOR_PROPERTIES = (id: string) => `doctor/get-props/${id}`;
export const DOCTOR_SPECIALIZATION = (id: string) =>
  `doctor/get-speciality-mapping/${id}`;
export const ADD_NEW_SPECIALIZATION = `doctor/add-speciality-mapping`;
export const DELETE_SPECIALIZATION = (id: string) =>
  `doctor/remove-speciality-mapping/${id}`;
export const DOCTOR_PROPS_BY_ID = (id: string) => `doctor/get-props/${id}`;
export const DOCTOR_MODIFY_PROPS = (id: string) =>
  `sync-seed-data/create-or-update/doctor-props?doctor_id=${id}`;
export const DOCTOR_SLOT_CONFIG = (id: string) =>
  `slots/doctor-slot-config/${id}`;
export const DOCTOR_PROCEDURE_CONFIG = `slots/doctor-procedure-config`;
export const DOCTOR_PROCEDURE_LIST = `doctor/procedures-by-doctor`;
export const DELETE_DOCTOR_PROCEDURE = (id: string) =>
  `slots/doctor-procedure-config/${id}`;
export const UPDATE_DOCTOR_PROCEDURE_CONFIG = `slots/create-doctor-procedure-config`;

export const PROM_GET_ALL_DOCTOR = `doctor/get-all-doctors`;
export const PROM_GET_ALL_PROCEDURES = "procedure/get-all-procedures";
export const PROM_GET_ALL_FORM_LIST = "forms/get-all-forms";
export const PROM_POST_FORM = "forms/submit-form";
export const PROM_GET_ALL_PATIENT = "patient/get-all-patients";
export const PROM_GET_PATIENT_BY_IDS = "patient/get-patient";

export const PROM_MATRICS = "dashboard/metrics";
export const PROM_MATRICS_HOSPITAL = "dashboard/get-all-hospitals";
export const PROM_MATRICS_DOCTOR = "dashboard/get-all-doctors";
export const PROM_MATRICS_PATIENT = "dashboard/get-all-patients";
