import * as React from "react";
import { Form, Input, Button, Select } from "antd";
import { useTranslation } from "react-i18next";

interface Props {
  onRegister: (a: any) => void;
  loading: boolean;
}

export default function SignupForm({ onRegister, loading }: Props) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  function handleSubmit(values: any) {
    if (onRegister) {
      const { username, password, email, name, phone_number } = values;
      onRegister({ username, password, email, name, phone_number });
    }
  }

  return (
    <Form
      size="large"
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        username: "",
        password: "",
      }}
      requiredMark={false}
    >
      <Form.Item
        label={"Name"}
        name="name"
        rules={[{ required: true, message: "Name required" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={"Username"}
        name="username"
        rules={[{ required: true, message: "Username required" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={"Phone with country code"}
        name="phone_number"
        rules={[{ required: true, message: "Phone Number required" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={"Email"}
        name="email"
        rules={[{ required: true, type: "email", message: "Email required" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={"Password"}
        name="password"
        rules={[{ required: true, message: "Password required" }]}
      >
        <Input
          onChange={() => {
            form.validateFields(["password_confirmation"]);
          }}
          type="password"
        />
      </Form.Item>
      <Form.Item
        label={"Confirm Password"}
        name="password_confirmation"
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value) {
                return Promise.reject("Confirm Password Required");
              }
              if (value && getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject("Password not matched");
            },
          }),
        ]}
      >
        <Input type="password" />
      </Form.Item>
      <br />
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          shape="round"
          size={"large"}
          className="submit"
        >
          Register
        </Button>
      </Form.Item>
    </Form>
  );
}
