import { Button } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchAccountByDomain,
  fetchApplications,
  resetOTPSessionId,
  setUserImpersonation,
} from "../../../redux/reducers/auth.slice";
import SignInSignOutButton from "../../../shared/components/MSComponents/SignInSignOutButton";
import { RootState } from "../../../shared/constants";
import { useQuery } from "../../../shared/Utils/utilities";
import AuthLayout from "../auth.layout";
import { CognitoPage } from "./cognito-login";
import { OTPForm } from "./otp.form";
import { PinForm } from "./pin.form";
import { UsernameForm } from "./username.form";

export const LoginPage = () => {
  const [username, setUsername] = useState(null as any);
  const [loginByPin, setLoginByPin] = useState(false as any);
  const [countryCode] = useState("+91" as string);
  const { otpSessionId, account, applications } = useSelector(
    (state: RootState) => state.auth
  );
  const [isotp, setOtp] = useState(false as any);
  const [loginType, setLoginType] = useState("local");
  const [loginsEnabled, setLoginsEnabled] = useState(null as any);
  const RouteParams: any = useParams();
  const [kind, setKind] = useState("org");
  //const navigate = useNavigate();
  const dispatch = useDispatch();
  const query = useQuery();

  useEffect(() => {
    if (!account) {
      localStorage.clear();
      dispatch(fetchAccountByDomain());
    }
  }, []);
  useEffect(() => {
    if (account) {
      dispatch(fetchApplications());
      localStorage.setItem("account_id", account?.id);
    }
  }, [account]);

  useEffect(() => {
    if (applications?.length > 0) {
      const selectedApplication = applications?.filter((val: any) => {
        return val.slug == RouteParams["source"];
      });
      let loginsEnabled = ["otp", "pin", "ad", "cognito"];
      if (selectedApplication?.length == 1)
        if (selectedApplication[0]?.allowed_logins?.length > 0)
          loginsEnabled = selectedApplication[0]?.allowed_logins;
      setLoginsEnabled(loginsEnabled);
    }
  }, [applications]);

  useEffect(() => {
    if (loginsEnabled?.length == 1) {
      if (loginsEnabled[0] == "cognito") {
        setLoginType("cognito");
      }
    }
  }, [loginsEnabled]);

  useEffect(() => {
    setKind(RouteParams["source"] === "patient" ? "patient" : "org");
    if (query.get("sessionId")) {
      setUsername(query.get("number"));
    } else {
      dispatch(resetOTPSessionId());
    }
    dispatch(setUserImpersonation(null));
  }, []);

  useEffect(() => {
    setOtp(true);
  }, [otpSessionId]);

  return (
    <>
      {loginsEnabled && (
        <AuthLayout otpSessionId={otpSessionId} isotp={isotp}>
          {loginType === "local" && (
            <>
              {(loginsEnabled.indexOf("pin") !== -1 ||
                loginsEnabled.indexOf("otp") !== -1) && (
                <>
                  {otpSessionId && isotp ? (
                    <OTPForm
                      kind={kind}
                      countryCode={countryCode}
                      username={username}
                      loginByPin={setLoginByPin}
                      setOtp={(value: any) => {
                        setOtp(value);
                        dispatch(resetOTPSessionId());
                        setUsername(null);
                      }}
                    />
                  ) : loginByPin ? (
                    <PinForm
                      kind={kind}
                      countryCode={countryCode}
                      username={username}
                      setOtp={(value: any) => {
                        setLoginByPin(value);
                        dispatch(resetOTPSessionId());
                        setUsername(null);
                      }}
                      loginByPin={setLoginByPin}
                      loginsEnabled={loginsEnabled}
                    />
                  ) : (
                    <UsernameForm
                      kind={kind}
                      username={username}
                      onUsernameChange={setUsername}
                      setLoginByPin={setLoginByPin}
                      loginsEnabled={loginsEnabled}
                    />
                  )}
                </>
              )}

              {loginsEnabled.indexOf("ad") !== -1 ? (
                <SignInSignOutButton />
              ) : (
                ""
              )}
              {loginsEnabled.indexOf("cognito") !== -1 && (
                <Button
                  className="submit mt20"
                  onClick={() => setLoginType("cognito")}
                  size={"large"}
                  block
                >
                  Sign In via Cognito
                </Button>
              )}
            </>
          )}
          {loginType === "cognito" && (
            <>
              <CognitoPage />
            </>
          )}

          {/* {!otpSessionId && (TNC_URL !== "" || PRIVACY_POLICY_URL !== "") && (
            <div className="text-center mt20">
              By logging in, you agree to our{" "}
              {TNC_URL !== "" && (
                <a
                  className="ant-typography doctor-secondary-color cursor"
                  href={TNC_URL}
                >
                  <u>Terms and Conditions</u>
                </a>
              )}
              {(TNC_URL !== "" || PRIVACY_POLICY_URL !== "") && " and "}
              {PRIVACY_POLICY_URL !== "" && (
                <a
                  className="ant-typography doctor-secondary-color cursor"
                  href={PRIVACY_POLICY_URL}
                >
                  <u>Privacy Policy</u>
                </a>
              )}
            </div>
          )} */}
        </AuthLayout>
      )}
    </>
  );
};
