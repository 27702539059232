import {
  Button,
  Col,
  DatePicker,
  Input,
  message,
  Row,
  Select,
  Space,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as BackIcon } from "../../../../assets/icons/backicon.svg";
import { fetchBranches } from "../../../../redux/reducers/branch.slice";
import { fetchDepartments } from "../../../../redux/reducers/department.slice";
import {
  fetchDoctorAvailSlots,
  fetchDoctorProcedures,
  resetDoctorAvailSlots,
} from "../../../../redux/reducers/doctor.slice";
import {
  addNewPatient,
  fetchPatientsByNumber,
  resetSearchedPatientList,
} from "../../../../redux/reducers/patients.slice";
import { RootState } from "../../../../shared/constants";
import { Branch } from "../../../../shared/types/branch.type";
import { disabledNonBookingDates } from "../../../../shared/Utils/utilities";
import PatientForm from "../../../admin/Patient/components/patients.form";

const { Option } = Select;
const { Search } = Input;

type Props = {
  createAppointment?: (values: any) => Promise<void>;
  showBack?: any;
  onBack?: (values: any) => void;
  loading?: any;
};

const DoctorAddAppointment: React.FunctionComponent<Props> = ({
  createAppointment,
  showBack,
  onBack,
  loading,
}) => {
  const dispatch = useDispatch();
  const { branches } = useSelector((state: RootState) => state.branch);
  const { Patients, loading: loader } = useSelector(
    (state: RootState) => state.patient
  );
  // const { procedures } = useSelector((state: RootState) => state.master);
  const { profile } = useSelector((state: RootState) => state.profile);
  const { doctor_avail_slots } = useSelector(
    (state: RootState) => state.doctor
  );
  const [branch, setBranch] = useState(null as any);
  const [procedures, setProcedures] = useState([] as any);
  const [procedure, setProcedure] = useState(null as any);
  const [patient, setPatient] = useState(null as any);
  const [addpatient, setAddPatient] = useState(false);
  const [number, setSearchedNumber] = useState(null as any);
  const [slot, setSlot] = useState(null as any);
  const [slotDate, setSlotDate] = useState(moment() as any);
  const [show, setShowCount] = useState(false);
  const [isCreatingNewAppointment, setIsCreatingNewAppointment] =
    useState(false);

  useEffect(() => {
    const onScroll: EventListener = (event: Event) => {
      // <-- DOM-EventListener
      event.preventDefault();
      return false;
    };

    const win: Window = window; // <-- DOM-Window, extends DOM-EventTarget
    win.addEventListener("wheel", onScroll);

    return () => window.removeEventListener("wheel", onScroll);
  }, []);

  const getProcedures = useCallback(
    async (branch: string) => {
      const res = await dispatch(
        fetchDoctorProcedures({
          doctor_id: profile.id,
          branch_id: branch,
          service_type_id: profile.service_type_id,
        })
      );

      if (res?.payload?.data?.data?.doctors?.length > 0) {
        let proc = [] as any;
        if (res?.payload?.data?.data?.doctors[0]?.global_procedures) {
          proc = [...res?.payload?.data?.data?.doctors[0]?.global_procedures];
        }
        if (res?.payload?.data?.data?.doctors[0]?.branches[0]?.procedures) {
          proc = [
            ...proc,
            ...res?.payload?.data?.data?.doctors[0]?.branches[0]?.procedures,
          ];
        }
        setProcedures(proc);
      }
    },
    [dispatch, profile.id, profile.service_type_id]
  );

  const procedureFilter = useCallback(
    (data: any) => {
      return data.procedure_id === procedure;
    },
    [procedure]
  );

  const addAppointment = useCallback(async () => {
    setIsCreatingNewAppointment(true);

    if (patient === null) {
      message.error("Choose Patient");
      setIsCreatingNewAppointment(false);
      return;
    }

    let external_procedure_id;
    let slot_date;
    doctor_avail_slots.length !== 0 &&
      doctor_avail_slots.availableSlots.map((item: any) => {
        slot_date = item.appointment_date;
        return item.procedures.filter(procedureFilter).forEach((_item: any) => {
          external_procedure_id = _item.external_procedure_id;
        });
      });

    let data = {
      doctor_id: profile.id,
      branch_id: branch,
      patient_id: patient,
      source: "doctor_web",
      procedure_id: procedure,
      slot_id: JSON.parse(slot)?.slot_id,
      slot_time: JSON.parse(slot)?.s_time,
      external_procedure_id: external_procedure_id,
      slot_date: slot_date,
    };

    if (data.slot_id === undefined) {
      message.error("Choose Slot");
      setIsCreatingNewAppointment(false);
      return;
    }

    if (createAppointment) {
      await createAppointment?.(data).then(() => {
        setIsCreatingNewAppointment(false);
      });
    }
  }, [
    createAppointment,
    branch,
    doctor_avail_slots.availableSlots,
    doctor_avail_slots.length,
    patient,
    procedure,
    procedureFilter,
    profile.id,
    slot,
  ]);

  const handleAddAppointment = useCallback(async () => {
    addAppointment();
  }, [addAppointment]);

  const getSlots = useCallback(
    (branch: any, procedure: any, slot_date: any) => {
      setSlotDate(slot_date);
      setSlot(null);
      setBranch(branch);
      setProcedure(procedure);
      dispatch(
        fetchDoctorAvailSlots({
          doctor_id: profile.id,
          branch_id: branch,
          procedure_id: procedure,
          slot_date: moment(slot_date).format("YYYY-MM-DD"),
        })
      );
    },
    [dispatch, profile.id]
  );

  useEffect(() => {
    if (branches.length !== 0) {
      setBranch(branches[0]?.id);
    }
  }, [branches]);

  useEffect(() => {
    if (procedures.length !== 0) {
      setProcedure(procedures[0]?.id);
    }
  }, [branches, procedures]);

  useEffect(() => {
    if (branch) {
      getProcedures(branch);
    }
  }, [branch, getProcedures]);

  useEffect(() => {
    if (branch && procedure) {
      getSlots(branch, procedure, slotDate);
    }
  }, [branch, getSlots, procedure, slotDate]);

  const getPatientDetails = async (patient: any) => {
    setShowCount(false);
    setAddPatient(false);
    if (patient.length > 10) {
      message.error("Enter Valid Mobile Number");
      return;
    }
    if (patient.length < 9) {
      return;
    }
    if (patient.length === 10) {
      setSearchedNumber(patient);
      const response = await dispatch(fetchPatientsByNumber(patient));
      if (
        response?.payload?.status === 201 ||
        response?.payload?.status === 200
      ) {
        setShowCount(true);

        if (response?.payload?.data?.data.length !== 0) {
          setPatient(
            response?.payload?.data?.data.find(
              (a: any) => a.is_primary === true
            )?.id ?? response?.payload?.data?.data[0]?.id
          );
          setAddPatient(false);
        }

        if (response?.payload?.data?.data.length === 0) {
          setPatient(0);
          setAddPatient(true);
        }
      }
    }
  };

  useEffect(() => {
    dispatch(fetchDepartments());
    dispatch(fetchBranches());
    // dispatch(fetchMasterProcedures());
    return () => {
      dispatch(resetDoctorAvailSlots());
      dispatch(resetSearchedPatientList());
    };
  }, [dispatch]);

  const _addNewPatient = (item: any) => {
    if (item === 0) {
      setAddPatient(!addpatient);
      setPatient(item);
      return;
    }
    setAddPatient(false);
    setPatient(item);
  };

  const onSubmit = (values: any) => {
    values.addresses.city_id = values?.addresses?.city_id[1];
    values.addresses = [values.addresses];
    dispatch(addNewPatient(values));
    setTimeout(() => {
      setAddPatient(false);
      if (number) {
        dispatch(fetchPatientsByNumber(number));
      }
    }, 1000);
  };

  const intialNumber = {
    mobile: number,
    nationality_id: "",
  };

  return (
    <>
      <Col span={24} className="white-background h76">
        <p className="doctor-primary-color bold" style={{ padding: "11.5px" }}>
          {" "}
          <Space align={"center"} size={"small"}>
            {" "}
            {showBack && (
              // <LeftOutlined style={{ fontSize: "20px" }} onClick={() => onBack && onBack(false)} className="cursor" />
              <BackIcon
                style={{ fontSize: "20px" }}
                onClick={() => onBack && onBack(false)}
                className="cursor"
              />
            )}{" "}
            Add Appointment{" "}
          </Space>
        </p>
      </Col>
      <Col span={24} className="padding20">
        <Row>
          <Col
            span={24}
            className="doctor-box-shadow doctor-top-border addAppointment"
          >
            <Row className="patientForm addApp">
              <Col span={24} className="doctor-color-heading2">
                <Row>
                  <Col span={24} className={""}>
                    <span className="doctor-primary-color font15 bold">
                      Appointment Details{" "}
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col span={12} className="white-background padding20">
                <label className={"doctor-primary-color bold"}>Hospital</label>{" "}
                <br />
                <Select
                  className={
                    "doctor-secondary-border full-width border-radius5 capitalize mt5"
                  }
                  // placeholder={"Choose Branch Name"}
                  defaultValue={"Choose Branch Name"}
                  size="large"
                  value={branch}
                  onChange={(evt: any) => getSlots(evt, procedure, slotDate)}
                >
                  {branches &&
                    branches.map((branch: Branch) => {
                      return (
                        <Option value={branch.id} className={"capitalize"}>
                          {" "}
                          {branch.name}
                        </Option>
                      );
                    })}
                </Select>
                <br />
                <br />
                <label className={"mt30 doctor-primary-color bold"}>
                  Appointment Type
                </label>{" "}
                <br />
                <Select
                  defaultValue={"Choose Appointment Type"}
                  className={
                    "doctor-secondary-border full-width border-radius5 capitalize mt5"
                  }
                  value={procedure}
                  size="large"
                  onChange={(evt: any) => getSlots(branch, evt, slotDate)}
                >
                  {procedures &&
                    procedures
                      .filter((a: any) => a.name !== "instant")
                      .map((item: any) => {
                        return (
                          <Option value={item.id} className={"capitalize"}>
                            {item.name === "consultation"
                              ? "In-Hospital"
                              : item.name}
                          </Option>
                        );
                      })}
                </Select>
              </Col>
              <Col span={12} className="white-background padding20">
                <label className={"doctor-primary-color bold"}>
                  Appointment Date
                </label>{" "}
                <br />
                <DatePicker
                  style={{ color: "#23BCB5 !important" }}
                  value={slotDate}
                  disabled={procedure === null ? true : false}
                  className={
                    "full-width doctor-secondary-border border-radius5 mb8 mt5"
                  }
                  size="large"
                  disabledDate={disabledNonBookingDates}
                  onChange={(evt: any) => getSlots(branch, procedure, evt)}
                ></DatePicker>
                <br />
                <br />
                <label className="mt30 doctor-primary-color bold">
                  Appointment Time{" "}
                </label>{" "}
                <br />
                <Select
                  className={
                    "doctor-secondary-border full-width border-radius5 capitalize mt5"
                  }
                  size="large"
                  disabled={
                    doctor_avail_slots && doctor_avail_slots.length === 0
                      ? true
                      : false
                  }
                  onChange={(evt) => setSlot(evt)}
                  placeholder={"Choose Time Slot"}
                  value={slot}
                >
                  {doctor_avail_slots.length !== 0 &&
                    doctor_avail_slots.availableSlots.map((item: any) => {
                      return item.procedures
                        .filter(procedureFilter)
                        .map((_item: any) => {
                          return _item.branches.map((__item: any) => {
                            return __item.slots.map((___item: any) => {
                              return (
                                <Option
                                  value={JSON.stringify(___item)}
                                  className={"capitalize"}
                                >
                                  {___item.s_time}{" "}
                                  {___item.is_reserved_slot && <>(R)</>}{" "}
                                  {___item.is_payment_mandatory && <>(Pr)</>}{" "}
                                </Option>
                              );
                            });
                          });
                        });
                    })}
                </Select>
              </Col>
            </Row>
          </Col>

          <Col span={24} className="doctor-box-shadow doctor-top-border mt20">
            <Row className="addApp">
              <Col span={24} className="doctor-color-heading2">
                <Row>
                  <Col span={24} className={""}>
                    <span className="doctor-primary-color font15 bold">
                      Patient Details{" "}
                    </span>
                  </Col>
                </Row>
              </Col>

              <Col span={12} className="white-background padding20">
                <label className="mt20 doctor-primary-color bold">
                  Phone Number{" "}
                </label>{" "}
                <br />
                <Search
                  loading={loader}
                  onWheel={(e) => e.currentTarget.blur()}
                  maxLength={10}
                  style={{ width: "100%" }}
                  allowClear
                  type={"number"}
                  size={"large"}
                  placeholder="Enter Patient Mobile Number"
                  className="doctor-search border-radius5 mt5"
                  onSearch={(evt: any) => getPatientDetails(evt)}
                  onChange={(evt: any) => getPatientDetails(evt.target.value)}
                ></Search>
                {show && (
                  <p className="doctor-secondary-color">
                    {Patients.length} patient found for this number
                  </p>
                )}
              </Col>
              <Col span={12} className="white-background padding20 patientForm">
                <label className="mt20 doctor-primary-color bold">
                  Choose Patient{" "}
                </label>{" "}
                <br />
                {
                  <Select
                    onChange={(evt) => _addNewPatient(evt)}
                    value={patient}
                    size={"large"}
                    placeholder={"Add New Patient"}
                    className={
                      "full-width doctor-secondary-border border-radius5 mt5"
                    }
                  >
                    {Patients &&
                      Patients.map((patient: any) => {
                        return (
                          <Option value={patient.id}>{patient.name}</Option>
                        );
                      })}
                    {number && <Option value={0}>Add New Patient</Option>}
                  </Select>
                }
              </Col>
              {addpatient && (
                <Col span={24} className="white-background padding20">
                  <Row>
                    <PatientForm
                      patient={intialNumber}
                      mode={"edit"}
                      showCancel={false}
                      onCancel={(value: any) => setAddPatient(value)}
                      onSubmit={(values) => onSubmit(values)}
                    />
                  </Row>
                </Col>
              )}
            </Row>
          </Col>

          <Col span={24} className={"mt20"}>
            <Button
              block
              disabled={isCreatingNewAppointment}
              className="doctor-secondary-button"
              loading={isCreatingNewAppointment || loading}
              size="large"
              onClick={handleAddAppointment}
            >
              {isCreatingNewAppointment ? "Submitting" : "Submit"}
            </Button>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default DoctorAddAppointment;
