import React from "react";

import { SaveOutlined } from "@ant-design/icons";
import { Button, Input, Select } from "antd";
import { DefaultProps } from "../../Constants/DefaultProps";
import RequiredFields from "../../Constants/RequiredFields";
import { BasicComponents } from "../../Constants/Tools";
import Util from "../../Utils/Util";
import ID from "../../Utils/UUID";
import ToolProps from "./ToolProps";
const { Option } = Select;
export default class ComponentSelectorModal extends React.Component {
  constructor(props) {
    super(props);
    let { elemProps, element, uiDataDict } = this.props;
    let options = [],
      variables = [];
    if (element === "column") {
      if (
        elemProps &&
        elemProps.hasOwnProperty("type") &&
        !elemProps.validation
      ) {
        //enabling validation for older forms without validation
        let validation = DefaultProps.find(
          (obj) => obj.type === elemProps.type
        ).validation;
        if (validation) {
          elemProps = { ...elemProps, validation };
        }
      }
      options = elemProps["uiData"]
        ? uiDataDict[elemProps["uiData"]]
          ? uiDataDict[elemProps["uiData"]].options
          : []
        : [];
      variables = elemProps["localvar"] ? elemProps["localvar"].split(",") : [];
    }
    this.state = {
      elemProps: elemProps,
      element: element,
      options: options,
      variables: variables,
      tool: elemProps.type,
    };
  }

  componentDidUpdate(prevProps) {
    let { elemProps, element, options, variables, tool } = this.state;
    if (prevProps !== this.props) {
      if (prevProps.element !== this.props.element) {
        element = this.props.element;
      }
      if (
        this.props.elemProps &&
        prevProps.elemProps &&
        prevProps.elemProps["type"] !== this.props.elemProps["type"]
      ) {
        tool = this.props.elemProps["type"];
      }
      if (prevProps.elemProps !== this.props.elemProps) {
        elemProps = this.props.elemProps;
        if (
          element === "column" &&
          prevProps.elemProps["uiData"] !== this.props.elemProps["uiData"]
        ) {
          options = elemProps["uiData"]
            ? this.props.uiDataDict[elemProps["uiData"]]
              ? this.props.uiDataDict[elemProps["uiData"]].options
              : []
            : [];
        }
        if (
          element === "column" &&
          prevProps.elemProps["localvar"] !== this.props.elemProps["localvar"]
        ) {
          variables = elemProps["localvar"]
            ? elemProps["localvar"].split(",")
            : [];
        }
      }
      if (
        element === "column" &&
        elemProps["uiData"] &&
        prevProps.uiDataDict[elemProps["uiData"]] &&
        this.props.uiDataDict[elemProps["uiData"]] &&
        prevProps.uiDataDict[elemProps["uiData"]].options !==
          this.props.uiDataDict[elemProps["uiData"]].options
      ) {
        options = this.props.uiDataDict[elemProps["uiData"]].options;
      }
      this.setState({ elemProps, element, options, variables, tool });
    }
  }

  onToolSelection = (e) => {
    let { tool, elemProps, variables, options } = this.state;
    tool = e;
    let properties = DefaultProps.find((obj) => obj.type === tool);
    if (elemProps.header) {
      elemProps = {
        ...properties,
        header: elemProps.header,
        label: elemProps.label,
        offset: this.props.sectionOffset ? "w-100" : "",
      };
    } else {
      elemProps = {
        ...properties,
        label: elemProps.label,
        offset: this.props.sectionOffset ? "w-100" : "",
      }; //reinitialize label if already filled by user
    }
    if (tool === "selection" || tool === "radio" || tool === "checkbox") {
      variables = [];
    } else {
      options = [];
    }
    this.setState({ tool, elemProps, variables, options });
  };

  onColumnDetailChanged = (e, idx) => {
    let { name, value } = e.target ? e.target : e;
    let { columnDetailConfig } = this.state.elemProps;
    if (!columnDetailConfig) {
      columnDetailConfig = [{ serialno: "", name: "" }];
    }
    columnDetailConfig[idx][name] = value;
    let elemProps = {
      ...this.state.elemProps,
      columnDetailConfig,
    };
    this.setState({ elemProps });
  };

  onColumnDetailAdd = (idx) => {
    let { columnDetailConfig } = this.state.elemProps;
    if (!columnDetailConfig) {
      columnDetailConfig = [{ serialno: "", name: "" }];
    }
    columnDetailConfig.splice(idx + 1, 0, { serialno: "", name: "" });
    let elemProps = {
      ...this.state.elemProps,
      columnDetailConfig,
    };
    this.setState({ elemProps });
  };

  onColumnDetailDel = (idx) => {
    let { columnDetailConfig } = this.state.elemProps;
    columnDetailConfig.splice(idx, 1);
    let elemProps = {
      ...this.state.elemProps,
      columnDetailConfig,
    };
    this.setState({ elemProps });
  };

  onColMetaChange = (e) => {
    let { name, value } = e.target ? e.target : e;
    let { elemProps } = this.state;
    if (name === "enableHideShow") {
      name = "class";
      value = value ? "displayparent" : "displayparent_removed";
    }
    if (name === "singleLine") {
      name = "offset";
      value = value ? "w-100" : "";
    }
    if (name === "autoKey") {
      value = Util.toCamelCase(value);
    }
    elemProps = {
      ...elemProps,
      [name]: value,
    };
    this.setState({ elemProps });
  };

  onElementMetaChange = (e) => {
    const { element } = this.state;
    switch (element) {
      case "section":
        let { name, value } = e.target ? e.target : e;
        this.changeSectionMeta({
          sectionId: this.state.elemProps["id"],
          name,
          value,
        });
        break;
      case "group":
      case "row":
      case "subSection":
      case "compactRow":
      case "childrow":
        this.onRowMetaChange(e);
        break;
      case "column":
        this.onColMetaChange(e);
        break;
      default:
    }
  };

  changeSectionMeta = (e) => {
    let { elemProps } = this.state;
    let { name, value } = e.target ? e.target : e;
    if (name === "sectionOffset") {
      value = value ? "w-100" : "";
    }

    elemProps = {
      ...elemProps,
      [name]: value,
    };
    this.setState({ elemProps });
  };

  onRowMetaChange = (e) => {
    let { elemProps, element } = this.state;
    let { name, value } = e.target ? e.target : e;
    if (name === "type") {
      // change element on rowDisplayTypeChange
      element = value;
    }
    if (name === "source" || name === "displayconditionValues") {
      value = this.getShowHideConfig(
        name === "source" ? name : "value",
        value === "none" ? "" : value
      );
      name = "config";
    }
    elemProps = {
      ...elemProps,
      [name]: value,
    };
    this.setState({ elemProps, element });
  };

  saveOptionsInElement = (options) => {
    let { elemProps } = this.state;
    options.forEach((o) => {
      elemProps = {
        ...elemProps,
        [o.name]: o.value,
      };
    });
    this.setState({ elemProps });
  };

  getShowHideConfig = (name, value) => {
    let row = this.state.elemProps;
    let config =
      row["config"] &&
      row["config"]["displaycondition"] &&
      row["config"]["displaycondition"].length
        ? row["config"]
        : {
            displaycondition: [
              //setting default, empty config
              {
                value: "",
                source: "",
              },
            ],
          };
    config.displaycondition[0][name] = value;
    return config;
  };

  saveTool = () => {
    let { options, elemProps, variables, tool } = this.state;
    if (
      tool === "selection" ||
      tool === "radio" ||
      tool === "checkbox" ||
      tool === "typeahead" ||
      tool === "hyper_link"
    ) {
      elemProps["uiData"] = ID.uuid(); //label field is always available as it is Required
    }
    if (elemProps.primaryDate !== undefined) {
      this.props.emitPrimaryDate(elemProps.primaryDate);
    }
    if (options.length) {
      options.forEach((opt, idx) => {
        options[idx] = {
          ...opt,
          key: "option" + idx,
        };
      });
      let uiData = {
        key: elemProps["uiData"],
        value: options,
      };
      this.props.onUiDictDataEmit(uiData); //store uiData on Section level
    }
    if (variables.length) {
      let localvar = variables.join();
      elemProps = {
        ...elemProps,
        localvar: localvar,
      };
    }
    this.props.onElementSave({ elemProps });
  };

  onOptionNumberChange = (e) => {
    let { value } = e.target;
    let { options } = this.state;
    let total = options.length;
    if (value > total) {
      // Add more new options in the end
      for (let i = 0; i < value - total; i++)
        options.push({ key: "", value: "" });
    } else if (value < total) {
      // Delete options from the end
      options.splice(value, total - value);
    }
    this.setState({ options });
  };

  onOptionValueChanged = (e) => {
    let { options } = this.state;
    options[e.target.name]["value"] = e.target.value;
    this.setState({ options });
  };

  onOptAddition = (idx) => {
    let { options } = this.state;
    options.splice(idx + 1, 0, { key: "", value: "" });
    this.setState({ options });
  };

  onOptDeletion = (idx) => {
    let { options } = this.state;
    options.splice(idx, 1);
    this.setState({ options });
  };

  onVariableNumberChange = (e) => {
    let { value } = e.target;
    let { variables } = this.state;
    let total = variables.length;
    if (value > total) {
      // Add more new variables in the end
      for (let i = 0; i < value - total; i++) variables.push("");
    } else if (value < total) {
      // Delete variables from the end
      variables.splice(value, total - value);
    }
    this.setState({ variables });
  };

  onVariableChanged = (e) => {
    let { name, value } = e.target;
    let { variables } = this.state;
    if (variables.indexOf(value) === -1) variables[name] = value;
    this.setState({ variables });
  };

  onVariableAddition = (idx) => {
    let { variables } = this.state;
    variables.splice(idx + 1, 0, "");
    this.setState({ variables });
  };

  onVariableDeletion = (idx) => {
    let { variables } = this.state;
    variables.splice(idx, 1);
    this.setState({ variables });
  };

  areRequiredFieldsEmpty() {
    let { elemProps, element, tool } = this.state;
    let Elem;
    // let labelRequiredNames = ['drug', 'drugType', 'frequency', 'dosage', 'duration'];
    if (element === "section") {
      if (elemProps && elemProps.showType === "compactTable") {
        if (elemProps.splitTable) Elem = RequiredFields.SplitTableSection;
        else Elem = RequiredFields.TableSection;
      } else if (elemProps && elemProps.showType === "xyTable") {
        Elem = RequiredFields.XYTable;
      } else Elem = RequiredFields.Section;
    } else if (element === "childrow") {
      Elem = RequiredFields.ChildRow;
    } else if (element === "row" || element === "group") {
      Elem = RequiredFields.Row;
    } else if (element === "subSection") {
      Elem = RequiredFields.Subsection;
    } else if (element === "compactRow") {
      Elem = RequiredFields.CompactRow;
    } else if (element === "column") {
      if (elemProps.showType === "section") {
        Elem = RequiredFields.Section;
      } else if (elemProps["class"] === "displayparent") {
        Elem = RequiredFields.DisplayParent;
      } else if (tool === "header") {
        Elem = RequiredFields.StaticColumn;
      } else if (tool === "autoComplete") {
        Elem = RequiredFields.AutoComplete;
      } else if (tool === "typeahead" && elemProps.async) {
        Elem = RequiredFields.AutoComplete;
      } else {
        Elem = RequiredFields.ColumnWithoutLabel;
      }
    }
    return !!Elem.filter((field) => {
      let f = elemProps[field];
      return f === undefined || (f.trim && f.trim() === "");
    }).length;
  }

  render() {
    let requiredError = true;
    let Elems = ["selection", "checkbox", "radio"];
    let isOptionsEmpty = Elems.indexOf(this.state.tool) === -1 ? false : true,
      isVariablesEmpty = false;
    if (this.state.options && this.state.options.length) {
      isOptionsEmpty = this.state.options.some((o) => !o.value);
    }
    if (this.state.variables && this.state.variables.length) {
      isVariablesEmpty = this.state.variables.some((o) => !o);
    }
    const { isInsidePopup } = this.props;
    if (this.state.elemProps)
      requiredError =
        this.areRequiredFieldsEmpty() || isOptionsEmpty || isVariablesEmpty; // to render required error changes if elemProps are changed
    return (
      <div style={{ width: "100%" }}>
        {requiredError && (
          <div className="text-info">
            Please fill all Required fields marked with *
          </div>
        )}
        <div className="properties-editor mt-3">
          <div style={{ textAlign: "right" }}>
            <Button
              disabled={requiredError}
              type={requiredError ? "secondary" : "primary"}
              style={{ marginRight: "20px" }}
              onClick={() => this.saveTool()}
            >
              <SaveOutlined />
              {isInsidePopup && "Save"}
            </Button>
          </div>
          {this.state.element === "column" && (
            <div className="row" style={{ padding: "0 20px 0 0" }}>
              <div className="col-md-12 mb-1">
                <label className="mr-2">
                  Label
                  {this.state.tool === "header" && (
                    <span className="required">*</span>
                  )}
                </label>
                <Input
                  className="form-control"
                  type="textbox"
                  name="label"
                  placeholder="Enter text here"
                  onChange={(e) => this.onColMetaChange(e)}
                  value={
                    (this.state.elemProps && this.state.elemProps["label"]) ||
                    ""
                  }
                />
              </div>
              <div className="col-md-12 mb-1">
                <label className="mr-2">
                  Select a Component<span className="required">*</span>
                </label>
                <Select
                  style={{ width: "100%" }}
                  className="form-control"
                  type="select"
                  name="name"
                  onChange={(e) => this.onToolSelection(e)}
                  value={this.state.tool ? this.state.tool : "initial"}
                >
                  <Option disabled value="initial">
                    Select tool
                  </Option>
                  {BasicComponents.map((tool, idx) => (
                    <Option key={idx} value={tool.key}>
                      {tool.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          )}
          <div className="col-md-12 pl-0">
            {!(
              (this.state.element &&
                this.state.element === "column" &&
                !this.state.tool) ||
              !this.state.element
            ) && (
              <ToolProps
                element={
                  this.state.element === "column"
                    ? this.state.tool
                    : this.state.element
                }
                meta={this.state.elemProps}
                options={this.state.options}
                variables={this.state.variables}
                colIdArray={this.props.colIdArray}
                availableNameArr={this.props.availableNameArr}
                tagConfigList={this.props.tagConfigList}
                showHideParentArray={this.props.showHideParentArray}
                uiDataDict={this.props.uiDataDict}
                // onToolPropsChange={(e) => this.onColMetaChange(e)}
                onToolPropsChange={(e) => this.onElementMetaChange(e)}
                saveOptionsInElement={this.saveOptionsInElement.bind(this)}
                onOptAddition={(e) => this.onOptAddition(e)}
                onOptDeletion={(e) => this.onOptDeletion(e)}
                isOptionsEmpty={isOptionsEmpty}
                isVariablesEmpty={isVariablesEmpty}
                showPrimaryDate={this.props.showPrimaryDate}
                onColumnDetailChanged={this.onColumnDetailChanged.bind(this)}
                onColumnDetailAdd={(e) => this.onColumnDetailAdd(e)}
                onColumnDetailDel={(e) => this.onColumnDetailDel(e)}
                onOptionNumberChange={(e) => this.onOptionNumberChange(e)}
                onOptionValueChanged={(e) => this.onOptionValueChanged(e)}
                onVariableNumberChange={(e) => this.onVariableNumberChange(e)}
                onVariableChanged={(e) => this.onVariableChanged(e)}
                onVariableAddition={(e) => this.onVariableAddition(e)}
                onVariableDeletion={(e) => this.onVariableDeletion(e)}
              />
            )}
            {this.state.element === "column" && !this.state.tool && (
              <label className="mr-2" style={{ textAlign: "center" }}>
                Select a Component to Customize your field!
              </label>
            )}
          </div>
        </div>
      </div>
    );
  }
}
