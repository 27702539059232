import { Button, Form, Input, Typography } from "antd";
import Title from "antd/es/typography/Title";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  attemptPin,
  fetchMyPermissions,
  fetchSelf,
} from "../../../redux/reducers/auth.slice";
import { fetchProfile } from "../../../redux/reducers/profile.slice";
import { RootState } from "../../../shared/constants";
import { ROUTE_ADMIN_DASHBOARD } from "../../../shared/routes/admin.routes.constants";
import { ROUTE_BOSS_INSTANT_VC_DOCTOR } from "../../../shared/routes/boss.routes.constants";
import { ROUTE_CRM_DASHBOARD } from "../../../shared/routes/crm.routes.constants";
import { ROUTE_DOCTOR_DASHBOARD } from "../../../shared/routes/doctor.routes.constants";
import {
  ROUTE_PATIENT_DASHBOARD,
  ROUTE_PATIENT_LINKING,
} from "../../../shared/routes/patient.routes.constants";
import { ROUTE_FORGET_PASSWORD } from "../../../shared/routes/route.constants";

const { Text } = Typography;

interface Props {
  loginByPin: (b: boolean) => void;
  username: any;
  kind: string;
  countryCode: string;
  setOtp: (value: any) => void;
  loginsEnabled: Array<string>;
}

export const PinForm = ({
  loginByPin,
  countryCode,
  kind,
  username,
  setOtp,
  loginsEnabled,
}: Props) => {
  const { account, applications } = useSelector(
    (state: RootState) => state.auth
  );
  const [applicationId, setApplicationId] = useState("");
  const RouteParams: any = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const selectedApplication = applications?.find(
      (a: any) => a.slug === RouteParams?.source
    );
    setApplicationId(selectedApplication?.id);
  }, [applications, account, RouteParams?.source]);

  return (
    <>
      <Title className="mt100  bold" level={3}>
        Enter PIN
      </Title>

      <p className="doctor-primary-color text-left">
        Enter Pin Set on : {countryCode} {username} <br></br>
        <Text
          className="doctor-secondary-color bold  cursor"
          underline
          onClick={() => setOtp(false)}
        >
          Change Phone Number?
        </Text>
      </p>
      <Form
        autoComplete="new-password"
        onFinish={async ({ pin }) => {
          let response;
          response = await dispatch(
            attemptPin({
              password: pin,
              phone: username,
              country_code: countryCode,
              kind: kind,
              application_id: applicationId,
              account_id: account?.id,
            })
          );
          if (response?.payload?.data?.data?.access_token) {
            if (RouteParams["source"] === "doctor") {
              (async function () {
                try {
                  await dispatch(fetchMyPermissions());
                  const response = await dispatch(fetchProfile("users"));
                  if (response.payload.status === 200) {
                    window.location.href = ROUTE_DOCTOR_DASHBOARD;
                  }
                } catch (e) {
                  console.error(e);
                }
              })();
            }
            if (RouteParams["source"] === "patient") {
              (async function () {
                try {
                  await dispatch(fetchMyPermissions());
                  const response = await dispatch(fetchProfile("patients"));
                  if (response.payload.status === 200) {
                    if (
                      Object.keys(response?.payload?.data?.data).length === 0
                    ) {
                      window.location.href = ROUTE_PATIENT_LINKING(username);
                    } else {
                      window.location.href = ROUTE_PATIENT_DASHBOARD;
                    }
                  }
                } catch (e) {
                  console.error(e);
                }
              })();
            }

            if (RouteParams["source"] === "boss") {
              (async function () {
                try {
                  await dispatch(fetchSelf());
                  await dispatch(fetchMyPermissions());
                  const response = await dispatch(fetchProfile("users"));
                  if (
                    response.payload.status === 201 ||
                    response.payload.status === 200
                  ) {
                    window.location.href = ROUTE_BOSS_INSTANT_VC_DOCTOR;
                  }
                } catch (e) {
                  console.error(e);
                }
              })();
            }

            if (RouteParams["source"] === "admin") {
              (async function () {
                try {
                  await dispatch(fetchSelf());
                  await dispatch(fetchMyPermissions());
                  const response = await dispatch(fetchProfile("users"));
                  if (response.payload.status === 200) {
                    window.location.href = ROUTE_ADMIN_DASHBOARD(account?.slug);
                  }
                } catch (e) {
                  console.error(e);
                }
              })();
            }

            if (RouteParams["source"] === "crm") {
              (async function () {
                try {
                  await dispatch(fetchSelf());
                  await dispatch(fetchMyPermissions());
                  const response = await dispatch(fetchProfile("users"));
                  if (response.payload.status === 200) {
                    window.location.href = ROUTE_CRM_DASHBOARD;
                  }
                } catch (e) {
                  console.error(e);
                }
              })();
            }
          }
        }}
      >
        <Form.Item
          name="pin"
          rules={[{ required: true, message: "Please enter 6-Digit Pin" }]}
        >
          <Input
            className="noborder doctor-app-box-shadow padding20 masked"
            maxLength={6}
            type={"number"}
            autoComplete="new-password"
            size={"large"}
            placeholder={"* * * * *"}
          />
        </Form.Item>

        {loginsEnabled.indexOf("otp") !== -1 && (
          <Button
            size="large"
            className="doctor-border-button doctor-padding-15"
            onClick={() => loginByPin(false)}
          >
            {" "}
            Login By OTP
          </Button>
        )}
        <Button
          size="large"
          className="doctor-secondary-button doctor-padding-15"
          htmlType={"submit"}
          style={{ float: "right" }}
        >
          {" "}
          Login
        </Button>
        {loginsEnabled.indexOf("pin") !== -1 && (
          <Button
            type={"link"}
            href={"/"}
            className={"mt10"}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              navigate(
                ROUTE_FORGET_PASSWORD(
                  RouteParams["account"],
                  RouteParams["source"],
                  username
                )
              );
            }}
          >
            Forgot PIN?
          </Button>
        )}
      </Form>
    </>
  );
};
