import {
  Button,
  Col,
  DatePicker,
  Input,
  message,
  Modal,
  Row,
  TimePicker,
  Typography,
} from "antd";

import moment from "moment";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import {
  disabledPreviousDates,
  getDayFormat,
  timeDifference,
} from "../../../../shared/Utils/utilities";

import { updateDoctorSlotDelay } from "../../../../redux/reducers/slot.slice";

import executeWithLoaderAndStatus from "./sliding-status-wrapper";
import { useDispatch } from "react-redux";

const { Text } = Typography;

interface MarkDelayModalProps {
  appointmentData: any;
  setLoadingSlider: (on: boolean) => void;
  setStatus: (status: string) => void;
  markDelay: any;
  setMarkDelay: (value: boolean) => void;
  newDate: any;
  setNewDate: (value: any) => void;
  start_time: string;
  setStartTime: (value: string) => void;
  end_time: string;
  setEndTime: (value: string) => void;
}

const MarkDelayModal: React.FC<MarkDelayModalProps> = ({
  appointmentData,
  markDelay,
  setMarkDelay,
  setLoadingSlider,
  setStatus,
  newDate,
  setNewDate,
  start_time,
  setStartTime,
  end_time,
  setEndTime,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const markDelayAppointment = useCallback(
    (_appointmentData: any, start_time: string, end_time: string) => {
      if (start_time === "" || end_time === "") {
        message.error("Provide New Time");
        return;
      }

      if (start_time === end_time) {
        message.error("End time should not be equal to start time");
        return;
      }
      var ok = timeDifference(start_time, end_time);

      const updateSlots = async function () {
        try {
          const response = await dispatch(
            updateDoctorSlotDelay({
              doctor_slot_config_id: _appointmentData?.doctor_slot_config_id,
              new_start_time: start_time,
              new_end_time: end_time,
              date: moment(newDate).format("YYYY-MM-DD"),
            })
          );

          if (response.payload?.data?.statusCode === 400) {
            message.error(response.payload?.data?.message);
            return;
          } else {
            setTimeout(() => {
              navigate(-1);
            }, 2000);
          }
        } catch (e) {
          console.error(e);
        }
      };

      if (ok >= 0) {
        updateSlots();
      } else {
        message.error("End time should not be greater than start time");
      }
    },
    [navigate, newDate, dispatch]
  );

  return (
    <Modal
      centered
      footer={false}
      title={"Mark Delay"}
      maskClosable={true}
      onCancel={() => setMarkDelay(false)}
      visible={markDelay}
      width={"30%"}
    >
      <Row>
        <Col span={24} className="text-center">
          <Text className="sub-text cursor capitalize">
            {appointmentData.branch_name}
          </Text>{" "}
          <br />
          <h3
            className="doctor-primary-color nopadding font12"
            style={{ marginBottom: "0px" }}
          >
            {getDayFormat(appointmentData.date, false)},{" "}
            {moment(appointmentData.date).format("Do, MMM YYYY")}{" "}
          </h3>
        </Col>

        <Col span={12} className="mt20">
          <Text className="doctor-primary-color cursor">
            <b>Slot Date</b>
          </Text>
          <br />
          <DatePicker
            disabledDate={disabledPreviousDates}
            format="DD MMM'YY"
            style={{ width: "100%" }}
            value={newDate}
            onChange={(e: any) => setNewDate(e)}
            size={"large"}
          ></DatePicker>
          <br />
        </Col>

        <Col span={24} className="mt20">
          <Text className="doctor-primary-color cursor">
            <b>Prevoius Time</b>
          </Text>{" "}
          <br />
        </Col>
      </Row>

      <Row gutter={20}>
        <Col span={12}>
          <Text className="sub-text cursor font12">Start Time</Text> <br />
          <Input disabled value={appointmentData.start} size={"large"}></Input>
        </Col>

        <Col span={12}>
          <Text className="sub-text cursor font12">End Time</Text> <br />
          <Input disabled value={appointmentData.end} size={"large"}></Input>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col span={24} className="mt20">
          <Text className="doctor-primary-color cursor">
            <b>New Time</b>
          </Text>{" "}
          <br />
        </Col>
        <Col span={12}>
          <Text className="sub-text cursor font12">Start Time</Text> <br />
          <TimePicker
            use12Hours
            format="h:mm:ss A"
            style={{ width: "100%" }}
            onChange={(evt) => setStartTime(moment(evt).format("h:mm a"))}
            size={"large"}
          ></TimePicker>
        </Col>

        <Col span={12}>
          <Text className="sub-text cursor font12">End Time</Text> <br />
          <TimePicker
            use12Hours
            format="h:mm:ss A"
            style={{ width: "100%" }}
            onChange={(evt) => setEndTime(moment(evt).format("h:mm a"))}
            size={"large"}
          ></TimePicker>
        </Col>
      </Row>

      <Row className="text-center">
        <Col span={24}>
          <Button
            size="large"
            block
            className="doctor-secondary-button mt50"
            onClick={() =>
              executeWithLoaderAndStatus({
                loading_slider: setLoadingSlider,
                setting_status: setStatus,
                status: "Marking Delay your practice...",
                func: markDelayAppointment,
                args: [appointmentData, start_time, end_time],
              })
            }
          >
            {" "}
            Mark Delay
          </Button>
          <p className="cursor font12 text-center mt5">
            All impacted patients will be updated <br />
            about the new time
          </p>
        </Col>
      </Row>
    </Modal>
  );
};

export default MarkDelayModal;
