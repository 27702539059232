import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { fetchRemoteConfigValue } from "../firebase";
import { ForgetPasswordPage } from "../pages/auth/components/forget-password.page";
import { LoginPage } from "../pages/auth/components/login.page";
import LandingPage from "../pages/landing.page";
import { NotFoundPage } from "../pages/not-found.page";
import {
  ACCOUNT_PREFIX,
  PROM_DOCTOR_IDS,
  RootState,
} from "../shared/constants";
import {
  ROUTE_FORGET_PASSWORD,
  ROUTE_LANDING,
  ROUTE_LOGIN,
} from "../shared/routes/route.constants";
import BossRoutes from "./boss.routes";
import DoctorRoutes from "./doctor.routes";
import OrderRoutes from "./order.routes";
import PatientRoutes from "./patient.routes";
import { decodeJWT } from "../helpers";

type TApp = "doctor" | "patient" | "boss" | "prom";

function RouteGuard({ ws, app, ...rest }: any) {
  const { token } = useSelector((state: RootState) => state.auth);
  const { profile } = useSelector((state: RootState) => state.profile);
  const application_slug = localStorage.getItem("application_slug");

  if (token && application_slug && app === "max") {
    app = application_slug;
  }

  const [isPromEnabled, setIsPromEnabled] = useState(true);

  useEffect(() => {
    void fetchRemoteConfigValue("prom_web")
      .then((res) => {
        const userToken = localStorage.getItem("token");

        const userId = JSON.parse(decodeJWT(userToken ?? "").user).id;

        const isPromDoctor = PROM_DOCTOR_IDS.includes(userId);

        setIsPromEnabled((res?.asBoolean() && isPromDoctor) ?? false);
      })
      .catch((err) => console.log(err));
  }, []);

  const getRoutes = useCallback(
    (app: TApp): JSX.Element => {
      switch (app) {
        case "doctor":
          return <DoctorRoutes {...rest} />;
        case "patient":
          return <PatientRoutes {...rest} />;
        case "boss":
          return <BossRoutes {...rest} />;
        case "prom": {
          // return <DoctorRoutes {...rest} />;
          if (isPromEnabled) {
            return <DoctorRoutes {...rest} />;
          } else
            return (
              // redirect to 404 if any prom url is used
              <Routes>
                <Route path="*" element={<Navigate to="/404" replace />} />
                <Route
                  path="/404"
                  element={<NotFoundPage></NotFoundPage>}
                ></Route>
              </Routes>
            );
        }

        default:
          return (
            <Routes>
              <Route
                path="/404"
                element={<NotFoundPage></NotFoundPage>}
              ></Route>
            </Routes>
          );
      }
    },
    [isPromEnabled, rest]
  );

  return token && profile ? (
    getRoutes(app)
  ) : (
    <Routes>
      <Route
        path={ROUTE_LOGIN(":account", ":source")}
        element={<LoginPage />}
      />
      <Route
        path={ROUTE_FORGET_PASSWORD(":account", ":source", ":number")}
        element={<ForgetPasswordPage />}
      />
      <Route path={ROUTE_LANDING(":account")} element={<LandingPage />} />
      <Route
        element={<Navigate to={ROUTE_LANDING(ACCOUNT_PREFIX)} replace />}
      />
    </Routes>
  );
}

export default RouteGuard;