import React from "react";
import { PrimaryColor } from "../../providerEnum.js";

export default class Spinner extends React.Component {
  render() {
    let accInfo = sessionStorage.getItem("accountInfo");
    let color =
      (accInfo && accInfo.theme && PrimaryColor[accInfo.theme]) ||
      PrimaryColor["default"];

    return (
      <div style={{ textAlign: "center" }}>
        {/* <Loader
                    type="Oval"
                    color={color}
                    height={this.props.height}
                    width={this.props.width}
                /> */}
      </div>
    );
  }
}
