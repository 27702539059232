import { Col, List, Row, Space, Table, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchVideoLogs } from "../../../../redux/reducers/tickets.slice";
const { Text } = Typography;

// const attemptdata = [{
//     patient: "Satish Chandra",
//     start: "26 Sep’21  |  6:52 PM",
//     end: "26 Sep’21  |  6:54 PM",
//     duration: "12 mins & 6 secs",
//     type: "Idle",
//     attempt: "1",
// }]
// const eventData = [{
//     event: "Participant-Disconnected",
//     participant: "Patient Satish",
//     participant_status: "Disconnected",
//     room_status: "Completed",
//     room_duration: "12 mins & 6 secs",
//     publish_type: "Audio",
//     created_on: "30 Sep’21, 10:00 AM"
// }]

const PatientVideoConsultLogs: React.FunctionComponent<any> = (Props) => {
  console.log(Props?.appointment);
  const [eventData, seteventData] = useState();
  const [attemptData, setattemptData] = useState();
  const [patientDetails, setpatientDetails] = useState({} as any);
  const [doctorDetails, setdoctorDetails] = useState({} as any);
  const [callSummary, setcallSummary] = useState([] as any);

  const callsummary = [
    {
      label: "Appointment",
      record: `${moment
        .unix(Props?.appointment?.start_timestamp)
        .format("hh:mm A")}  |  ${
        Props?.appointment?.doctor?.name
      }  |  ${moment(Props?.appointment?.appointment_date).format(
        "DD MMM'YY"
      )}`,
    },
    // {
    //     label:"Summary",
    //     record:"Consultation done with 1 valid attempt and total duration of 12 mins & 6 secs"
    // },
    // {
    //     label:"Attempts",
    //     record:"Total Attempts made 3 and total duration of 19 mins & 37 secs but only 1 person was there in remaining attempts"
    // },
    {
      label: "Final Status",
      record: `${
        callSummary && callSummary.length !== 0 ? callSummary[0]?.event : "-"
      }`,
    },
    {
      label: "Paticipants Joined",
      record: ` Patient: “${Props?.appointment?.patient?.pii?.first_name} ${Props?.appointment?.patient?.pii?.last_name}”, Dr. :  “${Props?.appointment?.doctor?.name}” `,
    },
    {
      label: "Patient Devices",
      record: `${
        patientDetails?.device_type ? patientDetails?.device_type : ""
      } ${patientDetails?.device_os ? patientDetails?.device_os : ""} - ${
        patientDetails?.device_name ? patientDetails?.device_name : ""
      } ${patientDetails?.device_ip ? patientDetails?.device_ip : ""}  `,
    },
    {
      label: "Doctor Devices",
      record: `${
        doctorDetails?.device_type ? doctorDetails?.device_type : ""
      } ${doctorDetails?.device_os ? doctorDetails?.device_os : ""} - ${
        doctorDetails?.device_name ? doctorDetails?.device_name : ""
      } ${doctorDetails?.device_ip ? doctorDetails?.device_ip : ""}  `,
    },
  ];

  console.log("callSummary", callSummary);

  const dispatch = useDispatch();
  const attemptcolumns = [
    {
      title: "Participant",
      dataIndex: "participant",
    },
    {
      title: "Start",
      dataIndex: "start",
    },
    {
      title: "End",
      dataIndex: "end",
    },
    {
      title: "Duration",
      dataIndex: "duration",
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Attempt",
      dataIndex: "attempt",
    },
  ];
  const eventcolumns = [
    {
      title: "Event",
      dataIndex: "event",
    },
    {
      title: "Participant",
      dataIndex: "participant",
    },
    {
      title: "Participant Status",
      dataIndex: "participant_status",
    },
    {
      title: "Room Status",
      dataIndex: "room_status",
    },
    {
      title: "Room Duration",
      dataIndex: "room_duration",
    },
    {
      title: "Publish Type",
      dataIndex: "publish_type",
    },
    {
      title: "Created On",
      dataIndex: "created_on",
      render: (a: any) => <p>{moment(a).format("YYYY/MM/DD").toString()}</p>,
    },
  ];

  useEffect(() => {
    dispatch(
      fetchVideoLogs({
        id: Props.room_id,
        dates: moment(Props.appointment_date).format("YYYY/MM/DD").toString(),
      })
    ).then((item: any) => {
      console.log("item?.payload?.data?", item?.payload?.data);
      setcallSummary(
        item?.payload?.data?.data?.call_summary
          ? item?.payload?.data?.data?.call_summary
          : []
      );
      setattemptData(
        item?.payload?.data?.data?.call_attempt_logs
          ? item?.payload?.data?.data?.call_attempt_logs
          : []
      );
      seteventData(
        item?.payload?.data?.data?.call_event_logs
          ? item?.payload?.data?.data?.call_event_logs
          : []
      );

      setpatientDetails(
        item?.payload?.data?.data?.call_summary?.find(function (el: any) {
          return el.user_type === "patient";
        })
      );
      setdoctorDetails(
        item?.payload?.data?.data?.call_summary?.find(function (el: any) {
          return el.user_type === "doctor";
        })
      );
    });
  }, [Props.appointment_date, Props.room_id, dispatch]);

  console.log("callsummarycallsummary", callsummary);

  return (
    <Row>
      <Col span={24}>
        <p className="primary-color-text mt10 bold">Call Summary</p>
        <List
          bordered
          size="small"
          className="white-background"
          dataSource={callsummary}
          renderItem={(item) => (
            <List.Item>
              <Col span={6}>
                {" "}
                <Text type="secondary">{item.label}</Text>{" "}
              </Col>
              <Col span={18}>
                <p className="primary-color-text mt10 bold capitalize">
                  {item.record}
                </p>
              </Col>
            </List.Item>
          )}
        />
      </Col>

      <Col span={24} className="mt30">
        <p className="primary-color-text mt10 bold">Call Attempt Logs</p>
        <Table
          loading={false}
          locale={{
            emptyText: (
              <>
                <Space direction={"vertical"} size={10}>
                  <Text>No Records</Text>
                </Space>
              </>
            ),
          }}
          pagination={false}
          scroll={{ x: true }}
          dataSource={attemptData}
          columns={attemptcolumns}
        />
      </Col>

      <Col span={24} className="mt30">
        <p className="primary-color-text mt10 bold">Call Event Logs</p>
        <Table
          loading={false}
          locale={{
            emptyText: (
              <>
                <Space direction={"vertical"} size={10}>
                  <Text>No Records</Text>
                </Space>
              </>
            ),
          }}
          pagination={false}
          scroll={{ x: true }}
          dataSource={eventData}
          columns={eventcolumns}
        />
      </Col>
    </Row>
  );
};

export default PatientVideoConsultLogs;
