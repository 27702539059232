export const LayoutComponents = [
  { key: "section", name: "Section" },
  { key: "column", name: "Column" },
  { key: "row", name: "Row" },
  { key: "group", name: "Group" },
  { key: "childrow", name: "Child Row" },
];

export const BasicComponents = [
  { key: "textbox", name: "Textbox" },
  { key: "textarea", name: "Textarea" },
  { key: "selection", name: "Selection" },
  { key: "typeahead", name: "Type Ahead/Select" },
  { key: "autoComplete", name: "Auto Complete Textbox" },
  { key: "hyper_link", name: "Hyper Link" },
  { key: "numeric_pain_score", name: "Numeric Pain Score" },
  { key: "textFormatter", name: "Text Formatter" },
  { key: "checkbox", name: "Checkboxes" },
  { key: "radio", name: "Radio Buttons" },
  { key: "date", name: "Date" },
  { key: "hidden", name: "Hidden" },
  { key: "time", name: "Time" },
  { key: "header", name: "Header" },
];
