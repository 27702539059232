import { Button, Form, Input, notification } from "antd";
import { useForm } from "antd/es/form/Form";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { resetOTPWithPassword } from "../../../redux/reducers/auth.slice";
import { RootState } from "../../../shared/constants";
import { ROUTE_LOGIN } from "../../../shared/routes/route.constants";

interface Props {
  loginByPin: (b: boolean) => void;
  phone: string;
  kind: string;
  countryCode: string;
}

export const ResetWithOTPForm = ({
  loginByPin,
  countryCode,
  kind,
  phone,
}: Props) => {
  const { account, applications } = useSelector(
    (state: RootState) => state.auth
  );
  const { otpSessionId, loggingIn } = useSelector(
    (state: RootState) => state.auth
  );
  const [applicationId, setApplicationId] = useState("");
  const RouteParams: any = useParams();
  const [form] = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const selectedApplication = applications?.find(
      (a: any) => a.slug === RouteParams?.source
    );
    setApplicationId(selectedApplication?.id);
  }, [applications, account]);

  return (
    <>
      <Title className=" bold" level={3}>
        Reset Pin
      </Title>
      <Form
        layout="vertical"
        onFinish={async ({ otp, confirmPassword, password }) => {
          let response;

          if (
            confirmPassword.length !== 6 ||
            password.length !== 6 ||
            otp.length !== 6
          ) {
            notification.error({ message: "Please enter 6 digits for pin." });
            return;
          }
          if (confirmPassword !== password) {
            notification.error({ message: "Password does not match" });
            return;
          }

          response = await dispatch(
            resetOTPWithPassword({
              password,
              otp,
              session_id: otpSessionId,
              type: "pin",
            })
          );
          if (response?.payload?.data?.data?.success) {
            navigate(ROUTE_LOGIN(account?.slug, RouteParams["source"]));
          }
        }}
      >
        <Form.Item
          name="otp"
          label="OTP"
          rules={[{ required: true, message: "Please input your OTP!" }]}
        >
          <Input
            maxLength={6}
            min={6}
            type={"number"}
            className="noborder doctor-app-box-shadow padding20"
            size={"large"}
            placeholder={"* * * * * *"}
          />
        </Form.Item>

        <Form.Item
          name="password"
          label="PIN"
          rules={[
            {
              required: true,
              message: "Please enter  pin!",
            },
          ]}
        >
          <Input
            maxLength={6}
            min={6}
            type={"number"}
            className="noborder doctor-app-box-shadow padding20 masked"
            size={"large"}
            placeholder={"* * * * * *"}
          />
        </Form.Item>
        <Form.Item
          label="CONFIRM PIN"
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: "Please enter confirm pin!",
            },
          ]}
        >
          <Input
            maxLength={6}
            min={6}
            type={"number"}
            className="noborder doctor-app-box-shadow padding20 masked"
            size={"large"}
            placeholder={"* * * * * *"}
          />
        </Form.Item>

        <Button
          size="large"
          className="doctor-secondary-button doctor-padding-15"
          htmlType={"submit"}
        >
          {" "}
          Set Password
        </Button>
      </Form>
    </>
  );
};
