import { useEffect, useState } from "react";
import { Row, Col, Button, Modal } from "antd";
import Webcam from "react-webcam";
import Diagnostics from "../../pages/doctor/Video-Room/components/diagnostics.page";
import { useNavigate } from "react-router-dom";
import { ReactComponent as VideoOffGreen } from "../../assets/icons/video_off_green.svg";
import { ReactComponent as VideoOnGreen } from "../../assets/icons/videoongreen.svg";
import { ReactComponent as MicOnGreen } from "../../assets/icons/micongreen.svg";
import { ReactComponent as MicOffwhite } from "../../assets/icons/micoffwhite.svg";
import { ReactComponent as CameraSwitch } from "../../assets/icons/cswitch2.svg";
import { ROUTE_DOCTOR_APPOINTMENTS_CALL } from "../routes/doctor.routes.constants";
import {
  ROUTE_PATIENT_WAITING_AREA,
  ROUTE_PATIENT_WAITING_AREA_ATTANDANT,
} from "../routes/patient.routes.constants";
import { useDispatch, useSelector } from "react-redux";
import {
  checkinAppointment,
  setVideoRoom,
  submitCallLogs,
  updateAppointmentById,
} from "../../redux/reducers/appointment.slice";
import axios from "axios";
import { RootState } from "../constants";
import { useMediaQuery } from "react-responsive";

type Props = {
  roomId?: any;
  call_validation?: any;
  type?: any;
  appointment_id?: any;
  appointment?: any;
  cancelVideoModal?: () => void;
  showAppointmentDetails?: (value: any) => void;
  guestId?: any;
  _webcamEnabled?: any;
};

function VideoVerification({
  roomId,
  type,
  appointment_id,
  appointment,
  cancelVideoModal,
  showAppointmentDetails,
  guestId,
  _webcamEnabled,
}: Props) {
  const screenType = {
    desktop: useMediaQuery({ minWidth: 992 }),
    tablet: useMediaQuery({ minWidth: 768, maxWidth: 991 }),
    mobile: useMediaQuery({ maxWidth: 767 }),
  };

  const navigate = useNavigate();
  const [audio, setAudio] = useState(false);
  const [webcamEnabled, setWebCamEnabled] = useState(_webcamEnabled);
  const [diagnostics, setDiagnosticsModal] = useState(false);
  const { profile } = useSelector((state: RootState) => state.profile);
  const dispatch = useDispatch();
  const [ip, setIP] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIP(res.data.IPv4);
  };

  const joinCall = () => {
    if (type === "doctor") {
      (async function () {
        try {
          dispatch(
            submitCallLogs({
              event: "join",
              user_type: type,
              room: roomId,
              user_id: profile?.id,
              device_type: "Browser",
              device_name: window.navigator.appCodeName,
              device_ip: ip,
              device_os: window.navigator.appVersion,
              reason: "Joined on Web",
            })
          );

          if (appointment.latest_status === "consulting") {
            setWebCamEnabled(false);
            showAppointmentDetails && showAppointmentDetails(appointment);
            cancelVideoModal && cancelVideoModal();
            localStorage.setItem(
              "vc-route",
              ROUTE_DOCTOR_APPOINTMENTS_CALL(roomId, type, appointment_id)
            );
            dispatch(
              setVideoRoom({
                id: roomId,
                source: type,
                appointment: appointment_id,
              })
            );
          } else {
            let _appointmentFinal = {
              status: "consulting",
            };
            const response = await dispatch(
              updateAppointmentById({
                id: appointment_id,
                data: _appointmentFinal,
              })
            );
            if (response.payload.status === 200) {
              setWebCamEnabled(false);
              showAppointmentDetails && showAppointmentDetails(appointment);
              cancelVideoModal && cancelVideoModal();
              localStorage.setItem(
                "vc-route",
                ROUTE_DOCTOR_APPOINTMENTS_CALL(roomId, type, appointment_id)
              );
              dispatch(
                setVideoRoom({
                  id: roomId,
                  source: type,
                  appointment: appointment_id,
                })
              );
            }
          }
        } catch (e) {
          console.error(e);
        }
      })();
    }
    if (type === "guest") {
      (async function () {
        try {
          // dispatch(submitCallLogs({
          //     "event": "waiting",
          //     "user_type": type,
          //     "room": roomId,
          //     "device_type": "Browser",
          //     "device_name": window.navigator.appCodeName,
          //     "device_ip": ip,
          //     "device_os": window.navigator.appVersion
          // }))
          navigate(
            ROUTE_PATIENT_WAITING_AREA_ATTANDANT(
              roomId,
              guestId,
              appointment_id,
              "guest"
            )
          );
        } catch (e) {
          console.error(e);
        }
      })();
    }
    if (type === "patient") {
      (async function () {
        try {
          dispatch(
            submitCallLogs({
              event: "waiting_room_join",
              user_type: type,
              room: roomId,
              user_id: profile?.id,
              device_type: "Browser",
              device_name: window.navigator.appCodeName,
              device_ip: ip,
              device_os: window.navigator.appVersion,
            })
          );
          if (!appointment.enable_check_in) {
            navigate(ROUTE_PATIENT_WAITING_AREA(roomId, type, appointment_id));
          } else {
            const response = await dispatch(checkinAppointment(appointment_id));
            if (response.payload.status === 201) {
              navigate(
                ROUTE_PATIENT_WAITING_AREA(roomId, type, appointment_id)
              );
            }
          }
        } catch (e) {
          console.error(e);
        }
      })();
    }
  };
  //console.log(_webcamEnabled)
  return (
    <Row justify="center">
      <Col span={24} className={"mt-20"} style={{ maxHeight: "340px" }}>
        {webcamEnabled ? (
          <Webcam
            mirrored
            audio={audio}
            videoConstraints
            height={"340px"}
            style={{ width: "100%" }}
          />
        ) : (
          <div
            style={{ width: "100%", height: "340px", backgroundColor: "black" }}
          ></div>
        )}
      </Col>
      <Col
        className="doctor-light-background padding20 border-radius mt20"
        span={24}
      >
        <Row justify="center" align="middle">
          <Col span={8} className="text-center">
            {audio ? (
              <Button
                className="bgTransparentBtn btnItemCenter border-radius5"
                icon={<MicOnGreen width={34.79} height={34.79} />}
                onClick={() => setAudio(false)}
              />
            ) : (
              <Button
                className="doctor-secondary-button btnItemCenter border-radius5"
                icon={<MicOffwhite width={52} height={52} />}
                onClick={() => setAudio(true)}
              />
            )}
          </Col>
          <Col span={8} className="text-center">
            <Button
              className="bgTransparentBtn cameraSwitch btnItemCenter border-radius5"
              icon={<CameraSwitch className="" width={34.79} height={34.79} />}
              style={{ cursor: "no-drop" }}
            />
          </Col>
          <Col span={8} className="text-center">
            {webcamEnabled ? (
              <Button
                className="bgTransparentBtn btnItemCenter border-radius5"
                icon={<VideoOnGreen width={34.79} height={34.79} />}
                onClick={() => setWebCamEnabled(false)}
              />
            ) : (
              <Button
                className="doctor-secondary-button btnItemCenter border-radius5"
                icon={<VideoOffGreen width={52} height={52} />}
                onClick={() => setWebCamEnabled(true)}
              />
            )}
          </Col>
        </Row>
      </Col>
      {/* <Col span={24} className="mt10 text-center">
                <Text className="doctor-secondary-color cursor font16" underline
                    onClick={() => setDiagnosticsModal(true)}>Test Audio and Video</Text>
            </Col> */}
      <Col span={24} className="mt10 text-center">
        <Button
          block
          className="doctor-secondary-button fw6"
          size={"large"}
          style={screenType.mobile ? { height: "50px" } : {}}
          //  disabled={!call_validation?.validationStatus}
          onClick={() => joinCall()}
        >
          Join Video Call
        </Button>
      </Col>

      <Modal
        className={"doctor-app-dark-modal"}
        visible={diagnostics}
        footer={false}
        centered
        closable
        onCancel={() => setDiagnosticsModal(false)}
        title={"Test your Audio and Video"}
      >
        <Diagnostics></Diagnostics>
      </Modal>
    </Row>
  );
}

export default VideoVerification;
