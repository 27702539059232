import React from "react";

const FormElements = {};
class Textarea extends React.Component {
  render() {
    const { placeholder, label, value, labelSize } = this.props.element;
    // labelSize empty => show input in next line
    //labelSize not empty => show input in same line as label
    return (
      <div className={!labelSize ? "col-display-block" : ""}>
        <label> {label}</label>
        <input
          disabled
          className={labelSize ? "ml-3" : ""}
          type="textarea"
          placeholder={placeholder}
          value={value}
        />
      </div>
    );
  }
}

class Hidden extends React.Component {
  render() {
    const { placeholder, label, value, labelSize } = this.props.element;
    // labelSize empty => show input in next line
    //labelSize not empty => show input in same line as label
    return (
      <div className={!labelSize ? "col-display-block" : ""}>
        <label> {label}(Will be hidden)</label>
        <input
          disabled
          className={labelSize ? "ml-3" : ""}
          type="textbox"
          placeholder={placeholder}
          value={value}
        />
      </div>
    );
  }
}

class Time extends React.Component {
  render() {
    const { placeholder, label, value, labelSize } = this.props.element;
    // labelSize empty => show input in next line
    //labelSize not empty => show input in same line as label
    return (
      <div className={!labelSize ? "col-display-block" : ""}>
        <label>{label}</label>
        <input
          className={labelSize ? "ml-3" : ""}
          type="time"
          disabled
          placeholder={placeholder}
          value={value}
        />
      </div>
    );
  }
}

class Textbox extends React.Component {
  render() {
    const { placeholder, label, value, labelSize } = this.props.element;
    // labelSize empty => show input in next line
    //labelSize not empty => show input in same line as label
    return (
      <div className={!labelSize ? "col-display-block" : ""}>
        <label>{label}</label>
        <input
          className={labelSize ? "ml-3" : ""}
          disabled
          type="textbox"
          placeholder={placeholder}
          value={value}
        />
      </div>
    );
  }
}

class TextFormatter extends React.Component {
  render() {
    const { placeholder, label, value, pattern, labelSize } =
      this.props.element;
    // labelSize empty => show input in next line
    //labelSize not empty => show input in same line as label
    return (
      <div className={!labelSize ? "col-display-block" : ""}>
        <label>{label}</label>
        <input
          className={labelSize ? "ml-3" : ""}
          disabled
          pattern={pattern}
          type="textFormatter"
          value={value}
          placeholder={placeholder}
        />
      </div>
    );
  }
}

class Selection extends React.Component {
  render() {
    const { placeholder, label, value, labelSize } = this.props.element;
    return (
      <div className={labelSize ? "col-display-inline" : ""}>
        <label className={labelSize ? "mr-3 mt-2" : ""}>{label}</label>
        <select
          style={{ width: "55%", borderRadius: "3px", height: "30px" }}
          className="form-control mt-2 mb-2"
          value={value}
        >
          <option disabled value="initial">
            {placeholder ? placeholder : "Select one"}
          </option>
          {this.props.options.map((option, index) => (
            <option key={index} value={option.key}>
              {option.value}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

class Typeahead extends React.Component {
  render() {
    const { placeholder, label, value, labelSize } = this.props.element;
    return (
      <div className={labelSize ? "col-display-inline" : ""}>
        <label className={labelSize ? "mr-3 mt-2" : ""}>{label}</label>
        <select
          style={{ width: "55%", borderRadius: "3px", height: "30px" }}
          className="form-control mt-2 mb-2"
          value={value}
        >
          <option disabled value="initial">
            {placeholder ? placeholder : "Type to see options..."}
          </option>
          {this.props.options.map((option, index) => (
            <option key={index} value={option.key}>
              {option.value}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

class AutoComplete extends React.Component {
  render() {
    const { placeholder, label, value, labelSize } = this.props.element;
    return (
      <div className={labelSize ? "col-display-inline" : ""}>
        <label className={labelSize ? "mr-3 mt-2" : ""}>{label}</label>
        <select
          style={{ width: "55%", borderRadius: "3px", height: "30px" }}
          className="form-control mt-2 mb-2"
          value={value}
        >
          <option disabled value="initial">
            {placeholder ? placeholder : "Select one"}
          </option>
          {this.props.options.map((option, index) => (
            <option key={index} value={option.key}>
              {option.value}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

class Checkbox extends React.Component {
  render() {
    const { label, labelSize } = this.props.element;
    return (
      <div className={labelSize ? "col-display-inline" : ""}>
        <label className={labelSize ? "mr-3 mt-2" : ""}>{label}</label>
        <div className="d-flex" style={{ flexWrap: "wrap" }}>
          {this.props.options &&
            this.props.options.map((option, index) => (
              <div
                key={index}
                className="form-check"
                style={{ marginRight: "15px" }}
              >
                <label className="form-check-label">
                  <input
                    type="checkbox"
                    disabled
                    className="form-check-input"
                    value={option.key}
                    checked={false}
                  />
                  {option.value}
                  <i className="input-helper"></i>
                </label>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

class Radio extends React.Component {
  render() {
    const { label, labelSize } = this.props.element;
    return (
      <div className={labelSize ? "col-display-inline" : ""}>
        <label className={labelSize ? "mr-3 mt-2" : ""}>{label}</label>
        <div className="d-flex" style={{ flexWrap: "wrap" }}>
          {this.props.options &&
            this.props.options.map((option, index) => (
              <div
                key={index}
                className="form-check"
                style={{ marginRight: "15px" }}
              >
                <label className="form-check-label">
                  <input
                    type="radio"
                    disabled
                    className="form-check-input"
                    value={option.key}
                  />
                  {option.value}
                  <i className="input-helper"></i>
                </label>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

class Date extends React.Component {
  // constructor(props) {
  //     super(props);
  // }

  render() {
    const { placeholder, label, value, labelSize } = this.props.element;
    // labelSize empty => show input in next line
    //labelSize not empty => show input in same line as label
    return (
      <div className={!labelSize ? "col-display-block" : ""}>
        <label>{label}</label>
        <input
          className={labelSize ? "ml-3" : ""}
          disabled
          type="date"
          placeholder={placeholder}
          value={value}
        />
      </div>
    );
  }
}
class Header extends React.Component {
  render() {
    const { label, labelSize } = this.props.element;
    // labelSize empty => show input in next line
    //labelSize not empty => show input in same line as label
    return (
      <div className={!labelSize ? "col-display-block" : ""}>
        <label>{label}</label>
      </div>
    );
  }
}

class Hyper_link extends React.Component {
  render() {
    const { label, labelSize } = this.props.element;
    // labelSize empty => show input in next line
    //labelSize not empty => show input in same line as label
    return (
      <div className={!labelSize ? "col-display-block" : ""}>
        <label>{label}</label>
      </div>
    );
  }
}

class Numeric_pain_score extends React.Component {
  render() {
    const { label, labelSize } = this.props.element;
    // labelSize empty => show input in next line
    //labelSize not empty => show input in same line as label
    return (
      <div className={!labelSize ? "col-display-block" : ""}>
        <label>{label}</label>
      </div>
    );
  }
}
// ClassNames are mapped with Component keys in Constants/Tools.js
FormElements.Textarea = Textarea;
FormElements.Textbox = Textbox;
FormElements.TextFormatter = TextFormatter;
FormElements.Selection = Selection;
FormElements.Typeahead = Typeahead;
FormElements.AutoComplete = AutoComplete;
FormElements.Checkbox = Checkbox;
FormElements.Date = Date;
FormElements.Time = Time;
FormElements.Hidden = Hidden;
FormElements.Radio = Radio;
FormElements.Header = Header;
FormElements.Hyper_link = Hyper_link;
FormElements.Numeric_pain_score = Numeric_pain_score;
export default FormElements;
