import { CalendarOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Drawer, Popover, Row, Typography } from "antd";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { fetchPlacedOrders } from "../../../../redux/reducers/pharmacy.slice";
import { RootState } from "../../../../shared/constants";
import TableLayout from "../../../../shared/layouts/table.layout";

type Props = {
  status?: any;
};

function DiagnosticsTable(props: Props) {
  const dispatch = useDispatch();
  const [audit, setAuditTrail] = useState(false);
  const [rowDetail, setRowDetail] = useState(null as any);
  const [filterData, setFilterData] = useState(null as any);
  const [filters, setFilters] = useState(null as any);

  const { Title, Text } = Typography;
  const { placedOrderSummary, loading, pagination } = useSelector(
    (state: RootState) => state.pharmacy
  );
  const csvLink = useRef("" as any);

  useEffect(() => {
    getDiagnosticsOrders();
  }, []);

  useEffect(() => {
    getDiagnosticsOrders();
  }, [filters]);

  const getDiagnosticsOrders = async () => {
    let payload = {
      order_status: "open",
      order_type: "diagnostics",
      offset: pagination.offset,
      limit: pagination.limit,
      ...filters,
    };
    await dispatch(fetchPlacedOrders(payload));
  };

  const columns = [
    {
      title: "Order Id",
      dataIndex: ["order_id"],
    },
    {
      title: "Details",
      dataIndex: "items",
      render: (key: any) => (
        <Popover
          content={
            <ul>
              {key?.map((item: any) => {
                return <li key={item?.id}>{item?.item_name} </li>;
              })}
            </ul>
          }
        >
          {(key?.length ? key?.length : 0) + " " + "Items"}
        </Popover>
      ),
      width: "100px",
    },
    {
      title: "Source",
      dataIndex: ["source"],
    },
    {
      title: "Patient Details",
      render: (a: any) => {
        return (
          <>
            <span>
              {a.patient
                ? a?.patient?.pii?.first_name + " " + a?.patient?.pii?.last_name
                : ""}
            </span>
            <br />
            {a?.patient?.pii?.mobile}
          </>
        );
      },
      width: "150px",
    },
    {
      title: "Invoice Details",
      render: (a: any) => {
        return (
          <>
            <span>Rs {a?.total_amount}</span>
            <br />
            {a?.bill_number ? `Invoice: ` + a?.bill_number : ""}
          </>
        );
      },
    },
    {
      title: "Order Date",
      render: (key: any) =>
        moment(key?.created_at).format("DD MMM'YY, hh:mm A"),
      width: "170px",
    },
    {
      title: "Service Date",
      render: (key: any) =>
        key?.service_date ? moment(key?.service_date).format("DD MMM'YY") : "",
    },
    {
      title: "Slot",
      render: (key: any) => key?.slot,
    },
    {
      title: "Request ID",
      render: (key: any) => key?.external_id,
    },
    {
      title: "UHID",
      render: (key: any) => key?.patient?.uhid,
    },
    {
      title: "Assigned",
      render: (a: any) => {
        return (
          <>
            <span>{a.assigned_to ? a.assigned_to.split("::")[0] : ""}</span>
            <br />
            {a.assigned_to
              ? a.assigned_to.split("::")[1] == "null"
                ? ""
                : a.assigned_to.split("::")[1]
              : ""}
          </>
        );
      },
      width: "150px",
    },
    {
      title: "Status",
      render: (key: any) => key?.order_status,
    },
    {
      title: "Updated On",
      render: (key: any) =>
        key?.updated_at
          ? moment(key?.updated_at).format("DD MMM'YY, hh:mm A")
          : "",
    },
    {
      title: "Action",
      key: "action",
      render: (a: any) => (
        <Button type={"link"} onClick={() => handleAudit(a)}>
          Audit Trail
        </Button>
      ),
    },
  ];

  const handleAudit = async (a: any) => {
    setAuditTrail(true);
    setRowDetail(a);
  };

  const formElements = {
    onFinish: () => {},
    onFinishFailed: () => {},
    onValuesChange: filterFormSubmit,
    initialValues: {},
    setValues: {
      ...filters,
      dateVal: filters?.start_date &&
        filters?.end_date && [
          moment(filters?.start_date),
          moment(filters?.end_date),
        ],
    },
    formElements: [
      {
        label: "",
        type: "RangePicker",
        name: "dateVal",
        colSpan: 8,
      },
      {
        label: "",
        type: "Select",
        name: "collection_at",
        colSpan: 5,
        allowClear: true,
        placeholder: "Select Type",
        options: [
          // { name: "All", value: "ALL" },
          { name: "Home", value: "HOME" },
          { name: "Lab", value: "LAB" },
        ],
      },
      {
        label: "",
        type: "Select",
        name: "search_by",
        colSpan: 5,
        allowClear: true,
        placeholder: "Select Option",
        options: [
          { name: "Order ID", value: "order_id" },
          { name: "Bill No", value: "bill_number" },
          { name: "Mobile", value: "mobile" },
          { name: "Digicare ID", value: "external_id" },
        ],
      },
      {
        label: "",
        type: "Input",
        name: "search_value",
        colSpan: 5,
        placeholder: "Search by Option",
        prefix: <SearchOutlined />,
      },
    ],
  };

  function filterFormSubmit(changed: any, all: any) {
    let filtersObj = {} as any;
    let search_by_key;
    for (let key of Object.keys(all)) {
      if (all[key] == "" || all[key] == null || all[key] == undefined) {
      } else {
        if (key === "dateVal") {
          // filtersObj.order_date = moment(all.dateVal).format("YYYY-MM-DD");
          filtersObj.start_date = all.dateVal
            ? all.dateVal[0].format("YYYY-MM-DD")
            : null;
          filtersObj.end_date = all.dateVal
            ? all.dateVal[1].format("YYYY-MM-DD")
            : null;
        } else if (key == "search_value") {
          filtersObj[all.search_by] = all.search_value;
        } else if (key == "search_by") {
          search_by_key = all.search_by;
          delete filtersObj.search_by;
        } else {
          filtersObj[key] = all[key];
        }
      }
      delete filtersObj.search_by;
      delete filtersObj.search_value;
    }
    setFilters(filtersObj);
  }

  const downloadFilterData = (e: any) => {
    setFilterData(e);
    setTimeout(() => {
      csvLink?.current.link.click();
    }, 1000);
  };

  const onPageChange = async (e: any) => {
    let pageNumber = e - 1;
    let payload = {
      order_status: "open",
      order_type: "diagnostics",
      offset: pageNumber * 10,
      limit: pagination.limit,
      ...filters,
    };
    await dispatch(fetchPlacedOrders(payload));
  };

  return (
    <div>
      <CSVLink
        data={placedOrderSummary}
        className="hidden"
        // onClick={() => downloadFilterData}
        filename={"diagnostics.csv"}
        ref={csvLink}
        target="_blank"
      ></CSVLink>
      <Row className="padding10">
        <Col span={24}>
          <TableLayout
            loading={loading}
            dataSource={placedOrderSummary}
            columns={columns}
            onChange={(e) => onPageChange(e)}
            downloadFilterData={downloadFilterData}
            type="diagnosticsSummaryFilter"
            formData={formElements}
            total={pagination.total}
            pagination={{ position: ["none", "none"] }}
            maxWidth={1500}
          />
        </Col>
      </Row>

      {/*  Audit Trail Drawer */}
      <Drawer
        title={`Audit Trail`}
        placement="right"
        width={"80%"}
        onClose={() => setAuditTrail(false)}
        visible={audit}
      >
        <Row className="mt20">
          <Col span={24}>
            <Title level={5}>
              {" "}
              <CalendarOutlined />{" "}
              <span className="pdl5">Diagnostic Order Details</span>
            </Title>
          </Col>
        </Row>
        <Row
          className="mt20"
          style={{
            padding: "10px",
            background: "#c9c4c4",
            borderRadius: "5px",
          }}
        >
          <Col span={8} className="pdh20">
            <Text>Collection At: </Text>
            <Text className="sub-heading capitalize">
              {rowDetail?.collection_at}
            </Text>
          </Col>
          <Col span={8} className="pdh5">
            <Text>Order Date: </Text>
            <Text className="sub-heading">
              {moment(rowDetail?.order_date)
                .subtract("330", "m")
                .format("DD MMM'YY,hh:mm A")}
            </Text>
          </Col>
          <Col span={8} className="pdh20">
            <Text>Total: </Text>
            <Text className="sub-heading">
              Rs. {rowDetail?.total_amount}
              {/* <Tag color="cyan">Payment Pending</Tag> */}
            </Text>
          </Col>
        </Row>
        <Row className="mt20">
          <Col span={18} className="pdh20">
            <Text className="sub-heading">Lab Test Details</Text>
          </Col>
          <Col span={6} className="pdh20">
            <Text className="sub-heading">Price </Text>
          </Col>
        </Row>
        <Row className="mt10">
          {rowDetail?.items?.map((item: any, index: any) => (
            <>
              <Col span={18} className="pdh20">
                <Text>
                  {index + 1}. {item?.item_name}
                </Text>
              </Col>
              <Col span={6} className="pdh20">
                <Text>Rs. {item?.item_amount} </Text>
              </Col>
            </>
          ))}
        </Row>
        {/* <Row className="mt10">
                    <Col span={18} className='pdh20'>
                        <Text type='secondary'>Discount</Text>
                    </Col>
                    <Col span={6} className='pdh20'>
                        <Text type='secondary'>-Rs. 50 </Text>
                    </Col>
                </Row> */}
        <Divider />
        <Row className="mt10">
          <Col span={18} className="pdh20">
            <Text type="secondary">Patient Name</Text>
          </Col>
          <Col span={6} className="pdh20">
            <Text type="secondary">
              {rowDetail?.patient?.pii?.first_name +
                " " +
                rowDetail?.patient?.pii?.last_name}
            </Text>
          </Col>
        </Row>
        <Row className="mt10">
          <Col span={18} className="pdh20">
            <Text type="secondary">Collection Date & Time</Text>
          </Col>
          <Col span={6} className="pdh20">
            <Text type="secondary">
              {rowDetail?.service_date
                ? moment(rowDetail?.service_date).format("DD MMM'YY, hh:mm A")
                : ""}
            </Text>
          </Col>
        </Row>
        <Row className="mt10">
          <Col span={18} className="pdh20">
            <Text type="secondary">Collection Address</Text>
          </Col>
          <Col span={6} className="pdh20">
            <Text type="secondary"> {rowDetail?.address}</Text>
          </Col>
        </Row>
      </Drawer>
    </div>
  );
}

export default DiagnosticsTable;
