import React from "react";
import ValidationComponent from "./ValidationComponent";

import { InfoCircleOutlined } from "@ant-design/icons";
import { Col, Input, Tooltip } from "antd";
import "./tooltip.css";

class TextboxComponent extends React.Component {
  constructor(props) {
    super(props);
    this.changeValue = this.changeValue.bind(this);
    this.onFocus = this.onFocus.bind(this);
  }

  changeValue = (event) => {
    event.preventDefault();
    this.props.emitTextboxValue({
      name: event.target.name,
      value: event.target.value,
    });
  };

  onFocus = (event) => {
    event.preventDefault();
    this.props.emitFocusEvent();
  };

  textToHtmlConversion(text) {
    return { __html: text };
  }

  setErrorStatus = (errorStatus) => {
    this.props.emitErrorStatus(errorStatus);
  };

  checkForLabelValue(str) {
    if (!str || !str.trim()) {
      return false;
    }
    return true;
  }

  render() {
    // console.log('-----------------------------------textbox---------------------------------');
    const {
      type = "textbox",
      name,
      placeholder,
      label,
      labelSize,
      size,
      value,
      readOnly,
      offset,
      showType,
      showUnit,
      info,
      viewType,
      validation,
      bold_label,
      bold_value,
    } = this.props;
    let label_style = { color: "#000000d9" };
    if (bold_label) {
      label_style.fontWeight = 500;
    }
    // validation={this.props.validation}
    return (
      <>
        {labelSize && (
          <>
            {viewType ? (
              <>
                {value ? (
                  <>
                    <div
                      className={`ant-col ant-col-${labelSize * 2} `}
                      style={{
                        textAlign: "left",
                        paddingRight: 10,
                        display:
                          this.checkForLabelValue(label) || value ? "" : "none",
                      }}
                    >
                      <label
                        for="basic_username"
                        className="ant-form-item"
                        title="Username"
                        style={label_style}
                      >
                        {label}
                      </label>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <div
                className={`ant-col ant-col-${labelSize * 2} `}
                style={{
                  textAlign: "left",
                  paddingRight: 10,
                  display:
                    this.checkForLabelValue(label) || value ? "" : "none",
                }}
              >
                <label
                  for="basic_username"
                  className="ant-form-item"
                  title="Username"
                  style={label_style}
                >
                  {label}
                  {validation && validation.required && (
                    <span style={{ color: "#ff4d4f" }}>*</span>
                  )}
                </label>
              </div>
            )}
          </>
        )}
        <Col
          span={`${showType !== "xyTable" && size ? size * 2 : ""} 
                ${showType === "table" ? "p-0" : ""}`}
          style={{ paddingRight: "10px" }}
        >
          {!labelSize && (
            <label
              className={"col-form-label mb-0"}
              style={{
                display: this.checkForLabelValue(label) ? "" : "none",
                color: "#000000d9",
              }}
            >
              {label}
              {showUnit && (
                <sub style={{ bottom: 0, marginLeft: "3px" }}>({showUnit})</sub>
              )}
            </label>
          )}
          {readOnly ? (
            <label
              className="form-label"
              style={bold_value ? label_style : {}}
              dangerouslySetInnerHTML={this.textToHtmlConversion(value)}
            ></label>
          ) : (
            <>
              <div style={{ display: info ? "flex" : "", width: "90%" }}>
                {viewType ? (
                  <>
                    {value} &nbsp;
                    {viewType && showUnit && value ? (
                      <>
                        <span>{showUnit}</span>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <>
                    {/* <Form.Item
                                        label={label}
                                        name={name}
                                        rules={[{ required: true, message: `Please input your ${name} !` }]}
                                    > */}
                    <Input
                      className="form-control"
                      id={name}
                      name={name}
                      type={type}
                      disabled={readOnly ? true : false}
                      value={value}
                      placeholder={placeholder ? placeholder : showUnit}
                      onChange={this.changeValue}
                      onFocus={this.onFocus}
                      suffix={<>{showUnit && <span>{showUnit}</span>}</>}
                      prefix={
                        <>
                          {info && (
                            <Tooltip title={info}>
                              <InfoCircleOutlined
                                style={{ color: "rgba(0,0,0,.45)" }}
                              />
                            </Tooltip>
                          )}
                        </>
                      }
                    />
                    {/* </Form.Item> */}
                  </>
                )}
              </div>
              {this.props.validation && (
                <ValidationComponent
                  value={value}
                  checkForm={this.props.checkForm}
                  isDirty={this.props.isDirty}
                  validation={this.props.validation}
                  emitErrorStatus={this.setErrorStatus.bind(this)}
                />
              )}
            </>
          )}
        </Col>

        {offset && <div className={offset}></div>}
      </>
    );
  }
}

export default TextboxComponent;
