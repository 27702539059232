import "../styles/template.list.scss";
import Eq5d5QuestionnaireData from "./Eq5d5QuestionnaireData";
import KoovsQuestionnaireData from "./KoovsQuestionnaireData";

function TemplateList(props: any) {
  const { selectedInfo } = props;

  const capitalizeFirstLetter = (text: string) => {
    if (!text) return "";
    return text
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const findMaxNumber = (daysInputs: any) => {
    if (selectedInfo?.frequency_values) {
      const numericValues = daysInputs?.map((input: any) => Number(input));
      const highestValue = Math.max(...numericValues);
      return highestValue;
    }
  };

  return (
    <div className="specialties_action_container">
      <div className="top_action_container_temp">
        <div className="template_department_container">
          <div>
            {selectedInfo?.form_name && (
              <div className="template_name">
                {capitalizeFirstLetter(selectedInfo?.form_name)}
              </div>
            )}
            {selectedInfo?.department && (
              <div className="department_name">
                {capitalizeFirstLetter(selectedInfo?.department)}
              </div>
            )}

            {selectedInfo?.procedures && (
              <div className="procedure_name_container">
                Procedures:
                {selectedInfo.procedures.map(
                  (
                    procedure: {
                      procedure_name: string;
                      procedure_id: number;
                    },
                    index: number
                  ) => (
                    <div
                      key={procedure.procedure_id}
                      className="procedure_name"
                    >
                      {procedure.procedure_name}
                      {index !== selectedInfo.procedures.length - 1 && ","}
                    </div>
                  )
                )}
              </div>
            )}
          </div>
          {/* <div className="specialties_action_doctors">
            {selectedInfo?.doctor?.map((doc: any, index: any) => (
              <div className="doctor_name" key={index}>
                {doc?.name}
              </div>
            ))}
          </div> */}
        </div>
        {selectedInfo?.frequency_values && (
          <div className="specialties_action_days_container_temp">
            <div className="total_days">
              <h1>Post Discharge Days</h1>
              <span>{findMaxNumber(selectedInfo?.frequency_values)}</span>
            </div>
            <div className="specialties_action_days">
              {selectedInfo?.frequency_values
                ?.slice()
                ?.sort((a: any, b: any) => a - b)
                ?.map((day: any, index: any) => (
                  <div className="days" key={index}>
                    <span className="days_text">Day</span>
                    <span className="days_value">{day}</span>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
      <div className="bottom_action_container">
        <div className="select_patients_container"></div>
        {selectedInfo?.score_system && (
          <div className="display_form_type">
            {selectedInfo?.score_system === "koos"
              ? `Koos Template`
              : `EQ-5D-5L Template`}
          </div>
        )}

        {selectedInfo?.score_system === "koos" && (
          <div className="template_form_view">
            <div className="question_container_list">
              {Object.keys(selectedInfo?.form_template?.formdata).map(
                (sectionKey) => {
                  const section =
                    selectedInfo?.form_template?.formdata[
                      sectionKey as keyof any
                    ];

                  return (
                    <KoovsQuestionnaireData
                      key={sectionKey}
                      section={section}
                      sectionKey={sectionKey}
                    />
                  );
                }
              )}
            </div>
          </div>
        )}
        {selectedInfo?.score_system === "eq" && (
          <div className="template_form_view">
            <div className="question_container_list">
              {Object.keys(selectedInfo?.form_template?.formdata).map(
                (sectionKey) => {
                  const section =
                    selectedInfo?.form_template?.formdata[
                      sectionKey as keyof any
                    ];
                  return (
                    <Eq5d5QuestionnaireData
                      key={sectionKey}
                      section={section}
                      sectionKey={sectionKey}
                    />
                  );
                }
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default TemplateList;
