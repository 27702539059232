import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import "../styles/loading.prom.scss";

function LoadingProm() {
  return (
    <div className="loading_prom">
      <Spin
        indicator={
          <LoadingOutlined
            style={{
              fontSize: 48,
            }}
            spin
          />
        }
      />
    </div>
  );
}

export default LoadingProm;
