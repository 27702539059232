import React, { useEffect, useState } from "react";
import { Card, Row, Col, Input, Button, Space, message } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { getAge } from "../../../../shared/Utils/utilities";
import {
  addAppointemntNote,
  updateAppointemntNote,
} from "../../../../redux/reducers/appointment.slice";
import { useDispatch } from "react-redux";
import { ReactComponent as BackIcon } from "../../../../assets/icons/backicon.svg";
const { TextArea } = Input;

type Props = {
  appointment?: any;
  onBack?: (values: any) => void;
  showBack?: any;
  details?: any;
};

const PrepareCaseSummary: React.FunctionComponent<Props> = ({
  showBack,
  appointment,
  onBack,
  details,
}) => {
  const dispatch = useDispatch();
  const [remark, addRemark] = useState(null as any);
  const addAppointmentNote = (appointment: any) => {
    let remarksExists = appointment?.notes.filter(
      (appointment: any) => appointment.type === "case_summary"
    );
    if (remarksExists.length > 0) {
      dispatch(
        updateAppointemntNote({
          id: remarksExists[0].id,
          data: {
            note: remark,
            appointment_id: appointment.id,
            type: "case_summary",
            note_by: "my team",
          },
        })
      ).then((val: any) => {
        if (val?.payload?.data?.statusCode == 200) {
          message.success("Updated Successfully");
          onBack && onBack(false);
        }
      });
    } else {
      dispatch(
        addAppointemntNote({
          note: remark,
          appointment_id: appointment.id,
          type: "case_summary",
          note_by: "my team",
        })
      ).then((val: any) => {
        if (val?.payload?.data?.statusCode == 200) {
          message.success("Added Successfully");
          onBack && onBack(false);
        }
      });
    }
  };
  useEffect(() => {
    if (appointment && appointment?.notes.length !== 0) {
      let note = appointment?.notes.filter(
        (appointment: any) => appointment.type === "case_summary"
      );
      if (note.length !== 0) {
        addRemark(note[0].note);
      }
    }
  }, [appointment]);

  return (
    <Row className="noborder fullheight doctor-light-background">
      <Col span={24} className="white-background padding10">
        <Row>
          <Col span={1}>
            <p className="doctor-primary-color bold mt10">
              {showBack && (
                // <LeftOutlined style={{ fontSize: "20px" }} onClick={() => onBack && onBack(false)} className="cursor" />
                <BackIcon
                  style={{ fontSize: "20px" }}
                  onClick={() => onBack && onBack(false)}
                  className="cursor"
                />
              )}
            </p>
          </Col>
          <Col span={20}>
            <p className="doctor-primary-color bold mb-10 ">
              {" "}
              Case Summary <br></br>
              <span className="doctor-primary-color bold cursor mt-10 capitalize">
                {details?.pii?.first_name} {details?.pii?.last_name}{" "}
                <small className="sub-text light-text">
                  ({details?.dob && getAge(details?.dob)} ,{" "}
                  {details?.genderMaster?.name})
                </small>
              </span>
            </p>
          </Col>
        </Row>
      </Col>
      <Col span={24} className="padding20">
        <Row className={"doctor-box-shadow doctor-top-border"}>
          <Col span={24} className="doctor-color-heading">
            <Row>
              <Col span={24}>
                <p className="doctor-primary-color bold mb-10">
                  Prepare Case Summary
                </p>
              </Col>
            </Row>
          </Col>
          <Col span={24} className="white-background padding20">
            <TextArea
              value={remark}
              onChange={(evt) => addRemark(evt.target.value)}
              rows={30}
              placeholder={"Type here"}
            ></TextArea>
          </Col>
        </Row>
        <Button
          block
          target={"_blank"}
          size={"large"}
          className={"mt20 doctor-secondary-button"}
          onClick={() => addAppointmentNote(appointment)}
        >
          Save
        </Button>
      </Col>
    </Row>
  );
};

export default PrepareCaseSummary;
