import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";
import { ACCOUNT_PREFIX, RootState } from "../../shared/constants";
import {
  ACCOUNT_BY_DOMAIN,
  APPLICATIONS,
  ATTEMPT_OTP_LOGIN,
  ATTEMPT_OTP_LOGIN_ATTANDANT,
  ATTEMPT_PIN,
  CHANGE_PASSWORD_PIN,
  COORDINATOR_SWITCH,
  FORGET_PASSWORD_PIN,
  FORGET_PASSWORD_WITH_OTP,
  LOGOUT,
  MY_PERMISSIONS,
  REGISTER_DEVICE,
  REMOVE_DEVICE,
  RESET_OTP_WITH_PASSWORD,
  SELF_NOTIFICATION_PREFERENCE,
  SET_PASSWORD_PIN,
  SWITCH_APPLICATION,
  USERS_SELF,
  VALIDATE_OTP_LOGIN,
  VALIDATE_OTP_LOGIN_ATTANDANT,
  VALIDATE_OTP_LOGIN_PATIENT,
  VALIDATE_OTP_LOGIN_WITH_IMPERSONATE,
} from "../../shared/routes/route.constants";
import { Client } from "../../shared/Utils/api-client";

const config = {
  name: "auth",
};
export const fetchApplications: any = createAsyncThunk(
  `${config.name}/fetchApplications`,
  async () => {
    return await Client.get(APPLICATIONS());
  }
);
export const fetchSelf: any = createAsyncThunk(
  `${config.name}/fetchSelf`,
  async () => {
    return await Client.get(USERS_SELF);
  }
);
export const fetchMyPermissions: any = createAsyncThunk(
  `${config.name}/fetchMyPermissions`,
  async () => {
    return await Client.get(MY_PERMISSIONS);
  }
);
export const fetchAccountByDomain: any = createAsyncThunk(
  `${config.name}/fetchAccountByDomain`,
  async () => {
    return await Client.get(
      ACCOUNT_BY_DOMAIN(window.location.pathname.split("/")[1])
    );
  }
);
export const switchApplicationToken: any = createAsyncThunk(
  `${config.name}/switchApplicationToken`,
  async (data: any) => {
    return await Client.get(SWITCH_APPLICATION(data.application_id));
  }
);
export const attemptOTPLogin: any = createAsyncThunk(
  `${config.name}/attemptOTPLogin`,
  async (data: { type: string; username: string }) => {
    return await Client.post(ATTEMPT_OTP_LOGIN, data);
  }
);

export const forgetPasswordWithOTP: any = createAsyncThunk(
  `${config.name}/forgetPasswordWithOTP`,
  async (data: { type: string; username: string }) => {
    return await Client.post(FORGET_PASSWORD_WITH_OTP, data);
  }
);

export const attemptOTPLoginAttandant: any = createAsyncThunk(
  `${config.name}/attemptOTPLoginAttandant`,
  async (id: any) => {
    return await Client.post(ATTEMPT_OTP_LOGIN_ATTANDANT(id), {});
  }
);

export const validateOTPLoginAttandant: any = createAsyncThunk(
  `${config.name}/validateOTPLoginAttandant`,
  async (data: any) => {
    return await Client.post(VALIDATE_OTP_LOGIN_ATTANDANT(data.id), data.data);
  }
);

export const setPassword: any = createAsyncThunk(
  `${config.name}/setPassword`,
  async (data: { password: string; type: string }) => {
    return await Client.post(SET_PASSWORD_PIN, data);
  }
);
export const changePassword: any = createAsyncThunk(
  `${config.name}/changePassword`,
  async (data: { oldPassword: string; password: string; type: string }) => {
    return await Client.post(CHANGE_PASSWORD_PIN, data);
  }
);
export const forgetPassword: any = createAsyncThunk(
  `${config.name}/forgetPassword`,
  async (data: { oldPassword: string; password: string; type: string }) => {
    return await Client.post(FORGET_PASSWORD_PIN, data);
  }
);
export const setSelfNotificationPreference: any = createAsyncThunk(
  `${config.name}/setSelfNotificationPreference`,
  async (data: { oldPassword: string; password: string; type: string }) => {
    return await Client.post(SELF_NOTIFICATION_PREFERENCE, data);
  }
);
export const fetchSelfNotificationPreference: any = createAsyncThunk(
  `${config.name}/fetchSelfNotificationPreference`,
  async () => {
    return await Client.get(SELF_NOTIFICATION_PREFERENCE);
  }
);
export const validateOTP: any = createAsyncThunk(
  `${config.name}/validateOTP`,
  async (
    data: {
      session_id: string;
      user_id: string;
      otp: string;
      impersonate: boolean;
      application_id: string;
      current_application: string;
    },
    { getState }
  ) => {
    const {
      auth: { applications, currentApplication },
    }: RootState = getState() as RootState;
    let application_id = applications.find(
      (a: any) => a.slug === currentApplication
    )?.id;
    if (data.application_id !== application_id) {
      // console.log(`state mismatch appliation changed from ${data.application_id} to ${application_id} incoming slug ${data.current_application} cur slug ${currentApplication}`)
      application_id = data.application_id;
      // if (data.current_application) {
      //     const dispatch = useDispatch();
      //     await dispatch(switchApplication(data.current_application));
      // }
    }
    return await Client.post(
      data?.impersonate
        ? VALIDATE_OTP_LOGIN_WITH_IMPERSONATE
        : VALIDATE_OTP_LOGIN,
      {
        ...data,
        application_id,
      }
    );
  }
);

export const attemptPin: any = createAsyncThunk(
  `${config.name}/attemptPin`,
  async (data: any) => {
    return await Client.post(
      ATTEMPT_PIN,
      {
        ...data,
      },
      { shouldHide: false }
    );
  }
);
export const coordinatorSwitch: any = createAsyncThunk(
  `${config.name}/coordinatorSwitch`,
  async (doctorId: string) => {
    console.log(COORDINATOR_SWITCH(doctorId));
    return Client.post(COORDINATOR_SWITCH(doctorId), {});
  }
);
export const registerDevice: any = createAsyncThunk(
  `${config.name}/registerDevice`,
  async (data: any) => {
    const headers: any = { "Content-Type": "application/json" };

    if (localStorage.getItem("token")) {
      headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
    }

    const config = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    };
    return await window.fetch(REGISTER_DEVICE, config);
  }
);

export const validatePatientOTP: any = createAsyncThunk(
  `${config.name}/validatePatientOTP`,
  async (data: { session_id: string; otp: string }, { getState }) => {
    const {
      auth: { applications, currentApplication },
    }: RootState = getState() as RootState;
    return await Client.post(VALIDATE_OTP_LOGIN_PATIENT, {
      ...data,
      application_id: applications.find(
        (a: any) => a.slug === currentApplication
      )?.id,
    });
  }
);
export const resetOTPWithPassword: any = createAsyncThunk(
  `${config.name}/resetOTPWithPassword`,
  async (data: any, { getState }) => {
    return await Client.post(RESET_OTP_WITH_PASSWORD, {
      ...data,
    });
  }
);

export const logoutUser: any = createAsyncThunk(
  `${config.name}/logoutUser`,
  async (data: any) => {
    return await Client.delete(LOGOUT(data.id), {});
  }
);

export const clearNotificationDevice: any = createAsyncThunk(
  `${config.name}/clearNotificationDevice`,
  async (data: any) => {
    const headers: any = { "Content-Type": "application/json" };

    if (localStorage.getItem("token")) {
      headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
    }

    const config = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    };
    return await window.fetch(REMOVE_DEVICE, config);
  }
);

//CRUD

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    loggingIn: false,
    loadingApplications: false,
    loadingAccount: false,
    applications: [] as any[],
    userNotificationPreferences: [] as any[],
    otpSessionId: null,
    error: "",
    currentApplication: "",
    user: null,
    impersonatedUser: null,
    settingUserNotificationPreference: false,
    myPermissions: null,
    account: null,
    token: null,
  },
  reducers: {
    logout: (state) => {
      state.user = null;
      logOutProviders();
      localStorage.clear();
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
      localStorage.setItem("token", action.payload);
    },
    resetOTPSessionId: (state) => {
      state.otpSessionId = null;
    },
    switchApplication: (state, action) => {
      state.currentApplication = action.payload.slug;
      localStorage.setItem("application_id", action.payload.application_id);
      localStorage.setItem("application_slug", action.payload.slug);
    },
    setUserImpersonation: (state, { payload }) => {
      console.log(payload);
      state.impersonatedUser = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAccountByDomain.pending, (state) => {
        state.loadingAccount = true;
        state.account = null;
      })
      .addCase(fetchAccountByDomain.fulfilled, (state, action) => {
        state.loadingAccount = false;
        state.account = action.payload?.data?.data;
      })
      .addCase(switchApplicationToken.fulfilled, (state, action) => {
        const applicationId = action?.meta?.arg?.application_id;
        state.token = action.payload?.data?.data?.access_token;
        if (state.token) {
          state.currentApplication = state.applications?.find(
            (s: any) => s.id === applicationId
          )?.slug;
          localStorage.setItem(
            "token",
            action.payload?.data?.data?.access_token
          );
          localStorage.setItem(
            "application_slug",
            state.applications?.find((s: any) => s.id === applicationId)?.slug
          );
        }
        // const dispatch = useDispatch();
        // dispatch(fetchSelf());
      })
      .addCase(fetchSelf.pending, (state) => {})
      .addCase(fetchSelf.fulfilled, (state, action) => {
        state.user = action.payload?.data?.data;
      })
      .addCase(fetchSelf.rejected, (state) => {
        state.user = null;
        // const dispatch = useDispatch();
        // dispatch((logout()))
      })
      .addCase(fetchSelfNotificationPreference.fulfilled, (state, action) => {
        state.userNotificationPreferences = action.payload?.data?.data;
        state.settingUserNotificationPreference = false;
      })
      .addCase(fetchSelfNotificationPreference.rejected, (state, action) => {
        state.userNotificationPreferences = [];
        state.settingUserNotificationPreference = false;
      })
      .addCase(fetchSelfNotificationPreference.pending, (state, action) => {
        state.settingUserNotificationPreference = true;
      })
      .addCase(fetchMyPermissions.fulfilled, (state, action) => {
        state.myPermissions = action.payload?.data?.data;
      })
      .addCase(fetchMyPermissions.rejected, (state) => {
        state.user = null;
        // const dispatch = useDispatch();
        // dispatch((logout()))
      })

      .addCase(fetchApplications.pending, (state) => {
        state.loadingApplications = true;
        state.applications = [];
      })
      .addCase(fetchApplications.fulfilled, (state, action) => {
        state.loadingApplications = false;
        state.applications = action.payload?.data?.data;
      })

      .addCase(attemptOTPLogin.pending, (state) => {
        state.loggingIn = true;
        state.error = "";
      })
      .addCase(attemptOTPLogin.fulfilled, (state, action) => {
        state.loggingIn = false;
        state.otpSessionId = action.payload?.data?.data?.session_id;
      })
      .addCase(attemptOTPLogin.rejected, (state, action) => {
        state.loggingIn = false;
        state.error = action.error.message;
      })

      .addCase(forgetPasswordWithOTP.pending, (state) => {
        state.loggingIn = true;
        state.error = "";
      })
      .addCase(forgetPasswordWithOTP.fulfilled, (state, action) => {
        state.loggingIn = false;
        state.otpSessionId = action.payload?.data?.data?.session_id;
      })
      .addCase(forgetPasswordWithOTP.rejected, (state, action) => {
        state.loggingIn = false;
        state.error = action.error.message;
      })

      .addCase(attemptOTPLoginAttandant.pending, (state) => {
        state.loggingIn = true;
        state.error = "";
      })
      .addCase(attemptOTPLoginAttandant.fulfilled, (state, action) => {
        state.loggingIn = false;
        state.otpSessionId = action.payload?.data?.data?.session_id;
      })
      .addCase(attemptOTPLoginAttandant.rejected, (state, action) => {
        state.loggingIn = false;
        state.error = action.error.message;
      })

      .addCase(validateOTP.pending, (state) => {
        state.loggingIn = true;
        state.error = "";
      })
      .addCase(validateOTP.fulfilled, (state, action) => {
        state.loggingIn = false;
        state.token = action.payload?.data?.data?.access_token;
        localStorage.setItem("token", action.payload?.data?.data?.access_token);
      })
      .addCase(coordinatorSwitch.fulfilled, (state, action) => {
        state.loggingIn = false;
        state.token = action.payload?.data?.data?.access_token;
        localStorage.setItem("token", action.payload?.data?.data?.access_token);
      })
      .addCase(validateOTP.rejected, (state, action) => {
        state.loggingIn = false;
        state.error = action.error.message;
      })

      .addCase(attemptPin.pending, (state) => {
        state.loggingIn = true;
        state.error = "";
      })
      .addCase(attemptPin.fulfilled, (state, action) => {
        state.loggingIn = false;
        state.token = action.payload?.data?.data?.access_token;
        localStorage.setItem("token", action.payload?.data?.data?.access_token);
      })
      .addCase(attemptPin.rejected, (state, action) => {
        state.loggingIn = false;
        state.error = action.error.message;
      })

      //validateOTPLoginAttandant

      .addCase(validateOTPLoginAttandant.pending, (state) => {
        state.loggingIn = true;
        state.error = "";
      })
      .addCase(validateOTPLoginAttandant.fulfilled, (state, action) => {
        state.loggingIn = false;
        state.token = action.payload?.data?.data?.access_token;
        localStorage.setItem("token", action.payload?.data?.data?.access_token);
      })
      .addCase(validateOTPLoginAttandant.rejected, (state, action) => {
        state.loggingIn = false;
        state.error = action.error.message;
      })

      .addCase(validatePatientOTP.pending, (state) => {
        state.loggingIn = true;
        state.error = "";
      })
      .addCase(validatePatientOTP.fulfilled, (state, action) => {
        state.loggingIn = false;
        state.token = action.payload?.data?.data?.access_token;
        localStorage.setItem("token", action.payload?.data?.data?.access_token);
      })
      .addCase(validatePatientOTP.rejected, (state, action) => {
        state.loggingIn = false;
        state.error = action.error.message;
      })

      .addCase(logoutUser.pending, (state) => {
        state.loggingIn = true;
        state.error = "";
      })
      .addCase(logoutUser.fulfilled, (state: any) => {
        logOutProviders();
        localStorage.clear();
        state = {
          loggingIn: false,
          loadingAccount: false,
          otpSessionId: null,
          error: "",
          user: null,
          account: null,
          token: null,
        };

        window.location.href = `/${ACCOUNT_PREFIX}`;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.loggingIn = false;
        state.error = action.error.message;
      });
  },
});

export const {
  logout,
  setUser,
  setUserImpersonation,
  resetOTPSessionId,
  setToken,
  switchApplication,
} = authSlice.actions;

export default authSlice.reducer;

const logOutProviders = async () => {
  if (localStorage.getItem("auth-type")) {
    if (localStorage.getItem("auth-type") === "cognito") {
      try {
        await Auth.signOut();
      } catch (error) {
        console.log("error signing out: ", error);
      }
    }
  }
};
