import { Navigate, Route, Routes } from "react-router-dom";
import Notifications from "../pages/appnotifications.page";
import DoctorCordinator from "../pages/boss/DoctotCordinator/doctorCordinator";
import DoctorAnalyticsPage from "../pages/doctor/Analytics/analytics.page";
import IPDAppointments from "../pages/doctor/Appointments/components/ipd-appointments.page";
import OPDAppointments from "../pages/doctor/Appointments/components/opd-appointments.page";
import DoctorChatsPage from "../pages/doctor/Chats/doctor-chat.page";
import DoctorDashboardPage from "../pages/doctor/Dashboard/doctor-dashboard.page";
import DoctorPatientsList from "../pages/doctor/DoctorPatients/doctor.patients.page";
import DoctorNotification from "../pages/doctor/Notification/notification.page";
import DoctorPermissions from "../pages/doctor/Permissions/permissions.page";
import DoctorProfile from "../pages/doctor/Profile/profile.page";
import PromAnalytics from "../pages/doctor/Prom/prom.analytics";
import PromDoctor from "../pages/doctor/Prom/prom.doctor";
import PromPatient from "../pages/doctor/Prom/prom.patient";
import PromTamplate from "../pages/doctor/Prom/prom.template";
import DoctorReportIssues from "../pages/doctor/Report-Issue/report-issue.page";
import DoctorResetPin from "../pages/doctor/Reset-Issue/reset-pin.page";
import DoctorPrescriptionSetting from "../pages/doctor/Setting-Prescriptions/prescription.page";
import VideoMeetingRoom from "../pages/doctor/Video-Room/video.room.page";
import DoctorDesktopLayout from "../shared/layouts/doctor-desktop.layout";
import {
  ROUTE_DOCTOR_ANALYTICS,
  ROUTE_DOCTOR_APPOINTMENTS,
  ROUTE_DOCTOR_APPOINTMENTS_CALL,
  ROUTE_DOCTOR_CHATS,
  ROUTE_DOCTOR_CORDINATOR,
  ROUTE_DOCTOR_DASHBOARD,
  ROUTE_DOCTOR_NOTIFCATION,
  ROUTE_DOCTOR_PATIENTS,
  ROUTE_DOCTOR_PERMISSIONS,
  ROUTE_DOCTOR_PRESCRIPTION_SETTING,
  ROUTE_DOCTOR_PROFILE,
  ROUTE_DOCTOR_REPORT_ISSUE,
  ROUTE_DOCTOR_RESET_PIN,
  ROUTE_NOTIFICATIONS,
} from "../shared/routes/doctor.routes.constants";
import {
  ROUTE_PROM_ANALYTICS,
  ROUTE_PROM_DOCTOR,
  ROUTE_PROM_PATIENT,
  ROUTE_PROM_TEMPLATE,
} from "../shared/routes/prom.routes.constants";

const ROUTES: any[] = [
  {
    name: "Doctor Dashboard",
    route: ROUTE_DOCTOR_DASHBOARD,
    component: <DoctorDashboardPage />,
  },
  {
    name: "Doctor Appointments OPD",
    route: ROUTE_DOCTOR_APPOINTMENTS("opd"),
    component: <OPDAppointments />,
  },
  {
    name: "Doctor Appointments IPD",
    route: ROUTE_DOCTOR_APPOINTMENTS("ipd"),
    component: <IPDAppointments />,
  },

  {
    name: "Doctor Appointments OPD Call",
    route: ROUTE_DOCTOR_APPOINTMENTS_CALL(":id", "source", ":appointment"),
    component: VideoMeetingRoom,
  },
  {
    name: "Doctor Profile Setting",
    route: ROUTE_DOCTOR_PROFILE,
    component: <DoctorProfile />,
  },
  {
    name: "Doctor Notifications Setting",
    route: ROUTE_DOCTOR_NOTIFCATION,
    component: <DoctorNotification />,
  },
  {
    name: "Doctor Permissions Setting",
    route: ROUTE_DOCTOR_PERMISSIONS,
    component: <DoctorPermissions />,
  },
  {
    name: "Doctor Prescription Setting",
    route: ROUTE_DOCTOR_PRESCRIPTION_SETTING,
    component: <DoctorPrescriptionSetting />,
  },
  {
    name: "Doctor Report Setting",
    route: ROUTE_DOCTOR_REPORT_ISSUE,
    component: <DoctorReportIssues />,
  },
  {
    name: "Doctor Reset Pin Setting",
    route: ROUTE_DOCTOR_RESET_PIN,
    component: <DoctorResetPin />,
  },
  {
    name: "Doctor Analytics",
    route: ROUTE_DOCTOR_ANALYTICS,
    component: <DoctorAnalyticsPage />,
  },

  {
    name: "Doctor Patients",
    route: ROUTE_DOCTOR_PATIENTS,
    component: <DoctorPatientsList />,
  },
  {
    name: "Doctor Chats",
    route: ROUTE_DOCTOR_CHATS,
    component: <DoctorChatsPage />,
  },
  {
    name: "Doctor Notifications",
    route: ROUTE_NOTIFICATIONS(":type"),
    component: <Notifications />,
  },
  {
    name: "Doctor Coordinator",
    route: ROUTE_DOCTOR_CORDINATOR,
    component: <DoctorCordinator />,
  },
  // PROM Routes
  {
    name: "PROM",
    route: ROUTE_PROM_DOCTOR,
    component: <PromDoctor />,
  },
  {
    name: "Prom Dashboard",
    route: ROUTE_PROM_DOCTOR,
    component: <PromDoctor />,
  },
  {
    name: "Prom Analytics",
    route: ROUTE_PROM_TEMPLATE,
    component: <PromTamplate />,
  },
  {
    name: "Prom Patient",
    route: ROUTE_PROM_PATIENT,
    component: <PromPatient />,
  },
  {
    name: "Prom Analytics",
    route: ROUTE_PROM_ANALYTICS,
    component: <PromAnalytics />,
  },
];

const DoctorRoutes = () => {
  const DoctorRoutesPermitted = ROUTES;
  return (
    <DoctorDesktopLayout>
      <Routes>
        {DoctorRoutesPermitted.map((route: any) => {
          return (
            <Route
              path={route.route}
              element={route.component}
              key={route.route}
            />
          );
        })}
        <Route element={<Navigate to={ROUTE_DOCTOR_DASHBOARD} replace />} />
      </Routes>
    </DoctorDesktopLayout>
  );
};
export default DoctorRoutes;
