import { Col, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { redirectUrl } from "../redux/reducers/settings.slice";

export const RedirectPage = () => {
  const dispatch = useDispatch();
  const RouteParams: any = useParams();
  const [loader, setLoader] = useState(true as any);
  const [error, setError] = useState(false as any);

  useEffect(() => {
    (async function () {
      try {
        const redirectURL = await dispatch(redirectUrl(RouteParams["id"]));

        if (redirectURL.payload && redirectURL.payload.status === 200) {
          setLoader(false);
          window.location.href = redirectURL?.payload?.data?.data;
        } else {
          setLoader(false);
          setError(redirectURL?.error?.message);
        }
      } catch (e) {}
    })();
  }, [RouteParams, dispatch]);

  return (
    <Row justify={"center"}>
      {loader ? (
        <Col span={24}>
          <Spin></Spin>
          <p>Redirecting</p>
        </Col>
      ) : (
        <p>{error}</p>
      )}
    </Row>
  );
};
