const ID = require("../../Utils/UUID");

const drug_row = {
  rowId: ID.uuid(),
  rowType: "6columnDrug",
  config: "",
  status: "empty",
  columns: [
    {
      name: "drugType",
      size: "1",
      labelSize: "",
      offset: "",
      placeholder: "Medium",
      type: "typeahead",
      uiData: "drugs_route_uidata",
      value: "",
      conditionalFormulas: [],
      colId: "",
      async: false,
      globalsectionvars: [],
      validation: { required: false },
      values: [],
      updateTimeStamp: "",
      auto: false,
    },
    {
      name: "name",
      size: 2,
      labelSize: "",
      offset: "",
      placeholder: "Enter drug",
      type: "typeahead",
      uiData: "",
      value: "",
      conditionalFormulas: [],
      colId: "",
      async: true,
      globalsectionvars: [],
      validation: { required: false },
      values: [],
      updateTimeStamp: "",
      auto: true,
      autoKey: "drugs",
    },
    {
      name: "dosage",
      type: "textbox",
      placeholder: "x mg",
      size: 1,
      uiData: "",
      conditionalFormulas: [],
      globalsectionvars: [],
      values: [],
      value: "",
      validation: {
        isNumber: false,
        minValue: "",
        maxValue: "",
        maxLength: "",
        minLength: "",
        required: false,
      },
    },
    {
      name: "frequency",
      size: 2,
      labelSize: "",
      offset: "",
      placeholder: "Dosage",
      type: "typeahead",
      uiData: "drugs_frequency_uidata",
      value: "",
      conditionalFormulas: [],
      colId: "",
      async: false,
      globalsectionvars: [],
      validation: { required: false },
      values: [],
      updateTimeStamp: "",
      dataType: "frequency",
    },
    {
      name: "duration",
      type: "textbox",
      placeholder: "No. of days",
      size: 1,
      uiData: "",
      conditionalFormulas: [],
      globalsectionvars: [],
      values: [],
      value: "",
      updateTimeStamp: null,
    },
    {
      name: "comment",
      placeholder: "Any other remarks",
      type: "textarea",
      size: 3,
      rowCount: 1,
      uiData: "",
      conditionalFormulas: [],
      globalsectionvars: [],
      values: [],
      value: "",
      updateTimeStamp: null,
    },
  ],
  children: [],
  displayType: "row",
  type: "row",
  index: 0,
  ismultiple: true,
};

const comment_name_value_row = {
  rowId: ID.uuid(),
  rowType: "",
  config: "",
  status: "empty",
  columns: [
    {
      name: "name",
      size: "3",
      labelSize: "",
      offset: "",
      placeholder: "Enter Complaints",
      type: "typeahead",
      uiData: "",
      value: "",
      conditionalFormulas: [],
      colId: "",
      async: true,
      globalsectionvars: [],
      validation: { required: false },
      values: [],
      updateTimeStamp: "",
      dataType: "name",
      autoKey: "complaints",
      auto: true,
    },
    {
      name: "value",
      placeholder: "value",
      type: "textbox",
      size: 3,
      readOnly: false,
      uiData: "",
      label: "",
      offset: "",
      labelSize: "",
      showUnit: "",
      value: "",
      auto: false,
      conditionalFormulas: [],
      colId: "",
      formula: "",
      globalsectionvars: [],
      validation: {
        isNumber: false,
        minValue: "",
        maxValue: "",
        maxLength: "",
        minLength: "",
        required: false,
      },
      values: [],
    },
    {
      name: "comment",
      placeholder: "Enter Comment",
      type: "textbox",
      size: "3",
      readOnly: false,
      uiData: "",
      offset: "",
      labelSize: "",
      showUnit: "",
      value: "",
      auto: false,
      conditionalFormulas: [],
      colId: "",
      formula: "",
      globalsectionvars: [],
      validation: {
        isNumber: false,
        minValue: "",
        maxValue: "",
        maxLength: "",
        minLength: "",
        required: false,
      },
      values: [],
      dataType: "comment",
      subType: "comment",
    },
  ],
  children: [],
  displayType: "row",
  type: "row",
  updateTimeStamp: null,
};

const group_3_columns = {
  rowId: ID.uuid(),
  rowType: "",
  config: "",
  status: "empty",
  columns: [],
  children: [
    {
      rowId: ID.uuid(),
      rowType: "",
      config: "",
      status: "empty",
      columns: [
        {
          name: "drug",
          placeholder: "Enter A",
          type: "textbox",
          size: 3,
          readOnly: false,
          uiData: "",
          label: "Label A",
          offset: "",
          labelSize: "",
          showUnit: "",
          value: "",
          auto: false,
          conditionalFormulas: [],
          colId: "",
          formula: "",
          globalsectionvars: [],
          validation: {
            isNumber: false,
            minValue: "",
            maxValue: "",
            maxLength: "",
            minLength: "",
            required: false,
          },
          values: [],
        },
        {
          name: "value",
          placeholder: "Enter B",
          type: "textbox",
          size: 3,
          readOnly: false,
          uiData: "",
          label: "Label B",
          offset: "",
          labelSize: "",
          showUnit: "",
          value: "",
          auto: false,
          conditionalFormulas: [],
          colId: "",
          formula: "",
          globalsectionvars: [],
          validation: {
            isNumber: false,
            minValue: "",
            maxValue: "",
            maxLength: "",
            minLength: "",
            required: false,
          },
          values: [],
        },
        {
          name: "comment",
          placeholder: "Enter C",
          type: "textbox",
          size: 3,
          readOnly: false,
          uiData: "",
          label: "Enter C",
          offset: "",
          labelSize: "",
          showUnit: "",
          value: "",
          auto: false,
          conditionalFormulas: [],
          colId: "",
          formula: "",
          globalsectionvars: [],
          validation: {
            isNumber: false,
            minValue: "",
            maxValue: "",
            maxLength: "",
            minLength: "",
            required: false,
          },
          values: [],
        },
      ],
      children: [],
      displayType: "row",
      type: "row",
      updateTimeStamp: null,
    },
  ],
  displayType: "row",
  type: "group",
  updateTimeStamp: null,
  name: "<Group Name>",
  cloningAllowed: true,
};

const compact_textbox_row = {
  rowId: ID.uuid(),
  rowType: "",
  config: "",
  status: "empty",
  columns: [
    {
      name: "value",
      placeholder: "mmHg",
      type: "textbox",
      size: "2",
      readOnly: false,
      uiData: "",
      label: "Blood Pressure",
      offset: "",
      labelSize: "1",
      value: "",
      auto: false,
      conditionalFormulas: [],
      colId: "",
      formula: "",
      globalsectionvars: [],
      validation: {
        isNumber: false,
        minValue: "",
        maxValue: "",
        maxLength: "",
        minLength: "",
        required: false,
      },
      values: [],
    },
    {
      name: "value",
      placeholder: "Enter Pulse rate",
      type: "textbox",
      size: "2",
      readOnly: false,
      uiData: "",
      label: "Pulse",
      offset: "",
      labelSize: "1",
      value: "",
      auto: false,
      conditionalFormulas: [],
      colId: "",
      formula: "",
      globalsectionvars: [],
      validation: {
        isNumber: false,
        minValue: "",
        maxValue: "",
        maxLength: "",
        minLength: "",
        required: false,
      },
      values: [],
    },
    {
      name: "value",
      placeholder: "Enter Height",
      type: "textbox",
      size: "2",
      readOnly: false,
      uiData: "",
      label: "Height",
      offset: "",
      labelSize: "1",
      value: "",
      auto: false,
      conditionalFormulas: [],
      colId: "",
      formula: "",
      globalsectionvars: [],
      validation: {
        isNumber: false,
        minValue: "",
        maxValue: "",
        maxLength: "",
        minLength: "",
        required: false,
      },
      values: [],
    },
    {
      name: "value",
      placeholder: "Enter Weight",
      type: "textbox",
      size: "2",
      readOnly: false,
      uiData: "",
      label: "Weight",
      offset: "",
      labelSize: "1",
      value: "",
      auto: false,
      conditionalFormulas: [],
      colId: "",
      formula: "",
      globalsectionvars: [],
      validation: {
        isNumber: false,
        minValue: "",
        maxValue: "",
        maxLength: "",
        minLength: "",
        required: false,
      },
      values: [],
    },
    {
      name: "value",
      placeholder: "Enter Value",
      type: "textbox",
      size: "2",
      readOnly: false,
      uiData: "",
      label: "Waist",
      offset: "",
      labelSize: "1",
      value: "",
      auto: false,
      conditionalFormulas: [],
      colId: "",
      formula: "",
      globalsectionvars: [],
      validation: {
        isNumber: false,
        minValue: "",
        maxValue: "",
        maxLength: "",
        minLength: "",
        required: false,
      },
      values: [],
    },
    {
      name: "value",
      placeholder: "Enter text",
      type: "textbox",
      size: "2",
      readOnly: false,
      uiData: "",
      label: "Hip",
      offset: "",
      labelSize: "1",
      value: "",
      auto: false,
      conditionalFormulas: [],
      colId: "",
      formula: "",
      globalsectionvars: [],
      validation: {
        isNumber: false,
        minValue: "",
        maxValue: "",
        maxLength: "",
        minLength: "",
        required: false,
      },
      values: [],
    },
    {
      name: "value",
      placeholder: "Enter Temperature",
      type: "textbox",
      size: "2",
      readOnly: false,
      uiData: "",
      label: "Temperature",
      offset: "",
      labelSize: "1",
      value: "",
      auto: false,
      conditionalFormulas: [],
      colId: "",
      formula: "",
      globalsectionvars: [],
      validation: {
        isNumber: false,
        minValue: "",
        maxValue: "",
        maxLength: "",
        minLength: "",
        required: false,
      },
      values: [],
    },
    {
      name: "value",
      placeholder: "Enter text",
      type: "textbox",
      size: "2",
      readOnly: false,
      uiData: "",
      label: "SPO2",
      offset: "",
      labelSize: "1",
      value: "",
      auto: false,
      conditionalFormulas: [],
      colId: "",
      formula: "",
      globalsectionvars: [],
      validation: {
        isNumber: false,
        minValue: "",
        maxValue: "",
        maxLength: "",
        minLength: "",
        required: false,
      },
      values: [],
    },
  ],
  children: [],
  displayType: "row",
  type: "row",
  updateTimeStamp: null,
};

const drugs_route_uidata = [
  { key: "option0", value: "Capsule" },
  { key: "option1", value: "Tablet" },
  { key: "option2", value: "Injection" },
  { key: "option3", value: "Syrup" },
  { key: "option4", value: "Ointment" },
  { key: "option5", value: "Inhaler" },
  { key: "option6", value: "Sachet" },
  { key: "option7", value: "Others" },
];

const drugs_frequency_uidata = [
  { key: "option0", value: "1-1-1" },
  { key: "option1", value: "1-1-0" },
  { key: "option3", value: "1-0-0" },
  { key: "option6", value: "0-0-1" },
  { key: "option4", value: "0-1-1" },
  { key: "option5", value: "0-1-0" },
  { key: "option2", value: "1-0-1" },
];

const examples = {
  drug_row,
  comment_name_value_row,
  group_3_columns,
  compact_textbox_row,
  drugs_route_uidata,
  drugs_frequency_uidata,
};

export default examples;
