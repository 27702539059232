import { t } from "i18next";
import React from "react";
import { useDispatch } from "react-redux";
import DesktopFormLayout from "../../../../shared/layouts/page-header.layout";
import "../Builder/scss/form-builder.scss";
import PrescriptionTemplateForm from "./template-form.page";
const PrescriptionTemplateCreate: React.FunctionComponent<any> = () => {
  const dispatch = useDispatch();
  return (
    <div className={"padding15"}>
      <DesktopFormLayout
        title={t("Prescription.AddTemplate")}
        subtitle={t("Prescription.AddTemplateSub")}
      >
        <PrescriptionTemplateForm onSubmit={async (values: any) => {}} />
      </DesktopFormLayout>
    </div>
  );
};

export default PrescriptionTemplateCreate;
