import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import { Col, Dropdown, Menu, Row, Tag, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { fetchAppointment } from "../../../redux/reducers/appointment.slice";
import { fetchMasterProcedures } from "../../../redux/reducers/master.slice";
import { fetchAppointmentInstantVC } from "../../../redux/reducers/slot.slice";
import { RootState } from "../../../shared/constants";
import TableLayout from "../../../shared/layouts/table.layout";
import TabsLayout from "../../../shared/layouts/tabs.layout";
import { secondsToHourMins } from "../../../shared/Utils/utilities";
import GeneralTicketActions from "../../crm/components/ticket-general-drawers";
const { Title } = Typography;
var _ = require("lodash");

function InstantVCPage() {
  const dispatch = useDispatch();
  const { appointments, loading, pagination } = useSelector(
    (state: RootState) => state.appointment
  );
  const { profile } = useSelector((state: RootState) => state.profile);
  const { procedures } = useSelector((state: RootState) => state.master);
  const [initAction, setInitAction] = useState(false as any);
  const [selectedAppointment, setSelectedAppointment] = useState(null as any);
  const [filters, setFilters] = useState(null as any);
  const [status, setStatus] = useState([] as any);
  const [doctors, setDoctors] = useState([] as any);
  const [allAppointments, setAllVCAppointments] = useState([] as any);
  const [allDownloadAppointments, setAllDownloadAppointments] = useState(
    [] as any
  );
  const [tab, settab] = useState("1" as any);
  const { connection } = useSelector((state: RootState) => state.chat);

  const showDetails = (data: any, type: any) => {
    setSelectedAppointment(data);
    setInitAction(type);
  };

  let pageNumber =
    pagination?.offset && pagination?.limit
      ? pagination.offset / pagination.limit
      : 0;

  useEffect(() => {
    setStatus(_.groupBy(appointments, "instant_appt_summary_status"));
    setDoctors(
      _.groupBy(appointments, function (item: any) {
        return item?.doctor?.name;
      })
    );
    setAllVCAppointments(appointments);
  }, [appointments]);

  useEffect(() => {
    const wsCurrent = connection;

    if (!wsCurrent) return;
    wsCurrent.onmessage = (e: any) => {
      const _message = JSON.parse(e.data);
      if (_message.type === "appointment_update") {
        dispatch(fetchAppointmentInstantVC());
        return;
      }
    };
  });

  useEffect(() => {
    dispatch(fetchAppointmentInstantVC());
  }, []);

  useEffect(() => {
    if (procedures?.length !== 0) {
      (async function () {
        let instant =
          procedures &&
          procedures.find(
            (procedure_info: any) => procedure_info?.name === "instant"
          );

        const _response = await dispatch(
          fetchAppointment({
            procedure_id: instant?.id,
            limit: 1000,
            latest_status:
              tab === "2" ? "done" : tab === "3" ? "cancelled" : "open",
          })
        );
        if (_response.payload.status === 200) {
          setAllDownloadAppointments(
            _response?.payload?.data?.data?.items || []
          );
        }
      })();
    }
  }, [procedures, tab]);

  function filterFormSubmit(changed: any, all: any) {
    let filtersObj = {} as any;
    for (let key of Object.keys(all)) {
      if (all[key] == "" || all[key] == null || all[key] == undefined) {
      } else {
        filtersObj[key] = all[key];
      }
    }
    setFilters(filtersObj);
    FilterData(filtersObj);
  }

  const cleanify = (instant: any, filtersObj: any) => {
    let result: any = true;
    if (filtersObj?.doctor !== undefined) {
      result = instant?.doctor?.name === filtersObj?.doctor;
    }
    if (filtersObj?.status !== undefined) {
      result =
        result && instant?.instant_appt_summary_status === filtersObj?.status;
    }
    if (filtersObj?.phone !== undefined) {
      result =
        (result &&
          instant?.patient?.pii?.mobile.indexOf(filtersObj?.phone) !== -1) ||
        instant?.patient?.pii?.name.indexOf(
          (filtersObj?.phone).toLowerCase()
        ) !== -1;
    }

    return result;
  };

  const FilterData = (filtersObj: any) => {
    if (filtersObj?.date !== undefined) {
      let instant = procedures.find(
        (procedure_info: any) => procedure_info?.name === "instant"
      );
      dispatch(
        fetchAppointment({
          procedure_id: instant?.id,
          latest_status:
            tab === "2" ? "done" : tab === "3" ? "cancelled" : "open",
          start_date: moment(filtersObj?.date[0]).format("YYYY-MM-DD"),
          end_date: moment(filtersObj?.date[1]).format("YYYY-MM-DD"),
          offset: pagination.offset,
          limit: pagination.limit,
        })
      );
      return;
    }

    const allAppointments = appointments.filter((instant: any) =>
      cleanify(instant, filtersObj)
    );
    setAllVCAppointments(allAppointments);
  };

  const headers = [
    { label: "Patient Details", key: "patient.pii.name" },
    { label: "Doctor Name", key: "doctor.name" },
    { label: "Status", key: "instant_appt_summary_status" },
    { label: "Booking Time", key: "created_at" },
    { label: "Doctor Queue", key: "patient_live_queue" },
    { label: "Patient Waiting Time", key: "wait_time_diff" },
  ];

  const formElements = {
    onFinish: () => {},
    onFinishFailed: () => {},
    onValuesChange: filterFormSubmit,
    initialValues: {},
    setValues: {},
    formElements: [
      {
        label: "",
        type: "Select",
        name: "status",
        colSpan: 3,
        allowClear: true,
        placeholder: "Status",
        class: "capitalize",
        options:
          status &&
          Object.keys(status).map((item: any) => {
            return { name: item, value: item };
          }),
      },
      {
        label: "",
        type: "Select",
        name: "doctor",
        colSpan: 3,
        allowClear: true,
        placeholder: "Doctor",
        class: "capitalize",
        options:
          doctors &&
          Object.keys(doctors).map((item: any) => {
            return { name: item, value: item };
          }),
      },
      {
        label: "",
        type: "Input",
        name: "phone",
        colSpan: 6,
        placeholder: "Search by Patient Phone/Name",
        prefix: <SearchOutlined />,
      },
      {
        label: "",
        type: "RangePicker",
        name: "date",
        colSpan: 4,
        placeholder: "Search by date",
      },
      {
        label: "",
        type: "Button",
        disabled: true,
        name: (
          <>
            {allDownloadAppointments?.length !== 0 && (
              <CSVLink
                data={allDownloadAppointments?.map((val: any) => {
                  return (
                    val.id && {
                      ...val,
                      created_at: moment(val.created_at).format(
                        "DD/MM/YYYY hh:mm:ss"
                      ),
                    }
                  );
                })}
                headers={headers}
                filename={"allAppointments.csv"}
              >
                <DownloadOutlined /> Download Data
              </CSVLink>
            )}
          </>
        ),
        colSpan: 6,
        flex: "none",
      },
    ],
  };

  const columns: any = [
    {
      title: "Patient Details",
      dataIndex: ["patient", "pii"],
      key: "patient",
      width: 230,
      fixed: "left",
      className: "capitalize",
      render: (key: any, record: any) => {
        return (
          <>
            {" "}
            <a className="bold-600">
              {key?.first_name} {key?.last_name}{" "}
            </a>
            {record?.patient?.uhid && (
              <>
                <br />
                <small>UHID: {record?.patient?.uhid}</small>
              </>
            )}
            <br />
            <small>Ph: {"+91" + key?.mobile}</small>{" "}
          </>
        );
      },
    },
    {
      title: "Doctor Name",
      dataIndex: ["doctor", "name"],
      className: "capitalize",
      render: (key: any, record: any) => (
        <>
          {key} <br></br>
          {record?.practice_status === "start" ? (
            <Tag color="green" className={"tag-green"}>
              Practice {record?.practice_status + "ed"}
            </Tag>
          ) : record?.practice_status === "pause" ? (
            <Tag color="orange" className={"tag-orange"}>
              Practice {record?.practice_status + "ed"}
            </Tag>
          ) : (
            <Tag color="red" className={"tag-red"}>
              Practice {record?.practice_status + "ped"}
            </Tag>
          )}
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "instant_appt_summary_status",
      key: "instant_appt_summary_status",
      className: "capitalize",
      render: (key: any) =>
        key === "Live" ? (
          <Tag color="green" className={"success-tag"}>
            {key}
          </Tag>
        ) : (
          <Tag className={"instant-tag"}>{key}</Tag>
        ),
    },
    {
      title: "Booking Time",
      dataIndex: "created_at",
      key: "created_at",
      render: (key: any) => moment(key).format("YYYY-MM-DD, h:m A"),
    },

    {
      title: "Doctor Queue",
      dataIndex: "patient_live_queue",
      className: "capitalize",
      key: "patient_live_queue",
      width: "10%",
    },
    {
      title: "Patient Waiting Time",
      dataIndex: "wait_time_diff",
      key: "wait_time_diff",
      className: "capitalize",
      render: (key: any) =>
        key <= 15 ? (
          <p style={{ color: "#EB872A" }}>{secondsToHourMins(key)}</p>
        ) : key <= 15 && key >= 30 ? (
          <p style={{ color: "#B2403A" }}>{secondsToHourMins(key)}</p>
        ) : key > 1440 ? (
          "-"
        ) : (
          <p style={{ color: "#E1454E" }}>{secondsToHourMins(key)}</p>
        ),
    },
    {
      title: "EP Generated at",
      dataIndex: "prescription_ready_time",
      className: "prescription_ready_time",
      key: "prescription_ready_time",
      render: (key: any) => key && moment(key).format("YYYY-MM-DD, h:m A"),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      width: "5%",
      render: (record: any) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item onClick={() => showDetails(record, "audit_trail")}>
                Audit Trail
              </Menu.Item>
              {(record.latest_status !== "done" ||
                record.latest_status === "cancelled") && (
                <Menu.Item
                  onClick={() => showDetails(record, "cancel_appointment")}
                >
                  Cancel Appointment
                </Menu.Item>
              )}
            </Menu>
          }
        >
          <svg
            width="30"
            className={"cursor"}
            height="7"
            viewBox="0 0 30 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.27179 5.12988C4.1674 5.12988 4.89343 4.40409 4.89343 3.50879C4.89343 2.61348 4.1674 1.8877 3.27179 1.8877C2.37618 1.8877 1.65015 2.61348 1.65015 3.50879C1.65015 4.40409 2.37618 5.12988 3.27179 5.12988Z"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M15.1639 5.12988C16.0595 5.12988 16.7855 4.40409 16.7855 3.50879C16.7855 2.61348 16.0595 1.8877 15.1639 1.8877C14.2684 1.8877 13.5424 2.61348 13.5424 3.50879C13.5424 4.40409 14.2684 5.12988 15.1639 5.12988Z"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M27.056 5.12988C27.9516 5.12988 28.6777 4.40409 28.6777 3.50879C28.6777 2.61348 27.9516 1.8877 27.056 1.8877C26.1604 1.8877 25.4344 2.61348 25.4344 3.50879C25.4344 4.40409 26.1604 5.12988 27.056 5.12988Z"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </Dropdown>
      ),
    },
  ];

  const completed_columns: any = [
    {
      title: "Patient Details",
      dataIndex: ["patient", "pii"],
      key: "patient",
      width: 230,
      fixed: "left",
      className: "capitalize",
      render: (key: any, record: any) => {
        return (
          <>
            {" "}
            <a className="bold-600">
              {key?.first_name} {key?.last_name}{" "}
            </a>
            {record?.patient?.uhid && (
              <>
                <br />
                <small>UHID: {record?.patient?.uhid}</small>
              </>
            )}
            <br />
            <small>Ph: {"+91" + key?.mobile}</small>{" "}
          </>
        );
      },
    },
    {
      title: "Doctor Name",
      dataIndex: ["doctor", "name"],
      className: "capitalize",
      render: (key: any, record: any) => (
        <>
          {key} <br></br>
          {record?.practice_status === "start" ? (
            <Tag color="green" className={"tag-green"}>
              Practice {record?.practice_status + "ed"}
            </Tag>
          ) : record?.practice_status === "pause" ? (
            <Tag color="orange" className={"tag-orange"}>
              Practice {record?.practice_status + "ed"}
            </Tag>
          ) : (
            <Tag color="red" className={"tag-red"}>
              Practice {record?.practice_status + "ped"}
            </Tag>
          )}
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "instant_appt_summary_status",
      key: "instant_appt_summary_status",
      className: "capitalize",
      render: (key: any) =>
        key === "Live" ? (
          <Tag color="green" className={"success-tag"}>
            {key}
          </Tag>
        ) : (
          <Tag className={"instant-tag"}>{key}</Tag>
        ),
    },
    {
      title: "Booking Time",
      dataIndex: "created_at",
      key: "created_at",
      render: (key: any) => moment(key).format("YYYY-MM-DD, h:m A"),
    },
    {
      title: "EP Generated at",
      dataIndex: "prescription_ready_time",
      className: "prescription_ready_time",
      key: "prescription_ready_time",
      render: (key: any) => key && moment(key).format("YYYY-MM-DD, h:m A"),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      render: (record: any) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item onClick={() => showDetails(record, "audit_trail")}>
                Audit Trail
              </Menu.Item>
              {(record.latest_status !== "done" ||
                record.latest_status === "cancelled") && (
                <Menu.Item
                  onClick={() => showDetails(record, "cancel_appointment")}
                >
                  Cancel Appointment
                </Menu.Item>
              )}
            </Menu>
          }
        >
          <svg
            width="30"
            className={"cursor"}
            height="7"
            viewBox="0 0 30 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.27179 5.12988C4.1674 5.12988 4.89343 4.40409 4.89343 3.50879C4.89343 2.61348 4.1674 1.8877 3.27179 1.8877C2.37618 1.8877 1.65015 2.61348 1.65015 3.50879C1.65015 4.40409 2.37618 5.12988 3.27179 5.12988Z"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M15.1639 5.12988C16.0595 5.12988 16.7855 4.40409 16.7855 3.50879C16.7855 2.61348 16.0595 1.8877 15.1639 1.8877C14.2684 1.8877 13.5424 2.61348 13.5424 3.50879C13.5424 4.40409 14.2684 5.12988 15.1639 5.12988Z"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M27.056 5.12988C27.9516 5.12988 28.6777 4.40409 28.6777 3.50879C28.6777 2.61348 27.9516 1.8877 27.056 1.8877C26.1604 1.8877 25.4344 2.61348 25.4344 3.50879C25.4344 4.40409 26.1604 5.12988 27.056 5.12988Z"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </Dropdown>
      ),
    },
  ];

  useEffect(() => {
    (async function () {
      try {
        const response = await dispatch(fetchMasterProcedures());
        if (response.payload.status === 200) {
          let instant = response.payload?.data?.data.find(
            (procedure_info: any) => procedure_info?.name === "instant"
          );
          if (profile.type === "doctor") {
            dispatch(
              fetchAppointment({
                doctor_id: profile?.id,
                procedure_id: instant?.id,
              })
            );
          } else {
            dispatch(
              fetchAppointment({
                procedure_id: instant?.id,
                offset: pagination.offset,
                limit: pagination.limit,
                latest_status: "open",
              })
            );
          }
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  const getAppointments = (evt: any) => {
    let instant = procedures.find(
      (procedure_info: any) => procedure_info?.name === "instant"
    );
    dispatch(
      fetchAppointment({
        procedure_id: instant?.id,
        latest_status:
          evt === "2" ? "done" : evt === "3" ? "cancelled" : "open",
        offset: pagination.offset,
        limit: pagination.limit,
      })
    );
    settab(evt);
  };

  const allTabs = [
    {
      title: `Open`,
      component: (
        <Row className="padding10">
          <Col span={24}>
            <TableLayout
              loading={loading}
              dataSource={allAppointments}
              columns={columns}
              type="vcfilter"
              formData={formElements}
              filters={filters}
              onChange={(e) => onPageChange(e)}
              defaultCurrentPage={pageNumber + 1}
              total={pagination?.total}
              showTotal={true}
              pagination={{ position: ["none", "none"] }}
            />
          </Col>
        </Row>
      ),
      key: "open",
    },
    {
      title: "Completed",
      key: "done",
      component: (
        <Row className="padding10">
          <Col span={24}>
            <TableLayout
              loading={loading}
              dataSource={allAppointments}
              columns={completed_columns}
              type="vcfilter"
              formData={formElements}
              filters={filters}
              total={pagination.total}
              showTotal={true}
              onChange={(e) => onPageChange(e)}
              defaultCurrentPage={pageNumber + 1}
              pagination={{ position: ["none", "none"] }}
            />
          </Col>
        </Row>
      ),
    },
    {
      title: "Cancelled",
      key: "cancelled",
      component: (
        <Row className="padding10">
          <Col span={24}>
            <TableLayout
              loading={loading}
              dataSource={allAppointments}
              columns={completed_columns}
              type="vcfilter"
              formData={formElements}
              filters={filters}
              total={pagination.total}
              showTotal={true}
              onChange={(e) => onPageChange(e)}
              defaultCurrentPage={pageNumber + 1}
              pagination={{ position: ["none", "none"] }}
            />
          </Col>
        </Row>
      ),
    },
  ] as any;

  const onPageChange = async (e: any) => {
    pageNumber = e - 1;
    let instant = procedures.find(
      (procedure_info: any) => procedure_info?.name === "instant"
    );
    dispatch(
      fetchAppointment({
        procedure_id: instant?.id,
        latest_status:
          tab === "2" ? "done" : tab === "3" ? "cancelled" : "open",
        offset: pageNumber * 10,
        limit: pagination.limit,
      })
    );
  };

  return (
    <Row>
      <Col span={24}>
        <Title className="paddingcollapse" level={4}>
          Instant VC Appointments
        </Title>
        <Row>
          <Col span={24} className={"boss-table"}>
            {
              <TabsLayout
                AllTabs={allTabs}
                active={tab}
                onChange={(evt) => getAppointments(evt)}
              ></TabsLayout>
            }
          </Col>
        </Row>
      </Col>
      {initAction && (
        <GeneralTicketActions
          appointment={selectedAppointment}
          action={initAction}
          hideMe={() => setInitAction(false)}
        ></GeneralTicketActions>
      )}
    </Row>
  );
}
export default InstantVCPage;
