export const Section = ["showType", "title", "status"];
export const TableSection = ["showType", "title", "status", "noOfColumns"];
export const XYTable = [
  "showType",
  "title",
  "status",
  "noOfColumns",
  "noOfRows",
  "rowHeaders",
  "colHeaders",
];
export const SplitTableSection = ["showType", "title", "status"];
export const Row = ["status", "type"];
export const Subsection = ["noOfColumns"];
export const CompactRow = [];
export const ChildRow = ["status", "type"];
export const Column = ["name", "placeholder", "type", "label"];
export const DisplayParent = ["name", "placeholder", "type", "colId"];
export const ColumnWithoutLabel = ["name", "placeholder", "type"];
export const AutoComplete = ["name", "autoKey", "type"];
export const StaticColumn = ["label"];

const RequiredFields = {
  Section,
  TableSection,
  XYTable,
  SplitTableSection,
  Row,
  Subsection,
  CompactRow,
  ChildRow,
  Column,
  DisplayParent,
  ColumnWithoutLabel,
  AutoComplete,
  StaticColumn,
};

export default RequiredFields;
