import actionmapSlice from "./actionmap.slice";
import agentReducer from "./agents.slice";
import appointmentReducer from "./appointment.slice";
import assignementSlice from "./assignment.slice";
import authReducer from "./auth.slice";
import branchReducer from "./branch.slice";
import calllogsSlice from "./calllogs.slice";
import chatSlice from "./chat.slice";
import contactSlice from "./contacts.slice";
import departmentReducer from "./department.slice";
import doctorReducer from "./doctor.slice";
import leadsSlice from "./leads.slice";
import masterReducer from "./master.slice";
import messageTemplateSlice from "./messge-template.slice";
import metadataSlice from "./metadata.slice";
import patientReducer from "./patients.slice";
import permissionReducer from "./permission.slice";
import pharmacySlice from "./pharmacy.slice";
import prescriptionSlice from "./prescription.slice";
import profileSlice from "./profile.slice";
import promSlice from "./prom.slice";
import regionReducer from "./region.slice";
import requestLogsSlice from "./request-logs.slice";
import roleReducer from "./role.slice";
import settingsSlice from "./settings.slice";
import slotReducer from "./slot.slice";
import teamReducer from "./team.slice";
import ticketsSlice from "./tickets.slice";
import uploadSlice from "./upload.slice";
import userReducer from "./user.slice";

let reducers = {
  auth: authReducer,
  agents: agentReducer,
  user: userReducer,
  branch: branchReducer,
  region: regionReducer,
  department: departmentReducer,
  permission: permissionReducer,
  team: teamReducer,
  role: roleReducer,
  doctor: doctorReducer,
  master: masterReducer,
  slot: slotReducer,
  appointment: appointmentReducer,
  requestLogs: requestLogsSlice,
  patient: patientReducer,
  prescription: prescriptionSlice,
  messageTemplate: messageTemplateSlice,
  upload: uploadSlice,
  profile: profileSlice,
  chat: chatSlice,
  contact: contactSlice,
  leads: leadsSlice,
  tickets: ticketsSlice,
  metadata: metadataSlice,
  calllogs: calllogsSlice,
  pharmacy: pharmacySlice,
  assignment: assignementSlice,
  actionmap: actionmapSlice,
  settings: settingsSlice,
  prom: promSlice,
};

export default reducers;
