import {
  selectIsConnectedToRoom,
  selectRemotePeers,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";
import { Box, Flex } from "@100mslive/react-ui";
import { Col, Row } from "antd";
import { useEffect } from "react";
import { ConferenceMainView } from "../layouts/mainView";
import { useIsHeadless } from "./AppData/useUISettings";
import { Footer } from "./Footer";
import FullPageProgress from "./FullPageProgress";
import { Header } from "./Header";
import { RoleChangeRequestModal } from "./RoleChangeRequestModal";

const Conference = ({ loginType }) => {
  const isHeadless = useIsHeadless();
  const isConnectedToRoom = useHMSStore(selectIsConnectedToRoom);
  const hmsActions = useHMSActions();
  const remotePeers = useHMSStore(selectRemotePeers);

  useEffect(() => {
    console.log(remotePeers);
  });

  useEffect(() => {
    return () => {
      // This is needed to handle mac touchpad swipe gesture
      if (isConnectedToRoom) {
        hmsActions.leave();
      }
    };
  }, [hmsActions, isConnectedToRoom]);

  if (!isConnectedToRoom) {
    return (
      <Row
        justify={"center"}
        style={{ height: "100vh", backgroundColor: "black" }}
      >
        {" "}
        <Col span={24} className={"text-center"} id={"hy"}>
          <FullPageProgress />
        </Col>
      </Row>
    );
  }

  return (
    <Flex css={{ size: "100%" }} direction="column">
      {!isHeadless && (
        <Box css={{ h: "$18", "@md": { h: "$17" } }} data-testid="header">
          <Header />
        </Box>
      )}
      <Box
        css={{
          w: "100%",
          flex: "1 1 0",
          // minHeight: `calc(100vh - 20vh)`,
          backgroundColor: "Black",
        }}
        data-testid="conferencing"
      >
        <ConferenceMainView role={loginType} />
      </Box>
      {!isHeadless && (
        <Box
          css={{
            flexShrink: 0,
            minHeight: "$24",
            backgroundColor: "Black",
            paddingTop: "20px",
          }}
          data-testid="footer"
        >
          <Footer loginType={loginType} />
        </Box>
      )}
      <RoleChangeRequestModal />
    </Flex>
  );
};

export default Conference;
