import { LoadingOutlined, MoreOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import moment from "moment";
import React, { Suspense } from "react";

// import { useDispatch } from "react-redux";
// import { fetchLeaveForDaysBetween } from "../../../../redux/reducers/slot.slice";

interface ExtraMenuProps {
  startConsultation: any;
  _appointmentData: any;
  profile: any;
  slot: any;
  // openBlockShiftModal: () => void;
  setMarkDelay: (on: boolean) => void;
  // setUnmarkLeave: (on: boolean) => void;
  // setMarkLeave: (on: boolean) => void;
  // appointments: Array<any>;
}

const ExtraMenu: React.FunctionComponent<ExtraMenuProps> = ({
  startConsultation,
  _appointmentData,
  // profile,
  slot,
  // openBlockShiftModal,
  setMarkDelay,
  // setUnmarkLeave,
  // setMarkLeave,
  // appointments,
}) => {
  // const dispatch = useDispatch();

  // const RenderBlockShiftButton = React.lazy(async () => {
  //   const cur_date = new Date();
  //   const appointment_date = new Date(_appointmentData.date);

  //   if (appointment_date < cur_date || appointments.length > 0) {
  //     return {
  //       default: () => <></>,
  //     };
  //   }

  //   return {
  //     default: () => (
  //       <Menu.Item key="2" onClick={() => openBlockShiftModal()}>
  //         Block Shift
  //       </Menu.Item>
  //     ),
  //   };
  // });

  // const RenderMarkUnmarkButton = React.lazy(async () => {
  //   // if markUnmarkLeave returns null, then return a a empty component
  //   const { button_text, button_caller } = await markOrUnMarkButton();

  //   if (!button_caller || appointments.length > 0) {
  //     return {
  //       default: () => <></>,
  //     };
  //   }

  //   return {
  //     default: () => (
  //       <>
  //         <Menu.Item key="1" onClick={() => button_caller(true)}>
  //           {button_text}
  //         </Menu.Item>
  //       </>
  //     ),
  //   };
  // });

  // const isOnLeave = useCallback(
  //   async (date: string, branch_id: string, doctor_id: string) => {
  //     try {
  //       const formattedData = moment(date).format("YYYY-MM-DD");
  //       const data = {
  //         doctor_ids: [doctor_id],
  //         branch_id: branch_id,
  //         start_date: formattedData,
  //         end_date: formattedData,
  //       };
  //       const response = await dispatch(fetchLeaveForDaysBetween(data));
  //       const payload = response.payload;

  //       if (payload.status === 400) {
  //         message.error(payload.data.message);
  //         return null;
  //       }

  //       if (payload.data.statusCode === 200 && payload.data.data.length > 0) {
  //         return payload.data.data[0];
  //       } else {
  //         return null;
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   },
  //   [dispatch]
  // );

  // const markOrUnMarkButton = useCallback(async () => {
  //   let button_caller = null;
  //   let button_text: string = "";

  //   const cur_date = new Date();
  //   const appointment_date = new Date(_appointmentData.date);

  //   const leaveData = await isOnLeave(
  //     moment(_appointmentData.date).format("YYYY-MM-DD"),
  //     _appointmentData.branch,
  //     profile.id
  //   );

  //   if (appointment_date < cur_date) {
  //     if (leaveData) {
  //       button_caller = setUnmarkLeave;
  //       button_text = "Unmark Leave";
  //     } else {
  //       return {
  //         button_caller: null,
  //         button_text: "",
  //       };
  //     }
  //   }

  //   if (leaveData) {
  //     button_caller = setUnmarkLeave;
  //     button_text = "Unmark Leave";
  //   } else {
  //     button_caller = setMarkLeave;
  //     button_text = "Mark Leave";
  //   }

  //   return {
  //     button_caller: button_caller,
  //     button_text: button_text,
  //   };
  // }, [
  //   _appointmentData.branch,
  //   _appointmentData.date,
  //   isOnLeave,
  //   profile.id,
  //   setMarkLeave,
  //   setUnmarkLeave,
  // ]);

  const ExtraMenu = (
    <Menu>
      {slot?.practice_status === "start" &&
      _appointmentData?.date === moment().format("YYYY-MM-DD") ? (
        <Menu.Item key="0" onClick={() => startConsultation("pause", false)}>
          Pause Practice
        </Menu.Item>
      ) : (
        <>
          <Suspense
            fallback={
              <Menu.Item>
                <LoadingOutlined /> Loading...
              </Menu.Item>
            }
          >
            <Menu.Item key="0" onClick={() => setMarkDelay(true)}>
              Mark Delay
            </Menu.Item>
            {/* <RenderMarkUnmarkButton /> */}
            {/* <RenderBlockShiftButton /> */}
          </Suspense>
        </>
      )}
    </Menu>
  );

  return (
    <Dropdown overlay={ExtraMenu} trigger={["click"]}>
      <MoreOutlined style={{ fontSize: "20px" }} className="cursor" />
    </Dropdown>
  );
};

export default ExtraMenu;
