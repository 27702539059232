import { Col, Row, Spin, Typography } from "antd";
import { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import MainImage from "../../assets/images/doctor.svg";
import {
  fetchAccountByDomain,
  fetchApplications,
  switchApplication,
} from "../../redux/reducers/auth.slice";
import { RootState } from "../../shared/constants";
import LoginMobileBanner from "../../shared/icons/LoginMobileBanner";
import MaxLogoIcon from "../../shared/icons/MaxLogoIcon";
import { ROUTE_PAGE_NOT_FOUND } from "../../shared/routes/route.constants";
import "./index.less";

const { Title } = Typography;

function AuthLayout({
  children,
  otpSessionId,
  isotp,
}: {
  children: ReactNode;
  otpSessionId: any;
  isotp: boolean;
}) {
  const dispatch = useDispatch();
  const { account, applications } = useSelector(
    (state: RootState) => state.auth
  );
  const RouteParams: any = useParams();
  useEffect(() => {
    localStorage.setItem("account_id", account?.id);
    const _host = window.location.host.split(".")[0];
    // debugger;
    if (_host === "medi" || _host === "localhost:3001") {
      return;
    }
    if (_host !== RouteParams["source"]) {
      window.location.href = ROUTE_PAGE_NOT_FOUND();
      return;
    }
    const application = applications.filter((val: any) => {
      return val.slug === RouteParams["source"];
    });
    application?.length > 0 &&
      dispatch(
        switchApplication({
          slug: application[0].slug,
          application_id: application[0].id,
        })
      );
  }, [RouteParams, account, applications, dispatch]);

  const screenType = {
    desktop: useMediaQuery({ minWidth: 992 }),
    tablet: useMediaQuery({ minWidth: 768, maxWidth: 991 }),
    mobile: useMediaQuery({ maxWidth: 767 }),
  };

  useEffect(() => {
    dispatch(fetchAccountByDomain());
    dispatch(fetchApplications());
  }, [dispatch]);

  return (
    <>
      <Row
        className={
          (screenType.desktop ? " " : "padding20 ") + "full-height auth-layout "
        }
      >
        <Col span={14} xl={14} sm={0} xs={0}>
          <div
            className="main-image-wrapper"
            style={{
              backgroundImage: `url(${MainImage})`,
              height: "100vh",
              backgroundColor: "#C1DAE8",
            }}
          />
        </Col>

        <Col xl={10} sm={24}>
          <Row>
            <Col
              span={18}
              xs={24}
              offset={screenType.desktop ? 3 : 0}
              style={
                screenType.desktop
                  ? { maxWidth: "400px", marginTop: "150px" }
                  : {}
              }
            >
              {
                <>
                  <Title
                    className={screenType.desktop ? "bold" : "bold"}
                    level={3}
                    style={{ marginBottom: "0px" }}
                  >
                    Welcome To
                  </Title>
                  <MaxLogoIcon
                    width="130"
                    height={screenType.desktop ? "70" : "50"}
                  />
                </>
              }
              <Col span={0} xs={24} xl={0} xxl={0} sm={0}>
                {screenType.desktop ? (
                  <img src={MainImage} style={{ width: "100%" }} alt="auth" />
                ) : !otpSessionId ? (
                  <LoginMobileBanner />
                ) : (
                  ""
                )}
              </Col>

              {!applications?.length || !account ? <Spin /> : children}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default AuthLayout;
