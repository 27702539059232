import { Col, Row } from "antd";
import * as React from "react";

class HyperLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
    };
  }

  render() {
    const { value, label } = this.props;
    return (
      <Col className="gutter-row" span={24}>
        <Row>
          <a href={value} target="_blank" rel="noreferrer">
            {" "}
            {label}
          </a>
        </Row>
      </Col>
    );
  }
}

export default HyperLink;
