import React, { useState } from "react";
import ID from "../Utils/UUID";
import ComponentSelectorModal from "./EditComponents/ComponentSelectorModal";
import Section from "./Schema/Section";
// import usersService from '../../../Services/users.service';
import CommonModalView from "../Common/Modal/CommonModalView";
import { DefaultProps } from "../Constants/DefaultProps";
import Util from "../Utils/Util";
import CustomRowExamples from "./Schema/CustomRowExamples";
import CustomRowJSON from "./Schema/CustomRowJSON";
import { Row, Col, Button } from "antd";
// const $ = window["jQuery"];
export default class EditContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // currentSectionId: '',
      currentSectionIdx: 0,
      colIdArray: [],
      availableNameArr: [],
      showHideParentArray: [],
      openCreateSectionPopup: false,
      element: "",
      tagConfigList: ["p", "s", "ds", "d", "v", "i"],
      elementConfig: null,
      elemProps: null,
      options: null,
      variables: null,
    };

    if (this.props.sections.length) {
      this.checkForUUID();
    }
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.activeKey === "edit";
  }

  componentDidMount() {
    // $(window).scroll(function () {
    //     if ($(this).scrollTop() > 141) {
    //         $('.properties-editor-container').addClass('fixme');
    //     } else {
    //         $('.properties-editor-container').removeClass('fixme');
    //     }
    // });
    // if (this.props.sections && this.props.sections.length) {
    //     // to enable scroll using indexing
    //     this.props.sections.forEach(s => {
    //         this[s['id']] = React.createRef();
    //     });
    // }
  }

  checkForUUID() {
    let { sections } = this.props;
    sections.map((s, idx) => {
      if (!s.id) {
        let uuid = ID.uuid();
        sections[idx] = {
          ...sections[idx],
          id: uuid,
        };
      }
    });
    this.props.onFormChange(sections);
  }

  defaultSectionProps = () => {
    let row = this.defaultRowProps();
    return {
      formname: "",
      id: ID.uuid(),
      uiDataDic: {},
      title: "",
      showType: "none",
      rows: [row],
      sectionOffset: "",
      status: "empty",
      collapse: false,
      hideShowOff: false,
      updateTimeStamp: null,
    };
  };

  defaultRowProps = () => {
    return {
      rowId: ID.uuid(),
      rowType: "",
      config: "",
      status: "empty",
      columns: [{}],
      children: [],
      displayType: "row",
      type: "row", //can be row or group
      updateTimeStamp: null,
    };
  };

  defaultCompactTableRowProps = (noOfColumns, headerStr) => {
    let columns = [],
      header = headerStr ? headerStr.split(",") : [];
    if (!noOfColumns) {
      columns.push({});
    }
    for (let i = 0; i < noOfColumns; i++) {
      if (header.length && header[i]) {
        columns.push({ header: header[i] });
      } else columns.push({});
    }
    return {
      rowId: ID.uuid(),
      type: "compactRow",
      config: "",
      status: "empty",
      columns: columns,
      children: [],
    };
  };
  defaultSubsectionRowProps = () => {
    return {
      rowId: ID.uuid(),
      type: "subSection",
      config: "",
      status: "empty",
      columns: [],
      noOfColumns: "",
      header: "",
      subSectionSize: 0,
      name: "",
      children: [
        {
          rowId: ID.uuid(),
          type: "compactRow",
          config: "",
          status: "empty",
          columns: [{}],
          children: [],
        },
      ],
    };
  };

  async logErrorToService(error, errorInfo) {
    try {
      // add service call here
      let data = {
        error: error,
        errorInfo: errorInfo,
      };
      //const response = await usersService.logAppError(data);
      //console.log(response)
    } catch (err) {
      console.log(err);
    }
  }

  changeSectionMeta = (meta) => {
    try {
      // if (meta.name === 'sectionOffset') {
      //     meta.value = meta.value ? "w-100" : "";
      // }
      let { sections } = this.props;
      sections.forEach((section, idx) => {
        if (meta.sectionId === section["id"]) {
          sections[idx] = {
            ...sections[idx],
            [meta.name]: meta.value,
          };
          return;
        }
      });
      // this.setState({ currentSectionId: meta.sectionId }, () =>
      //     this.props.onFormChange(sections));
      this.props.onFormChange(sections);
    } catch (error) {
      this.logErrorToService(error, "FE: EditContainer : changeSectionMeta");
    }
  };

  deleteSection = (id) => {
    try {
      let { sections } = this.props;
      let newForm = [];
      newForm = sections.filter((section) => section["id"] !== id);
      this.props.onFormChange(newForm);
    } catch (error) {
      this.logErrorToService(error, "FE: EditContainer : deleteSection");
    }
  };

  findSection(id) {
    let { sections } = this.props;
    return sections.find((section) => section["id"] === id);
  }

  addRow = (meta) => {
    try {
      let insertAtIndex, newRow;
      let { sectionId, rowId, childRowId } = meta;
      let section = this.findSection(sectionId);
      if (section.showType === "compactTable" && !childRowId) {
        if (!section.splitTable)
          newRow = this.defaultCompactTableRowProps(
            section.noOfColumns,
            section.header
          );
        else newRow = this.defaultSubsectionRowProps();
      } else if (section.showType === "compactTable" && childRowId) {
        let rowIndex = section.rows.findIndex((row) => row["rowId"] === rowId);
        newRow = this.defaultCompactTableRowProps(
          section.rows[rowIndex].noOfColumns,
          section.rows[rowIndex].header
        );
      } else newRow = this.defaultRowProps();
      let rows = section.rows;
      if (!childRowId) {
        insertAtIndex =
          rows.length && rowId
            ? rows.findIndex((row) => row["rowId"] === rowId) + 1
            : rows.length;
        rows.splice(insertAtIndex, 0, newRow);
      } else {
        let rowIndex = rows.findIndex((row) => row["rowId"] === rowId);
        let children = rows[rowIndex]["children"];
        insertAtIndex = children
          ? children.findIndex((childRow) => childRow["rowId"] === childRowId) +
            1
          : children.length;
        children.splice(insertAtIndex, 0, newRow);
        rows[rowIndex] = {
          ...rows[rowIndex],
          ["children"]: children,
        };
      }
      this.changeSectionMeta({
        sectionId: sectionId,
        name: "rows",
        value: rows,
      });
    } catch (err) {
      this.logErrorToService(err, "FE: EditContainer : addRow");
    }
  };

  deleteRow = (meta) => {
    try {
      let { sectionId, rowId, childRowId } = meta;
      let rows = this.findSection(sectionId).rows;
      if (!childRowId) {
        rows = rows.filter((row) => row["rowId"] !== rowId);
      } else {
        let rowIndex = rows.findIndex((row) => row["rowId"] === rowId); // find row index
        let children = rows[rowIndex]["children"].filter(
          (childRow) => childRow["rowId"] !== childRowId
        ); // delete child row
        rows[rowIndex]["children"] = children;
      }
      this.changeSectionMeta({
        sectionId: sectionId,
        name: "rows",
        value: rows,
      });
    } catch (err) {
      this.logErrorToService(err, "FE: EditContainer : deleteRow");
    }
  };

  onRowDisplayTypeChanged = (value) => {
    try {
      let { sectionIndex, rowIndex } = this.state.elementConfig;
      let { elemProps } = this.state;
      let { sections } = this.props;
      if (value === "group") {
        elemProps = {
          ...elemProps,
          ["name"]: "",
        };
        let newRow = this.defaultRowProps();
        elemProps.columns = [];
        elemProps.children.push(newRow);
      } else if (value === "row") {
        if (elemProps["name"]) delete elemProps["name"];
        elemProps.children = [];
        elemProps.columns = [{}];
      }
      sections[sectionIndex].rows[rowIndex] = elemProps;
      this.props.onFormChange(sections);
    } catch (err) {
      this.logErrorToService(
        err,
        "FE: EditContainer : onRowDisplayTypeChanged"
      );
    }
  };

  scrollToSection = (sectionId) => {
    this[sectionId].current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  onCloneSection = (section) => {
    try {
      let { sections } = this.props;
      let idx = sections.findIndex((s) => s["id"] === section.id);

      //replace Section ID
      section["id"] = ID.uuid();
      let sectionsWithSameName = sections.filter(
        (s) => s["formname"] === section.formname
      );
      if (sectionsWithSameName.length >= 1) {
        section["title"] = section["title"] + "(copied)";
        section["formname"] = Util.toCamelCase(section["title"]);
      }

      // ref created to enable scroll
      this[section["id"]] = React.createRef();
      //replace ID for rows
      if (section.rows && section.rows.length) {
        section.rows.forEach((r, idx) => {
          section.rows[idx]["rowId"] = ID.uuid();

          //replace ID for sub-rows (children)
          if (r.children && r.children.length) {
            section.rows[idx].children.forEach((child, cidx) => {
              section.rows[idx]["children"][cidx]["rowId"] = ID.uuid();
            });
          }
        });
      }

      //insert Section below current section
      sections.splice(idx + 1, 0, section);
      this.props.onFormChange(sections);
    } catch (err) {
      this.logErrorToService(err, "FE: EditContainer : cloneSection");
    }
  };

  onDragStart = (e, index) => {
    this.draggedItem = index;
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", e.target.parentNode);
    e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
  };

  onDragOver = (index) => {
    this.draggedOverItem = index;
  };

  onDragEnd = () => {
    // if the item is dragged over itself, ignore
    if (this.draggedItem === this.draggedOverItem) {
      return;
    }

    // filter out the currently dragged item
    let sections = this.props.sections.filter(
      (item, idx) => idx !== this.draggedItem
    );

    // add the dragged item after the dragged over item
    sections.splice(
      this.draggedOverItem,
      0,
      this.props.sections[this.draggedItem]
    );
    this.props.onFormChange(sections);
    this.draggedItem = this.draggedOverItem = null;
  };

  getXYTableRows(meta) {
    let rows = [];
    let cols = [];
    let colHeaders = (meta.colHeaders && meta.colHeaders.split(",")) || [];
    let colProps = DefaultProps.find((tool) => tool.type === "textbox");
    let row = null;
    let rowHeaders = (meta.rowHeaders && meta.rowHeaders.split(",")) || [];
    for (let x = 0; x < Number(meta.noOfRows); x++) {
      for (let y = 0; y < Number(meta.noOfColumns); y++) {
        let column =
          this.state.elemProps["showType"] === "xyTable" &&
          meta.rows &&
          meta.rows[x] &&
          meta.rows[x].columns &&
          meta.rows[x].columns[y]
            ? meta.rows[x].columns[y]
            : colProps; // properties might previouly be initialised
        cols.push({
          ...column,
          label_y: colHeaders[y] || "-",
          label_x: rowHeaders[x] || "-",
        });
      }
      row =
        this.state.elemProps["showType"] === "xyTable"
          ? meta.rows[x]
          : this.defaultRowProps();
      row = {
        ...row,
        label_x: rowHeaders[x] || "-",
        columns: cols,
      };
      rows.push({ ...row });
      row = null;
      cols = [];
    }
    return rows;
  }

  onSectionMetaSave = (elemProps) => {
    try {
      let sections = this.props.sections;
      let { tagConfigList } = this.state;
      //if section is tagged/untagged, delete/add that tag from available list of tags
      if (elemProps["tag"]) {
        let idx = tagConfigList.findIndex((l) => elemProps["tag"] === l);
        if (idx !== -1) {
          tagConfigList.splice(idx, 1);
          if (this.state.elemProps["tag"])
            tagConfigList.push(this.state.elemProps["tag"]);
        }
      } else {
        let idx = tagConfigList.findIndex(
          (l) => this.state.elemProps["tag"] === l
        );
        if (idx === -1) tagConfigList.push(this.state.elemProps["tag"]);
      }
      //if showType is changed
      if (elemProps["showType"] === "freeText") {
        elemProps.rows = [];
        elemProps.html = "";
      } else if (elemProps["showType"] === "xyTable") {
        //manage any extra config to be added/deleted apart from defaultSectionProps
        elemProps = { ...elemProps, rows: this.getXYTableRows(elemProps) };
      } else if (elemProps["showType"] === "compactTable") {
        ////if splitTable is toggled
        if (
          elemProps["splitTable"] === true &&
          this.state.elemProps["splitTable"] !== true
        ) {
          let newRow = this.defaultSubsectionRowProps();
          delete elemProps["header"];
          delete elemProps["noOfColumns"];
          elemProps = {
            ...elemProps,
            rows: [newRow],
          };
        } else if (
          elemProps["splitTable"] === false &&
          this.state.elemProps["splitTable"] !== false
        ) {
          let newRow = this.defaultCompactTableRowProps(
            elemProps["noOfColumns"],
            elemProps["header"]
          );
          elemProps = {
            ...elemProps,
            header: "",
            noOfColumns: "",
            splitTable: false,
            rows: [newRow],
          };
        }
      } else if (
        elemProps["showType"] !== "compactTable" &&
        this.state.elemProps.showType === "compactTable"
      ) {
        let newRow = this.defaultRowProps();
        delete elemProps["header"];
        delete elemProps["noOfColumns"];
        delete elemProps["splitTable"];
        elemProps = {
          ...elemProps,
          rows: [newRow],
        };
      }
      if (
        elemProps["showType"] !== "xyTable" &&
        this.state.elemProps.showType === "xyTable"
      ) {
        // reset few config params when showType is changed
        delete elemProps["rowHeaders"];
        delete elemProps["colHeaders"];
        delete elemProps["noOfColumns"];
        delete elemProps["noOfRows"];
      }
      elemProps = {
        ...elemProps,
        formname: elemProps.title ? Util.toCamelCase(elemProps.title) : "",
      };
      if (this.state.openCreateSectionPopup) {
        this[elemProps["id"]] = React.createRef(); // to enable scroll using indexing
        sections.push(elemProps);
      } else {
        sections[this.state.elementConfig.sectionIndex] = elemProps;
      }
      this.setState(
        {
          elemProps: null,
          element: null,
          tagConfigList,
          openCreateSectionPopup: false,
        },
        () => this.props.onFormChange(sections)
      );
    } catch (err) {
      this.logErrorToService(err, "FE: EditContainer : onSectionMetaSave");
    }
  };

  onFreeTextValueChange = (event, index) => {
    //console.log(this.props.sections);
    let sections = this.props.sections;
    sections[index].html = event;
    this.props.onFormChange(sections);
  };

  onColMetaSave = (elemProps) => {
    try {
      const { sectionIndex, rowIndex, childIndex, columnIndex } =
        this.state.elementConfig;
      let sections = this.props.sections;
      if (elemProps["colId"] && elemProps["class"] === "displayparent") {
        if (
          this.state.showHideParentArray &&
          this.state.showHideParentArray.length === 0
        ) {
          // add config in all children
          let defaultConfig = {
            displaycondition: [
              {
                value: "",
                source: "",
              },
            ],
          };
          if (!(childIndex === undefined)) {
            // if change made in childRow columns
            sections[sectionIndex].rows[rowIndex].children = sections[
              sectionIndex
            ].rows[rowIndex].children.map((child) => ({
              ...child,
              config: defaultConfig,
            }));
          } else {
            sections[sectionIndex].rows = sections[sectionIndex].rows.map(
              (row) => ({ ...row, config: defaultConfig })
            );
          }
        }
      }
      if (!(childIndex === undefined)) {
        // if change made in childRow
        sections[sectionIndex].rows[rowIndex].children[childIndex].columns[
          columnIndex
        ] = elemProps;
      } else {
        // change was made in row
        sections[sectionIndex].rows[rowIndex].columns[columnIndex] = elemProps;
      }
      this.setState({ elemProps: null, element: null }, () =>
        this.props.onFormChange(sections)
      );
    } catch (err) {
      console.log(err);
      this.logErrorToService(err, "FE: EditContainer : onColMetaSave");
    }
  };

  onRowMetaSave = (newProps) => {
    try {
      const { sectionIndex, rowIndex, childIndex } = this.state.elementConfig;
      let oldProps = this.state.elemProps;
      const isChildRow = !(childIndex === undefined);
      if (!isChildRow && newProps["type"] !== oldProps["type"]) {
        this.onRowDisplayTypeChanged(newProps["type"]);
      }
      if (newProps["config"] !== oldProps["config"] && newProps.config) {
        let value = newProps["config"]["displaycondition"][0]["value"];
        if (value && value.split(",").length)
          newProps = { ...newProps, class: "deferRendering hide" };
        if (!value || !value.trim()) newProps = { ...newProps, class: "" };
      }
      if (this.state.element === "subSection") {
        let newRow = this.defaultCompactTableRowProps(
          newProps.noOfColumns,
          newProps.header
        );
        newProps = { ...newProps, children: [newRow] };
      }
      let sections = this.props.sections;
      if (isChildRow) {
        // if change made in childRow
        sections[sectionIndex].rows[rowIndex].children[childIndex] = newProps;
      } else {
        // change was made in row
        sections[sectionIndex].rows[rowIndex] = newProps;
      }
      this.setState({ elemProps: null, element: null }, () =>
        this.props.onFormChange(sections)
      );
    } catch (err) {
      this.logErrorToService(err, "FE: EditContainer : onRowMetaSave");
    }
  };

  onEditElement = (element, meta) => {
    try {
      const {
        sectionIndex,
        rowIndex,
        childIndex,
        columnIndex,
        colIdArray,
        showHideParentArray,
        availableNameArr,
      } = meta;
      let elemProps;
      if (element === "row") element = meta.row["type"];
      switch (element) {
        case "section":
          elemProps = this.props.sections[sectionIndex];
          break;
        case "group":
        case "row":
        case "subSection":
        case "childrow":
        case "compactRow":
          elemProps = meta.row;
          break;
        case "childrow":
          elemProps = meta.row;
          break;
        case "column":
          elemProps = meta.column;
          break;
      }
      this.setState({
        element,
        elemProps,
        colIdArray,
        showHideParentArray,
        availableNameArr,
        elementConfig: { sectionIndex, rowIndex, childIndex, columnIndex },
        //  ,currentSectionId: this.props.sections[sectionIndex].id
      });
    } catch (err) {
      this.logErrorToService(err, "FE: EditContainer : onEditElement");
    }
  };

  createSectionByPopUp = () => {
    this.setState({
      element: "section",
      elemProps: this.defaultSectionProps(),
      elementConfig: {
        sectionIndex: this.props.sections ? this.props.sections.length : 0,
        rowIndex: null,
        childIndex: null,
        columnIndex: null,
      },
      openCreateSectionPopup: true,
    });
  };

  onElementSave = (savedData) => {
    try {
      const { element } = this.state;
      let { elemProps } = savedData;
      switch (element) {
        case "section":
          this.onSectionMetaSave(elemProps);
          break;
        case "group":
        case "row":
        case "childrow":
        case "subSection":
        case "compactRow":
          this.onRowMetaSave(elemProps);
          break;
        case "column":
          this.onColMetaSave(elemProps);
          break;
        default:
      }
    } catch (err) {
      this.logErrorToService(err, "FE: EditContainer : onElementSave");
    }
  };

  saveDatainUiDict = (uiData) => {
    let section = this.props.sections[this.state.elementConfig.sectionIndex];
    let { uiDataDic } = section;
    uiDataDic = {
      ...uiDataDic,
      [uiData.key]: { ["options"]: uiData.value },
    };
    this.changeSectionMeta({
      sectionId: section.id,
      name: "uiDataDic",
      value: uiDataDic,
    });
  };

  changeSection = (idx) => {
    this.setState({ currentSectionIdx: idx });
  };

  shouldShowPrimaryDate() {
    let { element, elemProps, elementConfig } = this.state;
    if (
      !this.props.sections ||
      !this.props.sections.length ||
      !elementConfig ||
      !elementConfig.sectionIndex ||
      !this.props.sections[elementConfig.sectionIndex]
    )
      return false;
    if (!elemProps || !elementConfig || !this.props.sections) return;
    if (
      element === "column" &&
      elemProps.type === "date" &&
      elemProps.primaryDate
    ) {
      return true;
    }
    let showDate =
      !this.props.globalConfig ||
      !this.props.globalConfig.hasOwnProperty(
        this.props.sections[elementConfig.sectionIndex].formname
      ) ||
      !this.props.globalConfig[
        this.props.sections[elementConfig.sectionIndex].formname
      ].primaryDate;
    return showDate;
  }

  emitPrimaryDate = (value) => {
    let { globalConfig } = this.props;
    let sectionName =
      this.props.sections[this.state.elementConfig.sectionIndex].formname;
    if (!globalConfig) {
      globalConfig = {};
    }
    if (!globalConfig.hasOwnProperty(sectionName)) {
      globalConfig = {
        ...globalConfig,
        [sectionName]: {},
      };
    }
    if (!globalConfig[sectionName].primaryDate || !value) {
      globalConfig[sectionName] = {
        ...globalConfig[sectionName],
        primaryDate: value,
      };
    }
    this.props.changeGlobalConfig(globalConfig);
  };

  closeCreateSectionPopup = () => {
    this.setState({
      openCreateSectionPopup: false,
      elemProps: null,
      element: null,
      elementConfig: null,
    });
  };

  onEmitSelectedRow = (key) => {
    let templateRow = CustomRowJSON[key];
    let { sections } = this.props;
    let { sectionIndex } = this.state.elementConfig;
    if (
      !sections ||
      !sections.length ||
      !this.state.elementConfig ||
      !sections[sectionIndex]
    )
      return;
    sections[sectionIndex].rows.push(templateRow);
    if (key === "drug_row") {
      sections[sectionIndex].uiDataDic = {
        ...sections[sectionIndex].uiDataDic,
        ["drugs_route_uidata"]: {
          ["options"]: CustomRowJSON["drugs_route_uidata"],
        },
        ["drugs_frequency_uidata"]: {
          ["options"]: CustomRowJSON["drugs_frequency_uidata"],
        },
      };
    }
    this.setState(
      { elemProps: null, element: null, openCustomModal: false },
      () => this.props.onFormChange(sections)
    );
  };

  onAddCustomRowEmit = (meta) => {
    this.onEditElement("section", meta);
    this.setState({ openCustomModal: true });
  };

  render() {
    let { sections } = this.props;
    let showPrimaryDate = this.shouldShowPrimaryDate();
    let currSection =
      sections && sections.length
        ? sections[this.state.currentSectionIdx]
          ? sections[this.state.currentSectionIdx]
          : sections[0]
        : null;
    return (
      <>
        <div style={{ height: "100%" }}>
          {/* Edit Container */}
          <div>
            <p className="media-only-mobile mobile-only-view-label">
              *Please use desktop for best experience*
            </p>
            <div className="section-selector row">
              <div
                className="form-place-holder col-md-1"
                onClick={this.createSectionByPopUp.bind(this)}
              >
                <div style={{ display: "inline-block", marginRight: "5px" }}>
                  <i className="mdi mdi-plus"></i>
                </div>
                <p style={{ display: "inline" }}>Add a New Section..</p>
              </div>
              <div className="col-md-11">
                <ul>
                  {sections &&
                    sections.map((section, idx) => (
                      <li
                        key={idx}
                        onClick={this.changeSection.bind(this, idx)}
                      >
                        <Button
                          type="secondary"
                          style={{ margin: "5px" }}
                          size="small"
                        >
                          {section.title ? section.title : `Section ${idx + 1}`}
                        </Button>
                      </li>
                    ))}
                </ul>
              </div>
            </div>

            <div className="editor row">
              <Row>
                {sections && !!sections.length && (
                  <Col span={16} className="col-md-9">
                    {/* {sections.map((section, idx) => */}
                    <div
                      className="section-form"
                      ref={currSection.id} /*key={idx}*/
                    >
                      <Section
                        // currentSectionId={this.state.currentSectionId}
                        section={currSection}
                        key={currSection["id"]}
                        serialNo={this.state.currentSectionIdx + 1}
                        onSectionMetaEmit={this.changeSectionMeta.bind(this)}
                        onDelSectionButtonEmit={this.deleteSection.bind(this)}
                        onRowAddition={this.addRow.bind(this)}
                        onRowDeletion={this.deleteRow.bind(this)}
                        onEditRow={this.onEditElement.bind(this, "row")}
                        onFreeTextChange={this.onFreeTextValueChange.bind(this)}
                        onEditSubrow={this.onEditElement.bind(this, "childrow")}
                        onEditColumn={this.onEditElement.bind(this, "column")}
                        cloneSection={this.onCloneSection.bind(this)}
                        onEditSection={this.onEditElement.bind(this, "section")}
                        onAddCustomRow={this.onAddCustomRowEmit.bind(this)}
                      />
                    </div>
                  </Col>
                )}
                {((sections && !!sections.length) ||
                  this.state.openCreateSectionPopup) && (
                  <Col span={8}>
                    <Row>
                      <ComponentSelectorParent
                        openPopUp={this.state.openCreateSectionPopup}
                        closeCreateSectionPopup={this.closeCreateSectionPopup.bind(
                          this
                        )}
                      >
                        {this.state.element &&
                          this.state.elemProps &&
                          this.state.elementConfig && (
                            <ComponentSelectorModal
                              elemProps={this.state.elemProps}
                              isInsidePopup={this.state.openCreateSectionPopup}
                              sectionOffset={
                                sections[this.state.elementConfig.sectionIndex]
                                  ? sections[
                                      this.state.elementConfig.sectionIndex
                                    ]["sectionOffset"]
                                  : ""
                              }
                              colIdArray={this.state.colIdArray}
                              availableNameArr={this.state.availableNameArr}
                              tagConfigList={this.state.tagConfigList}
                              element={this.state.element}
                              showPrimaryDate={showPrimaryDate}
                              emitPrimaryDate={this.emitPrimaryDate.bind(this)}
                              uiDataDict={
                                sections[this.state.elementConfig.sectionIndex]
                                  ? sections[
                                      this.state.elementConfig.sectionIndex
                                    ]["uiDataDic"]
                                  : {}
                              }
                              showHideParentArray={
                                this.state.showHideParentArray
                              }
                              onElementSave={(e) => this.onElementSave(e)}
                              onUiDictDataEmit={this.saveDatainUiDict.bind(
                                this
                              )}
                            />
                          )}
                      </ComponentSelectorParent>
                    </Row>
                  </Col>
                )}
              </Row>
            </div>
            <CommonModalView
              open={this.state.openCustomModal}
              title={"Add Template Row"}
              size="lg"
              emitModalCloseEvent={() =>
                this.setState({ openCustomModal: false })
              }
            >
              <CustomRow
                emitSelectedRow={this.onEmitSelectedRow.bind(this)}
                showType={
                  this.state.elemProps ? this.state.elemProps.showType : "none"
                }
              />
            </CommonModalView>
          </div>
        </div>
      </>
    );
  }
}

export function CustomRow({ emitSelectedRow, showType }) {
  const [selectedRowKey, setSelectedRow] = useState("");
  const examples =
    showType === "compactTextbox"
      ? CustomRowExamples.compact_textbox
      : CustomRowExamples.general;
  return (
    <>
      {examples.map((row) => (
        <div className="form-group mb-3 col-12 border-bottom">
          <div className="form-check form-check-danger">
            <label className="form-check-label" htmlFor="name">
              {row.label}
              <input
                type="radio"
                className="form-check-input"
                name={row.key}
                checked={selectedRowKey === row.key}
                onChange={(e) => setSelectedRow(row.key)}
              />{" "}
              <i className="input-helper"></i>
            </label>
            <img src={`/images/template-row/${row.src}`} />
          </div>
        </div>
      ))}
      <button
        disabled={!selectedRowKey}
        className="btn btn-success btn-rounded"
        onClick={() => emitSelectedRow(selectedRowKey)}
      >
        Add Row!
      </button>
    </>
  );
}

export function ComponentSelectorParent({
  children,
  openPopUp,
  closeCreateSectionPopup,
}) {
  return (
    (openPopUp && (
      //visible for section Creation
      <CommonModalView
        open={openPopUp}
        title={"Section Details"}
        emitModalCloseEvent={closeCreateSectionPopup.bind(this)}
      >
        <div>{children}</div>
      </CommonModalView>
    )) || (
      //always visible on the right
      <div
        className="col-md-3 properties-editor-container"
        style={{ width: "100%", padding: "5px" }}
      >
        <h6>
          Click on <i className="mdi mdi-pencil-box-outline"></i> of any element
          to start editing.
        </h6>
        {children}
      </div>
    )
  );
}
