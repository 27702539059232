import {
  Card,
  Col,
  Modal,
  Row,
  Select,
  Skeleton,
  Space,
  Spin,
  Typography,
} from "antd";
import moment from "moment";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as BackIcon } from "../../../../assets/icons/backicon.svg";
import {
  fetchIPDAppointment,
  fetchIPDAppointmentById,
} from "../../../../redux/reducers/appointment.slice";
import {
  fetchPatientsDischargeSummary,
  fetchPatientsIPDSummary,
  fetchPrescriptions,
  fetchPrescriptionsLabReports,
  reset_prescription,
} from "../../../../redux/reducers/prescription.slice";
import {
  fetchPatientDocumentFileById,
  fetchUploadedFileById,
} from "../../../../redux/reducers/upload.slice";
import { RootState } from "../../../../shared/constants";
import FileViewLayout from "../../../../shared/layouts/file-view.layout";
import FilesLayout from "../../../../shared/layouts/files.layout";
import PatientNameCard from "../../../../shared/layouts/name-card";
import NoDataLayout from "../../../../shared/layouts/no-data.layout";
import {
  formatDate,
  formatOnlyDate,
  getDayFormat,
} from "../../../../shared/Utils/utilities";
import PatientList from "../../Patients/patients.page";

import AppointmentDetailsIPD from "./appointment-detailsIPD";
import AppointmentRecords from "./appointment-records";
import type { TPatientFilterData } from "./patientFilterTabs";

var _ = require("lodash");
const { Text } = Typography;
const { Option } = Select;
type Props = {
  appointment?: any;
};

// type of IPD Patient Filter Tabs
type TIPDPatientFiltersTabs = "Preadmission" | "Discharged" | "Admit";

interface IIPDFilterTabsProps {
  onChangeFilter: (d: TIPDPatientFiltersTabs) => void;
  patientFiltersData: TPatientFilterData<TIPDPatientFiltersTabs>;
  selectedFilter: string;
  loading: boolean;
}

// Specify the order of tabs
const tabOrder: TIPDPatientFiltersTabs[] = [
  "Preadmission",
  "Admit",
  "Discharged",
];

const IPDFilterTabs: React.FC<IIPDFilterTabsProps> = ({
  onChangeFilter,
  selectedFilter,
  patientFiltersData,
  loading,
}) => {
  return (
    <Row
      style={{
        width: "calc(100% + 60px)",
        marginLeft: "-20px",
        marginRight: "-20px",
      }}
    >
      {loading ? (
        <Space
          style={{
            paddingLeft: "20px",
            paddingRight: "20px",

            width: "100%",
            height: "74px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <Skeleton.Button active size="default" shape="default" />
          <Skeleton.Button active size="default" shape="default" />
          <Skeleton.Button active size="default" shape="default" />
        </Space>
      ) : (
        tabOrder.map((key) => {
          const val = patientFiltersData[key];
          const isSelected = selectedFilter === key;

          return (
            <Col
              key={key}
              span={8}
              style={{
                height: "74px",
              }}
              className="patient_tab_col"
            >
              <div
                className="patient_tab"
                onClick={() => onChangeFilter(key)}
                data-isSelected={isSelected}
              >
                <div className="doctor-primary-color bold stat">
                  {val.noOfPatients}
                </div>
                <div className="doctor-primary-color font12">{val.title}</div>
              </div>
            </Col>
          );
        })
      )}
    </Row>
  );
};

const IPDAppointments: React.FunctionComponent<Props> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const _appointmentData: any = location?.state || {};
  const { prescriptions, labreports, dischargeSummary, ipdsummary } =
    useSelector((state: RootState) => state.prescription);
  const [detail, setDetails] = useState(false);
  const {
    loading: loader,
    appointments,
    appointment,
  } = useSelector((state: RootState) => state.appointment);
  const [appointmentData, setAppointmentData] = useState(null as any);
  const { profile } = useSelector((state: RootState) => state.profile);
  const [timeFilter, setTimeFilter] = useState(6 as any);
  const [intialFilter, setFilter] = useState("Admit");
  const [isDocumentView, setIsDocumentsView] = useState(false);
  const [fileviewer, setFileViewer] = useState(false);
  const [_file, setFile] = useState(null as any);
  const [loading, setLoading] = useState(false as any);
  const { signedUrl } = useSelector((state: RootState) => state.upload);
  const [viewDetails, setViewDetails] = useState(false);

  useEffect(() => {
    dispatch(
      fetchIPDAppointment({
        ipd_date: _appointmentData?.date,
        branch_id: _appointmentData?.branch,
        doctor_id: profile.id,
      })
    );
  }, [_appointmentData?.branch, _appointmentData?.date, dispatch, profile.id]);

  const showAppointmentDetails = async (item: any) => {
    setLoading(true);
    setTimeFilter(6);
    await dispatch(reset_prescription());
    try {
      await dispatch(fetchIPDAppointmentById(item?.max_id));
      setAppointmentData(item);

      await dispatch(
        fetchPatientsIPDSummary({
          uhid: item?.max_id,
          status:
            intialFilter === "Preadmission"
              ? "PRE_ADMISSION"
              : intialFilter === "Discharged"
              ? "DISCHARGED"
              : "ADMITTED",
        })
      );

      if (item?.admit_status !== "Preadmission") {
        await dispatch(
          fetchPrescriptions({
            max_id: item?.max_id,
            end_date: moment().format("YYYY-MM-DD"),
            start_date: moment().subtract(6, "month").format("YYYY-MM-DD"),
          })
        );
        await dispatch(
          fetchPrescriptionsLabReports({
            max_id: item?.max_id,
            flow: "OP",
            end_date: moment().format("YYYY-MM-DD"),
            start_date: moment().subtract(6, "month").format("YYYY-MM-DD"),
          })
        );
        await dispatch(
          fetchPatientsDischargeSummary({
            uhid: item?.max_id,
            start_date: moment().subtract(6, "month").format("YYYY-MM-DD"),
            end_date: moment().format("YYYY-MM-DD"),
          })
        );
      }

      if (item?.admit_status === "Admit") {
        setIsDocumentsView(true);
      } else {
        setIsDocumentsView(false);
      }
      setDetails(true);
      setLoading(false);
    } catch (error) {}
  };

  const getUserPrescriptions = async (time: any) => {
    setTimeFilter(time);
    await dispatch(
      fetchPrescriptions({
        max_id: appointmentData?.max_id || appointment?.patient?.uhid,
        end_date: moment().format("YYYY-MM-DD"),
        start_date: moment().subtract(time, "month").format("YYYY-MM-DD"),
      })
    );
    await dispatch(
      fetchPrescriptionsLabReports({
        max_id: appointmentData?.max_id || appointment?.patient?.uhid,
        start_date: moment().subtract(time, "month").format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
        flow: "OP",
      })
    );
    await dispatch(
      fetchPatientsDischargeSummary({
        uhid: appointmentData?.max_id || appointment?.patient?.uhid,
        start_date: moment().subtract(time, "month").format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
      })
    );
  };
  const filters = useMemo(
    () => _.groupBy(appointments, "admit_status"),
    [appointments]
  );

  const filterAppointments = useCallback((type: any) => {
    setFilter(type);
    setDetails(false);
  }, []);

  const showFileDetails = useCallback(
    (item: any, type: any) => {
      (async function () {
        try {
          setFileViewer(true);

          let response;
          if (type === "doctor") {
            response = await dispatch(
              fetchUploadedFileById({
                type: item.type,
                id: item.document_data_id || item.data_id,
              })
            );
          } else {
            response = await dispatch(fetchPatientDocumentFileById(item.id));
          }
          if (
            response.payload.status === 200 ||
            response.payload.status === 201
          ) {
            setFile(item);
            setFileViewer(true);
          }
        } catch (e) {
          console.log(e);
        }
      })();
    },
    [dispatch]
  );

  const patientFiltersData: TPatientFilterData<TIPDPatientFiltersTabs> =
    useMemo(() => {
      return {
        Preadmission: {
          title: "Pre-Admission",
          noOfPatients: filters["Preadmission"]
            ? filters["Preadmission"].length
            : 0,
        },
        Discharged: {
          title: "Discharged",
          noOfPatients: filters["Discharged"]
            ? filters["Discharged"].length
            : 0,
        },

        Admit: {
          title: "Admitted",
          noOfPatients: filters["Admit"] ? filters["Admit"].length : 0,
        },
      };
    }, [filters]);

  return (
    <Row>
      <Col
        span={7}
        className="full-height white-background padding20 overflowhide border-right"
      >
        <Row style={{ height: "56px" }}>
          <Col span={20}>
            <Space style={{ alignItems: "baseline" }}>
              {/* <LeftOutlined style={{ fontSize: "15px" }} className="cursor" onClick={() => history.goBack()} /> */}
              <BackIcon
                style={{ fontSize: "15px" }}
                className="cursor"
                onClick={() => navigate(-1)}
              />
              <span
                style={{ fontSize: "18px" }}
                className="bold doctor-primary-color "
              >
                IPD Patients
              </span>
            </Space>
          </Col>
          <Col span={4} className="text-right "></Col>
        </Row>

        <Row
          className="doctor-light-background padding20 borderRightGray nomarginLR20"
          style={{ marginRight: "-20px" }}
        >
          <Col span={24}>
            <Text className="cursor font15 capitalize branchColor">
              <strong>{_appointmentData.branch_name}</strong>
            </Text>{" "}
            <br />
            <h4
              className="doctor-primary-color nopadding"
              style={{ marginBottom: "17px" }}
            >
              {" "}
              <span className="underline2">
                {getDayFormat(_appointmentData.date, false)}
              </span>
              , {formatOnlyDate(_appointmentData.date)}{" "}
            </h4>
          </Col>
        </Row>
        <Row>
          <IPDFilterTabs
            onChangeFilter={filterAppointments}
            selectedFilter={intialFilter}
            patientFiltersData={patientFiltersData}
            loading={loader}
          />

          <Col span={24} className={"mt10"}>
            {intialFilter === "Preadmission" ? (
              <p className="doctor-primary-color">
                Showing pre-admitted patients as per HIS
              </p>
            ) : intialFilter === "Discharged" ? (
              <p className="doctor-primary-color">
                Showing patients discharged in last 7 days
              </p>
            ) : (
              <p className="doctor-primary-color">
                Showing admitted patients as per HIS
              </p>
            )}
          </Col>
        </Row>
        <Row className={"nomargin"}>
          {loader ? (
            <Col span={20} offset={2} className={"mt20"}>
              <Skeleton active></Skeleton>{" "}
              <Skeleton active className={"mt20"}></Skeleton>
            </Col>
          ) : (
            <Col span={24} className={"nopadding mt20"}>
              <PatientList
                type={2}
                selectedAppointment={appointment}
                appointments={_.filter(appointments, {
                  admit_status: intialFilter,
                })}
                showDetails={(item: any) => showAppointmentDetails(item)}
              ></PatientList>
            </Col>
          )}
        </Row>
      </Col>
      <Col span={17} className="full-height">
        {loading ? (
          <Row>
            {" "}
            <Col span={24} className={"text-center mt200"}>
              {" "}
              <Spin className={"mt200 text-center"}></Spin>{" "}
              <p>Fetching Details</p>{" "}
            </Col>
          </Row>
        ) : !detail ? (
          <NoDataLayout
            className={"mt200"}
            placeholder={"Select a patient to view their details"}
          ></NoDataLayout>
        ) : (
          <Fragment>
            <Row className="h76">
              <Col span={24} className=" white-background paddingLR20">
                <PatientNameCard
                  type={"ipd"}
                  details={appointmentData}
                  showBack={false}
                ></PatientNameCard>
              </Col>
            </Row>

            {isDocumentView ? (
              <Row
                className="timepicker mt-10 padding20"
                style={{
                  paddingBottom: "15px",
                  display: "block",
                  width: "100%",
                }}
              >
                <Col span={24} className="white-background padding20">
                  <small className="timepickers">
                    Time Filter <br></br>
                  </small>
                  <Select
                    style={{ width: "20%" }}
                    placeholder={"Choose Time Filter"}
                    value={timeFilter || 6}
                    onChange={(evt: any) => getUserPrescriptions(evt)}
                  >
                    <Option value={1}>1 Month</Option>
                    <Option value={3}>3 Months</Option>
                    <Option value={6}>6 Months</Option>
                    <Option value={12}>12 Months</Option>
                  </Select>
                </Col>
                <Col span={24}>
                  {loader ? (
                    <p className={"text-center md200"}>
                      <Spin></Spin>
                    </p>
                  ) : (
                    ""
                  )}

                  {prescriptions.length === 0 &&
                  labreports.length === 0 &&
                  ipdsummary?.steps.length === 0 &&
                  dischargeSummary?.discharge_reports?.length === 0 ? (
                    <Col span={24} className="white-background mt-20">
                      <NoDataLayout
                        placeholder={"No records found"}
                        className={"mt20"}
                        vh={"80vh"}
                      ></NoDataLayout>
                    </Col>
                  ) : (
                    ""
                  )}
                </Col>

                {prescriptions.length !== 0 ? (
                  <Row
                    className="mt-20"
                    style={{ paddingBottom: "15px", margin: "-20px" }}
                  >
                    <Col span={24}>
                      <FilesLayout
                        files={prescriptions}
                        modal={true}
                        type={"doctor"}
                        showPatientCard={false}
                        details={appointment.patient}
                        showBack={false}
                      ></FilesLayout>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}

                {labreports.length !== 0 ? (
                  <Row
                    className="mt-20"
                    style={{ paddingBottom: "15px", margin: "-20px" }}
                  >
                    <Col span={24}>
                      <FilesLayout
                        files={labreports}
                        modal={true}
                        type={"doctor"}
                        showPatientCard={false}
                        details={appointment.patient}
                        showBack={false}
                      ></FilesLayout>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}

                {dischargeSummary &&
                  dischargeSummary?.discharge_reports &&
                  dischargeSummary?.discharge_reports?.length !== 0 && (
                    <Row className="">
                      <Col span={24} className="doctor-color-heading2">
                        <Row>
                          <Col span={24} className={""}>
                            <span className="doctor-primary-color font15 bold">
                              Discharge Summary
                            </span>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24} className="white-background padding-main">
                        <Row>
                          {dischargeSummary?.discharge_reports.map(
                            (file: any) => {
                              return (
                                <Col xs={24} sm={12} md={6} lg={4} xl={4}>
                                  {" "}
                                  <Card
                                    className={"padding10 mb-10"}
                                    title={
                                      <p className="text-center">
                                        <div style={{ height: "50px" }}>
                                          <img
                                            onClick={() =>
                                              window.open(file.url)
                                            }
                                            alt="img"
                                            src={
                                              require("../../../../assets/images/doc.svg")
                                                .default
                                            }
                                            height="38px"
                                            width={"60%"}
                                            className={"cursor"}
                                          ></img>
                                        </div>
                                      </p>
                                    }
                                    bordered={true}
                                  >
                                    <p className={"text-center font12"}>
                                      {formatDate(file.created_at, "DD MMM'YY")}
                                    </p>
                                  </Card>{" "}
                                </Col>
                              );
                            }
                          )}

                          {/* <FilesLayout
                                  files={dischargeSummary?.discharge_reports}
                                  modal={true}
                                  type={"doctor"}
                                  showPatientCard={false}
                                  details={appointment.patient}
                                  showBack={false}
                                ></FilesLayout> */}
                        </Row>
                      </Col>
                    </Row>
                  )}
              </Row>
            ) : (
              <>
                <Col
                  span={24}
                  className="doctor-box-shadow doctor-top-border padding20 full-height"
                >
                  {appointmentData?.admit_status !== "Discharged" &&
                    appointment &&
                    Object.keys(appointment).length !== 0 && (
                      <Row>
                        <Col span={24} className="doctor-color-heading2">
                          <Row>
                            <Col span={24} className={""}>
                              <span className="doctor-primary-color font15 bold">
                                Appointment Details{" "}
                              </span>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <AppointmentDetailsIPD
                            ipd_status={appointmentData?.ipd_status}
                            appointment={appointment}
                          ></AppointmentDetailsIPD>
                        </Col>
                      </Row>
                    )}

                  {ipdsummary &&
                    ipdsummary?.steps &&
                    ipdsummary?.steps.map((item: any) => {
                      return (
                        <Row className="mt20">
                          <Col span={24} className="doctor-color-heading2 ">
                            <Row>
                              <Col span={24} className={""}>
                                <span className="doctor-primary-color font15 bold">
                                  {item?.section}
                                </span>
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            span={24}
                            className="white-background padding10"
                            style={{ paddingLeft: "20px" }}
                          >
                            <Row className="mt10">
                              <Col span={12}>
                                <p className="doctor-primary-color">
                                  {item.info.map((item: any) => {
                                    return (
                                      <>
                                        {item} <br></br>
                                      </>
                                    );
                                  })}
                                </p>
                              </Col>
                              <Col span={12} className="text-right">
                                {item?.action && (
                                  <p
                                    className="doctor-primary-color cursor"
                                    onClick={() => setViewDetails(true)}
                                  >
                                    {item?.action?.button_name}
                                  </p>
                                )}
                              </Col>
                            </Row>
                          </Col>
                          <Modal
                            footer={false}
                            visible={viewDetails}
                            onCancel={() => setViewDetails(false)}
                          >
                            <ul>
                              {item?.action?.info.map((_item: any) => {
                                return <li>{_item}</li>;
                              })}
                            </ul>
                          </Modal>
                        </Row>
                      );
                    })}

                  {/* All Healthcare Records */}
                  {(appointmentData?.admit_status !== "Preadmission" &&
                    prescriptions.length !== 0) ||
                    (labreports.length !== 0 && (
                      <Row className="mt20">
                        <Col span={24} className="doctor-color-heading2">
                          <Row>
                            <Col span={20} className={""}>
                              <span className="doctor-primary-color font15 bold">
                                Max Healthcare Records
                              </span>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24} className="white-background">
                          <AppointmentRecords
                            type={"all"}
                            prescriptions={[...prescriptions, ...labreports]}
                            showNoData={true}
                            isPrescription={true}
                            details={appointment}
                            openFilesUploaded={(type: any) =>
                              setIsDocumentsView(true)
                            }
                            showFileDetails={(item: any) =>
                              showFileDetails(item, "doctor")
                            }
                          ></AppointmentRecords>
                        </Col>
                      </Row>
                    ))}

                  {prescriptions.length === 0 &&
                    labreports.length === 0 &&
                    Object.keys(appointment).length === 0 && (
                      <NoDataLayout
                        placeholder="No Data Found"
                        className=""
                      ></NoDataLayout>
                    )}
                </Col>
              </>
            )}
          </Fragment>
        )}
      </Col>

      <FileViewLayout
        modal={true}
        details={_file}
        fileviewer={fileviewer}
        setFileViewer={(value: any) => setFileViewer(value)}
        file={signedUrl}
      ></FileViewLayout>
    </Row>
  );
};

export default IPDAppointments;
