import { Button, Layout, List, Typography } from "antd";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { capitalizeFirstLetter, debouncedSearch } from "../../../helpers";
import { Client } from "../../../shared/Utils/api.client-prom";
import { PROM_GET_ALL_DOCTOR } from "../../../shared/routes/doctor.routes.constants";
import LoadingProm from "./Components/loadingProm";
import { PatientsList } from "./Components/patientsList";
import "./styles/prom.doctor.scss";

const { Title, Text } = Typography;

interface Doctor_Type {
  doctor_id: string;
  doctor_name: string;
  patients_info: PatientInfo[];
}

interface PatientInfo {
  procedure_id: number;
  procedure_name: string;
  form_id: number;
  form_name: string;
  patient_id: string;
  patient_name: string;
  combined_response_status: string[];
  created_at: string;
}

const PAGE_SIZE = 20;

async function fetchDoctorReq(cursor: string, searchString: string) {
  const response = await Client.post(PROM_GET_ALL_DOCTOR, {
    limit: PAGE_SIZE,
    cursor,
    searchString,
  });

  return response;
}

function PromDoctor() {
  const [isLoadingDoctors, setIsLoadingDoctors] = useState(false);
  const [nextCursor, setNextCursor] = useState<string>("MA==");

  const [hasMoreDoctors, setHasMoreDoctors] = useState(false);

  const [accumulatedDoctors, setAccumulatedDoctors] = useState<any[]>([]);

  const [filterPatientDropdown, setFilterPatientDropdown] = useState(false);

  const [searchText, setSearchText] = useState<string>("");

  const [selectedDoctor, setSelectedDoctor] = useState<any | null>(null);
  const [activeTab, setActiveTab] = useState("In-Progress");

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any | null>([]);

  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const tabContentRef = useRef<HTMLDivElement | null>(null);
  const svgRef = useRef<SVGSVGElement | null>(null);

  const procedures = useMemo(() => {
    return selectedDoctor?.patients_info.reduce((acc: any, patient: any) => {
      const {
        procedure_name,
        procedure_id,
        form_id,
        form_name,
        patient_id,
        patient_name,
        combined_response_status,
        created_at,
      } = patient;

      const existingProcedure = acc.find(
        (item: any) => item?.procedure_id === procedure_id
      );

      if (existingProcedure) {
        existingProcedure?.patient_details?.push({
          patient_id,
          patient_name,
          combined_response_status,
          created_at,
          procedure_name,
          form_id,
          procedure_id,
          doctor_id: selectedDoctor?.doctor_id,
        });
      } else {
        // if (selectedDoctor?.doctor_id === selectedItem?.doctor_id) {
        acc.push({
          procedure_id,
          procedure_name,
          form_id,
          form_name,
          doctor_id: selectedDoctor?.doctor_id,
          patient_details: [
            {
              patient_id,
              patient_name,
              combined_response_status,
              created_at,
              procedure_name,
              form_id,
              procedure_id,
              doctor_id: selectedDoctor?.doctor_id,
            },
          ],
        });
      }

      return acc;
    }, []);
  }, [selectedDoctor?.doctor_id, selectedDoctor?.patients_info]);

  const dropdown = procedures;

  // filter for started pending and completed
  let startedOnly: any = [];
  let startedCompleted: any = [];
  let completedOnly: any = [];

  if (Array.isArray(selectedItem?.patient_info)) {
    selectedItem?.patient_info?.forEach((patient: any) => {
      if (selectedItem?.doctor_id === selectedDoctor?.doctor_id) {
        if (
          (!patient?.combined_response_status.includes("started") &&
            patient?.combined_response_status.includes("pending") &&
            !patient?.combined_response_status.includes("completed")) ||
          (patient?.combined_response_status.includes("started") &&
            patient?.combined_response_status.includes("pending") &&
            !patient?.combined_response_status.includes("completed"))
        ) {
          startedOnly.push(patient);
        } else if (
          (patient?.combined_response_status.includes("started") &&
            patient?.combined_response_status.includes("pending") &&
            patient?.combined_response_status.includes("completed")) ||
          (!patient?.combined_response_status.includes("started") &&
            patient?.combined_response_status.includes("pending") &&
            patient?.combined_response_status.includes("completed"))
        ) {
          startedCompleted.push(patient);
        } else if (
          patient?.combined_response_status.includes("completed") &&
          !patient?.combined_response_status.includes("started") &&
          !patient?.combined_response_status.includes("pending")
        ) {
          completedOnly.push(patient);
        }
      }
    });
  }

  // all tab data
  const tabs = [
    {
      label: "New",
      content: (
        <PatientsList
          data={startedOnly}
          setFilterPatientDropdown={setFilterPatientDropdown}
          filterPatientDropdown={filterPatientDropdown}
        />
      ),
    },
    {
      label: "In-Progress",
      content: (
        <PatientsList
          data={startedCompleted}
          setFilterPatientDropdown={setFilterPatientDropdown}
          filterPatientDropdown={filterPatientDropdown}
        />
      ),
    },
    {
      label: "Completed",
      content: (
        <PatientsList
          data={completedOnly}
          setFilterPatientDropdown={setFilterPatientDropdown}
          filterPatientDropdown={filterPatientDropdown}
        />
      ),
    },
  ];

  // doctor sorted
  // const filteredDoctorSorted = allDoctorList?.sort((a: any, b: any) => {
  //   if (filterDoctor === "name") {
  //     return a?.doctor_name.localeCompare(b?.doctor_name);
  //   } else if (filterDoctor === "id") {
  //     return a?.doctor_id.localeCompare(b?.doctor_id);
  //   }
  //   return 0;
  // });

  const handleDoctorClick = (doctor: Doctor_Type) => {
    setActiveTab("In-Progress");
    setSelectedDoctor(doctor);
    setFilterPatientDropdown(false);
  };

  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
    setFilterPatientDropdown(false);
  };

  const closeDropdown = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setDropdownOpen(false);
    }
  };

  const handleDropdownClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    item: any
  ) => {
    event.preventDefault();
    setSelectedItem({
      procedures_name: item?.procedure_name,
      form_name: item?.form_name,
      patient_info: item?.patient_details,
      doctor_id: item?.doctor_id,
    });
  };

  // TODO: optimise this to preserve the data instead of calling another req on load more
  async function hasMoreAtNextCursor(
    cursor: string,
    searchString = ""
  ): Promise<boolean> {
    const res = await fetchDoctorReq(cursor, searchString);
    if (res?.status === 200 && res?.data?.doctors.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  const handleFetchDoctors = useCallback(
    async (cursor = "MA==", searchString = "") => {
      try {
        setIsLoadingDoctors(true);

        const res = await fetchDoctorReq(cursor, searchString);

        setIsLoadingDoctors(false);

        if (res?.status === 200) {
          setNextCursor(res.data?.nextCursor);

          setAccumulatedDoctors((prev) => [...prev, ...res.data?.doctors]);

          if (cursor === "MA==") {
            setSelectedDoctor(res?.data.doctors?.[0]);
          }

          const hasMore = await hasMoreAtNextCursor(
            res.data?.nextCursor,
            searchString
          );
          setHasMoreDoctors(hasMore);

          return {
            nextCursor: res.data?.nextCursor,
            doctors: res.data?.doctors,
          };
        }
      } catch (e) {
        console.error(e);
      }
    },
    []
  );

  const handleSearchChange = useCallback(
    (e) => {
      const value = e.target.value.trim();
      setSearchText(value);
      setNextCursor("MA==");
      setAccumulatedDoctors([]);

      debouncedSearch(value, handleFetchDoctors);
    },
    [handleFetchDoctors]
  );

  const handleFocus = () => {
    document.querySelector(".search-container")?.classList.add("focused");
    svgRef?.current?.classList.add("focused");
  };

  const handleBlur = () => {
    document.querySelector(".search-container")?.classList.remove("focused");
    svgRef?.current?.classList.remove("focused");
  };

  const handleTabClick = (index: any) => {
    setFilterPatientDropdown(false);
    setActiveTab(index);
  };

  const handleLoadMoreDoctors = useCallback(
    () => handleFetchDoctors(nextCursor, searchText),
    [handleFetchDoctors, nextCursor, searchText]
  );

  // initial doctor fetch when cursor is at 0
  // set selected doctor at 0th index after fetch
  useEffect(() => {
    handleFetchDoctors();
  }, [handleFetchDoctors]);

  useEffect(() => {
    if (procedures) {
      setSelectedItem({
        procedures_name: procedures?.[0]?.procedure_name,
        form_name: procedures?.[0]?.form_name,
        patient_info: procedures?.[0]?.patient_details,
        doctor_id: procedures?.[0]?.doctor_id,
      });
    }
  }, [procedures]);

  useEffect(() => {
    const handleClick = (event: any) => {
      closeDropdown(event);
      // closeDropdown_filter();
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    if (tabContentRef.current) {
      tabContentRef.current.scrollTop = 0;
    }
  }, [activeTab]);

  return (
    <>
      <div className="prom__doctor_container">
        {isLoadingDoctors && <LoadingProm />}
        <Layout className="prom__doctor_layout_container">
          <Layout className="left_layout">
            <Title className="title">Doctors</Title>
            <div className="search-container">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <mask
                  id="mask0_3030_1174"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="25"
                  height="24"
                >
                  <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_3030_1174)">
                  <path
                    d="M20.1 20.4348L13.8 14.6826C13.3 15.0478 12.725 15.337 12.075 15.55C11.425 15.763 10.7333 15.8696 10 15.8696C8.18333 15.8696 6.64583 15.2951 5.3875 14.1462C4.12917 12.9973 3.5 11.5935 3.5 9.93478C3.5 8.27609 4.12917 6.87228 5.3875 5.72337C6.64583 4.57446 8.18333 4 10 4C11.8167 4 13.3542 4.57446 14.6125 5.72337C15.8708 6.87228 16.5 8.27609 16.5 9.93478C16.5 10.6043 16.3833 11.2359 16.15 11.8293C15.9167 12.4228 15.6 12.9478 15.2 13.4043L21.5 19.1565L20.1 20.4348ZM10 14.0435C11.25 14.0435 12.3125 13.644 13.1875 12.8451C14.0625 12.0462 14.5 11.0761 14.5 9.93478C14.5 8.79348 14.0625 7.82337 13.1875 7.02446C12.3125 6.22554 11.25 5.82609 10 5.82609C8.75 5.82609 7.6875 6.22554 6.8125 7.02446C5.9375 7.82337 5.5 8.79348 5.5 9.93478C5.5 11.0761 5.9375 12.0462 6.8125 12.8451C7.6875 13.644 8.75 14.0435 10 14.0435Z"
                    fill="#B8C8D7"
                  />
                </g>
              </svg>
              <input
                placeholder="Search"
                onChange={handleSearchChange}
                className="search_input"
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
            </div>

            <div className="doctor_card_container" id="scrollableDiv">
              {/* <VirtualizedDoctors initialDoctors={doctors} /> */}

              <List
                dataSource={accumulatedDoctors}
                renderItem={(item: any) => {
                  return (
                    <div
                      key={item?.doctor_id}
                      className={`doctor_card ${
                        item?.doctor_id === selectedDoctor?.doctor_id
                          ? "active"
                          : ""
                      }`}
                      onClick={() => handleDoctorClick(item)}
                    >
                      <div className="name_image_display">
                        <img
                          src={item?.doctor_profile_pic}
                          alt={item?.doctor_name}
                          className="doctor_img"
                        />

                        <div>
                          <Title className="doctor_name" level={4}>
                            {capitalizeFirstLetter(item?.doctor_name)}
                          </Title>

                          <Text className="doctor_specialty">
                            {capitalizeFirstLetter(
                              item?.doctor_specialities?.[0]
                            )}
                          </Text>
                        </div>
                      </div>

                      <div className="arrow_svg">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="33"
                          viewBox="0 0 32 33"
                          fill="none"
                        >
                          <mask
                            id="mask0_2635_800"
                            maskUnits="userSpaceOnUse"
                            x="0"
                            y="0"
                            width="32"
                            height="33"
                          >
                            <rect
                              y="0.5"
                              width="32"
                              height="32"
                              fill="#D9D9D9"
                            />
                          </mask>
                          <g mask="url(#mask0_2635_800)">
                            <path
                              d="M16.7998 16.5L10.6665 10.3667L12.5332 8.5L20.5332 16.5L12.5332 24.5L10.6665 22.6333L16.7998 16.5Z"
                              fill="#003878"
                            />
                          </g>
                        </svg>
                      </div>
                    </div>
                  );
                }}
              />

              {hasMoreDoctors && accumulatedDoctors?.length > 0 && (
                <Button
                  onClick={handleLoadMoreDoctors}
                  loading={isLoadingDoctors}
                  type="primary"
                  style={{ width: "max-content", alignSelf: "center" }}
                >
                  Load More
                </Button>
              )}
            </div>
          </Layout>

          <div className="right_layout">
            {selectedDoctor ? (
              <div className="right_layout_sub">
                <div className="doctor_container">
                  <section className="selected_doctor">
                    <div className="selected_doctor_profile">
                      <img
                        src={selectedDoctor?.doctor_profile_pic}
                        alt={selectedDoctor?.name}
                        className="doc_profile_img"
                      />
                      <div className="doc_info">
                        <Title className="doctor_profile_title">
                          {capitalizeFirstLetter(selectedDoctor?.doctor_name)}
                        </Title>
                        <Text className="doctor_profile_specialty">
                          {capitalizeFirstLetter(
                            selectedDoctor?.doctor_specialities?.[0]
                          )}
                        </Text>
                      </div>
                    </div>

                    <section className="dropdown_container">
                      <div
                        className="dropdown_sub"
                        ref={dropdownRef}
                        onClick={toggleDropdown}
                      >
                        <div className="dropdown_display">
                          <div className="dropbtn">
                            {selectedItem?.procedures_name
                              ? capitalizeFirstLetter(
                                  selectedItem?.procedures_name
                                )
                              : "Dropdown"}
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="27"
                            height="27"
                            viewBox="0 0 27 27"
                            fill="none"
                          >
                            <mask
                              id="mask0_277_470"
                              maskUnits="userSpaceOnUse"
                              x="0"
                              y="0"
                              width="27"
                              height="27"
                            >
                              <rect
                                x="27"
                                width="27"
                                height="27"
                                transform="rotate(90 27 0)"
                                fill="#D9D9D9"
                              />
                            </mask>
                            <g mask="url(#mask0_277_470)">
                              <path
                                d="M13.5 14.175L18.675 9L20.25 10.575L13.5 17.325L6.75 10.575L8.325 9L13.5 14.175Z"
                                fill="#003878"
                              />
                            </g>
                          </svg>
                        </div>
                        <div
                          id="myDropdown"
                          className={
                            dropdownOpen
                              ? "dropdown-content show"
                              : "dropdown-content"
                          }
                        >
                          {dropdown?.map((item: any) => (
                            <>
                              {selectedDoctor?.doctor_id ===
                                item?.doctor_id && (
                                <div
                                  className="procedure_name"
                                  key={item?.doctor_id}
                                  onClick={(e: any) =>
                                    handleDropdownClick(e, item)
                                  }
                                >
                                  {capitalizeFirstLetter(item?.procedure_name)}
                                </div>
                              )}
                            </>
                          ))}
                        </div>
                      </div>
                      <div className="dropdown_sub">
                        {selectedItem?.form_name
                          ? capitalizeFirstLetter(selectedItem?.form_name)
                          : ""}
                      </div>
                    </section>
                  </section>
                </div>

                <div className="bottom_doctor_container">
                  <section className="tab_container">
                    <div className="tab-buttons">
                      {tabs.map((tab, index) => (
                        <div
                          key={index}
                          className={
                            tab.label === activeTab
                              ? "tab-button active"
                              : "tab-button"
                          }
                          onClick={() => handleTabClick(tab.label)}
                        >
                          {tab.label}
                        </div>
                      ))}
                    </div>
                    {selectedItem && (
                      <>
                        <div ref={tabContentRef} className="tab-content">
                          {tabs.find((tab) => tab.label === activeTab)?.content}
                        </div>
                      </>
                    )}
                  </section>
                </div>
              </div>
            ) : (
              <div className="no_data">No data</div>
            )}
          </div>
        </Layout>
      </div>
    </>
  );
}

export default PromDoctor;
