import React from "react";
import ValidationComponent from "./ValidationComponent";
class TextFormatterComponent extends React.Component {
  constructor(props) {
    super(props);
    this.changeValue = this.changeValue.bind(this);
  }

  changeValue = (event) => {
    this.props.emitTextboxValue({
      name: event.target.name,
      value: event.target.value,
    });
  };

  setErrorStatus = (errorStatus) => {
    this.props.emitErrorStatus(errorStatus);
  };

  checkForLabelValue(str) {
    if (!str || !str.trim()) {
      return false;
    }
    return true;
  }

  render() {
    // console.log('-----------------------------------textformatter---------------------------------');
    const {
      type = "textbox",
      name,
      placeholder,
      label,
      size,
      value,
      pattern,
      offset,
      rowType,
      showType,
    } = this.props;
    return (
      <React.Fragment>
        <div
          className={`ant-col ant-col-${size * 2} ${
            showType === "table" ? "p-0" : ""
          }`}
          style={{
            padding: rowType === "row" ? " " : "",
            margin: rowType === "row" ? " " : "",
          }}
        >
          <div className="form-group mb-0">
            <label
              style={{ display: this.checkForLabelValue(label) ? "" : "none" }}
            >
              <small>
                {" "}
                <strong> {label}</strong>{" "}
              </small>
            </label>
            <input
              className="form-control"
              id={name}
              pattern={pattern}
              name={name}
              type={type}
              value={value}
              placeholder={placeholder}
              onChange={this.changeValue}
            />
            {this.props.validation && (
              <ValidationComponent
                value={value}
                checkForm={this.props.checkForm}
                isDirty={this.props.isDirty}
                validation={this.props.validation}
                emitErrorStatus={this.setErrorStatus.bind(this)}
              />
            )}
          </div>
        </div>
        {offset && <div className={offset}> </div>}
      </React.Fragment>
    );
  }
}

export default TextFormatterComponent;
