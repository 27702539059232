import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  PROM_GET_ALL_DOCTOR,
  PROM_GET_ALL_FORM_LIST,
  PROM_GET_ALL_PATIENT,
  PROM_GET_ALL_PROCEDURES,
  PROM_GET_PATIENT_BY_IDS,
  PROM_MATRICS,
  PROM_MATRICS_DOCTOR,
  PROM_MATRICS_HOSPITAL,
  PROM_MATRICS_PATIENT,
  PROM_POST_FORM,
} from "../../shared/routes/doctor.routes.constants";

import { Client } from "../../shared/Utils/api.client-prom";

const config = {
  name: "prom",
};

export const fetchAllDoctor: any = createAsyncThunk(
  `${config.name}/fetchAllDoctor`,
  async (params: any) => {
    return await Client.post(PROM_GET_ALL_DOCTOR, params);
  }
);

export const fetchAllProcedures: any = createAsyncThunk(
  `${config.name}/fetchAllProcedures`,
  async (params: any) => {
    return await Client.get(PROM_GET_ALL_PROCEDURES, params);
  }
);

export const fetchAllFormList: any = createAsyncThunk(
  `${config.name}/fetchAllFormList`,
  async (params: any) => {
    return await Client.get(PROM_GET_ALL_FORM_LIST, params);
  }
);

export const postPromForm: any = createAsyncThunk(
  `${config.name}/postPromForm`,
  async (params: any) => {
    return await Client.post(PROM_POST_FORM, params);
  }
);

export const fetchAllPatientList: any = createAsyncThunk(
  `${config.name}/fetchAllPatientList`,
  async (params: any) => {
    return await Client.post(PROM_GET_ALL_PATIENT, params);
  }
);

export const fetchPatientByIds: any = createAsyncThunk(
  `${config.name}/fetchPatientByIds`,
  async (params: any) => {
    return await Client.post(PROM_GET_PATIENT_BY_IDS, params);
  }
);

export const promMatrics: any = createAsyncThunk(
  `${config.name}/promMatrics`,
  async (params: any) => {
    return await Client.post(PROM_MATRICS, params);
  }
);

export const promMatricsHospital: any = createAsyncThunk(
  `${config.name}/promMatricsHospital`,
  async (params: any) => {
    return await Client.post(PROM_MATRICS_HOSPITAL, params);
  }
);

export const promMatricsDoctor: any = createAsyncThunk(
  `${config.name}/promMatricsDoctor`,
  async (params: any) => {
    return await Client.post(PROM_MATRICS_DOCTOR, params);
  }
);

export const promMatricsPatient: any = createAsyncThunk(
  `${config.name}/promMatricsPatient`,
  async (params: any) => {
    return await Client.post(PROM_MATRICS_PATIENT, params);
  }
);

export const promSlice = createSlice({
  name: config.name,
  initialState: {
    loading: false,
    allDoctorList: [] as any,
    allProceduresList: [] as any,
    allFormsList: [] as any,
    allPatientList: [] as any,
    patientDetails: [] as any,
    matricsData: [] as any,
    matricsHospital: [] as any,
    matricsDoctor: [] as any,
    matricsPatient: [] as any,
    error: "" as string,
    response: {} as any,
  },
  reducers: {
    reset: (state) => {
      state.allDoctorList = [];
      state.loading = false;
      state.error = "";
    },
  },
  extraReducers(builder) {
    builder
      // Doctor  List
      .addCase(fetchAllDoctor.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchAllDoctor.fulfilled, (state, action) => {
        state.loading = false;
        state.allDoctorList = action.payload?.data;
      })
      .addCase(fetchAllDoctor.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      //GET ALL PROCEDURES
      .addCase(fetchAllProcedures.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchAllProcedures.fulfilled, (state, action) => {
        state.loading = false;
        state.allProceduresList = action.payload?.data;
      })
      .addCase(fetchAllProcedures.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      //GET ALL FORM LIST
      .addCase(fetchAllFormList.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchAllFormList.fulfilled, (state, action) => {
        state.loading = false;
        state.allFormsList = action.payload?.data;
      })
      .addCase(fetchAllFormList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // POST PROM FORM
      .addCase(postPromForm.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(postPromForm.fulfilled, (state, action) => {
        state.loading = false;
        state.response = action.payload;
      })
      .addCase(postPromForm.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      //GET PATIENT LIST
      .addCase(fetchAllPatientList.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchAllPatientList.fulfilled, (state, action) => {
        state.loading = false;
        state.allPatientList = action?.payload?.data;
      })
      .addCase(fetchAllPatientList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      //GET PATIENT BY IDS
      .addCase(fetchPatientByIds.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchPatientByIds.fulfilled, (state, action) => {
        state.loading = false;
        state.patientDetails = action?.payload?.data;
      })
      .addCase(fetchPatientByIds.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // PROM MATRICS
      .addCase(promMatrics.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(promMatrics.fulfilled, (state, action) => {
        state.loading = false;
        state.matricsData = action.payload.data;
      })
      .addCase(promMatrics.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // PROM MATRICS HOSPITAL
      .addCase(promMatricsHospital.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(promMatricsHospital.fulfilled, (state, action) => {
        state.loading = false;
        state.matricsHospital = action.payload.data;
      })
      .addCase(promMatricsHospital.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // PROM MATRICS DOCTOR
      .addCase(promMatricsDoctor.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(promMatricsDoctor.fulfilled, (state, action) => {
        state.loading = false;
        state.matricsDoctor = action.payload.data;
      })
      .addCase(promMatricsDoctor.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // PROM MATRICS PATIENT
      .addCase(promMatricsPatient.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(promMatricsPatient.fulfilled, (state, action) => {
        state.loading = false;
        state.matricsPatient = action.payload.data;
      })
      .addCase(promMatricsPatient.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { reset } = promSlice.actions;

export default promSlice.reducer;
