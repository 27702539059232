import React from "react";
import { Tag, Badge, Image } from "antd";

type TabTitleProps = {
  title: string;
  unread?: string;
  img?: string;
  badge?: string;
  subtext?: string;
};

function TabTitle({ title, unread, img, subtext, badge }: TabTitleProps) {
  return (
    <div className={unread ? "tab-w-marker" : img ? "tab-w-img" : ""}>
      {img ? (
        <div className="image-tab">
          {img == "image" && (
            <Image
              src={require("../../../assets/images/img.png").default}
              preview={false}
            />
          )}
          {img == "pdf" && (
            <Image
              src={require("../../../assets/images/pdf.png").default}
              preview={false}
            />
          )}
          <small className="text-secondary text-center">{title}</small>
          <small className="text-secondary text-center">{subtext}</small>
          {badge && <Badge className={badge.toLowerCase()} count={badge} />}
        </div>
      ) : (
        <>
          {title}
          {unread && (
            <Tag className="small-tag" color="#e1454e">
              {unread}
            </Tag>
          )}
        </>
      )}
    </div>
  );
}
export default TabTitle;
